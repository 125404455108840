import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {redirectBackAction, redirectBackInitAction} from '../../bdb-actions/backLinkActions';
import './backLink.css';


const BackLink = ({url, callback=()=>{}}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const starComponent = () => {
            dispatch(redirectBackInitAction(false));
        }
        starComponent();
    }, []);

    const history = useHistory();

    const comeBack = () => {
        dispatch(redirectBackAction(url));
        if ( url === "-1"){
            history.goBack();
        } else {
            history.push(url);
        }
        callback();
    }

    return ( 
        <Fragment>
            <div className="d-flex justify-content-start align-items-center">
                    <div className="back-link-arrow" onClick={() => {comeBack()}}></div>
                    <div className="back-link-font" onClick={() => {comeBack()}}>Volver</div>
            </div>
        </Fragment>
     );
}
 
export default BackLink;
