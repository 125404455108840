import axios from 'axios';

export function getDocumentsDisbursement(customerInfo){
    const headers = { 
        'Content-Type' : 'text/plain'
    };
    return axios.get(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}get-documents-disbursement/id/${customerInfo.custidenttype}-${customerInfo.custidentnum}`,  { params: { creditProject: customerInfo.creditProject, controlNumber: customerInfo.controlNumber, userRole:customerInfo.userRole},headers: headers});
}

export function getDocumentsByRequest(customerInfo){
    const headers = { 
        'Content-Type' : 'text/plain',
        'X-CustIdentNum' : customerInfo.custidentnum,
        'X-CustIdentType': customerInfo.custdoctype
        
    };
    return axios.get(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}get-documents-by-request/${customerInfo.controlNumber}/C?page=1&userRole=${customerInfo.userRole}`,  { headers: headers});
}

export function getDocumentVews(cloudPath){ 
    const headers = { 
        'Content-Type' : 'text/plain',       
    };
    return axios.get(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}get-disbursement-document-stream/${cloudPath}`, {headers: headers});
}

export function transferDisbursementDocuments(body){        
    const headers = { 
        'Content-Type' : 'text/plain',       
    };
    return axios.post(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}transfer-disbursement-document`,body, {headers: headers});
}

export function updateDocument(customerInfo, body){
    const headers = { 
        'Content-Type' : 'text/plain',
        'X-Name' : customerInfo.name,
        'X-CustIdentType' : customerInfo.custDocType,
        'X-CustIdentNum' : customerInfo.custIdentNum

    };
    return axios.patch(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}update-document/documentHandle`, body, {headers: headers});
}

export function deleteDisbursementDocuments(customerInfo){  
    const headers = { 
        'Content-Type' : 'text/plain',       
    };
    return axios.delete(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}delete-disbursement-documents`,  { params: { creditProject: customerInfo.creditProject, controlNumber: customerInfo.numberControl}}, {headers: headers});
}