import React, { Fragment } from 'react'
import { idmAnalystProdRol, documentTrainDataRol, OCRAnalizeViewRol, whiteListLargeFiles } from '../../../constants'
import BackLink from '../../bdb-components/BackLinkComponent/BackLink'
import ProductivityForm from '../../bdb-components/ProductivityFormComponent/ProductivityForm'
import { getdecrypt } from '../../bdb-services/GeneralService/globalService'
import ProductivitySumarize from '../../bdb-components/ProductivitySumarizeComponent/ProductivitySumarize'
import { useHistory } from "react-router-dom";
import './styles.scss';

const 
ReportProductivity = () => {
  const history = useHistory();

  const enableBack = () => {
    return idmAnalystProdRol.indexOf( getdecrypt('rolUser') ) === -1; 
  }
  const active_space = () => {
    let rolUser = getdecrypt('rolUser');
    return(rolUser === idmAnalystProdRol[1])
  }
  const getUploadLrgFiles = () => {
    history.push('/upload-lrg-files');
  }
  const getTrainDocsOCR = () => {
    history.push('/train-docs-ocr');
  }
  const getOCRAnalize = () => {
    history.push('/analyze-ocr-docs');
  }


  return (
    <Fragment>
        { 
          enableBack() &&
            <BackLink url="-1"/>
        }
        {
          active_space() &&
          <div className='empty_btn'></div>
        }
        <div className='productivity-buttons'>
          {OCRAnalizeViewRol.indexOf(getdecrypt('userLogin')) !== -1 ?
          <div className={'btn-trn-docs-geprod'} onClick={ ()=>getOCRAnalize()}>
              <span className='dly-text'>
                  OCR Analyze
              </span>
          </div>:
          null
          }
          {documentTrainDataRol.indexOf(getdecrypt('userLogin')) !== -1 ?
          <div className={'btn-trn-docs-geprod'} onClick={ ()=>getTrainDocsOCR()}>
              <span className='dly-text'>
                  Clasific. Doc
              </span>
          </div>:
          null
          }
          {whiteListLargeFiles.indexOf(getdecrypt('userLogin')) !== -1 ?
          <div className={'btn-upload-lrg-files-geprod'} onClick={ ()=>getUploadLrgFiles()}>
              <span className='dly-text'>
                  Framework
              </span>
          </div>:
          null
          }
          </div>
          <div className='d-flex'>
              <ProductivityForm />
              <ProductivitySumarize />
        </div>
        
    </Fragment>
  )
}

export default ReportProductivity