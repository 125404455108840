import React from 'react'
import Modal from "react-bootstrap/Modal";
import '../../../assets/styles/ComponentsStyles/modals.scss'

export const ConfirmationModal = ( { show = false, bodyContent = null, handleButton }) => {
    return (
        <Modal show={show} centered contentClassName="modal-override-content-clas">
                
                <Modal.Header bsPrefix="header-modal">
                    <span className="icon-warning"></span>
                </Modal.Header>                
                <Modal.Body bsPrefix="body-modal">
                    <p className="tittle-modal">¿Estás seguro de continuar?</p>
                    <p className="sub-tittle-modal">Por favor verifica los siguientes datos.</p>
                    {
                        bodyContent
                    }
                </Modal.Body>
                <Modal.Footer bsPrefix="footer-modal">
                    <button 
                        className="footer-button-modal"
                        id="Cancel"
                        onClick={ handleButton }>Cancelar</button>
                    <button 
                        className="footer-button-modal"
                        id="Accept"
                        onClick={ handleButton }>Aceptar</button>
                </Modal.Footer>

        </Modal>

    )
}
