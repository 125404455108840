import { 
    START_DYNAMIC_TABLE_COMPONENT,
    UPDATE_DYNAMIC_TABLE_COMPONENT,
    UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT,
    UPDATE_FILTER_TABLE_COMPONENT,
    SET_EDIT_ALERT_TABLE_COMPONENT,
    SET_ADD_ALERT_TABLE_COMPONENT,
    SET_EMPTY_ALERT_TABLE_COMPONENT,
    DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT
    
} from '../bdb-types/index';

export function startDynamicTable(data) {
    return async (dispatch) => {
        dispatch( startDynamicTableAction(data));
    }
}

const startDynamicTableAction = status => ({
    type: START_DYNAMIC_TABLE_COMPONENT,
    payload: status
});

export function updateDynamicTable(data) {
    return async (dispatch) => {
        dispatch( updateDynamicTableAction(null));
        dispatch( updateDynamicTableAction(data));
    }
}

const updateDynamicTableAction = status => ({
    type: UPDATE_DYNAMIC_TABLE_COMPONENT,
    payload: status
});

export function updateIndexDynamicTable(data) {
    return async (dispatch) => {
        dispatch( updateIndexDynamicTableAction(null));
        dispatch( updateIndexDynamicTableAction(data));
    }
}

const updateIndexDynamicTableAction = status => ({
    type: UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT,
    payload: status
});

export function updateFilterDynamicTable(data) {
    return async (dispatch) => {
        dispatch( updateFilterDynamicTableAction(data));
    }
}

const updateFilterDynamicTableAction = status => ({
    type: UPDATE_FILTER_TABLE_COMPONENT,
    payload: status
});

export function setEditTableComponent() {
    return async (dispatch) => {
        dispatch(setEditTableComponentAction(true));
        setTimeout(() => dispatch(setEditTableComponentAction(false)), 4000);
    }
}

const setEditTableComponentAction = status => ({
    type: SET_EDIT_ALERT_TABLE_COMPONENT,
    payload: status
});

export function setAddTableComponent() {
    return async (dispatch) => {
        dispatch(setAddTableComponentAction(true));
        setTimeout(() => dispatch(setAddTableComponentAction(false)), 4000);
    }
}

const setAddTableComponentAction = status => ({
    type: SET_ADD_ALERT_TABLE_COMPONENT,
    payload: status
});

export function setEmtyTableComponent() {
    return async (dispatch) => {
        dispatch(setEmtyTableComponentAction(true));
        setTimeout(() => dispatch(setEmtyTableComponentAction(false)), 4000);
    }
}

const setEmtyTableComponentAction = status => ({
    type: SET_EMPTY_ALERT_TABLE_COMPONENT,
    payload: status
});

export function disableButtonTableComponent() {
    return async (dispatch) => {
        dispatch(disableButtonTableComponentAction(false));
    }
}

const disableButtonTableComponentAction = status => ({
    type: DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT,
    payload: status
});

