import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../SelectListComponent/SelectList.css';
import { setOptionSelectedComponent } from '../../bdb-actions/selectActions';
import { subProcesses } from '../../../constants';
import { decryptUsingAES256 } from '../../bdb-services/GeneralService/globalService';

const SelectList = ({options, enableSubmenu, handleButton}) => {

    const optionSelectedReducer = useSelector(state => state.select.option)
    const [listOptions, setListOptions] = useState(options); 
    const [listOptionsTemp, setListOptionsTemp] = useState(options);
    const [showOptions, setShowOptions] = useState(false);
    const [showOptions2, setShowOptions2] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!(!!optionSelectedReducer) || !listOptions)return
        const isValidOption = listOptions.filter((opt)=>{
            if(opt.label === optionSelectedReducer.label)return true
            return false
        })
        console.log(Boolean(isValidOption),isValidOption)
        if(isValidOption.length === 0){
            dispatch(setOptionSelectedComponent(null));
        }
    },[])

    useEffect(() => {
        setListOptions(options);
    }, [setListOptions, options])

    useEffect(() => {
        setListOptionsTemp(options);
    }, [setListOptionsTemp, options])

    const filterOptions = filter => {
        setShowOptions(true);
        if (filter === '' ||   !!filter) {
            setListOptions(listOptionsTemp);
        } else {
            const listOptionsFilter = listOptions.filter( options =>
                options['label'].toLowerCase().indexOf(filter.toLowerCase()) > -1
            );
            setListOptions(listOptionsFilter);
        }
        
    }
    
    const selectOptions = option => {
        setShowOptions(false);
        setShowOptions2(false);
        dispatch(setOptionSelectedComponent( {...option, indexName: getIndexName(option)} ));
    }

    const getOptionStyle = option => {
        return optionSelectedReducer ? 
        optionSelectedReducer.id === option ? 'option-style option-select-color' : 'option-style' : 'option-style';
    }

    function getButtonStyle() {
        const display = optionSelectedReducer === null ? 'disable' : 'enable';
        return `button-${display} text-button-${display}`;
    }

    function buttonDisable() {
        return optionSelectedReducer === null;
    }

    function seletOption() {
        document.getElementById('selectDiv').value = '';
        handleButton();
    }

    function enableDisbursment(optionId){
        return (optionId === 'disbursement' && enableSubmenu);
    }
    function getIndexName(option){
        const documentNumberAdviserDecrypt = decodeURIComponent(escape(window.atob( sessionStorage.getItem('documentNumberAdviser') )));
        let secondaryKeysConfig = JSON.parse( decryptUsingAES256 (sessionStorage.getItem('secondaryKeysConfig'), documentNumberAdviserDecrypt, (decodeURIComponent(escape(window.atob( process.env.REACT_APP_AES_KEY ))))) );
        secondaryKeysConfig = secondaryKeysConfig[option.initPriorization];
        let indexName = ''
        if( secondaryKeysConfig['secondaryIndex'] === undefined ) {
            Object.entries(secondaryKeysConfig).forEach(([key, config]) => {
                if (config.active) {
                    indexName = config.secondaryIndex;
                }
              });
        } else {
            indexName = secondaryKeysConfig.secondaryIndex;
        }
        return indexName;
    }

    return (
        <div className="select-container">
            <div className="position-absolute">
                <label className="label-style">Selecciona el proceso</label>
                <div className="d-flex">
                    <input className="select-style" placeholder="Selecciona" id="selectDiv"
                    onChange={(e) => {filterOptions(e.target.value)}} 
                    value={optionSelectedReducer && optionSelectedReducer.label ? optionSelectedReducer.label : ''}/>
                    <button className={getButtonStyle()} disabled={buttonDisable()} 
                       data-cy='manager-case' onClick={() => seletOption()}>Gestionar caso</button>
                </div>
                <div className="simple-div">
                    <span className="icon-arrow-select" style={showOptions ? {"transform": "rotate(180deg)"} : null}
                       data-cy='btn-list-automatic' onClick={() => {setShowOptions(!showOptions); setShowOptions2(false)} }></span>
                </div>
                { 
                    showOptions ?
                    <div className="options-style">
                        { listOptions.map((option, index) => (
                            subProcesses.some(element => element.id === option.id) ? null :
                            <div key={index} className={getOptionStyle(option.id)} style={{width: '296px'}}
                            data-cy={`automatic-option-${index}`} onClick={() => { if (!enableDisbursment(option.id)) {selectOptions(option);} }}
                            onMouseOver={() => { enableDisbursment(option.id) ? setShowOptions2(true) : setShowOptions2(false); }}>  
                                <span className="span-option-style">{option.label}</span>
                                    { enableDisbursment(option.id) ?
                                        <spam className="icon-arrow-select-submenu" style={{ "transform": "rotate(-90deg)"}}></spam>
                                        : null 
                                    }
                            </div>
                        ))}
                    </div> : null
                }
                {
                    showOptions2 ?
                        <div className="options-style-second-level">
                        {
                            subProcesses.map((option, index) =>(
                                <div key={index} className={getOptionStyle(option.id)}
                                    onClick={() => { selectOptions(option);}}>
                                    <span className="span-option-style">{option.label}</span>
                                </div>
                            ))
                        }
                        </div> 
                    : null
                }
            </div>
        </div>
     );
}
export default SelectList;
