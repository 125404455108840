import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { caseToPrioritize, caseToReassigned } from '../../bdb-actions/casesActions';
import '../../../assets/styles/tableBodyStyle.css';
import { statusCaseUnit } from '../../../constants';
import { updateLoadingAnalist } from '../../bdb-actions/analystActions';
import { getdecrypt} from '../../bdb-services/GeneralService/globalService';
import { getDocumentsDisbursement } from '../../bdb-services/GeneralService/analystDisbursementService';
import Swal from 'sweetalert2';
import Loading from '../../bdb-components/LoadingComponent/Loading';
const enpointSelf = process.env.REACT_APP_SELFASSIGNMENT;


const Case = ({row}) => {
    const { document, requestNumber, dateAssignment, hourAssignment, 
        reqStatus, reqUser, process, libranzaCode, product, reasonReturn } = row;
    
    const history = useHistory();
    const loading = useSelector(state => state.analyst.loading);
    const dispatch = useDispatch();

    const casePrioritize = selectedCase => dispatch( caseToPrioritize(selectedCase));

    const caseReassigned = selectedCase => dispatch( caseToReassigned(selectedCase));

    const redirectPrioritize = row => {
        casePrioritize(row);
        history.push(`/finantial-leader/prioritize/${row.requestNumber}`);
    }

    const redirectReassignate = row => {
        caseReassigned(row);
        history.push(`/finantial-leader/reasignate/${row.requestNumber}`);
    }

    const redirectView = async () => {
        dispatch(updateLoadingAnalist(true));
        const customerInfo = {
          "custidentnum": row.document,
          "custidenttype": row.typeCustomer,
          "creditProject": row.creditProject,
          "controlNumber": row.numberControl,
          "userRole": getdecrypt('rolUser')
        }
        sessionStorage.setItem('typeCustomer', row.typeCustomer);
        sessionStorage.setItem('numberCustomer', row.document);
    
        await getDocumentsDisbursement(customerInfo).catch(
          err => {
            console.log('error', err);
            return { status: 500 }
          }
        )
          .then(
            data => {
              dispatch(updateLoadingAnalist(false));
              if (data.status == 200) {
                const documentDisbursement = data.data;
                sessionStorage.setItem('documentDisbursement', JSON.stringify(documentDisbursement));
                window.open(`${enpointSelf}documentsDC`);
              }
            }
          )
      }

    const showDate = (status, time) => {
        if (status !== 'PENDING' && !!row.dischargeDate) {
            const dateFormat = new Date(row.dischargeDate);
            const localDate = dateFormat.toLocaleDateString();
            const splitFormat = localDate.toString().split('/');
            const year = splitFormat[ 2 ] ? splitFormat[ 2 ] : undefined;
            const month = splitFormat[ 1 ] ? ( splitFormat[ 1 ].length > 1 ? splitFormat[ 1 ] : '0' + splitFormat[ 1 ] ) : undefined;
            const day = splitFormat[ 0 ] ? ( splitFormat[ 0 ].length > 1 ? splitFormat[ 0 ] : '0' + splitFormat[ 0 ] ) : undefined;
            const formatDate = year && month && day ? `${ year }/${ month }/${ day }` : '-';
            return time ? showTime(dateFormat) : formatDate;
        } else {
            return '—';
        }
    }

    const showTime =  date => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const showModal = (reasonReturn)=>{
        Swal.fire({
          title: 'Causal de devolución',
          iconHtml: "<div class='icon-warning'></div>",
          html:`<div id="swal-content-reason" class="custom-swal-content">${reasonReturn}</div>`,
          customClass:{
            popup:"swal-wide",
            title:"custom-swal-header",
            icon:"icon-warning no-border"
          },
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#002c76",
          showCancelButton: false,
          showConfirmButton: true,
          showCloseButton: false
        })
    }    

    return ( 
        <tr>
            { loading ? <Loading /> : null}
            <td className="td-component unset-align">{requestNumber}</td>
            <td className="td-component unset-align">
                {
                    document.startsWith('N/A') ? 'N/A' : document
                }
            </td>
            {  
                global.isGroupDisbursement() ? <td className="td-component unset-align">{global.translateCopies(product)}</td> : null 
            }
            <td className="td-component unset-align">{process}</td>
            <td className="td-component unset-align">{dateAssignment}</td>
            <td className="td-component unset-align">{hourAssignment}</td>
            <td className="td-component unset-align">{reqStatus === 'PENDING' ? '—' : reqUser}</td>
            <td className="td-component unset-align">{showDate(reqStatus, false)}</td>
            <td className="td-component unset-align">{showDate(reqStatus, true)}</td> 
            {  global.isLibrances() ? <td className="td-component unset-align">{libranzaCode}</td> : null }
            <td className="td-component unset-align">
            {
                (process === 'Orden de Desembolsos' && reqStatus === 'REJECT') ? 
                  <>
                    <div data-cy="return-view-button" className='disbursement-returned' onClick={() => showModal(reasonReturn)}>
                      <div className='disbursement-returned-icon'></div>
                      <span>{statusCaseUnit[reqStatus]}</span>
                    </div>
                  </>
                :
                    statusCaseUnit[reqStatus]
            }
            </td> 
            <td className="td-component unset-align">
            { reqStatus === 'PENDING' ?
                (
                <button 
                type="button"
                className="btn button-priority"
                onClick={() => redirectPrioritize(row)}
                >Priorizar </button> 
                ) : null 
            }
            { reqStatus === 'OPEN' || reqStatus === 'PAUSED' ? 
                ( 
                    <button 
                    type="button"
                    className="btn button-reassign"
                    onClick={() => redirectReassignate(row)}
                    >Reasignar</button>
                ) : null
            }
            { process === 'Orden de Desembolsos' && (reqStatus === 'APPROVED' || reqStatus === 'REJECT') ? 
                ( 
                    <button 
                    type="button"
                    className="btn button-view"
                    onClick={() => redirectView(row)}
                    >Ver</button>
                ) : null
            }
            
            </td>
        </tr>
     );

}
export default Case;
