import { KARDEX_DATECHANGE, UPDATE_ACTIVE_EVENT_KARDEX, SET_PROCESS_LIST, CHANGE_LOADING_VISIBILITY, RESET_ACTIVE_MESSAGE_KARDEX, SET_AREA_LIST, SET_KARDEX_PROCESSES, SET_SELECT_ROWS_KARDEX, SET_MANAGEMENT_LIST_KARDEX,RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG } from "../bdb-types";
import { getKardexManagementByVerticalUtils, mapSelectedListToReducer } from "../bdb-helpers/utils";
import { getKardexCount, postKardexNews, putKardexNews, getKardexByManagementDate } from '../bdb-services/GeneralService/controlboardService';
import moment from "moment";
import { getdecrypt } from "../bdb-services/GeneralService/globalService";
import Swal from "sweetalert2";
import { excludedKardexAreas } from "../../constants";

export const setManagementList = (event) => ({
    type: SET_MANAGEMENT_LIST_KARDEX,
    payload:event

})
export const setKardexProcesses = (event) => ({
    type: SET_KARDEX_PROCESSES,
    payload: event
})
export const updateActiveEvent = (event) => ({
    type: UPDATE_ACTIVE_EVENT_KARDEX,
    payload: event
})

export const updateLoading = (state) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
})
export const resetActveEvent = (adminEditKardex) => ({
    type: RESET_ACTIVE_MESSAGE_KARDEX,
    payload: adminEditKardex
})
export const resetActveEventKeepMng = (event) => ({
    type: RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG,
    payload: event
})
export const setSelectRows = (event) => ({
    type: SET_SELECT_ROWS_KARDEX,
    payload: event
})

export const dateChangeEdit = (event) => ({
    type: KARDEX_DATECHANGE,
    payload: event
})

const setAreaList = (option) => ({
    type: SET_AREA_LIST,
    payload: option
})
const setProcessList = (option) => ({
    type: SET_PROCESS_LIST,
    payload: option
})


export const getManagementsAndProcesses = (vertical, management, areaData, cache) => {

    return async (dispatch, getState) => {

        const inputManagement = management ? `${encodeURIComponent(vertical.replace(/"/g, ''))}/${encodeURIComponent(management)}` : undefined;
        const _date = getState().kardex.date ? moment(getState().kardex.date).format("Y-MM-DD") : undefined;
        const processNew = `${_date}/` + (areaData ? `${inputManagement}/${encodeURIComponent(areaData)}` : `${inputManagement}`);
        if(!_date || !inputManagement){return;}
        dispatch(updateLoading(true));
        dispatch(setProcessList([]));
        const response = await getKardexByManagementDate(processNew).catch(err => {
            console.log('Error', err)
            dispatch(setAreaList([]));
            dispatch(setProcessList([]));
            dispatch(updateLoading(false));
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });
        if (response.status !== 200) {dispatch(updateLoading(false));return;}
        if (areaData === undefined) {
            let dataMapped = mapSelectedListToReducer(response.data.Items, "area", 26);
            dataMapped = dataMapped.filter(el => !excludedKardexAreas.includes(el.id))
            dispatch(setAreaList(dataMapped));

            const _management = JSON.parse(sessionStorage.getItem("currentManagement"));
            if (!!_management && cache) {

                dispatch(
                    updateActiveEvent({
                        ...getState().prodContBoard.activeEvent,
                        management: { id: 'management', option: _management }
                    })
                )
            }
        } else {
            const dataProcess = mapSelectedListToReducer(response.data.Items, "process", 26)
            dispatch(setProcessList(dataProcess));
        }
        dispatch(updateLoading(false));

    }
}

export function sessionManagement(inputData) {
    let temporalActiveEvent = inputData
    sessionStorage.setItem('currentManagement', JSON.stringify(temporalActiveEvent.management.option))
    sessionStorage.setItem('currentArea', JSON.stringify(temporalActiveEvent.area.option))
}

export function postInsertKardexNew(dataInsert) {
    return async (dispatch, getState) => {
        let response;
        const { activeEvent, kardexProcesses, date } = getState().kardex
        const vertical = !activeEvent.vertical.option? getdecrypt('vertical') : activeEvent.vertical.option
        const { management, area } = activeEvent

        const body = kardexProcesses.map((item) => (
            {
                "vertical": vertical,
                "management": management.option.id,
                "area": area.option.id,
                "process": item.id,
                "user": getdecrypt('userLogin'),
                "date": moment(date).format("YYYY-MM-DD"),
                "totalCount": Number(item.totalCount),
                "pendingInsideAns": Number(item.pendingInsideAns) ?? 0,
                "pendingOutsideAns": Number(item.pendingOutsideAns) ?? 0,
                "amount": Number(item.amount) ?? 0,
                "isMasive": Boolean(item.isMasive),
                "comments": item.comments,
                "managed": item.managed,
                "fte": item.fte,
                "approvedRegisters": item.approvedRegisters,
                "returnedRegisters": item.returnedRegisters,
                "isStored": dataInsert.includes(item.id)?true:false
            }
        ))
        dispatch(updateLoading(true));

        response = await postKardexNews(body).then((res) => {
            dispatch(getProcessesKardexCount())
            return { ...res, status: 200 }
        })
            .catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });

        let activeModal = {}
        if (response.status === 200) {
            activeModal = { icon: 'success', tittle: '¡Listo!', text: 'La novedad se registró exitosamente.' }
        }
        Swal.fire({
            icon: activeModal.icon,
            title: activeModal.tittle,
            html: activeModal.text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
        }).then(result => {
            dispatch(updateLoading(false));
            dispatch(setSelectRows([]));
            // dispatch(resetActveEventKeepMng())
        });

    }
}
export function putInsertKardexNew(dataInsert) {
    return async (dispatch, getState) => {
        let response;
        const { activeEvent, kardexProcesses, date } = getState().kardex
        const vertical = !activeEvent.vertical.option? getdecrypt('vertical') : activeEvent.vertical.option
        const { management, area } = activeEvent
        const processList = kardexProcesses.filter(el => dataInsert.includes(el.id))
        const body = processList.map((item) => (
            {
                "id": item.entry_id,
                "vertical": vertical,
                "management": management.option.id,
                "area": area.option.id,
                "process": item.id,
                "user": getdecrypt('userLogin'),
                "date": moment(date).format("YYYY-MM-DD"),
                "totalCount": Number(item.totalCount),
                "pendingInsideAns": Number(item.pendingInsideAns) ?? 0,
                "pendingOutsideAns": Number(item.pendingOutsideAns) ?? 0,
                "amount": Number(item.amount) ?? 0,
                "isMasive": Boolean(item.isMasive),
                "comments": item.comments,
                "managed": item.managed,
                "fte": item.fte,
                "approvedRegisters": item.approvedRegisters,
                "returnedRegisters": item.returnedRegisters,
                "isStored": dataInsert.includes(item.id)?true:false

            }
        ))
        dispatch(updateLoading(true));

        response = await putKardexNews(body).then((res) => {
            dispatch(getProcessesKardexCount())
            return { ...res, status: 200 }
        })
            .catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        let activeModal = {}
        if (response.status === 200) {
            activeModal = { icon: 'success', tittle: '¡Listo!', text: 'La novedad se registró exitosamente.' }
        }
        else {
            activeModal = { icon: 'error', tittle: '<div>¡Lo sentimos!</div>', text: 'La novedad no se pudo registrar,<br>  intenta de nuevo.' }
        }
        Swal.fire({
            icon: activeModal.icon,
            title: activeModal.tittle,
            html: activeModal.text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
        }).then(result => {
            dispatch(updateLoading(false));
            dispatch(setSelectRows([]));
            // dispatch(resetActveEventKeepMng())
        });



    }
}


export function getProcessesKardexCount() {
    return async (dispatch, getState) => {
        let response;

        const { activeEvent, processList, date } = getState().kardex
        const { management, area } = activeEvent
        const startDate = moment(date).format("YYYY-MM-DD")
        const endDate = moment(date).add(1,'days').format("YYYY-MM-DD")
        const body = {
            "management": management.option.id,
            "area": area.option.id,
            "startDate": `${startDate}T05:00:00`,
            "endDate": `${endDate}T04:59:59`
        }
        dispatch(updateLoading(true));
        response = await getKardexCount(body).then((res) => {
            return { ...res, status: 200 }
        })
            .catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        let processes = processList.map((el, index) => {
            const process = response.data.find(item => item.process === el.id) ?? {
                managed: 0,
            }
            const pendingInsideAns = process.isStored ? process.pendingInsideAns : '' 
            const pendingOutsideAns = process.isStored ? process.pendingOutsideAns : '' 
            const totalCount = Number(process.managed) + Number(pendingInsideAns) + Number(pendingOutsideAns)
            return {
                ...el,
                entry_id: process.id ?? null,
                managed: process.managed,
                pendingInsideAns: pendingInsideAns,
                pendingOutsideAns: pendingOutsideAns,
                approvedRegisters: process.approvedRegisters ?? 0,
                returnedRegisters: process.returnedRegisters ?? 0,
                amount: process.amount ?? 0,
                fte: process.fte ?? 0,
                isStored: !!process.id,
                totalCount: totalCount,
                comments: process.comments ?? ''
            }
        }
        )
        dispatch(setKardexProcesses(processes))

        dispatch(updateLoading(false));

    }
}
export function getManagementByVertical(date){
    return async(dispatch, getState) => {
        dispatch(updateLoading(true));
        const dataMapped = await  getKardexManagementByVerticalUtils (date)
        dispatch(updateLoading(false));
        dispatch(setManagementList(dataMapped))
    }
}