import { 
    ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT,
    START_DYNAMIC_MODAL_COMPONENT,
    FINALIZE_DYNAMIC_MODAL_COMPONENT,
    SEND_VECTOR_DYNAMIC_MODAL_COMPONENT,
    EDIT_DYNAMIC_MODAL_COMPONENT
} from '../bdb-types/index';

// Cada reducer tiene su state
const initialState = {
    show: false,
    vector: null,
    disable: true,
    edit: null,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case START_DYNAMIC_MODAL_COMPONENT:
            return {
                ...state,
                show: action.payload,
            }
        case ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT: 
            return {
                ...state,
                disable: action.payload
            }
        case SEND_VECTOR_DYNAMIC_MODAL_COMPONENT: 
            return {
                ...state,
                disable: true,
                show: false,
                vector: action.payload
            }
        case FINALIZE_DYNAMIC_MODAL_COMPONENT: 
            return {
                ...state,
                disable: true,
                show: action.payload,
                edit: null,
                vector: null
            }
        case EDIT_DYNAMIC_MODAL_COMPONENT:
            return {
                ...state,
                disable: true,
                show: false,
                edit: action.payload,
                vector: null
            }
        default:
            return state;
    }
}