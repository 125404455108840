export const buldingPage =  'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'151\' height=\'141\' viewBox=\'0 0 151 141\'%3e%3' +
                            'cpath fill=\'%23AEAEAE\' fill-rule=\'nonzero\' d=\'M144.506 135.75V90.046h.001V14.25h-4.5v49.5h-19.503v-24h-4.5v48H96.506v-12h-4.5v14.296h.' +
                            '001v21.704h-19.5v-22.5h-4.5v22.5h-.001v24h-4.498v-12h-15.06v-91.5h43.56v7.497h-3v4.5h3v7.33l-.024-.002a4.857 4.857 0 0 1-4.852 4.406 4.88 4' +
                            '.88 0 0 1-4.875-4.875c0-.939.267-1.85.776-2.638L79.25 46.03a9.367 9.367 0 0 0-1.491 5.076c0 5.17 4.204 9.375 9.375 9.375a9.343 9.343 0 0 0 ' +
                            '9.332-8.471l-2.681-.26h2.721v-7.502h3.04v-4.5h-3.04V32.25h1.5v-4.5h-2.582L48.447 11.883V.75H23.006v10.5h-2.082L.688 27.75H.506v.147l-.012.0' +
                            '1.012.015v4.328h22.502v91.5h-15v12H.506v4.5h150v-4.5h-6zM27.507 35.444l15.701 15.7-15.7 15.7v-31.4zm-.001-19.694h16.441v12H27.506v-12zm16.4' +
                            '41 68.025L30.444 70.272l13.503-13.505v27.008zm0-38.253L30.677 32.25h13.27v13.272zm-16.44 28.175l14.854 14.852-14.853 14.853V73.697zm16.44 1' +
                            '9.629v27.009l-13.504-13.503 13.504-13.506zM81.363 27.75H48.448V16.632L81.363 27.75zM27.507 5.25h16.441v6H27.506v-6zm-19.7 22.5l14.72-12.002' +
                            'h.479V27.75H7.806zm19.701 82.509l13.491 13.491h-13.49v-13.491zm-15 17.991h46.5v7.5h-46.5v-7.5zm127.499 7.5h-19.5v-19.5h19.5v19.5zm0-24h-19.' +
                            '5v-19.5h19.5v19.5zm-19.5-43.5h19.5v19.5h-19.5v-19.5zm-23.999 24h19.499v19.5H96.507v-19.5zm-24.001 43.5v-19.5h19.501v19.5H72.506zm24.001-19.' +
                            '5h19.499v19.5H96.507v-19.5z\'/%3e%3c/svg%3e ")';

export const logoBanbogta = 'url("data:image/svg+xml;charset=UTF-8,%3c?xml version=\'1.0\' encoding=\'UTF-8\'?%3e%3csvg width=\'48px\' height=\'48px\' viewBox=\'0 0 48 48\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xli' +
'nk=\'http://www.w3.org/1999/xlink\'%3e%3c!-- Generator: Sketch 57.1 (83088) - https://sketch.com --%3e%3ctitle%3eGroup 11%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cdefs%3e%3clinearG' +
'radient x1=\'56.0883914%25\' y1=\'81.6675999%25\' x2=\'94.6089024%25\' y2=\'81.6675999%25\' id=\'linearGradient-1\'%3e%3cstop stop-color=\'%23ED1C24\' offset=\'0%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FFF' +
'FFF\' stop-opacity=\'0\' offset=\'94.8157514%25\'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1=\'10.3215998%25\' y1=\'49.9533251%25\' x2=\'88.6510422%25\' y2=\'49.9533251%25\' id=\'linearGradient-' +
'2\'%3e%3cstop stop-color=\'%23ED1C24\' offset=\'0%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FFCB05\' offset=\'100%25\'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1=\'50.0021978%25\' y1=\'-0.7860' +
'73229%25\' x2=\'50.0021978%25\' y2=\'35.6584867%25\' id=\'linearGradient-3\'%3e%3cstop stop-color=\'%23E39717\' offset=\'0%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23E8A116\' offset=\'12.36%25\'%3e%3c/stop%3e' +
'%3cstop stop-color=\'%23F4B710\' offset=\'43.44%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FCC509\' offset=\'73.21%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FFCB05\' offset=\'100%25\'%3e%3c/stop%3e%3c/line' +
'arGradient%3e%3clinearGradient x1=\'34.9788951%25\' y1=\'24.3674127%25\' x2=\'47.9115542%25\' y2=\'60.2040022%25\' id=\'linearGradient-4\'%3e%3cstop stop-color=\'%23E39717\' offset=\'0%25\'%3e%3c/stop%3e%3c' +
'stop stop-color=\'%23E8A116\' offset=\'10.13%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23F4B710\' offset=\'35.61%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FCC509\' offset=\'60%25\'%3e%3c/stop%3e%3cstop stop' +
'-color=\'%23FFCB05\' offset=\'81.96%25\'%3e%3c/stop%3e%3cstop stop-color=\'%23FFCB05\' offset=\'100%25\'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3cg id=\'Tab_00_C.D_Login\' stroke=\'none\' stroke-wi' +
'dth=\'1\' fill=\'none\' fill-rule=\'evenodd\'%3e%3cg id=\'Tab_00_00_C.D_Login_vacío\' transform=\'translate(-276.000000, -16.000000)\'%3e%3cg id=\'Group-11\' transform=\'translate(276.000000, 16.000000)\'%3' +
'e%3cg id=\'T_Header/Main\'%3e%3cg id=\'Brand-/-isotipo-/-white\'%3e%3cg id=\'monogram\'%3e%3cpath d=\'M23.7356998,0.0070803493 C11.0050876,0.233651527 0.480652313,9.30357899 0.0158288693,21.7649938 C' +
'-0.442056911,34.2334889 9.09029252,44.7265666 21.3075177,45.1938696 C27.523664,45.4346015 33.2402986,43.0343631 37.4237096,38.9702426 C41.4475544,35.0548094 41.2810504,28.1656295 38.0827876,31' +
'.2880636 C34.5653923,34.722033 29.646589,36.7753343 24.2837752,36.5770845 C14.0576595,36.201826 6.07934663,27.7762103 6.46091811,17.7575161 C6.81473895,8.46101742 15.8475766,1.10453449 23.9299' +
'544,0.658472485 L24.1380843,0 C24.0062687,0.0070803493 23.8744531,0.0070803493 23.7356998,0.0070803493 Z\' id=\'Shape\' fill=\'url(%23linearGradient-1)\' fill-rule=\'nonzero\'%3e%3c/path%3e%3cpath d=\'M' +
'25.519858,0.658823489 C35.7814042,1.29627682 44.2276061,10.2277063 14.364497,19.1591357 C21.9723146,20.6890237 27.5915678,24.3366733 27.5915678,24.3366733 C53.1588236,11.0493128 38.6152816,-0.04' +
'23751752 24.1479564,0.000121713596 L23.9400926,0.658823489 C24.4736098,0.623409415 25.0001983,0.623409415 25.519858,0.658823489 Z\' id=\'Shape\' fill=\'%23FFCB05\' fill-rule=\'nonzero\'%3e%3c/path%3e%3' +
'cpath d=\'M13.0062625,5.35541945 C16.2297823,2.6065524 20.2313931,0.868926598 24.0315339,0.655284081 C24.5664715,0.626798412 25.0944618,0.626798412 25.6155049,0.662405498 C30.6592018,0.975747856 ' +
'35.2652226,3.29020845 35.1262778,6.63727455 L37.6481262,3.59642939 C34.2787144,1.31045447 29.2558591,-0.0141291379 24.2399512,0.000113696503 L23.8370112,0.000113696503 C19.1128873,0.0855707032 1' +
'4.6944421,1.38879006 10.9846154,3.6391579 L13.0062625,5.35541945 Z\' id=\'Shape\' fill=\'url(%23linearGradient-2)\' fill-rule=\'nonzero\'%3e%3c/path%3e%3cpath d=\'M36.5231656,35.3026052 C36.5162214,35.1' +
'823276 36.0995693,32.2319889 36.0995693,32.2319889 C35.0648833,25.2205123 36.5092772,18.4283655 47.0366864,17.8694285 C31.7663877,10.3909918 21.6139654,17.5510466 14.1767258,19.2278578 C20.94732' +
'21,20.0839513 35.2176558,27.8807698 36.5231656,35.3026052 Z\' id=\'Shape\' fill=\'url(%23linearGradient-3)\' fill-rule=\'nonzero\'%3e%3c/path%3e%3cpath d=\'M41.5554229,19.9118236 C35.0476482,22.6486835 ' +
'35.7144,30.2282255 36.3394797,34.6738502 C36.4644956,35.035456 37.1173567,37.8006771 35.61022,40.6864335 C33.7419261,43.9337958 30.713762,46.3870432 24.8796844,47.9752728 C39.8329809,48.6843038 ' +
'56.9462752,34.0215416 41.5554229,19.9118236 Z\' id=\'Shape\' fill=\'url(%23linearGradient-4)\' fill-rule=\'nonzero\'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e")';

export const logoUpload = 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'14\' height=\'14\' viewBox=\'0 0 14 14\'%3e%3cg fill=\'%23002C76\' fill-rule=\'nonzero\'%3e%3cpa' +
'th d=\'M3.927 4.876c.17 0 .34-.077.475-.23l1.9-2.15v7.536c0 .422.306.768.68.768.373 0 .678-.346.678-.768V2.495l1.9 2.15c.136.154.306.23.476.23.17 0 .339-.076.475-.23a.811.811 0 0 0 0-1.075L7.456.115C7.422.' +
'077 7.388.038 7.355.038H7.32h-.034C7.253.038 7.253 0 7.219 0H7.15 6.81h-.033c-.034 0-.068 0-.102.038-.034 0-.068.039-.102.077-.034.039-.068.039-.102.077L3.418 3.647a.811.811 0 0 0 0 1.075c.17.077.34.154.50' +
'9.154z\'/%3e%3cpath d=\'M13.3 9.714c-.385 0-.7.35-.7.78v1.948H1.4v-1.948c0-.43-.315-.78-.7-.78-.385 0-.7.35-.7.78v2.727c0 .428.315.779.7.779h12.6c.385 0 .7-.35.7-.78v-2.726c0-.43-.315-.78-.7-.78z\'/%3e%3c/' +
'g%3e%3c/svg%3e")';

export const loadingIcon = 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' style=\'m' +
'argin: auto; background: none; display: block; shape-rendering: auto;\' width=\'200px\' height=\'200px\' viewBox=\'0 0 100 100\' preserveAspectRatio=\'xMidYMid\'%3e' +
'%3ccircle cx=\'50\' cy=\'50\' r=\'30\' stroke-width=\'10\' stroke=\'%23002c76\' stroke-dasharray=\'47.12388980384689 47.12388980384689\' fill=\'none\' stroke-lineca' +
'p=\'round\'%3e%3canimateTransform attributeName=\'transform\' type=\'rotate\' repeatCount=\'indefinite\' dur=\'1s\' keyTimes=\'0;1\' values=\'0 50 50;360 50 50\'%3e' +
'%3c/animateTransform%3e%3c/circle%3e%3c/svg%3e")';
