import React, { Fragment, useEffect, useState } from 'react';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import { useDispatch, useSelector } from 'react-redux';
import '../../bdb-views/AnalystView/Analyst.css'
import { casePrioritize } from '../../bdb-actions/casesActions';
import { useHistory } from 'react-router-dom';

const sylesCSS = {
  options: {
      'color': '#002c76',
      'cursor': 'pointer',
      fontSize: '16px'
  },
  caseLabel: {
    width: '100%',
    height: '33px',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#004aaf',
    
  },
  caseProcess: {
    width: '160px',
    height: '36px',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#29292a'
  }
}

const Prioritize = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [priorizatedCase, savePriorizatedCase] = useState({
        document: '',
        requestNumber: '',
        dateAssignment: '',
        hourAssignment: '',          
        reqStatus: '',
        assignedTo: '',
        skRequest: '',
        priorityFlag: ''
    });

    const [fixedAssingTo, saveFixedAssingTo] = useState('');
    
    const [fixedPriorityFlag, saveFixedPriorityFlag] = useState('');

    const [disabledButton, setDisabledButton] = useState(true);

    const selectedCase = useSelector(state => state.cases.selectedCase);

    useEffect(() => {
      savePriorizatedCase(selectedCase, process);
      saveFixedAssingTo(selectedCase.reqUser);
      const keyIndex = Object.keys(selectedCase).filter( key => key.includes('management#process') && key.length > 18);
      const priority = selectedCase[keyIndex].split('#')[2] === '1' 
        ? 'Alta' : 'Media';
      saveFixedPriorityFlag(priority);
    }, [selectedCase]);

    const onChangeForm = e => {
      savePriorizatedCase({
        ...priorizatedCase,
        [e.target.name] : e.target.value
      })
    }

    const submitPriorizateCaase = e => {
      e.preventDefault();
      dispatch(casePrioritize(priorizatedCase))

      history.push('/finantial-leader/cases');
    }

    const cancelPriorizate = () =>{
      history.push('/finantial-leader/cases');
    }

    return (
      <Fragment>
        <LeaderOptions /> 
          <div className="row justify-content-center mt-5">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={submitPriorizateCaase}>
                    <div className="form-group">
                        <div style={sylesCSS.caseLabel}>{`Caso ${priorizatedCase.requestNumber}`}</div>
                        <div style={sylesCSS.caseProcess}>{priorizatedCase.process}</div>
                        <label>Documento cliente</label>
                        <input type="text" className="form-control" disabled={true}
                        placeholder="Nombre Producto" value={priorizatedCase.document}
                      />
                      </div>
                      <div className="form-group">
                        <label>Gestor</label>
                        <input placeholder="Sin asignar" 
                              type="text" className="form-control"  name="reqUser"
                              disabled={true} onChange={onChangeForm} id="reqUser"
                        />
                      </div>
                      <div className="form-group">
                        <label>Prioridad</label>
                          <select className="select-style-case"
                              name="priorityFlag"
                              value={priorizatedCase.priorityFlag === '' || priorizatedCase.priorityFlag === undefined
                              || priorizatedCase.priorityFlag === null ? fixedPriorityFlag : priorizatedCase.priorityFlag}
                              onChange={(e) => {onChangeForm(e); setDisabledButton(false)}}
                          >
                              <option value="Alta">Alta</option>
                              <option value="Media">Media</option>
                        </select>
                      </div>
                        <div class="row d-flex">
                          <div class="btn-footer col-md-12 space-button">
                            <button type="button" className="btn-primary color-blue btn-style" onClick={cancelPriorizate}>Cancelar</button> 
                            <button type="submit" className={`btn-primary color-blue ${disabledButton ? 'btn-disabled-style':'btn-style'}`} 
                            disabled={disabledButton}>Guardar cambios</button>
                          </div>
                        </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
      </Fragment>
     );
}
 
export default Prioritize;