import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addMember, createGroupMassive, deleteAllMembers, deleteMember, editModeLeader, getNameById, groupInfo, groupMembers, updateNameGroup, updateNameMember } from '../../bdb-actions/leaderProductivityCtrlBrdActions';
import SearchForListComponent from '../SearchForListComponent/SearchForListComponent';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { useHistory } from 'react-router-dom';
import BackLink from '../BackLinkComponent/BackLink';
import { useCallback } from 'react';

const tittleNews = "Novedades administrativas";
const subtittleNews = "Para iniciar crea el grupo de gestores a los que le reportarás una novedad masiva.";

const inputStyles = {
    width: 'inherit',
    height: '22px',
    padding: '10px 15px 11px 15px',
    borderRadius: '3px',
    border: 'none',
    backgroundColor: '#fff',
    fontSize: '14px',
};

const listStyles = {
    maxHeight: '167px', 
    width: '40%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    position: 'absolute',
    fontSize: '16px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const initialEnables = {
    create: false,
    deleteAll: false,
    save: false
};

const initialButtonStructure = {
    id: 'create',
    label: 'Crear grupo'
};

export const GroupSettings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const focusInput = useRef();
    
    const { gestorName, infoGroup,loading, dataListStaff, membersGroup, editMode, redirect} = useSelector( state => state.leaderProdBoard );
    const { name } = infoGroup;
    const [enable, setEnable] = useState(initialEnables);
    const [buttonStructure, setButtonStructure] = useState(initialButtonStructure);
    const [membersGroupTemporal, setMembersGroupTemporal] = useState(membersGroup);
    
    const actions = {
        'add-click': addMemberAction,
        'delete-click': deleteMemberAction,
        'deleteAll-click': deleteAllMembersAction,
        'create': createGroupAction,
        'save': saveGroupAction,
        'gestorName': updateNameMemberAction,
        'name': updateNameGroupAction
    };

    const handleInputChange = ( e ) => {

        let action = actions[ e.target.name ];
        if (!!action) action(e);
    };

    function addMemberAction( {target}) {
        dispatch( updateNameMember( "" ) );
        dispatch( addMember( target.value ));
        focusElement();
    };
    function deleteMemberAction({target}) {
        dispatch( deleteMember( target.value ));
        focusElement();  
    };
    function deleteAllMembersAction() {
        Swal.fire({
            icon: 'info',
            html: '<b>¿Estás seguro de eliminar todos los miembros del grupo?</b>',
            color: '#151515',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch( deleteAllMembers() );
            }
        })
    };
    function updateNameMemberAction( {target} ){
        dispatch( updateNameMember(target.value ));
    }
    function updateNameGroupAction( {target} ){
        dispatch( updateNameGroup(target.value ) );
    }
    function createGroupAction( e ) {
        e.preventDefault();
        dispatch( createGroupMassive() );
    };
    function saveGroupAction( e ) {
        e.preventDefault();
        dispatch( createGroupMassive('PUT') );
    };

    const focusElement = () => {
        focusInput.current.focus();
    }
    const _getNameById = useCallback(
      () => {
        dispatch( getNameById({name: gestorName.toUpperCase()}));
      },
      [dispatch, gestorName],
    )
    useEffect(() => {

      if (gestorName?.length >= 3) {
        _getNameById()
        }
    }, [gestorName, _getNameById])

    useEffect(() => {
      const minMembers = membersGroup.length > 1;
      let create = minMembers && !!name;
      let deleteAll = minMembers;
      let save = (!validateArrayEquals( membersGroup , membersGroupTemporal ) || name !== JSON.parse(sessionStorage.getItem("infoGroup"))?.name )  && minMembers;
      
      setEnable({
          create,
          deleteAll,
          save
      });
    }, [membersGroup,name,membersGroupTemporal])
    useEffect(() => {
        if (editMode){
            setButtonStructure({
                id: 'save',
                label: 'Guardar cambios'
            })
            setMembersGroupTemporal(membersGroup);
        };
    }, [editMode])
    const _redirect = useCallback(
      () => {
        history.push(redirect)
      },
      [history, redirect],
    )
    
    useEffect(() => {
      if (!!redirect) _redirect();
    }, [redirect, _redirect])

    useEffect(()=>{
        const _infoGroup = JSON.parse(sessionStorage.getItem("infoGroup"))
        const _members = JSON.parse(sessionStorage.getItem("members"))

        if(!!_infoGroup){
            dispatch( groupInfo(_infoGroup) );
        }
        if(!!_members){
            setMembersGroupTemporal(_members);
            dispatch( groupMembers(_members) );
            dispatch(editModeLeader(true))
        }
    },[])
    
    

  return (
      <Fragment>
          {
            loading && <Loading />
          }
          <div className='group-settings_container font-OpenSans-Regular'>
              <div>
                  <h2 className='leaderProd-tittle'>{tittleNews}</h2>
                  <h3 className='leaderProd-sub-tittle'>{subtittleNews}</h3>
              </div>
              <form className='group-settings_form-box'>
                  <div className='flex-column mb-15'>
                    <label className='leaderProd-header-col' htmlFor="groupName">Nombre del grupo</label>
                    <input 
                        type='text'
                        id='name'
                        className='group-settings_input-box'
                        name='name'
                        value={name}
                        onChange={handleInputChange}
                        autoComplete='off'
                        placeholder='Nombre del grupo'
                    />
                  </div>
                  <div className='flex-column'>
                    <label className='leaderProd-header-col' htmlFor="gestorId">Miembros del grupo</label>
                    <SearchForListComponent
                        idInput={'gestorName'}
                        refInput={focusInput}
                        idClick={'add-click'}
                        idClickDragged={'delete-click'}
                        selectedOption={gestorName}
                        onClickOption={handleInputChange} 
                        onChangeCustomInput={handleInputChange}
                        options={dataListStaff}
                        selectStyle={inputStyles}
                        listStyle = {listStyles}
                        width={'100%'}
                        placeholder={'Nombre del Gestor'}
                        optionsAggregable={membersGroup}
                    />
                    <div className='group-settings_delete-box'>
                        <hr className='group-settings_separator' width='100%'/>
                        {
                            enable.deleteAll &&
                                <div 
                                    className='leaderProd-header-col group-settings_cursor-pointer' 
                                    onClick={() => handleInputChange( buildStructure('deleteAll-click') )}
                                    >Eliminar todos<span>&nbsp;X</span>
                                </div>
                        }
                    </div>
                  </div>
                  <button
                    className='group-settings_submit-button'
                    disabled={!enable[buttonStructure.id]}
                    name={buttonStructure.id}
                    onClick={(e) => handleInputChange( e )}
                    >
                        {buttonStructure.label}
                    </button>

              </form>
          </div>

      </Fragment>
  )
}

function buildStructure( name, value ){
    return {
        target: {
            name,
            value
        }
    }
};

function validateArrayEquals( array1, array2 ){
    if ( array1.length !== array2.length ) return false;
    let mayor;
    let menor;
    let equals = true;
    if ( array1.length >= array2.length ){
      mayor = array1
      menor = array2
    } else {
      mayor = array2;
      menor = array1;
    }
    for (const element of mayor) {
      let findElement = menor.find( el => el.documentNumber === element.documentNumber );
      if (!findElement) {
        equals = false;
        break;
      };
    };
    return equals;
}