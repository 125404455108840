import axios from 'axios';
import { getdecrypt } from '../global/global';
let headers = { 
    'Content-Type' : 'text/plain'
};

export function getDlyMngmntInfo(user) {
    return axios.get(process.env.REACT_APP_SELF_API_ENDPOINT + 'control-board/productivity/' + user, { headers });
}

export function getCtrlBrdInfoByCc( data ) {
    let numberOrName = !!data && (data.hasOwnProperty('number')) ? 'number' : 'name';
    const vertical = (data.vertical) ? data.vertical : ' ';
    const params = { [numberOrName] : `${data[numberOrName]}`, vertical };
    return axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/staff`, { params: params }, { headers });
}

export function getCtrlBrdInfoByUser(user) {
    return axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/staff`, { params: {user}}, { headers });
}

export function postCtrlBrdNews(data) { 
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/news/productivity-new`, data, { headers });
}

export function postCtrlBrd(data) {
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/productivity`, data, { headers });
}

export function putCtrlBrd(data) {
    return axios.put(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/productivity`, data, { headers });
}

export function deleteCtrlBrdById(data) {
    return axios.delete(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/productivity`, {data:{'id':`${data['id']}`, 'processType':`${data['processType']}`, 'partitions':data['partitions'], 'isAdministrative':data.isAdministrative ? data.isAdministrative:false }}, { headers });
}

export function getMngmntsAndProcessList(processNew) {
    return axios.get(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/config/processes/${processNew}`, { headers });
}

export function getProductivityNewByUser(numberIntput, beginDateIntput, endDateIntput, pageIntput){
    return  axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/news`,  { params: { number: numberIntput, beginDate: beginDateIntput, endDate: endDateIntput, page:pageIntput} }, { headers });
}

export function getProductivityNewByGroup(groupInput, beginDateIntput, endDateIntput, pageIntput){
    return  axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/news`,  { params: { group: groupInput, beginDate: beginDateIntput, endDate: endDateIntput, page:pageIntput} }, { headers });
}

export function deleteNewsById(card){
    return  axios.delete(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/news/delete-by-id`,  { params: { number: card.documentNumber, beginDate: card.beginDate, endDate:card.endDate } }, { headers });
}
export function putNewsCard(body,key){
    return  axios.put(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/news/update-by-id`, body,  { params: { number: key.number, beginDate: key.beginDate, endDate:key.endDate } }, { headers });
}

export function getLeaderGroup(leader) {
    return axios.get(process.env.REACT_APP_SELF_API_ENDPOINT + 'config/groups/getByLeader',  { params: { leader:leader} }, { headers });
}

export function postMassiveNew(data) {
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/news/masiveReport`, data, { headers });
}
export function postGroupMassive(body){
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }config/groups`, body, { headers });
}
export function putGroupMassive(body, id){
    return axios.put(`${ process.env.REACT_APP_SELF_API_ENDPOINT }config/groups/${id}`, body, { headers });
}
export function putStaffUser( body ){
    return axios.put(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/staff/user`, body, { headers });
}
export function deleteStaffPosition( id ){
    return axios.delete(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/staff/position/${id}`, { headers });
}
export function getStaffUser( params ){
    return axios.get(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/staff`, { params: { positionCode: params.positionCode, vertical: params.vertical }}, { headers });
}
export function postKardexNews(body, id){
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/kardex`, body, { headers });
}
export function putKardexNews(body){
    return axios.put(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/kardex`, body, { headers });
}
export function getKardexCount(body){
    return axios.post(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/kardex/productivity-summary`,  body, { headers });
}
export function getKardexByManagementDate(data){
    return axios.get(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/config/kardex/${data}`, { headers });
}
export function getCtrlBrdBackupCsv(data){
    return axios.get(`${ process.env.REACT_APP_SELF_API_ENDPOINT }control-board/backup-csv?date=${data}`, { headers });
}