import { 
    START_GET_CONVENTIONS_CONFIG,
    GET_CONVENTIONS_CONFIG_SUCCESS,
    GET_CONVENTIONS_CONFIG_FAILED,
    START_UPDATE_CONVENTIONS_CONFIG,
    SUCCESS_UPDATE_CONVENTIONS_CONFIG,
    FAIL_UPDATE_CONVENTIONS_CONFIG,
    START_UPDATE_CONVENTIONS_USER,
    SUCCESS_UPDATE_CONVENTIONS_USER,
    FAIL_UPDATE_CONVENTIONS_USER,
    SELECTED_CONVENTIONS_CONFIG
} from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    error: false,
    loading: false,
    dataConfig: null,
    alert: false,
    selected: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case START_GET_CONVENTIONS_CONFIG:
            return {
                ...state,
                loading: action.payload,
                error: false
            }
        case GET_CONVENTIONS_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dataConfig: action.payload
            }
        case GET_CONVENTIONS_CONFIG_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case START_UPDATE_CONVENTIONS_CONFIG:
            return {
                ...state,
                loading: action.payload,
                error: false,
                alert: false
            }
        case SUCCESS_UPDATE_CONVENTIONS_CONFIG:
            return {
                ...state,
                loading: false,
                error: false,
                alert: action.payload
                
            }   
        case FAIL_UPDATE_CONVENTIONS_CONFIG:
            return {
                ...state,
                loading: false,
                error: action.payload,
                alert: false
            }
        case START_UPDATE_CONVENTIONS_USER:
            return {
                ...state,
                loading: action.payload,
                error: false,
                alert: false
            }
        case SUCCESS_UPDATE_CONVENTIONS_USER:
            return {
                ...state,
                loading: false,
                error: false,
                alert: action.payload
            }   
        case FAIL_UPDATE_CONVENTIONS_USER:
            return {
                ...state,
                loading: false,
                error: action.payload,
                alert: false
            } 
        case SELECTED_CONVENTIONS_CONFIG: 
            return {
                ...state,
                selected: action.payload
            }      
        default:
            return state;
    }
}