import React, { useEffect } from 'react';
import {buldingPage} from '../../../assets/icons';
import '../../../assets/icons';
import { useHistory } from "react-router-dom";
import { getInformationDocumentStaff } from "../../bdb-helpers/utils"
import * as CryptoJS from "crypto-js";
import {idmAdminRol, idmAnalystRol, idmAnalystProdRol, idmLeaderProdRol,idmAdminProd, idmReporterFng, idmRootAdminProd } from '../../../constants';

function decryptUsingAES256(value, iv, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
}

const getRedirectionUser = idmRol => {
    const groupDisbursement = global.isGroupDisbursement()
    if (idmAdminRol.indexOf(idmRol) !== -1) {
        return '/finantial-leader';
    }
    if (idmAnalystRol.indexOf(idmRol) !== -1) {
        return groupDisbursement ? '/menu-disbursement' : '/finantial-analyst';
    }
    if (idmAnalystProdRol.indexOf(idmRol) !== -1){ 
        return '/report-productivity'
    }
    if (idmLeaderProdRol.indexOf(idmRol) !== -1){
        return '/leader-productivity'
    }
    if(idmAdminProd.indexOf(idmRol) !== -1 || idmRootAdminProd.indexOf(idmRol) !== -1){
        return '/staff-admin'
    }
    if (idmReporterFng.indexOf(idmRol) !== -1){
        return '/fng-report'
    }
    return '/orderDisbursement'
    //window.location.href = "https://carpetadigitaldevelop.labdigitalbdbqadigitalfolder.com/";
}

const HomePage = () => {
    const history = useHistory();
    
    const redirectPath = async () => {
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const rolUser = decryptUsingAES256(sessionStorage.getItem('rolUser'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        await getInformationDocumentStaff(rolUser)
        .catch( error =>{ 
            history.push(getRedirectionUser(rolUser));
            console.log(error)
        })
        .then( () =>{
            history.push(getRedirectionUser(rolUser));
        })
    }

    useEffect( () => {
        redirectPath();
    }, []);

    const sylesCSS = {
        logoUp: {
            background: `no-repeat ${buldingPage}`,
            backgroundSize: 'contain',
            height: '70%',
            width: '70%'
        },
        buildingContainer: {
            height: '500px',
            margin: '10px auto',
            width: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }

    return (
       <div style= {sylesCSS.buildingContainer}>
           <div></div>
       </div>
     );
}
 
export default HomePage;
