import { 
    CREATE_CASE_PREPOPULATE_REPORT,
    SEARCH_CASE_PREPOPULATE_REPORT,
    DELETE_CASE_PREPOPULATE_REPORT,
    SUCCESS_SEARCH_REPORT,
    ERROR_SEARCH_REPORT,
    SUCCESS_CREATE_CASE_REPORT,
    ERROR_CREATE_CASE_REPORT,
    SUCCESS_DELETE_CASE_REPORT,
    ERROR_DELETE_CASE_REPORT,
    SET_INDICATORS_PRODUCTIVITY,
    SET_INDICATORS_PRODUCTIVITY_SUCCESS,
    SET_INDICATORS_PRODUCTIVITY_ERROR,
    SET_INDICATORS_PRODUCTIVITY_RESET,
    STATUS_CASE,
    SAVE_CASE_PAUSED,
    FINISH_REQUESTS,
    UPDATE_ALERT_TOAST
 } from '../bdb-types';

const initialState = {
    case: null,
    messageLoading: null,
    success: null,
    initiated: false,
    alert: null,
    disabled: false,
    keyToDelete: null,
    statusCase: null,
    saveCasePaused: false,
    finishReqs:false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_INDICATORS_PRODUCTIVITY:
            return {
                ...state,
                messageLoading: action.payload
            }
        case SET_INDICATORS_PRODUCTIVITY_SUCCESS: 
            
                return {
                    ...state,
                    messageLoading: null,
                    alert: action.payload.message,
                    keyToDelete: action.payload.key,
                    disabled: false
                }
        case SET_INDICATORS_PRODUCTIVITY_RESET:
            
            return {
                ...state,
                messageLoading: null,
                keyToDelete: null,
                disabled: false
            }
        case UPDATE_ALERT_TOAST:
            return {
                ...state,
                alert: action.payload
            }
        case SET_INDICATORS_PRODUCTIVITY_ERROR:
            return {
                ...state,
                messageLoading: null,
                alert: action.payload
            }
        case CREATE_CASE_PREPOPULATE_REPORT:
            return {
                ...state,
                messageLoading: action.payload,
                case: null,
                disabled: true
            }
        case SEARCH_CASE_PREPOPULATE_REPORT:
            return {
                ...state,
                messageLoading: action.payload
            }
        case DELETE_CASE_PREPOPULATE_REPORT:
            return {
                ...state,
                case: action.payload,
                disable: false
            }
        case SUCCESS_SEARCH_REPORT:
            return {
                ...state,
                messageLoading: null,
                disabled: true,
                case: action.payload.Items[0]
            }
        case ERROR_SEARCH_REPORT:
            return {
                ...state,
                messageLoading: null,
                disabled: false,
                case: null
            }
        case SUCCESS_CREATE_CASE_REPORT:
            return {
                ...state,
                messageLoading: null,
                case: action.payload
            }
        case ERROR_CREATE_CASE_REPORT:
            return {
                ...state,
                messageLoading: null,
                case: action.payload
            }
        case SUCCESS_DELETE_CASE_REPORT:
            
            return {
                ...state,
                case: null,
                keyToDelete: null
            }
        case ERROR_DELETE_CASE_REPORT:
            
            return {
                ...state,
                case: action.payload,
                keyToDelete: null
            }
        case STATUS_CASE:
            return {
                ...state,
                statusCase: action.payload
            }
        case SAVE_CASE_PAUSED:
            return{
                ...state,
                saveCasePaused: action.payload
            }
        case FINISH_REQUESTS:
            return{
                ...state,
                finishReqs:action.payload
            }
        default:
            return state;
    }
}