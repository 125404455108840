import {
    DOWNLOAD_RESULT_CASES,
    CLEAN_RESULT_CASES,
    STAR_CASES,
    LOADING_TOTAL_CASES,
    SEARCH_SPECIFIC_CASE_FAIL,
    SEARCH_SPECIFIC_CASE_NOT_FOUND,
    SEARCH_SPECIFIC_CASE,
    SEARCH_SPECIFIC_CASE_SUCCESS,
    SELECT_CASE_TO_PRIORITIZE,
    CASE_PRIORITIZE_ERROR,
    CASE_PRIORITIZE_SUCCESSS,
    CASE_PRIORITIZE,
    CASE_REASSIGN_ERROR,
    CASE_REASSIGN_SUCCESSS,
    CASE_REASSIGN,
    SELECT_CASE_TO_REASSING,
    CLEAN_SPECIFIC_CASE,
    CASE_PRIORITIZE_ALERT,
    CASE_REASSIGN_ALERT
} from '../bdb-types';

const initialState = {
    cases: null,
    result: null,
    error: false,
    loading: false,
    loadingCases: false, 
    especifict: [],
    alert: false,
    show: false,
    selectedCase: null,
    id: null,
    alertReasing: null,
    alertPrioritize: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CLEAN_SPECIFIC_CASE:    
        return {
            ...state,
            especifict: action.payload
        }
        case STAR_CASES:    
            return {
                ...state,
                loading: action.payload
            }
        case LOADING_TOTAL_CASES:
            return {
                ...state,
                loadingCases: action.payload
            }    
        case DOWNLOAD_RESULT_CASES:
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case CLEAN_RESULT_CASES:
                return {
                    ...state,
                    result: action.payload
            }       
        case SEARCH_SPECIFIC_CASE: 
            return {
                ...state,
                alert: false,
                error: false,
                id: null,
                loading: action.payload
            }
        case SEARCH_SPECIFIC_CASE_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: false,
                show: true,
                especifict: action.payload.data.Items
            }
        case SEARCH_SPECIFIC_CASE_FAIL: 
            return {
                ...state,
                loading: false,
                error: false,
                show: false,
                id: null
            }
        case SEARCH_SPECIFIC_CASE_NOT_FOUND: 
            return {
                ...state,
                loading: false,
                id: null,
                error: action.payload,
                show: false,
                especifict: []
            }
        case SELECT_CASE_TO_PRIORITIZE:
            return {
                ...state,
                selectedCase: action.payload
            }
        case CASE_PRIORITIZE:
            return {
                ...state,
                loading: action.payload,
                especifict: []
            }
        case CASE_PRIORITIZE_SUCCESSS:
            return {
                ...state,
                loading: false,
                alert: false,
                id: action.payload.requestNumber,
                especifict: [action.payload]
            }
        case CASE_PRIORITIZE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                especifict: []
            }
        case SELECT_CASE_TO_REASSING:
            return {
                ...state,
                selectedCase: action.payload
            }
        case CASE_REASSIGN:
            return {
                ...state,
                loading: action.payload
            }
        case CASE_REASSIGN_SUCCESSS:
            return {
                ...state,
                loading: false,
                alert: false,
                id: action.payload.requestNumber,
                especifict: [action.payload]
            }
        case CASE_REASSIGN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                especifict: []
            }
        case CASE_PRIORITIZE_ALERT:
            return {
                ...state,
                alertPrioritize: action.payload
            }
        case CASE_REASSIGN_ALERT:
            return {
                ...state,
                alertReasing: action.payload
            }
        default:
            return state;
    }
}