import React, { Fragment, useState } from 'react'
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import BackLink from '../../../bdb-components/BackLinkComponent/BackLink'
import { STATUS_RESERVE_APPROVED, STATUS_RESERVE_REJECTED, STATUS_RESERVE_REPLACED } from '../../../../constants';
import { formatDate } from '../../../bdb-helpers/dates/datesHelper';

import './reserveGuarantee.css'

export const ReserveGuarantee = () => {
    const dataReserveGuarantee = useSelector( state => state.fng.reserveInfo );
    const { productReplaced, replacedFNGProduct, replacedFNGMessage, guaranteeInfo, clientInfo, guaranteeExtraInfo } = dataReserveGuarantee;
    const { efngCodigo, prfnCodigo, garaCodigo, valorGarantiaOriginal, reservaCodigo, fechaReserva, observaciones } = guaranteeInfo;
    const { btiidCodigo, clieCodigo, nombre } = clientInfo;


    const [ statusReserve, setStatusReserve] = useState( efngCodigo );

    //PENDIENTE VALIDAR OBTENCIÓN DE SUTITUÍDA?
    if ( statusReserve === 'REA' && replacedFNGProduct === 'EMP080' && productReplaced) {
        setStatusReserve( STATUS_RESERVE_REPLACED );
    } else if ( statusReserve === 'REA' ) {
        setStatusReserve( STATUS_RESERVE_APPROVED );
    } else if ( statusReserve === 'RER' ) {
        setStatusReserve( STATUS_RESERVE_REJECTED )
    }
    
    const copyToClipBoard = () => {
        let copyText = document.getElementById("numberReserve");
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
    }
    const getContentToast = ( result1, result2) => {
        return statusReserve === STATUS_RESERVE_APPROVED || statusReserve === STATUS_RESERVE_REPLACED ? result1 : result2;

    }
    const getContentTagStatus = ( result1, result2, result3) => {
        return statusReserve === STATUS_RESERVE_APPROVED ? result1 : statusReserve === STATUS_RESERVE_REPLACED ? result2 : result3;
    }
    const isStatusReplaced = () => {
        return statusReserve === STATUS_RESERVE_REPLACED;
    }

    return (
        <Fragment>
            <BackLink url={'/fng'}/>
            <div className='justify-content-center font-OpenSans-Regular hg-55'>
                <div className='reserve-full-box'>
                    <div className='mb-18 title-reserve font-OpenSans-Semibold'>Solicitar reserva FNG</div>
                    <div className={`mb-23 alert-reserve ${getContentToast('alert-approve','alert-reject')}`}>
                        La reserva fue {getContentToast('aprobada exitosamente','rechazada')}</div>
                    <div>
                        <div className='form-reserve'>
                            <div className='row-form-reserve mb-6'>
                                <div className={`status-reserve status-reserve${getContentTagStatus('-approve','-replaced','-reject')}`}>
                                    {getContentTagStatus('Aprobada','Sustituída','Rechazada')}
                                </div>
                                {
                                    isStatusReplaced() &&
                                        <div className='text-replaced'>Cód de garantía sustituido a EMP080</div>
                                }
                            </div>
                            <div className='row-reserve title-guarantee font-OpenSans-Bold'>Garantía { garaCodigo }</div>
                            <div className='row-form-reserve mb-9'>
                                <div className='titles-form width-34'>Documento cliente</div>
                                <div className='titles-form width-34'>Nombre cliente</div>
                            </div>
                            <div className='row-form-reserve'>
                                <p className='input-form input-form-short'>{`${btiidCodigo}.${clieCodigo}`}</p>
                                <div className='empty-space-1'></div>
                                <p className='input-form input-form-long'>{nombre}</p>
                            </div>
                            {
                                statusReserve !== STATUS_RESERVE_REJECTED ?
                                <>
                                <div className='row-form-reserve mb-9'>
                                    <div className='titles-form width-34'>Fecha reserva</div>
                                    <div className='titles-form width-34'>Producto FNG</div>
                                    <div className='titles-form'>Monto</div>
                                </div>
                                <div className='row-form-reserve'>
                                    <p className='input-form input-form-short'>{ formatDate( new Date( fechaReserva ), 'dd/mm/yyyy' ) }</p>
                                    <div className='empty-space-1'></div>
                                    <p className={`input-form input-form-short ${isStatusReplaced() && 'input-product-replaced'}`}>{prfnCodigo}</p>
                                    <div className='empty-space-1'></div>
                                    <p className='input-form input-form-short'>
                                        <NumberFormat
                                            value={ valorGarantiaOriginal }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            renderText={value => <>{value.replace(/,/gi,'.')}</>}
                                        /></p>    
                                </div>
                                <div className='row-form-reserve mb-9'>
                                    <div className='titles-form width-34'>Número reserva</div>
                                </div>
                                <div className='row-form-reserve'>
                                    <input
                                        id="numberReserve"
                                        className='input-form input-form-long'
                                        defaultValue={ reservaCodigo }
                                        disabled/>
                                    <div className='empty-space-1'></div>    
                                    <button className='button-copy'
                                        onClick={ copyToClipBoard }>Copiar Nº reserva</button>
                                </div>
                                </> : 
                                <>
                                <div className='row-form-reserve mb-9'>
                                    <div className='titles-form width-34'>Causal rechazo:</div>
                                </div>
                                <div className='row-form-reserve'>
                                    <div className='box-rejected'>{ observaciones && observaciones.length === 200 ? `${ observaciones }...` : observaciones }</div>
                                </div>

                                </>  
                            }
                        </div>
                    </div>
                </div>
            </div> 
        </Fragment>
    )
}
