import React, { useEffect, useState } from "react";
import "./SearchForListComponent.scss";

const SearchForListComponent = ({
  containerList = { width: "100%", position: "relative" },
  options,
  onClickOption,
  onChangeCustomInput,
  idInput,
  idClick,
  idClickDragged,
  selectedOption,
  selectStyle,
  listStyle,
  placeholder,
  disabled,
  optionsAggregable = [],
  refInput,
  showArrow = false,
  arrowStyles = { width: "15px", height: "15px", right: "18px" },
  onActiveContent = true
}) => {
  const [filterOptions, setFilterOptions] = useState(options);
  const [subListOptions, setSubListOptions] = useState([]);
  const [optionSelect, setOptionSelected] = useState(selectedOption);
  const [styleDivSelect, setStyleDivSelect] = useState(selectStyle);
  const [showOptions, setShowOptions] = useState(false);
  const [showSubListOptions, setShowSubListOptions] = useState(false);
  const [listOptions, setListOptions] = useState({});

  useEffect(() => {
    let disabledSeletedOptions = validateSelectedOptions(options);
    setFilterOptions(disabledSeletedOptions);
  }, [options]);

  useEffect(() => {
    setOptionSelected(selectedOption);
    setTimeout(() => {
  setShowOptions(false);
}, 200);
  }, [selectedOption]);

  useEffect(() => {
    if (
      onActiveContent &&
      !!options &&
      options.length > 0 &&
      !!optionSelect &&
      optionSelect.length > 2
    ) {
      setShowOptions(true);
    } else {
      setTimeout(() => {
  setShowOptions(false);
}, 200);
    }
  }, [options]);

  useEffect(() => {
    if (filterOptions.length === 0) {
      setTimeout(() => {
  setShowOptions(false);
}, 200);
    }
  }, [filterOptions]);

  const onChangeFilter = ({ target }) => {
    if (target.value?.length > 2) {
      setShowOptions(true);
    }
    setFilterOptions(
      options.filter(
        (option) =>
          option?.label &&
          normalizeStr(option?.label).includes(normalizeStr(target.value))
      )
    );
  };

  const validateSelectedOptions = (dataArray) => {
    if( !dataArray || dataArray.length === 0) return []
    return dataArray.map((obj) => {
      if (optionsAggregable.find((aggregated) => aggregated.id === obj.id)) {
        obj.disabled = true;
      }
      return obj;
    });
  };
  const normalizeStr = (str) => {
    const new_str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return new_str.toLowerCase();
  };
  const filterFunction = (data) => {
    if (!!onChangeCustomInput) {
      onChangeCustomInput(data);
    } else {
      onChangeFilter(data);
    }
    setOptionSelected(data.target?.value);
  };
  const onBlurHandler = (event) => {
    if (event.relatedTarget?.id === "select-list") {
      return;
    }
    if (showArrow) setTimeout(() => {
  setShowOptions(false);
}, 200);
  };
  return (
    <div
      id="select-list"
      tabIndex={0}
      onBlur={(e) => {
        onBlurHandler(e);
      }}
      style={containerList}
    >
      <div className="drag-box">
        {optionsAggregable.map((option, ix) => (
          <div key={ix} className="drag-option-box">
            <span>{option.label}</span>
            <div
              className="delete-option"
              onClick={(e) =>
                onClickOption({
                  target: {
                    name: idClickDragged,
                    value: option,
                  },
                })
              }
            >
              &nbsp;X
            </div>
          </div>
        ))}
        <input
          data-cy={`search-list-input-${idInput}`}
          className={`${!!styleDivSelect ? "" : "Pro-style-simple-select"}${
            showOptions ? "Pro-simple-select-custom-border" : ""
          }`}
          placeholder={placeholder}
          style={!!styleDivSelect ? styleDivSelect : {}}
          value={!!optionSelect ? optionSelect : ""}
          disabled={disabled}
          autoComplete="off"
          name={idInput}
          id={idInput}
          onChange={(e) => {
            filterFunction(e);
          }}
          ref={refInput}
        />
        {showArrow && (
          <div
            data-cy = {`simple-select-${idClick}`}
            className="arrow-select-icon"
            style={arrowStyles}
            onClick={() => {
              if (disabled) return
              setShowOptions(!showOptions)
            }}
          ></div>
        )}
      </div>

      {showOptions ? (
        <div 
          className='searchForList-box-lists'
          onMouseLeave={(e) =>{
            setShowSubListOptions(false)
            setSubListOptions([])
          }}>
          <div
            className={"optionsPro-content-style-simple-select"}
            style={listStyle}
          >
            {filterOptions.map((option, ix) => (
              <div
                data-cy = {`simple-option-${ix}`}
                key={ix}
                title={option?.id ? option?.id : ""}
                disabled={option?.disabled}
                className={`optionsPro-style-simple-select${subListOptions === option.subList ? ' force-hover' : ''}`}
                onMouseOver={(e) => {
                  setListOptions(option.id)
                  if (option.subList?.length > 0 ){
                    setSubListOptions(option.subList)
                    setShowSubListOptions(true)
                  } else {
                    setSubListOptions([])
                    setShowSubListOptions(false)
                  } 
                }}
                onClick={() => {
                  if (option.noSelected) return;
                  onClickOption({
                    target: {
                      name: idClick,
                      value: option,
                    },
                  });
                  setOptionSelected(option.label);
                  setTimeout(() => {
  setShowOptions(false);
}, 200);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
          {showSubListOptions && (
            <div
              className={"optionsPro-content-style-simple-select other"}
              style={listStyle}
            >
              {subListOptions.map((option, ix) => (
                <div
                  data-cy = {`select-sub-option-${ix}`}
                  key={ix}
                  title={option?.id ? option?.id : ""}
                  disabled={option?.disabled}
                  className={"optionsPro-style-simple-select"}
                  onClick={() => {
                    onClickOption({
                      target: {
                        name: idClick,
                        value: option,
                        valueMainList: listOptions
                      },
                    });
                    setSubListOptions([])
                    setShowSubListOptions(false)
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchForListComponent;
