import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { b64_to_utf8, cleanSessionStorageInfo, decryptUsingAES256, getdecrypt, sessionStorageSetItem } from "../bdb-services/global/global";
import '../bdb-services/bdb-interceptor-service/interceptor'
import { elasticRoleField, friendlyStatus, genericRole } from '../../constants'
import {
    GET_DOCUMENT_TYPE,
    GET_DOCUMENT_TYPE_SUCCESS,
    GET_DOCUMENT_TYPE_FAIL,
    POST_DOCUMENT_TYPE,
    POST_DOCUMENT_TYPE_SUCCESS,
    POST_DOCUMENT_TYPE_FAIL,
    GET_DOCUMENT_REQUEST_NUMBER,
    GET_DOCUMENT_REQUEST_NUMBER_SUCESS,
    GET_DOCUMENT_REQUEST_NUMBER_FAIL,
    UPLOAD_DOCUMENTS_CACHE,
    POST_ORDER_START,
    POST_ORDER_SUCCESS,
    POST_ORDER_FAIL,
    RESET_MODAL,
    RESET_INFO,
    PREVIEW_DOCUMENT,
    RESET_DOCUMENT_PREVIEW,
    UPDATE_PREVIEW_PROGRESS,
    SET_DELETE_MODAL,
    SET_INIT_FORM_VALUES,
    SET_INIT_DOCUMENTS_TYPES,
    FINISH_LOADING,
    START_LOADING,
    SHOW_MODAL_ERROR,
    HIDE_MODAL_ERROR,
    SHOW_CONFIRMATION_MODAL,
    ORDERS_DISBURSEMENT_SUCESS,
    DELETE_DATAFORM
} from '../bdb-types/index';
import { requestFactory } from '../bdb-services/bdb-self-assign-service/selfAssignService';

export function getDocumentsTypeByProduct(product,documentTypeId) {
    return async (dispatch) => {
        
        if (product !== null && product !== undefined) {
            dispatch(getDocumentsType(true));
            const documentType = documentTypeId == 'N' ? 'PJ':'PN'
            const userRole = sessionStorage.getItem(elasticRoleField) ? getdecrypt(elasticRoleField) : genericRole;
            const url = `${process.env.REACT_APP_TRANSVCMP}transvcmp/get-types/${product}?userRole=${userRole}&documentType=${documentType}`;
            let typesDocs = [];
            await axios.get(url)
                .then((response) => {
                    typesDocs = response.data;
                    typesDocs = orderDocumentsByRequired(typesDocs);
                    typesDocs = typesDocs.map((e, i) => {
                        const nameForDb = e.friendlyOriginalName + (e.friendlyName === 'Otros (si aplica)' ? ` ${uuid()}` : '');
                        return { ...e, index: i, nameForDb }
                    });
                    dispatch(getDocumentsTypeSucess(typesDocs));
                    sessionStorageSetItem('typesDocuments', typesDocs);
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(getDocumentsTypeFail([]));
                    dispatch(resetInfo());
                })
        }
    }
}

export function hideModalError() {
    return async (dispatch) => {
        dispatch(setHideModalError());
    };
}

export function deleteDocument(id, document) {
    return async (dispatch) => {
        dispatch(setStartloading());
        const cloudPath = document.cloudPath ? document.cloudPath : '';
        const typeName = document.nameForDb ? document.nameForDb : '';
        const url = `${process.env.REACT_APP_TRANSVCMP}transvcmp/delete-document-disbursement?id=${id}&key=${cloudPath}&typeName=${typeName}`;
        await axios.delete(url)
            .then(response => {
                dispatch(setFinishLoading());
            })
            .catch(error => {
                dispatch(setFinishLoading());
                dispatch(setShowModalError());
                console.error(error);
            });
    }
}

export function updateDocument(typesData, index, orderDisbursementData) {
    return async (dispatch) => {
        const documents = [...typesData];
        const document = documents[index];
        if (document.file) {
            const { creditProject, identiCustomer, numberControl, typeCustomerObj } = orderDisbursementData;
            const fileRender = new FileReader();
            fileRender.readAsDataURL(document.file);
            let dataFile = {}
            fileRender.onload = function () {
                const completeBase64 = (fileRender.result).split(',');
                const base64 = completeBase64 ? completeBase64[1] : undefined;
                dataFile['fileName'] = document.file.name;
                dataFile['typeName'] = document.nameForDb;
                dataFile['stream'] = base64;
                dataFile['identiCustomer'] = identiCustomer;
                dataFile['typeCustomer'] = typeCustomerObj.id;
                dataFile['controlNumber'] = numberControl;
                dataFile['creditProject'] = creditProject;
                if (document.expeditionDate) {
                    dataFile['expeditionDate'] = formatedDate(document.expeditionDate);
                }
                uploadDocument(dispatch, dataFile, documents, index);
            }
        }
    }
}

async function uploadDocument(dispatch, request, typesData, index) {
    const userRole = sessionStorage.getItem(elasticRoleField) ? getdecrypt(elasticRoleField) : genericRole;
    const url = `${process.env.REACT_APP_TRANSVCMP}transvcmp/index-document-disbursement`;
    const body = { ...request, userRole: userRole };
    dispatch(setStartloading());
    await axios.post(url, body)
        .then(response => {
            typesData[index]['cloudPath'] = response.data ? response.data.key : undefined;
            dispatch(uploadCache(typesData));
            dispatch(setFinishLoading());
        })
        .catch(error => {
            console.error(error);
            typesData[index]['upload'] = undefined;
            typesData[index]['file'] = undefined;
            dispatch(uploadCache(typesData));
            dispatch(setFinishLoading());
            dispatch(setShowModalError());
        });
}

export function transferDocuments(typesData, orderDisbursementData) {
    return async (dispatch) => {
       dispatch(setStartloading());
        const { numberControl,creditProject,identiCustomer,productObj,typeCustomerObj } = orderDisbursementData;
        const userLogin = sessionStorage.getItem('userLogin');
        const reqUser =
         decryptUsingAES256(
            userLogin,
            b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), 
            b64_to_utf8(process.env.REACT_APP_AES_KEY)
        );

        const toEmail = `${reqUser}@bancodebogota.com.co`;
   

        let emailBody = {
            toEmail: toEmail,
            controlNumber: numberControl,
            documents: []
        };
        for (const document of typesData) {
            if (document.nameForDb == 'Archivo plano') {
                const base64 = await fileToBase64(document.file)

                emailBody.documents.push({
                    base64: base64,
                    fileName: document.file.name
                })

            }
          
        }
      
        const customEmailUrl = `${process.env.REACT_APP_NOTIFICATION_ENDPOINT}custom-email`;
        const body = {}
        const officeCodeORuserLogin = sessionStorage.getItem('officeCode') ? sessionStorage.getItem('officeCode') : sessionStorage.getItem('userLogin')
        body['creditProject'] = creditProject;
        body['numberControl'] = numberControl;
        body['product'] = productObj.label;
        body['identiCustomer'] = identiCustomer;
        body['typeCustomer'] = typeCustomerObj.id;
        body['filingOfficeCode'] = decryptUsingAES256(officeCodeORuserLogin, b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        body['reqUser'] = reqUser;
        const url = `${process.env.REACT_APP_SELF_ASSIGN_ENDPOINT}case-stage/disbursement-order`
        axios.post(url, body)
            .then((respuesta) => {
                dispatch(setFinishLoading());
                dispatch(setShowConfirmationModal());
                axios.post(customEmailUrl,emailBody).catch(error => {
                    console.error(error);
         
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch(setFinishLoading());
                dispatch(setShowModalError());
                dispatch(postOrderFail(false));
            });
       
       
      
           
    }
}

export function getDocumentsByRequestNumber({ idRequest, product }) {
    return async (dispatch) => {
        if (idRequest !== undefined && product !== undefined) {
            dispatch(getDocumentByRequest(true));
            const userRole = sessionStorage.getItem(elasticRoleField) ? getdecrypt(elasticRoleField) : genericRole;
            const url = `${process.env.REACT_APP_TRANSVCMP}transvcmp/get-documents-disbursement/${idRequest}/${product}?userRole=${userRole}`
            const custIdentType = sessionStorage.getItem('typeCustomer');
            const custIdentNum = sessionStorage.getItem('numberCustomer');
            const headers = {
                'X-CustIdentType': custIdentType,
                'X-CustIdentNum': custIdentNum
            }
            await axios.get(url, { headers })
                .then((response) => {
                    dispatch(getDocumentByRequestSucess(response.data));
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(getDocumentByRequestFail([]));
                });
        }
    }
}

export function saveFormOrderDisbursement(data) {
    return async (dispatch) => {
        dispatch(setStartloading())
        const { numberControl } = data
        const path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/request-number/${numberControl}`;
        await axios.get(path).then((res)=>{
            dispatch(setFinishLoading())
            const errorModal = {
                message:'Ya existe una solicitud con este No de control',
                type:"error"
            }
            dispatch(setShowModalError(errorModal))
        }).catch((err)=>{
            dispatch(setFinishLoading())
            dispatch(postOrderSucess(data));
            sessionStorage.setItem('typeCustomer', data.typeCustomerObj.id);
            sessionStorage.setItem('numberCustomer', data.identiCustomer);
            sessionStorageSetItem('formOrder', data);
        });
      
       
    }
};



export function getOrdersDisbursement(){
    return async (dispatch) => {
        const userLogin = sessionStorage.getItem('userLogin');
        const reqUser = decryptUsingAES256(
            userLogin,
            b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), 
            b64_to_utf8(process.env.REACT_APP_AES_KEY)
        );
        const params = {
            process: 'disbursementOrder',
            management: 'groupDisbursement',
            indexName: 'REQ.BY.STATUS.ORDER.DATE',
            reqUser
        };
        dispatch( setStartloading());
        await requestFactory( 'requests/status/ALL', 'get', null, params, null )
            .then( response => {
                dispatch( getOrdersDisbursementSuccess( response.data.map(item=> {
                    return {...item,
                            reqStatus:friendlyStatus[item.reqStatus]?friendlyStatus[item.reqStatus]:friendlyStatus["default"]
                            }
                    }
                )
             ));
            })
            .catch( err => {
                console.error(err)
            });
        dispatch( setFinishLoading())
    }
}

export function uploadCache(data) {
    return async (dispatch) => {
        dispatch(uploadCacheSuccess(data));

    }
}
export function resetModalConfirmation() {
    return async (dispatch) => {
        dispatch(resetModal());
    }
}
export function resetData() {
    return async (dispatch) => {
        dispatch(resetInfo());
        cleanSessionStorageInfo();
    }
}

export function previewDocument(types, index) {
    return async (dispatch) => {
        if (types && types[index]) {
            let file = types[index].file;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                types[index].src = reader.result.toString();
                dispatch(getPreviewDocument(types[index]));
            }
        }
    }
}

export function previewTifDocument(types, index) {
    return async (dispatch) => {
        if (types && types[index]) {
            dispatch(getPreviewProgress(true));
            let file = types[index].file;
            let filename = file.name ? file.name.split('.') : [];
            filename.splice(filename.length - 1);
            filename = filename.join('.');
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const tiffBase64 = reader.result.toString().split('base64,')[1];
                const url = `${process.env.REACT_APP_TRANSVCMP}transvcmp/convert-tiff-to-png`;
                const body = {
                    'stream': tiffBase64
                };
                await axios.post(url, body)
                    .then(response => {
                        const blob = b64ToBlob(response.data.stream, ['data:image/png']);
                        file = new File([blob], `${filename}.png`, { type: 'image/png', lastModified: Date.now() });
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            types[index].src = reader.result.toString();
                            dispatch(getPreviewDocument(types[index]));
                        }
                    })
                    .catch(error => {
                        dispatch(getPreviewProgress(false));
                        console.error('An error has ocurred attempting to convert tif to png', error);
                    });
            }
        }
    }
}

export function resetDocumentPreview(type) {
    return async (dispatch) => {
        if (type) {
            dispatch(getResetDocumentPreview(type));
        }
    }
}

export function showDeleteModal(show) {
    return async (dispatch) => {
        dispatch(setShowDeleteModal(show));
    }
}

export function deleteDocumentTypeSuccess() {
    return async (dispatch) => {
         dispatch(update_DeleteDataform());
    }
}

const update_DeleteDataform = option => ({
    type: DELETE_DATAFORM,
    payload: option
});

const getDocumentsType = option => ({
    type: GET_DOCUMENT_TYPE,
    payload: option
});
const getDocumentsTypeSucess = option => ({
    type: GET_DOCUMENT_TYPE_SUCCESS,
    payload: option
});
const getDocumentsTypeFail = option => ({
    type: GET_DOCUMENT_TYPE_FAIL,
    payload: option
});
const postDocumentType = option => ({
    type: POST_DOCUMENT_TYPE,
    payload: option
});
const postDocumentTypeSucess = option => ({
    type: POST_DOCUMENT_TYPE_SUCCESS,
    payload: null
});
const postDocumentTypeFail = option => ({
    type: POST_DOCUMENT_TYPE_FAIL,
    payload: option
});
const getDocumentByRequest = option => ({
    type: GET_DOCUMENT_REQUEST_NUMBER,
    payload: option
});
const getDocumentByRequestSucess = option => ({
    type: GET_DOCUMENT_REQUEST_NUMBER_SUCESS,
    payload: option
});
const getDocumentByRequestFail = option => ({
    type: GET_DOCUMENT_REQUEST_NUMBER_FAIL,
    payload: option
});

const resetModal = () => ({
    type: RESET_MODAL,
    payload: null
});
const resetInfo = () => ({
    type: RESET_INFO,
    payload: null
});

const uploadCacheSuccess = state => ({
    type: UPLOAD_DOCUMENTS_CACHE,
    payload: state
});
const postOrderStart = option => ({
    type: POST_ORDER_START,
    payload: option
});
const postOrderSucess = option => ({
    type: POST_ORDER_SUCCESS,
    payload: option
});
const postOrderFail = option => ({
    type: POST_ORDER_FAIL,
    payload: option
});

const getPreviewDocument = type => ({
    type: PREVIEW_DOCUMENT,
    payload: type
});

const getResetDocumentPreview = type => ({
    type: RESET_DOCUMENT_PREVIEW,
    payload: type
});

const getPreviewProgress = loading => ({
    type: UPDATE_PREVIEW_PROGRESS,
    payload: loading
});

const setShowDeleteModal = option => ({
    type: SET_DELETE_MODAL,
    payload: option
});

export const setInitFormValues = option => ({
    type: SET_INIT_FORM_VALUES,
    payload: option
})

export const setInitDocumentsTypes = option => ({
    type: SET_INIT_DOCUMENTS_TYPES,
    payload: option
})
export const setStartloading = () => ({
    type: START_LOADING
});

export const setFinishLoading = () => ({
    type: FINISH_LOADING
});

export const setShowModalError = (message) => ({
    type: SHOW_MODAL_ERROR,
    payload: message
});

export const setHideModalError = () => ({
    type: HIDE_MODAL_ERROR
});

export const setShowConfirmationModal = () => ({
    type: SHOW_CONFIRMATION_MODAL
});

export const getOrdersDisbursementSuccess = ( payload ) => ({
    type: ORDERS_DISBURSEMENT_SUCESS,
    payload
})

function orderDocumentsByRequired ( documents ) {
    const noRequired = documents.filter( type => {
        return type.friendlyName.toLowerCase().includes('(si aplica');
    })
    const required = documents.filter(type => {
        return !type.friendlyName.toLowerCase().includes('(si aplica');
    })
    return [...required, ...noRequired];
}

function b64ToBlob(base64, contentType) {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}

function formatedDate(date) {
    const dateSplit = date.split('/')
    return `${dateSplit[2]}${dateSplit[1]}${dateSplit[0]}`
}
const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var base64 = reader.result
            resolve(base64.split(',')[1])
        }
        reader.onerror = error => reject(error);
    })

}