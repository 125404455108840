import { 
    REMOVE_KEY_PAIR,
    SET_KEY_PAIR,
    UPDATE_FILE,
    SET_GEOMETRIES,
    SET_PAGE_NUMBER,
    SET_MAX_PAGES,
    SET_IS_DRAWING,
    SET_CURRENT_KEY_PAIR,
    SET_ONBASE_TYPE,
    CHANGE_LOADING_VISIBILITY
 } from '../bdb-types';
import { v4 as uuidv4 } from 'uuid'
import { getdecrypt } from '../bdb-services/GeneralService/globalService';

const initialState = {
    topMargin: 350,
    leftMargin: 600,
    file: null,
    keyPairs: [[]],
    geometries: [],
    pageNumber: 1,
    maxPages: 1,
    isDrawing: false,
    currentKeyPair: null,
    onBaseType: null,
    loading: false,
    fileNameKey: ""
}

export default function(state = initialState, action) {
    switch(action.type) {
        case REMOVE_KEY_PAIR:
            let tmpKeyPairs = [...state.keyPairs]
            tmpKeyPairs[action.payload.page] = tmpKeyPairs[action.payload.page].toSpliced(action.payload.idx, 1)
            return {
                ...state,
                keyPairs: [...tmpKeyPairs]
            }
        case SET_KEY_PAIR:
            return {
                ...state,
                keyPairs: action.payload
            }
        case UPDATE_FILE:
            const user = getdecrypt('userLogin');
            return {
                ...initialState,
                fileNameKey: action.payload ? `${user ? user : "Generic"}_${uuidv4()}` : null,
                file: action.payload
            }
        case SET_GEOMETRIES:
            return {
                ...state,
                geometries: action.payload
            }
        case SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            }
        case SET_MAX_PAGES:
            return {
                ...state,
                maxPages: action.payload
            }
        case SET_IS_DRAWING:
            return {
                ...state,
                isDrawing: action.payload
            }
        case SET_CURRENT_KEY_PAIR:
            return {
                ...state,
                currentKeyPair: action.payload
            }
        case SET_ONBASE_TYPE:
            return {
                ...state,
                onBaseType: action.payload
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload              
            }
        default:
            return state;
    }
}