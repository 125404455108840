import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getReserves } from '../../../bdb-actions/fngActions';
import DailyManagement from '../../../bdb-components/DailyManagementComponent/DailyManagement';
import Loading from '../../../bdb-components/LoadingComponent/Loading';
import DatePickerCustomInput from '../../../bdb-components/ProductivityFormComponent/DatePickerCustomInput';
import Table from '../../../bdb-components/TableComponent/Table';
import { exportFromJsonToExcelFile, getBeforeWorkingDay } from '../../../bdb-helpers/utils';
import './fngReportStyles.scss';
import { columnsFngReport, columnsTableFng, widthsColumnsExcelFile } from '../../../../constants';

const nameSheet = 'Reporte';
const dayBeforeWorkingDay = getBeforeWorkingDay( moment() ).toDate();

const FngReport = () => {
    const [date, setDate] = useState( dayBeforeWorkingDay );
    const dispatch = useDispatch();
    const { reserves, reservesStatus ,loading } = useSelector( state => state.fng );

    const updateTable = () => {
        dispatch( getReserves( date ) );
    };
    
    const handleInputChange = (e) => {
        setDate(e);
    };

    const handleClick = ( {target} ) => {
        const button = target.id;

        if (button === "search"){
            updateTable();
        }
        if (button === "download"){
            const nameFile = `${nameSheet}_${ moment( date ).format("DD_MM_YYYY")}.xlsx`;
            exportFromJsonToExcelFile( reserves, columnsFngReport, nameSheet, nameFile, widthsColumnsExcelFile );
        }
    };

    useEffect(() => {
        updateTable();
    }, [])
    
    return (
    <section>
        { loading && <Loading />}
        <DailyManagement relative={ false }/>
        <h2 className='font-OpenSans-Regular tittle-report'>Reporte garantías FNG</h2>
        <div className='row-form'>
            <div className='col-date'>
                <label className='sub-tittle-report'>Fecha de reporte</label>
                <DatePicker
                    selected={ date }
                    onChange={ handleInputChange }
                    placeholderText="Selecciona"
                    dateFormat="dd-MM-yyyy"
                    customInput={<DatePickerCustomInput
                                    styleDiv='leader-dateInput'
                                    styleInput={'date-input leaderProd-select-time leaderProd-input-content'}
                                    styleArrow={'leader-arrow-left leader-arrow-select'}
                                />}
                />
                </div>
            <button
                id="search"
                className='search-button'
                onClick={ handleClick}
            >
                Buscar
            </button>

        </div>
        <Table
            result={ reservesStatus }
            headers={ columnsTableFng }
            group={ "fng" }
            showMonth={ false }
            showTotals={ false }
            onlyProcess={ "fngReserves" }
            tittleComplement={ "registros" }
            container={ { width: '100%'} }
        />
        <button
            id="download"
            className='download-btn'
            onClick={ handleClick}
            disabled={ reserves.length === 0 }
        >
            Descargar reporte
            <div className='upload-icon'></div>
        </button>
            
    </section>
  )
}

export default FngReport