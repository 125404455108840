import React from 'react';
import Modal from "react-bootstrap/Modal";
import '../../../assets/styles/ComponentsStyles/modals.scss'

export const ErrorModal = ( {show = false, handleButton, type, message, title}) => {
  return (
    <Modal show={show} centered >
        <Modal.Header bsPrefix="header-modal">
            <div className={"icon-" + (type ? type : "error")}></div>
        </Modal.Header>
        <Modal.Body bsPrefix="body-modal">
            <div className="tittle-modal">{title}</div>
            <div className="sub-tittle-modal"
            dangerouslySetInnerHTML={{__html: message ? message : "Se presentó un error inesperado"}}
            ></div>
        </Modal.Body>
        <Modal.Footer bsPrefix="footer-modal">
            <button 
                className="footer-button-modal width-100"
                id="Accept"
                onClick={ handleButton }>OK</button>
        </Modal.Footer>
    </Modal>
  )
    
};
