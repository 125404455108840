import { 
    ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT,
    START_DYNAMIC_MODAL_COMPONENT,
    FINALIZE_DYNAMIC_MODAL_COMPONENT,
    SEND_VECTOR_DYNAMIC_MODAL_COMPONENT,
    EDIT_DYNAMIC_MODAL_COMPONENT
} from '../bdb-types/index';

export function startDynamicModal(vector) {
    return async (dispatch) => {
        dispatch( startDynamicModalAction(vector));
    }
}

export function activeBtnDynamicModal(data) {
    return async (dispatch) => {
        dispatch( activeBtnDynamicModalAction(data));
    }
}

export function finalizeDynamicModal() {
    return async (dispatch) => {
        dispatch( finalizeDynamicModalAction(null));
    }
}

export function sendVectorDynamicModal(data) {
    return async (dispatch) => {
        dispatch( sendVectorDynamicModalAction(data));
    }
}


const startDynamicModalAction = status => ({
    type: START_DYNAMIC_MODAL_COMPONENT,
    payload: status
});

const activeBtnDynamicModalAction = status => ({
    type: ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT,
    payload: status
});

const finalizeDynamicModalAction = status => ({
    type: FINALIZE_DYNAMIC_MODAL_COMPONENT,
    payload: status
});

const sendVectorDynamicModalAction = status => ({
    type: SEND_VECTOR_DYNAMIC_MODAL_COMPONENT,
    payload: status
});

export function editDynamicTable(data) {
    return async (dispatch) => {
        dispatch( editDynamicTableAction(data));
    }
}

const editDynamicTableAction = status => ({
    type: EDIT_DYNAMIC_MODAL_COMPONENT,
    payload: status
});