import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoadingVisibility, queryGuarantee, resetQueryGuarantee } from '../../../bdb-actions/fngActions'

import './FngSearch.css'
import Loading from "../../../bdb-components/LoadingComponent/Loading";
import BackLink from "../../../bdb-components/BackLinkComponent/BackLink";

export const FngSearch = ( { setGuaranteeInfo } ) => {

    const dispatch = useDispatch();
    const [ buttonDisabled, setButtonDisabled ] = useState( true );
    const [ error, setError ] = useState( undefined );
    const [ guaranteeNumber, setGuaranteeNumber ] = useState( '' );
    const guaranteeInfo = useSelector( state => state.fng.guaranteeInfo );
    const loading = useSelector( state => state.fng.loading );

    const isEmptyJson = ( json ) => {
        const jsonConstructor = ( {} ).constructor;
        return !json || ( json.constructor === jsonConstructor && Object.keys( json ).length === 0 ) || json.constructor !== jsonConstructor;
    };

    useEffect( () => {
        setError( undefined );
    }, [] );

    useEffect( () => {
        if ( !guaranteeInfo ) {
            return;
        }
        if ( isEmptyJson( guaranteeInfo ) ) {
            setButtonDisabled( false );
            setError( 'El número de garantía no existe o no aplica' );
        } else if ( guaranteeInfo.error ) {
            setButtonDisabled( false );
            setError( guaranteeInfo.errorDescription );
        } else {
            setButtonDisabled( true );
            setError( undefined );
            setGuaranteeInfo( guaranteeInfo );
            dispatch( resetQueryGuarantee() );
        }
    }, [ guaranteeInfo ] );

    const submitHandle = ( e ) => {
        e.preventDefault();
        dispatch( changeLoadingVisibility( true ) );
        dispatch( queryGuarantee( guaranteeNumber ) );
    };

    const handleInputMessage = ( e ) => {
        const value = e.value.replace(/[^\d]/g, '');
        setGuaranteeNumber( value.replace(/[^\d]/g, '') );
        if ( !value ) {
            return;
        }
        if ( value.length < 10 ) {
            setButtonDisabled( true );
            setError( 'El número de garantía debe tener mínimo 10 dígitos' );
        } else {
            setButtonDisabled( false );
            setError( undefined );
        }
    };

    return <>
        { loading ?
            <Loading message={ 'Consultando garantía' }/> :
            <form onSubmit={ submitHandle }>
                <div className='search-form-container'>
                    <p className='fng-search-title'>
                        Solicitar reserva FNG
                    </p>
                    {
                        error ? (
                            <div className='search-fng-error'>
                                { error }
                            </div> ) : ''
                    }
                    <div className='search-form-input-title-container'>
                        <p className='search-form-input-title'>
                            Número de garantía
                        </p>
                    </div>
                    <div className='search-form-input-container'>
                        <input
                            value={ guaranteeNumber }
                            type='text'
                            id="searchGuaranteeNumber"
                            maxLength='15'
                            className={ 'search-form-input' + ( error ? ' search-form-input-error' : '' ) }
                            placeholder='Ingresa número de garantia'
                            onChange={ ( { target } ) => handleInputMessage( target ) }
                        />
                        <button
                            type='submit'
                            id='searchQueryButton'
                            className={ buttonDisabled ? 'search-query-btn-disabled' : 'search-query-btn' }
                            disabled={ buttonDisabled }>
                            Consultar
                        </button>
                    </div>
                </div>
            </form>
        }
    </>

}
