import React, { Fragment } from 'react'
import LeaderProductivity from '../../bdb-components/LeaderProductivityComponent/LeaderProductivity'

const ReportProductivity = () => {
  return (
    <Fragment>
        <LeaderProductivity />
    </Fragment>
  )
}

export default ReportProductivity