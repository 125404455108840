import React, { Fragment, useEffect, useState } from 'react';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import DynamicTableComponent from '../../bdb-components/DynamicTableComponent/DynamicTableComponent';
import './PrioritizationSettingsLibrances.css';
import { getConventionsConfig, updateConventionsUser, updateConventionsConfig} from '../../bdb-actions/libPrioritizationActions';
import { startDynamicTable, updateFilterDynamicTable, disableButtonTableComponent } from '../../bdb-actions/dynamicTableActions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { updateDynamicTable } from '../../bdb-actions/dynamicTableActions';

export const optionsTabDefault = {
    VoBoCentralized: { label: 'VoBo centralizado', flag: true },
    VoBoPayment: { label: 'VoBo pagaduría', flag: false }
};

const PrioritizationSettingsLibrances = () => {
 
    const dispatch = useDispatch();

    const loading = useSelector(state => state.prioritizationLib.loading );
    const data = useSelector(state => state.prioritizationLib.dataConfig);
    const dataTable = useSelector(state => state.dynamicTable.dataSet);
    const buttonAdd = useSelector(state => state.dynamicTable.activeButton);
    const selected = useSelector(state => state.prioritizationLib.selected);
    const add = useSelector(state => state.dynamicTable.alertAdd);
    const edit = useSelector(state => state.dynamicTable.alertEdit);
    const empty = useSelector(state => state.dynamicTable.alertEmpty);

    const [optionsTab, setOptionsTab] = useState(optionsTabDefault);
    const [optionsTabTemp, setOptionsTabTemp] = useState(optionsTabDefault);
    const [optionsTabKeys, setOptionsTabKeys] = useState(Object.keys(optionsTabDefault));
    const [dataSet, setDataSet] = useState(null);
    const [indexSelected, setIndexSelected] = useState(selected);
    const [activeButton, setActiveButton] = useState(buttonAdd);
    const [alertAdd, setAlertAdd] = useState(false);
    const [alertEdit, setAlertEdit] = useState(false);
    const [alertEmpty, setAlertEmpty] = useState(false);

    useEffect(() => {
        const loadConventionsConfig = () => dispatch(getConventionsConfig());
        loadConventionsConfig();
    }, []);

    useEffect(() => {
        setAlertAdd(add);
    }, [add]);

    useEffect(() => {
        setAlertEdit(edit);
    }, [edit]);

    useEffect(() => {
        setAlertEmpty(empty);
    }, [empty]);

    useEffect(() => {
        const loadNewOptions = () => setOptionsTab(optionsTabTemp);
        loadNewOptions();
    }, [optionsTabKeys]);

    useEffect(() => {
        setActiveButton(buttonAdd);
    }, [buttonAdd]);

    useEffect(() => {
        const initDynamicTableComponent = () => {
            dispatch( startDynamicTable(data))
        }
        initDynamicTableComponent();
    }, [data]);

    useEffect(() => {
        const initDataSet = () => {
            setDataSet(dataTable);
        }
        initDataSet();
    }, [dataTable]);

    useEffect(() => {
        const initIndexSelected = () => {
            setIndexSelected(selected);
        }
        initIndexSelected();
    }, [selected]);

    const sendDataSet = () => {
       if (activeButton) {
            dispatch(updateConventionsConfig(dataSet['VoBoCentralized']['dataSet'], indexSelected));
            dispatch(updateConventionsUser(dataSet['VoBoPayment']['dataSet']));
            dispatch(disableButtonTableComponent());
       }
    }

    const optionDisplayStyle = (flag, index) => {
        const display = flag ? 'enable' : 'disabled';
        const space = index + 1 < optionsTabKeys.length ? 'margin-tab-librances' : '';
        return `tab-${display}-librances font-tab-librances color-${display}-librances ${space}`;
    }

    const changeOption = (optionKey) => {
        let options = optionsTab;
        optionsTabKeys.forEach(interactionKey => {
            options[interactionKey]['flag'] = interactionKey === optionKey ? true : false;
        });
        setOptionsTabKeys(Object.keys(options));
    }

    const getSubtitle = () => {
        return optionsTab['VoBoCentralized']['flag'] ?
            'Selecciona los convenios que deseas priorizar':'Agrega los convenios que deseas asignarle un gestor';
    }

    const filterOptions = filter => {
        dispatch( updateFilterDynamicTable(filter));
    }

    const showAlert = (styleAlert, messageAlert) => {
        return <div className={`structure-alert ${styleAlert}`}>{messageAlert}</div>
    }

    return ( 
        <Fragment>
            <LeaderOptions />
            {loading ?  <Loading /> : null}
            <div className="flex-center">
                <div className="tittle">Configuración de la demanda</div>
            </div>
            <div className="flex-center">
              <div className="div-subtittle">
                <div className="subtittle">{getSubtitle()}</div>
              </div>
            </div>
            <div className="padding-27">
              <div className="flex-center">
                <div>
                  <div onClick={() => sendDataSet()} 
                    className={activeButton ?  'button-apply-enable text-button' : 'button-apply-disable text-button'}
                    disabled={!activeButton}>
                    Aplicar cambios</div>
                </div>
              </div>
              <div>
                { alertEmpty ? <div className="d-flex content-alert">
                    {showAlert('orange-alert', 'No se encontraron resultados para tu búsqueda')}
                </div> : null }
                { alertEdit ? <div className="d-flex content-alert">
                    {showAlert('green-alert', 'El convenio se editó con éxito, ya puedes aplicar cambios')}
                </div> : null }
                { alertAdd ? <div className="d-flex content-alert">
                    {showAlert('green-alert', 'El convenio se agregó con éxito, ya puedes aplicar cambios')}
                </div> : null }
              </div>
            </div>
            <div className="header-tabs-librances">
                <div className="tabs-content-librances">
                    { optionsTabKeys.map( (optionKey, index) => (
                        <div key={index} className={optionDisplayStyle(optionsTab[optionKey]['flag'], index)}
                            onClick={() => {changeOption(optionKey)}}>{optionsTab[optionKey]['label']}</div> 
                    ))}
                </div>
                <div className="input-div-librances">
                    <input onChange={(e) => {filterOptions(e.target.value)}} 
                    className="input-search-librances" placeholder="Buscar convenio"></input>
                </div>
            </div>
            <div>
                {
                    dataSet ? 
                        optionsTabKeys.map( (optionKey, index) => (
                            optionsTab[optionKey]['flag'] ? 
                                <DynamicTableComponent
                                    key={index}
                                    data={dataSet[optionKey]}
                                    dataDefault={dataSet}
                                    id={optionKey}
                                    indexSelected={indexSelected}
                                /> : null
                        )) : null
                }
            </div>
        </Fragment>
       );
}
 
export default PrioritizationSettingsLibrances;