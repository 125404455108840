import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import "./historyFileComponent.scss";
import "../../bdb-views/UploadLargeFilesView/UploadLargeFilesView.scss";
import { useDispatch } from "react-redux";
import { getHistoryFiles } from "../../bdb-actions/uploadLargeFilesActions";

const classState = {
  RUNNING: {
    label: "Procesando",
    class: "info",
  },
  STARTING: {
    label: "Iniciando",
    class: "info",
  },
  STOPPING: {
    label: "Deteniendo",
    class: "info",
  },
  WAITING: {
    label: "Esperando",
    class: "info",
  },
  FAILED: {
    label: "Fallido",
    class: "error",
  },
  TIMEOUT: {
    label: "Fallido",
    class: "error",
  },
  ERROR: {
    label: "Fallido",
    class: "error",
  },
  STOPPED: {
    label: "Detenido",
    class: "error",
  },
  SUCCEEDED: {
    label: "Exitoso",
    class: "success",
  },
};

const HistoryFileComponent = ({ files }) => {
    const dispatch = useDispatch();
    const handleRefresh = () => {
        dispatch(getHistoryFiles())
    }
  return (
    <section className="Insert_large_files_col" style={{marginTop: '15px'}}>
      <div
        style={{ position: "relative", marginTop: "15px", width: '84%' }}
      >
        <h3 className="historyFile-title">Historial</h3>
        <span className="historyFile-paragraph">
          Una vez cargados los documentos, podrás visualizar su estado de
          procesamiento y cargarlos nuevamente cuando sea fallido.
        </span>
        <div className="historyFile-box-refresh" onClick={handleRefresh}>
          <div className="historyFile-refresh-button"></div>
          <span className="historyFile-text-refresh">Actualizar</span>
        </div>
      </div>
      <table className="historyFile-table">
        <thead style={{ display: 'block'}}>
        <tr>
          <th>Fecha de carga</th>
          <th>Tipo de documento</th>
          <th>Estado</th>
        </tr>

        </thead>
        <tbody style={{ display: 'block', height:'191px', overflowY: 'auto'}}>
        {files.map((file, idx) => (
          <tr key={idx}>
            <td>{moment(file.date).local('es').format('DD MMM YYYY')}</td>
            <td>{file.name}</td>
            <td>
              <div
                className={`historyFile-box-tag-${
                  classState[file.state]?.class
                }`}
              >
                {classState[file.state]?.label}
              </div>
            </td>
          </tr>
        ))}

        </tbody>
      </table>
    </section>
  );
};

export default HistoryFileComponent;
