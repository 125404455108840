import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../../assets/styles/leaderStyle.css';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {validationSearchCaseNumber} from '../../../constants';

function b64_to_utf8( str ) {
  return decodeURIComponent(escape(window.atob( str )));
}

const LeaderOptions = () => {

  const history = useHistory();

  const buttonAdd = useSelector(state => state.dynamicTable.activeButton);
  const [activeButton, setActiveButton] = useState(buttonAdd);
  const management = b64_to_utf8(sessionStorage.getItem("management"));

  useEffect(() => {
    setActiveButton(buttonAdd);
  }, [buttonAdd]);

  const getSettings = () => {
    const management = b64_to_utf8(sessionStorage.getItem('management'));
    if (management === 'housing') {
      history.push('/finantial-leader/settings-priority-housing');
    }
    if (management === 'librances') {
      history.push('/finantial-leader/settings-librances');
    } 
    if (management === 'massiveBanking') {
      history.push('/finantial-leader/settings-priority');
    }
    if (management === 'groupDisbursement') {
      history.push('/finantial-leader/settings-center-disbursement');
    }
  }

  const getParametersSettings = () => {
    history.push('/finantial-leader/settings-parameters-disbursement');
  }
  
  const getActionRedirect = redirectPath => {
    const location = window.location.href.split('/').reverse();
    if (location[0] === 'settings-librances' && activeButton) {
      Swal.fire({
        icon: 'info',
        title: '¿Estás seguro de salir de la configuración?',
        html: 'Los cambios no aplicados se perderán.',
        showCancelButton: true,
        confirmButtonText: 'Cancelar',
        confirmButtonColor: '#002c76',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Salir',
      }).then( result => {
        if (result.isDismissed) {
          history.push(redirectPath);
        }
      });
    } else if(validationSearchCaseNumber.indexOf(management) !== -1) {
      history.push(redirectPath);
    }
  }
  
    return ( 
      <Fragment>
        <div>
            <div className="text-center my-5 content-option-leader">
              <div data-cy={`option-0`} onClick={() => {getActionRedirect('/finantial-leader')}} className="options">Indicadores</div> 
              &nbsp; | &nbsp; 
              <div data-cy={`option-1`} onClick={() => {getActionRedirect('/finantial-leader/cases')}} className="options">Buscar caso</div>
              {
                management === 'groupDisbursement' ?
                <>
                  &nbsp; | &nbsp; 
                  <div data-cy={`option-2`} onClick={() => {getActionRedirect('/finantial-leader/settings-librances')}} className="options">Configuración Libranza</div>
                </>: null

              }
              {
                !(global.isGroupVive() || global.isGroupDisbursement() ) &&
                  <>
                    &nbsp; | &nbsp; 
                    <div data-cy={`option-3`} onClick={() => {getSettings()}} className="options">Configuración</div>
                    &nbsp;
                  </>
              }
            </div>
        </div>
      </Fragment>
     );
}
 
export default LeaderOptions;