import { UPDATE_FILE, UPDATE_FILE_SUCCESS, UPDATE_FILE_ERROR,RESET_PLANT_FILE } from '../bdb-types/index';
import Swal from 'sweetalert2';
import axios from 'axios';
import interceptor from '../../interceptor';


export function updateFileAction(file) {
    const validationNumbers = file.part === file.total;
    return (dispatch) => {
        dispatch( UpdateUser(true) );
        try {
            axios.post( process.env.REACT_APP_SELF_API_ENDPOINT + 'upload/document', file)
                .then(function (response) {
                    const status = response.status;
                    if (validationNumbers && status === 200) {
                        file['note'] = 'FINAL';
                        file['arrayBytes'] = '';
                        setTimeout(() => dispatch(updateFileActionBasic(file)), 35000);
                    }
                });
        } catch (error) {
            dispatch( updatefileError(false) );
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error subiendo el archivo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
            console.log(error);
        }
    }
}

export function updateFileActionBasic(file) {
    return (dispatch) => {
        dispatch( UpdateUser(true) );
        try {
            axios.post( process.env.REACT_APP_SELF_API_ENDPOINT + 'upload/document', file)
                .then(function (response) {
                        const status = response.status;
                        const data = response.data['message'];
                        const fileName = data.replace( status === 202 ? 'This document already exists ' : 'Saved successfully! ', '');
                        const label = status === 202 ? 'se encuentra duplicado' : 'se subió con éxito';
                        dispatch( updatefileSuccess(false) );
                        Swal.fire({
                            icon: status === 202 ? 'warning' : 'success',
                            title: status === 202 ? 'Lo sentimos' : '¡Listo!',
                            text: `El archivo ${fileName} ${label}`,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: '#002c76'
                        });
                });
        } catch (error) {
            console.log(error);
            dispatch( updatefileError(false) );

            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error subiendo el archivo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

const UpdateUser = state => ({
    type: UPDATE_FILE,
    payload: true
});

const updatefileSuccess = state => ({
    type: UPDATE_FILE_SUCCESS,
    payload: state
});

const updatefileError = state => ({
    type: UPDATE_FILE_ERROR,
    payload: state
});
