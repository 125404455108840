import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import './styles.scss';
import { getManagementsAndProcesses, postInsertProductivity, updateActiveEdit, updateActiveEvent, resetActveEvent, isSameData, updateRepoblate, 
    initCardSumze, sessionManagement, identificationCard, enableManageArea, deleteProductivityCard , validationConflic, getManagementByVerticalMANAGER, updateCards} from '../../bdb-actions/productivityControlBoardActions';
import { getDateFormat, getTimeFormatted, sumStrings } from '../../bdb-helpers/utils';
import { ABSENCE, arrayInconsistency } from '../../../constants';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { deleteCtrlBrdById } from '../../bdb-services/GeneralService/controlboardService';
import DatePickerCustomInput from './DatePickerCustomInput';
import SearchForListComponent from '../SearchForListComponent/SearchForListComponent';

const selectStyle = {
    height: '40px',
    fontSize: '16px',
    zIndex: '5',
    padding: '9px 20px 9px 16px',
    color: '#4a4a4a',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHheight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #d6d6d6',
    backgroundColor: '#ffffff',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingRight: '48px'
};

const styleList = {
    maxHeight: '167px', 
    width: '50%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const styleArrow = {
    width: '15px',
    height: '15px',
    right: '18px',
    zIndex: '5',
};

const dataProcess = [
    "area",
    "id",
    "management",
    "process",
    "timeEnd",
    "timeInit",
    "observations",
]

const affectations =  [
     "rejecteds",
     "approveds",
     "amount",
     "total"
]

const nameIdSelectList = ["management", "area", "process"];
const nameIdInputsNumber = ["processAmount","approveds", "rejecteds", "total", "caseSmax"];
const enablesContentInit = { enableInfo: false, enableAmount: false, enableCases: false, enableSave: false, enableSaveChange: true, enableMasiveRegisters: false };
const ProductivityForm = () => {
    const IS_ADMINISTRATIVE = Boolean(sessionStorage.getItem('isAdministrative') === 'true')
    const dispatch = useDispatch();
    const { activeEvent, activeEventTempo, areaList, processList, restocking, prodCards, activeEdit, enableManager, changeArea, managementListManager } = useSelector(state => state.prodContBoard);
    const loading = useSelector(state => state.prodContBoard.loading);
    const { management, process, area, timeInit, timeEnd, approveds, rejecteds, total, observations, amount, processAmount, repoblate, caseSmax } = activeEvent;
    const [enablesContent, setEnablesContent] = useState(enablesContentInit);
    const [changeManagement, setchangeManagement] = useState(false);
   
    const handleInputChange = ({ target }) => {
        const temporalActiveEvent = { ...activeEvent };
        temporalActiveEvent[target.name] = target.name.includes("time")? 
                                                getTimeFormatted(target.value) 
                                            :
                                                nameIdInputsNumber.includes(target.name) ? 
                                                    target.name.includes("processAmount") ?  
                                                        (/^[\d]+[.][\d.]+/.test(target.value) && /[.]$/.test(target.value)) || /[.][\d]{3}$/.test(target.value) ?  
                                                            target.value.replace(/[^\d.]/g, '').replace(/^[^\d]/g,'').slice(0,-1)
                                                        :   
                                                            target.value.replace(/[^\d.]/g, '').replace(/^[^\d]/g,'')
                                                    :
                                                    target.value.replace(/[^\d]/g, '') 
                                                : 
                                                target.value;
        dispatch(updateActiveEvent(temporalActiveEvent));
    }
    const handleButtonSave = async (e) => {
            setEnablesContent({ ...enablesContent, enableSave: false, enableSaveChange: false });
            dispatch(postInsertProductivity({ ...activeEvent }, 'post'));
            dispatch(sessionManagement());
            setchangeManagement(true)
    }

    const editSave = async (e) => {
        if( activeEvent.area?.option.id !== activeEventTempo.area?.option.id && !requireSmax() ) {
            activeEvent.caseSmax = '0'
        }
        if (!!activeEvent && !!activeEvent.partitions && activeEvent.partitions.length > 0) {
            let toDeleteCard = {
                'id': activeEvent.id,
                'processType': activeEvent.processType,
                'partitions': activeEvent.partitions,
                'isAdministrative':IS_ADMINISTRATIVE
            }
            dispatch(deleteProductivityCard(toDeleteCard));
        } else {
                if(!!changeArea && (changeArea !== area.option.label)) {
                    await updateCtrBoard();
                    return;
                }
                dispatch(postInsertProductivity({ ...activeEvent }, 'put'));
                dispatch(sessionManagement());
                setchangeManagement(true)
        }
    }
    const updateCtrBoard = async () =>{
        let validationCart;
        await dispatch(validationConflic({ ...activeEvent }))
        .then(resp => {
           if(resp){
                let toDeleteCard = {
                    'id': activeEvent.id,
                    'processType': activeEvent.processType,
                    'partitions': activeEvent.partitions,
                }
                validationCart = resp;
                return deleteCtrlBrdById(toDeleteCard);
           }
        })
        .then(resp =>{
            if(validationCart){
                dispatch(postInsertProductivity({ ...activeEvent }, 'post', false));
                dispatch(sessionManagement());
                setchangeManagement(true)
            }
        })
    }

    const editCancel = () => {
        dispatch(initCardSumze());
        dispatch(enableManageArea(false));
        dispatch( resetActveEvent() );
        dispatch(updateRepoblate(false))
    }

    const isAffectations = () => {
        return ( !!area && !!area.option ) ? !ABSENCE.includes(area.option.id) : false;
    }
    const isAbsences = () => {
        return ( !!area && !!area.option )  ? area.option.id !== ABSENCE[0] : false;
    }

    const titleProcess = () =>{
        if ( arrayInconsistency.includes(area.option.id) ) {
            return 'Tipo de inconsistencia'
        }
        return 'Proceso'
    }

    const enableSaveFunction = ( enableInfo, validateCases, validateAmount) => {
      
        let response = 
        (enableInfo && (!!process && !!process.option) && !!timeInit && !!timeEnd)
        if(!IS_ADMINISTRATIVE){
            response = response &&  validateCases && validateAmount && (!requireSmax() || caseSmax)
        }
        return response
    }
    
    const requireSmax = () =>{
        return(area.option?.id === 'Fallas de Sistema' )

    }

    useEffect(()=>{
        dispatch(getManagementByVerticalMANAGER(false))
        return () =>{
            dispatch(updateCards([]))
            dispatch(resetActveEvent());
        }
    },[])

    useEffect(() => {
        if(!!approveds || !!rejecteds){
            let temporalActiveEvent = { ...activeEvent };
            temporalActiveEvent.total = sumStrings(approveds, rejecteds);
            dispatch(updateActiveEvent(temporalActiveEvent));
        }
    }, [approveds, rejecteds]);

    useEffect(() => {
        let temporalActiveEvent = { ...activeEvent };
        if (!enablesContent.enableAmount) {
            temporalActiveEvent.amount = "";
        }
        if (!enablesContent.enableCases || !enablesContent.enableInfo) {
            temporalActiveEvent.approveds = "";
            temporalActiveEvent.rejecteds = "";
            temporalActiveEvent.total = "";
        }
        dispatch(updateActiveEvent(temporalActiveEvent));
    }, [enablesContent.enableAmount, enablesContent.enableCases, enablesContent.enableInfo])


    useEffect(() => {
        let enableAmount = (!!process && !!process.option) ? process.option.hasAmount : false;
        let enableInfo = (!!management && !!management.option) && ( !!area && !!area.option);
        let enableCases = enableInfo && isAffectations();
        let validateCases = enableCases ? (!!approveds && !!total) : true;
        let validateAmount = enableAmount ? !!amount : true;
        let enableSave = false
    
        enableSave = enableSaveFunction( enableInfo, validateCases, validateAmount)
        let overrideFieldsDataProcess = requireSmax() ? [...dataProcess, "caseSmax"]: dataProcess;
        let enableSaveChange =  (enableSave) ?  !isSameData(activeEvent, activeEventTempo,  overrideFieldsDataProcess, affectations) : false;
        let enableMasiveRegisters= ( !!process && !!process.option  && Boolean(process.option.isMasive) )? true : false;
        let enablesContentTemporal = {
            enableSave,
            enableInfo,
            enableCases,
            enableAmount,
            enableSaveChange,
            enableMasiveRegisters
        }
        setEnablesContent(enablesContentTemporal);
        dispatch(identificationCard(prodCards))
       
    }, [activeEvent])

    useEffect(() => {
        if (!!management.option && !changeManagement ) {
            if (!restocking || !activeEdit) {
                let temporalActiveEvent = { ...activeEvent };
                if (!!temporalActiveEvent.area && temporalActiveEvent.area.option) temporalActiveEvent.area.option = null;
                if (!!temporalActiveEvent.process && temporalActiveEvent.process.option) temporalActiveEvent.process.option = null;
                dispatch(updateActiveEvent(temporalActiveEvent));
            } else{
                dispatch(updateActiveEdit(false))
            }
        }
        
        else if ((JSON.parse(sessionStorage.getItem("currentManagement"))?.id !== management.option?.id)|| changeManagement ) {
            let temporalActiveEvent = { ...activeEvent };
             temporalActiveEvent.management.option = JSON.parse(sessionStorage.getItem("currentManagement"));
            dispatch(updateActiveEvent(temporalActiveEvent));
            setchangeManagement(false);
        }
        
        if (!!management && !!management.option) {
            dispatch(getManagementsAndProcesses(management.option.id, undefined, IS_ADMINISTRATIVE));
        }

    }, [management])

     useEffect(() => {
        if (!!area && !!area.option) {
            if (!restocking || !activeEdit) {
                let temporalActiveEvent = { ...activeEvent };
                temporalActiveEvent.process = {'id':null , 'option':null};
                temporalActiveEvent.total = "";
                temporalActiveEvent.timeEnd = "";
                temporalActiveEvent.approveds = "";
                temporalActiveEvent.rejecteds = "";
                dispatch(updateActiveEvent(temporalActiveEvent));
            } else {
                dispatch(updateActiveEdit(false))
            }
        }
        if ( (!!area && !!management) && !!area.option && !!management.option) dispatch(getManagementsAndProcesses(management.option.id, area.option.id, IS_ADMINISTRATIVE)) 

    }, [area])  
    const onChangeSelect = (e) => {
        let dataSelect = !!e && e.target ? e.target.value : null;
        let temporalActiveEvent = {
            ...activeEvent,
            [e.target.name]: { id: [e.target.name], option:{...dataSelect} },
        };
        dispatch(updateActiveEvent(temporalActiveEvent));
    }
    return (
        <div className='productivity-full-box font-OpenSans-Regular'>
            {loading ? <Loading></Loading> : null}
            <div className='productivity-tittle'>Gestión diaria</div>
            <div className='productivity-sub-tittle'>Diligencia la información solicitada para registrar tu gestión diaria.</div>
            <div className='productivity-form-box'>
                <div className={'productivity-row ' + ((IS_ADMINISTRATIVE && enablesContent.enableInfo) ? 'mt-4 mb-5' : 'mb-10')}>
                    <div className='productivity-column'>
                        <span className='productivity-header-col'>Gerencia</span>

                        <SearchForListComponent
                                        idClick={'management'}
                                        selectedOption={ management?.option?.label }
                                        onClickOption={onChangeSelect} 
                                        options={ managementListManager }
                                        selectStyle={selectStyle}
                                        listStyle = {styleList}
                                        placeholder={'Selecciona'}
                                        showArrow={true}
                                        arrowStyles={styleArrow}
                                        onActiveContent={false}
                                    />
                  
                    </div>
                    <div className='productivity-column'>
                        <span className='productivity-header-col'>Área</span>
                        <SearchForListComponent
                                        idClick={'area'}
                                        selectedOption={ area?.option?.label }
                                        onClickOption={onChangeSelect} 
                                        options={ areaList }
                                        selectStyle={selectStyle}
                                        disabled={enableManager ? true : !management.option}
                                        listStyle = {styleList}
                                        placeholder={'Selecciona'}
                                        showArrow={true}
                                        arrowStyles={styleArrow}
                                        onActiveContent={false}
                                    />
                      
                    </div>
                </div>
                {(enablesContent.enableInfo && areaList.length > 0) &&
                    <>
                        <div className={'productivity-row ' + (IS_ADMINISTRATIVE ? 'mb-5' : 'mb-10')}>
                            <div className='productivity-column'>
                                <span className='productivity-header-col'>{!isAbsences() ? 'Tipo de Ausentismo' : titleProcess() }</span>
                                <SearchForListComponent
                                        idClick={'process'}
                                        selectedOption={ process?.option?.label }
                                        onClickOption={onChangeSelect} 
                                        options={ processList }
                                        selectStyle={selectStyle}
                                        disabled={false}
                                        listStyle = {styleList}
                                        placeholder={'Selecciona'}
                                        showArrow={true}
                                        arrowStyles={styleArrow}
                                        onActiveContent={false}
                                    />
                                
                            </div>
                           
                                <>
                                    <div className='productivity-column flex-col'>
                                        <span className='productivity-header-col'>Hora inicio</span>
                                        <DatePicker
                                            selected={getDateFormat(timeInit)}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "timeInit", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            timeFormat="HH:mm"
                                            timeCaption="time"
                                            dateFormat="HH:mm"
                                            customInput={<DatePickerCustomInput styleDiv='flex-center-c' styleInput={'productivity-select-time productivity-input-content'} styleArrow={'icon-arrow-simple-select'} />}
                                            minTime={getDateFormat("00:00:00")}
                                            maxTime={!!timeEnd ? getDateFormat(timeEnd) : getDateFormat("23:59:00")}
                                            timeIntervals={1}
                                            showTimeSelectOnly
                                            showTimeSelect
                                        />
                                    </div>
                                    <div className='productivity-column flex-col'>
                                        <span className='productivity-header-col'>Hora fin</span>
                                        <DatePicker
                                            selected={getDateFormat(timeEnd)}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "timeEnd", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            timeFormat="HH:mm"
                                            timeCaption="time"
                                            dateFormat="HH:mm"
                                            customInput={<DatePickerCustomInput styleDiv='flex-center-c' styleInput={'productivity-select-time productivity-input-content'} styleArrow={'icon-arrow-simple-select'} />}
                                            timeIntervals={1}
                                            minTime={!!timeInit ? getDateFormat(timeInit) : getDateFormat("00:00:00")}
                                            maxTime={getDateFormat("23:59:59")}
                                            showTimeSelectOnly
                                            showTimeSelect
                                        />
                                    </div>
                                </>
                        </div>
                        {!IS_ADMINISTRATIVE&&
                        <>
                        {(enablesContent.enableMasiveRegisters)?
                        <>
                            {(enablesContent.enableAmount)?
                                <>
                                <div className='productivity-row mb-10'>
                                    <div className='productivity-column flex-col'>
                                        <span className='productivity-header-col'>Cantidad procesos</span>
                                        <input
                                            className='productivity-input-box productivity-input-content'
                                            placeholder='#'
                                            name="processAmount"
                                            value={processAmount}
                                            onChange={handleInputChange}
                                            maxLength="5"
                                        ></input>
                                    </div>
                                    <div className='productivity-column flex-col'>
                                        <span className='productivity-header-col'>Reg. aprobados</span>
                                        <input
                                            className='productivity-input-box productivity-input-content'
                                            placeholder='#'
                                            name="approveds"
                                            value={approveds}
                                            onChange={handleInputChange}
                                            maxLength="5"
                                        ></input>
                                    </div>
                                    <div className='productivity-column'>
                                        <span className='productivity-header-col'>Monto</span>
                                        <div className='w-100'>
                                            <NumberFormat
                                            value={amount}
                                            displayType={"input"}
                                            className='productivity-input-box productivity-input-content'
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            prefix={"$ "}
                                            placeholder="$"
                                            renderText={(value) => <>{value}</>}
                                            onValueChange={(e) => handleInputChange({
                                                target: { name: "amount", value: e.value }
                                            })}
                                            maxLength="29"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='productivity-row mb-10'>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col'>Reg. devueltos</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                name="rejecteds"
                                                value={rejecteds}
                                                onChange={handleInputChange}
                                                maxLength="5"
                                            ></input>
                                        </div>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col font-weight-bold'>Total registros</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                readOnly
                                                value={total}
                                            ></input>
                                        </div>
                                        <div className='productivity-column-big'>
                                    <span className='productivity-header-col'>Observaciones</span>
                                    <textarea
                                        className='productivity-input-box-big productivity-input-box productivity-input-content'
                                        placeholder='Escribe las observaciones (máximo 200 caracteres).'
                                        maxLength={200}
                                        name="observations"
                                        value={observations}
                                        onChange={handleInputChange}
                                    ></textarea>
                                </div>
                                </div>
                                </>                                                                   
                            :
                            <div className='productivity-row mb-60'>
                                <div className='productivity-column-big'>
                                    <div className='productivity-row flex-wrap'>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col'>Cantidad procesos</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                name="processAmount"
                                                value={processAmount}
                                                onChange={handleInputChange}
                                                maxLength="5"
                                            ></input>
                                        </div>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col'>Reg. aprobados</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                name="approveds"
                                                value={approveds}
                                                onChange={handleInputChange}
                                                maxLength="5"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='productivity-row flex-wrap'>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col'>Reg. devueltos</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                name="rejecteds"
                                                value={rejecteds}
                                                onChange={handleInputChange}
                                                maxLength="5"
                                            ></input>
                                        </div>
                                        <div className='productivity-column flex-col'>
                                            <span className='productivity-header-col font-weight-bold'>Total registros</span>
                                            <input
                                                className='productivity-input-box productivity-input-content'
                                                placeholder='#'
                                                readOnly
                                                value={total}
                                            ></input>
                                        </div>
                                    </div>
                                </div> 
                                <div className='productivity-column-big'>
                                    <span className='productivity-header-col'>Observaciones</span>
                                    <textarea
                                        className='productivity-input-box-big productivity-input-box productivity-input-content'
                                        placeholder='Escribe las observaciones (máximo 200 caracteres).'
                                        maxLength={200}
                                        name="observations"
                                        value={observations}
                                        onChange={handleInputChange}
                                    ></textarea>
                                </div>
                            </div>
                            }
                        </>
                        :
                        <div className='productivity-row flex-wrap'>
                            {
                                enablesContent.enableCases &&
                                <>
                                    <div className='productivity-column-small'>
                                        <span className='productivity-header-col'>Aprobados</span>
                                        <input
                                            data-cy = 'approveds'
                                            className='productivity-input-box productivity-input-content'
                                            placeholder='#'
                                            name="approveds"
                                            value={approveds}
                                            onChange={handleInputChange}
                                            maxLength="7"
                                        ></input>
                                    </div>
                                    <div className='productivity-column-small'>
                                        <span className='productivity-header-col'>Devueltos</span>
                                        <input
                                            data-cy = 'rejecteds'
                                            className='productivity-input-box productivity-input-content'
                                            placeholder='#'
                                            name="rejecteds"
                                            value={rejecteds}
                                            onChange={handleInputChange}
                                            maxLength="7"
                                        ></input>
                                    </div>
                                    <div className='productivity-column-small'>
                                        <span className='productivity-header-col font-weight-bold'>Total</span>
                                        <input
                                            className='productivity-input-box productivity-input-content'
                                            placeholder='#'
                                            readOnly
                                            value={total}
                                        ></input>
                                    </div>
                                </>                              
                            }
                            {
                                requireSmax() &&
                                <>
                                    <div className='productivity-column-SMAX'>
                                        <span className='productivity-header-col'>Nº caso SMAX</span>
                                        <input
                                            className='productivity-input-box-SMAX productivity-input-content'
                                            placeholder='Escribe el Nº del caso'
                                            name="caseSmax"
                                            value={caseSmax}
                                            onChange={handleInputChange}
                                            maxLength="10"
                                        ></input>
                                    </div>
                                </>
                            }
                            <div className='productivity-column-big'>
                                <span className='productivity-header-col'>Observaciones</span>
                                <textarea
                                    className='productivity-input-box-big productivity-input-box productivity-input-content'
                                    placeholder='Escribe las observaciones (máximo 200 caracteres).'
                                    maxLength={200}
                                    name="observations"
                                    value={observations}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                            {(enablesContent.enableAmount) &&
                                <div className='wth-273 mt-70'>
                                    <span className='productivity-header-col'>Monto</span>
                                    <NumberFormat
                                        value={amount}
                                        displayType={"input"}
                                        className='productivity-input-box productivity-input-content'
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={"$ "}
                                        placeholder="$"
                                        renderText={(value) => <>{value}</>}
                                        onValueChange={(e) => handleInputChange({
                                            target: { name: "amount", value: e.value }
                                        })}
                                        maxLength="29"
                                    />
                                </div>
                            }
                        </div>
                        }
                    </>
                    }
                    </>
                }
                {
                    (!restocking) &&
                    <div className={(enablesContent.enableMasiveRegisters && enablesContent.enableAmount)? 'button-up':(`productivity-row mb-10 ${enablesContent.enableInfo ?'': 'mg-button-hide-content'}`)}>
                        <button
                            data-cy="btn-submit-productivity"
                            className='productivity-button-box productivity-button-content'
                            disabled={!enablesContent.enableSave}
                            onClick={handleButtonSave}
                        >
                            Guardar
                        </button>
                    </div>

                }
                {
                    restocking &&
                    <div className={(enablesContent.enableMasiveRegisters && enablesContent.enableAmount)? 'button-up':(` ${enablesContent.enableInfo ? (`${ repoblate ? 'edit-buton':''}`) : 'productivity-button-vontainer'}`)}>
                        <button
                            className='productivity-button-box productivity-button-editSave'
                            disabled={!enablesContent.enableSaveChange}
                            onClick={editSave}
                        >
                            Guardar cambios
                        </button>
                        <button
                            className='productivity-button-box productivity-button-editCancel'
                            onClick={editCancel}
                        >
                            Cancelar
                        </button>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default ProductivityForm