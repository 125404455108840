import React, { useState, useEffect } from 'react';
import './KardexTableComponent.scss';
import { useDispatch, useSelector } from 'react-redux';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { kardexHeaders } from '../../../constants';
import { getProcessesKardexCount, postInsertKardexNew, putInsertKardexNew, sessionManagement, setKardexProcesses, setSelectRows } from '../../bdb-actions/kardexActions';

const KardexTableComponent = () => {

    const [filter, setFilter] = useState('');

    const [selectAllRows, setSelectAllRows] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const dispatch = useDispatch();
    const { processList, date, activeEvent } = useSelector(state => state.kardex);
    const { area } = useSelector(state => state.kardex.activeEvent);
    const { kardexProcesses, selectRows, adminEditKardex } = useSelector(state => state.kardex);


    useEffect(() => {
        const validateRefresh = ( !!processList && !!area.option ) && (!adminEditKardex || !!date)
        if(validateRefresh) refreshKardex()
    }, [processList, date])

    const refreshKardex = () => {
        dispatch(setSelectRows([]));
        setSelectAllRows(false);
        dispatch(getProcessesKardexCount())
    }

    const filterOptions = (value) => {
        setFilter(value.toLowerCase())
    }

    const selectRowId = id => {
        setSelectAllRows(false);
        setShowErrors(false)
        if (selectRows.indexOf(id) === -1) {

            dispatch(setSelectRows([...selectRows, id]));
        } 
    }

    const rowFilter = row => {
        if (!(!!filter)) {
            return true;
        }
        return row.id.toLowerCase().includes(filter.toLowerCase())
    }


    const isDisabled = (item, header) => {
        if (header.id === 'managed') {
            return true
        }
        if (selectAllRows) {
            return false
        }

        return (selectRows.indexOf(item.id) === -1)
    }
    const scrollParentToChild = (parent, child) => {

        var parentRect = parent.getBoundingClientRect();
        var parentViewableArea = {
          height: parent.clientHeight,
          width: parent.clientWidth
        };
        var childRect = child.getBoundingClientRect();
        var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);
        if (!isViewable) {
              const scrollTop = childRect.top - parentRect.top;
              const scrollBot = childRect.bottom - parentRect.bottom;
              if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                  parent.scrollTop += scrollTop;
              } else {
                  parent.scrollTop += scrollBot;
              }
        }
      
      }
    const saveChanges = () => {
        const pending = validatePending()
        if (pending !== null) {
            const el  = document.getElementById(pending)
             const container = document.getElementById('content-body')
             scrollParentToChild(container,el)
            setShowErrors(true)
            return
        }
        setShowErrors(false)

        let modal
        let function_name
        modal={
            title: '¿Estás seguro de guardar el <br> registro?',
            html: 'Solo podrás editar durante el mismo día <br> que hiciste el registro'
        }
        if(unableSelectAll()){
            function_name = putInsertKardexNew
        }else{
            function_name = postInsertKardexNew
        }
        Swal.fire({
            icon: 'info',
            title: modal.title,
            html: modal.html,
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#c61f01',
            cancelButtonText: 'Cancelar',
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(function_name(selectRows))
                sessionManagement(activeEvent);

              } else if (result.isDenied) {
                return result
              }
        })  
    }
    const handleInputChange = (event, index, key) => {

        let processes = [...kardexProcesses]
        processes[index][key] = key === 'comments' ? event.target.value : event.target.value.replace(/[^\d]/g, '')
        processes[index].totalCount = Number(processes[index].managed) + Number(processes[index].pendingInsideAns) + Number(processes[index].pendingOutsideAns)
        dispatch(setKardexProcesses(processes))
    }
    const disableButton = () => {
        if (selectRows.length === 0) {
            return true
        }

        return false
    }
    const showAlert = (styleAlert, messageAlert) => {
        return <div className={`structure-alert ${styleAlert}`}>{messageAlert}</div>
    }
    const validatePending = () => {
        for (let item of selectRows) {
            const element = kardexProcesses.find(el => el.id === item)
            if (isEmpty(element.totalCount) || isEmpty(element.pendingInsideAns) || isEmpty(element.pendingOutsideAns) ) {
                return element.id
            }
        }
        return null
    }
    const unableSelectAll = () => {
        return kardexProcesses.some(el => !!el.isStored)
    }
    const formatProcess = (str) => {
        const text = str.substring(0, 85)
        return text + (text.length < str.length ? '...' : '')
    }
    const showErrorInput = (row, header) => {
        return !isDisabled(row, header) && isEmpty(row[header.id]) && showErrors
    }
    const isEmpty = (value) =>{
        return (value == null || value.length === 0);
      }

    return (
        <div>
            <div className="padding-27">
                <div className="flex-center">
                    {
                        validatePending() !== null && showErrors ?
                            <div className='content-alert-absolute'>
                                <div className="d-flex content-alert">
                                    {showAlert('red-alert', 'Dilegencia todos los campos para poder guardar')}
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            <div className="header-tabs-kardex">
                <div className="input-div-kardex">
                    <input
                        onChange={(e) => { filterOptions(e.target.value) }}
                        className="input-search-kardex" placeholder="Buscar proceso"></input>
                </div>
            </div>
            <div className="header-kardex-table">
                <div className="center-content-kardex-table">
                    {
                        kardexHeaders.map((element, index) => (

                            <div key={index} style={{ width: element.width }}>{element.name}</div>
                        ))
                    }
                </div>
                <div className="last-options-kardex-table">
                    {
                        <div>
                            <button
                                disabled={disableButton()}
                                onClick={() => { saveChanges() }} className="button-add-kardex-table">
                                Guardar
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div id="content-body" className="content-body-kardex-table">
                {
                    kardexProcesses.map((row, index2) => (

                        rowFilter(row) ?
                            <div key={index2} id={row.id}>
                                <div key={`row-${index2}`} id="row"
                                    className="row-kardex-table row-kardex-table-white "
                                >
                                    <div className="center-content-kardex-table">
                                        {
                                            kardexHeaders.map((header, index3) => (
                                                header.isInput ?

                                                    <div key={index3 + header.id} style={{ width: header['width'] }}>
                                                        {
                                                            header.id !== 'comments'
                                                                ?
                                                                <input
                                                                    readOnly={isDisabled(row, header)}
                                                                    className={'kardex-input form-control ' + (showErrorInput(row, header) ? 'invalid-kardex-input' : '')}
                                                                    placeholder="#"
                                                                    style={{ width: '72px' }}
                                                                    value={row[header['id']]}
                                                                    onChange={(e) => { handleInputChange(e, index2, header.id) }}
                                                                    maxLength="10"
                                                                ></input>
                                                                :
                                                                <textarea
                                                                    readOnly={isDisabled(row, header)}
                                                                    className='kardex-input kardex-textarea productivity-input-content form-control'
                                                                    placeholder='Escribe las observaciones (máximo 200 caracteres).'
                                                                    maxLength={200}
                                                                    name={header['id']}
                                                                    value={row[header['id']]}
                                                                    onChange={(e) => { handleInputChange(e, index2, header.id) }}
                                                                ></textarea>
                                                        }
                                                    </div>
                                                    :

                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={


                                                            <Tooltip placement="left" className="tooltip-kardex" id="tooltip-left">
                                                                {row[header['id']]}
                                                            </Tooltip>

                                                        }>
                                                        <div key={`process-${index2}`} style={{
                                                            width: header['width'],
                                                            paddingRight: '10px'
                                                        }}>
                                                            {formatProcess(row[header['id']])}
                                                        </div>
                                                    </OverlayTrigger>

                                            ))
                                        }

                                    </div>
                                    <div className="last-options-kardex-table">
                                        {
                                                <div>
                                                    <div
                                                        onClick={() => { selectRowId(row['id']) }}
                                                        className={(selectRows.includes(row['id'])? ' edit-icon-kardex-table-disabled ': '')+"edit-icon-kardex-table"}
                                                    ></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div key={`line-${index2}`} className="line-separator"></div>
                            </div>
                            : null
                    ))}
            </div>
        </div>
    );
}

export default KardexTableComponent;