import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { DeleteModal } from '../Modals/DeleteModal';
import '../../../assets/styles/ComponentsStyles/preview.scss'
import { useDispatch } from 'react-redux';
import { showDeleteModal } from '../../bdb-actions/bdbDocumentsActions';

const Preview = ( { documentInfo, setDocumentInfo, setResetDocumentInfo, handlePreviewDelete } ) => {
    
    const dispatch = useDispatch();
    const { src, file, friendlyName } = documentInfo;
    const { name } = file;

    const [ pdfScale ] = useState( 2 );
    const [ numPages, setNumPages ] = useState( null );
    const showDeleteModalFlag = useSelector (state => state.documentsReducer.showDeleteModal);

    const handlePreviewClose = () => {
        let temporalDocumentInfo = {
            ...documentInfo
        };
        delete temporalDocumentInfo.src;
        setDocumentInfo( temporalDocumentInfo );
        setResetDocumentInfo( true );
    };

    const openDeleteModal = () => {
        dispatch( showDeleteModal( true ) );
    };

    function onDocumentLoadSuccess( { numPages } ) {
        setNumPages( numPages );
    }

    return <>
        <div className='preview-main-container'>
            <div className='preview-header'>
                <div className='preview-metadata-container'>
                    <div className='preview-names-container'>
                        <p className='preview-document-type'>{ friendlyName }</p>
                        <p className='preview-document-name'>{ name }</p>
                    </div>
                </div>
                <div className='preview-close-container'>
                    <div
                        className='preview-close-icon'
                        onClick={ () => handlePreviewClose() }>
                    </div>
                </div>
            </div>
            <div className='preview-body'>
                {
                    name.indexOf( '.pdf' ) === -1 ?
                        <img src={ src } alt="preview"/>:
                        <Document file={ src } onLoadSuccess={ onDocumentLoadSuccess }>
                            {
                                Array.apply( undefined, Array( numPages ) )
                                    .map( ( x, i ) => i + 1 )
                                    .map( ( page, i ) => <Page key={ i } pageNumber={ page } scale={ pdfScale } />)
                            }
                        </Document>
                }
            </div>
            <div className='preview-footer'>
                <div className='preview-delete-container'>
                    <i
                        className='preview-delete-icon'
                        onClick={ () => { openDeleteModal() } }>
                        <span className='preview-delete-icon-label'>Eliminar</span>
                    </i>
                </div>
            </div>
        </div>
        <DeleteModal
            show={ showDeleteModalFlag }
            bodyContent={ <p>Esta operación no se podrá deshacer.</p> }
            handleButton={ handlePreviewDelete } />
    </>
}

export default Preview;
