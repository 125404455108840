import React from 'react'
import '../../../assets/styles/ComponentsStyles/toasts.scss'

export const ConfirmationSmall = ({ show, message, handleClick}) => {
    
    return (
        <>
        {
            !!show &&
                <div className='toast-background'>
                    <div className='check-green'></div>
                    <p>{message}</p>
                    <div 
                        className='toast-close'
                        onClick={handleClick}>Cerrar</div>
                </div>
        }
        </>
    )
}
