import axios from 'axios';
import { JSEncrypt } from 'jsencrypt'

const encriptString = (value) => {
    const keyPub = process.env.REACT_APP_PUBLIC_SHA_KEY;
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(keyPub);
    return encryptor.encrypt(value);
  }


export async function getUserInfoIDM(user)  {
    const userData = encriptString(user);
    const headers = { 
        'Content-Type' : 'text/plain'
    };
    let response = {};
    response =  await axios.post(process.env.REACT_APP_URL_IDM + 'get-info-user-idm', userData, { headers })
    .catch((err) => {
        response = {status : 400}
    })
    return response;
}