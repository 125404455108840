import { 
        GET_ITEMS_COMPONENT_START, 
        GET_ITEMS_COMPONENT_SUCCESS, 
        GET_ITEMS_COMPONENT_FAIL,
        GET_ITEMS_COMPONENT_REDIRECT,
        UPDATE_PRIORITY_START,
        UPDATE_PRIORITY_SUCCESS,
        UPDATE_PRIORITY_FAIL
    } from '../bdb-types/index';
import Swal from 'sweetalert2';
import axios from 'axios';
import interceptor from '../../interceptor';

export function getItemsComponent() {
    return async (dispatch) => {
        dispatch( itemsComponentStart(true));
        try {
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/default')
                .then(function (response) {
                    dispatch( itemsComponentSuccess(response.data) );
                });
        } catch(error) {
            dispatch( itemsComponentFail(false) );
            dispatch( itemsComponentRedirect(true));
        }
    }
}

export function getPriorityHousing() {
    return async (dispatch) => {
        dispatch( itemsComponentStart(true));
        try {
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/priority-housing')
                .then(function (response) {
                    dispatch( itemsComponentSuccess(response.data) );
                });
        } catch(error) {
            dispatch( itemsComponentFail(false) );
            dispatch( itemsComponentRedirect(true));
        }
    }
}

export function updatePriorityHousing(data) {
    return async (dispatch) => {
        dispatch(updatePriorityComponentStart(true));
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'config/change-priority-housing';
        const response = await axios.put( path, {
            Item: data
        } );
        if(response.status === 200) {
            dispatch(updatePriorityComponentSuccess(true));
            setTimeout(() => dispatch(updatePriorityComponentSuccess(false)), 2000);
        } else {
            dispatch(updatePriorityComponentFail(false));
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'No fue posible aplicar la priorización solicitada, intenta de nuevo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

const modalFail = () => {
    Swal.fire({
        icon: 'error',
        title: '¡Lo sentimos!',
        text: 'Presentamos inconvenientes técnicos, intenta de nuevo',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
    })
}

const itemsComponentStart = state => ({
    type: GET_ITEMS_COMPONENT_START,
    payload: state
});

const itemsComponentSuccess = state => ({
    type: GET_ITEMS_COMPONENT_SUCCESS,
    payload: state
});

const itemsComponentFail = state => ({
    type: GET_ITEMS_COMPONENT_FAIL,
    payload: state
});

const itemsComponentRedirect = state => ({
    type: GET_ITEMS_COMPONENT_REDIRECT,
    payload: state
});

export function updatePriorityComponent(order, product, priority, flow) {

    return async (dispatch) => {
        dispatch(updatePriorityComponentStart(true));
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'config/priority';
        const response = await axios.post(
            path,
            {
                "listValuesComponent": {
                    "flow": flow,
                    "priorization": priority,
                    "product": product
                },
                "order": order
            }
        );
        if(response.status === 200) {
            dispatch(updatePriorityComponentSuccess(true));
            setTimeout(() => dispatch(updatePriorityComponentSuccess(false)), 2000);
        } else {
            dispatch(updatePriorityComponentFail(false));
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'No fue posible aplicar la priorización solicitada, intenta de nuevo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

export function getPriorityCenterDisbursemet() {
    return async (dispatch) => {
        dispatch( itemsComponentStart(true));
        try {
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/disbursement')
                .then(function (response) {
                    dispatch( itemsComponentSuccess(response.data) );
                });
        } catch(error) {
            dispatch( itemsComponentFail(false) );
            dispatch( itemsComponentRedirect(true));
        }
    }
}

export function updatePriorityCenterDisbursemet(Item) {

    return async (dispatch) => {
        dispatch(updatePriorityComponentStart(true));
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'config/change-priority-disbursement';
        const response = await axios.put(path, { Item });
        if(response.status === 200) {
            dispatch(updatePriorityComponentSuccess(true));
            setTimeout(() => dispatch(updatePriorityComponentSuccess(false)), 2000);
        } else {
            dispatch(updatePriorityComponentFail(false));
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'No fue posible aplicar la priorización solicitada, intenta de nuevo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

const updatePriorityComponentStart = state => ({
    type: UPDATE_PRIORITY_START,
    payload: state
});

const updatePriorityComponentSuccess = state => ({
    type: UPDATE_PRIORITY_SUCCESS,
    payload: state
});

const updatePriorityComponentFail = state => ({
    type: UPDATE_PRIORITY_FAIL,
    payload: state
});
