import { 
    GET_PRODUCTIVITY_PRODUCT_START,
    GET_PRODUCTIVITY_PRODUCT_FAIL,
    GET_PRODUCTIVITY_PRODUCT,
    CHANGE_PRODUCTIVITY_PRODUCT,
    APPLY_PRODUCTIVITY,
    APPLY_PRODUCTIVITY_SUCCESS,
    APPLY_PRODUCTIVITY_FAIL,
    CLEAN_PRODUCTIVITY_PRODUCT
} from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    error: false,
    loading: false,
    data: null,
    alert: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_PRODUCTIVITY_PRODUCT_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_PRODUCTIVITY_PRODUCT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false
            }
        case GET_PRODUCTIVITY_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            } 
        case CHANGE_PRODUCTIVITY_PRODUCT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false
            }
        case APPLY_PRODUCTIVITY:
            return {
                ...state,
                loading: true,
                error: false
            }
        case APPLY_PRODUCTIVITY_SUCCESS:
            return {
                ...state,
                data: null,
                loading: false,
                error: false,
                alert: true
            }
        case APPLY_PRODUCTIVITY_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        case CLEAN_PRODUCTIVITY_PRODUCT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                alert: false
            }
        default:
            return state;
    }
}