import {
    QUERY_GUARANTEE,
    RESET_QUERY_GUARANTEE,
    RESERVE_GUARANTEE,
    CHANGE_LOADING_VISIBILITY,
    RESERVES_GUARANTEES_ALL,
    RESERVES_GUARANTEES_STATUS
} from "../bdb-types";

const initialState = {
    guaranteeInfo: undefined,
    reserveInfo: undefined,
    loading : false,
    reserves: [],
    reservesStatus: {
        'fng' : {
            'fngReserves' : {
                'SUCCESS': 0,
                'REJECT': 0,
                'REPLACED': 0
            }
        }
    }
};

export default function( state = initialState, action ) {
    switch( action.type ) {
        case QUERY_GUARANTEE:
            return {
                ...state,
                guaranteeInfo: action.payload
            }
        case RESET_QUERY_GUARANTEE:
            return {
                ...state,
                loading: false,
                guaranteeInfo: undefined,
                reserveInfo: undefined
            }
        case RESERVE_GUARANTEE:
            return {
                ...state,
                loading: false,
                reserveInfo: action.payload
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload
            }
        case RESERVES_GUARANTEES_ALL:
            return {
                ...state,
                reserves: [ ...action.payload ]
            }
        case RESERVES_GUARANTEES_STATUS:
            return {
                ...state,
                reservesStatus: { ...action.payload }
            }
        default:
            return state;
    }
}
