import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './LeaderProdStyles.scss';
import {
    getManagementsAndProcesses, updateActiveEvent, resetActveEvent, dateChangeEdit, getManagementByVertical
} from '../../bdb-actions/kardexActions';
import { getBeforeWorkingDay } from '../../bdb-helpers/utils';
import { idmAdminProd, idmRootAdminProd } from '../../../constants';
import Loading from '../LoadingComponent/Loading';
import SearchForListComponent from '../SearchForListComponent/SearchForListComponent';
import DatePickerCustomInput from '../../bdb-components/ProductivityFormComponent/DatePickerCustomInput';
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';

const selectStyle = {
    height: '40px',
    fontSize: '16px',
    zIndex: '5',
    padding: '9px 20px 9px 16px',
    color: '#4a4a4a',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHheight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #d6d6d6',
    backgroundColor: '#ffffff',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingRight: '48px'
};

const styleList = {
    maxHeight: '167px',
    width: '50%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const styleArrow = {
    width: '15px',
    height: '15px',
    right: '18px',
    zIndex: '15',
};



const KardexForm = () => {

    const BeforeWorkingDay = getBeforeWorkingDay(moment(), parseInt(process.env.REACT_APP_DAYS_BEFORE_TO_KARDEX)).toDate();
    const dispatch = useDispatch();
    const rol = getdecrypt('rolUser')
    const { activeEvent,managementList, areaList, date } = useSelector(state => state.kardex);
    const loading = useSelector(state => state.kardex.loading);
    const { vertical, management, area } = activeEvent;
    const rootAdminEditKardex = idmRootAdminProd.includes(rol);
    const adminEditKardex = idmAdminProd.includes(rol);
    const dateBelow = adminEditKardex? getBeforeWorkingDay(moment(), 2).toDate():moment().subtract(365, "days").toDate();
    const dateAbove = moment().subtract(1, "days").toDate();

    const handleSelectChange = ({ target }) => {
        let activeEventTemp = { ...activeEvent }
        const { value, name, valueMainList } = target
        const _management = name === 'management' ? value : activeEvent.management.option
        const _vertical = name === 'management' ? 
                        (!rootAdminEditKardex ? getdecrypt('vertical') : 
                            !!valueMainList ? valueMainList: null ) : 
                                activeEvent.vertical.option
        if (name === 'management' && !_vertical) return
        const _area = name === 'area' ? value : (name === 'management' || name === 'dateInit'? null : activeEventTemp.area.option)

        activeEventTemp.management = { id: 'management', option: _management }
        activeEventTemp.area = { id: 'area', option: _area }
        activeEventTemp.vertical = { id: 'vertical', option: _vertical }

        if(name === 'dateInit'){
            dispatch(getManagementByVertical(value))
            activeEventTemp.date = value
            dispatch(dateChangeEdit(value))
        }
        dispatch(updateActiveEvent(activeEventTemp))
        dispatch(getManagementsAndProcesses(_vertical, _management?.id, _area?.id ? _area?.id : undefined, false));
    }

    useEffect(() => {
        const _vertical = getdecrypt('vertical')
        if (!rootAdminEditKardex && !adminEditKardex){
            dispatch(dateChangeEdit(BeforeWorkingDay))
            dispatch(getManagementByVertical(BeforeWorkingDay))
            if (!management.option ) {
                const _management = JSON.parse(sessionStorage.getItem("currentManagement"));
                if (!!_management) {
                    dispatch(getManagementsAndProcesses(_vertical, _management.id, undefined, true));
                }
            }
        }
        return () =>{
            dispatch(resetActveEvent())
        }

    }, [])

    useEffect(() => {
        const _management = JSON.parse(sessionStorage.getItem("currentManagement"));
        if (date === '') {
            dispatch(updateActiveEvent({
                ...activeEvent,
                 management:{
                   id:'management',
                   option:_management?_management:null
                 }
                }));
            
        }
    }, [activeEvent])



    return (
        <div className=' font-OpenSans-Regular'>
            
            {loading ? <Loading></Loading> : null}
            <div className='productivity-tittle text-center'>Novedades de gestión</div>
            <div className='productivity-sub-tittle text-center'>Diligencia la información solicitada para reportar las novedades de la demanda diaria.</div>
            <div className='d-flex'>
                <div className='col-3'>
                    <span className='productivity-header-col d-block'>Fecha</span>
                        <div className='kardex-date-parent'>
                            <DatePicker
                                idClick={'date'}
                                selectedOption={'date'}
                                selected={  date }
                                disabled={ !rootAdminEditKardex && !adminEditKardex}
                                onChange={
                                    (e) => handleSelectChange({
                                    target: { name: "dateInit", value: e }
                                    })}
                                placeholderText="Selecciona"
                                dateFormat="dd-MM-yyyy"
                                minDate={dateBelow}
                                maxDate={dateAbove}
                                customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='leaderProd-select-time leaderProd-input-content w-100' styleArrow='kardex-arrow-right leader-arrow-select' />}
                            />                
                    </div> 
                </div>
                <div className='col-3'>
                    <span className='productivity-header-col' data-cy="span-productivity-vertical"> {rootAdminEditKardex ? 'Vertical y Gerencia' : 'Gerencia'}</span>
                    <SearchForListComponent
                        idClick={'management'}
                        selectedOption={management?.option?.id}
                        onClickOption={handleSelectChange}
                        options={managementList}
                        selectStyle={selectStyle}
                        listStyle={styleList}
                        placeholder={'Selecciona'}
                        showArrow={true}
                        arrowStyles={styleArrow}
                        disabled={!date}
                        onActiveContent={false}
                    />
                </div>
                <div className='col-3'>
                    <span className='productivity-header-col'>Área</span>
                    <SearchForListComponent
                        idClick={'area'}
                        selectedOption={area?.option?.id}
                        onClickOption={handleSelectChange}
                        options={areaList}
                        selectStyle={selectStyle}
                        listStyle={styleList}
                        placeholder={'Selecciona'}
                        showArrow={true}
                        arrowStyles={styleArrow}
                        disabled={!management.option || !date}
                        onActiveContent={false}
                    />
                </div>
            </div>


        </div>
    )
}

export default KardexForm