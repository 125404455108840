import { RESET_PLANT_FILE, NUMBER_RECORDS ,CHANGE_LOADING_VISIBILITY,  SET_DUPLICATE_STAFF, SET_STATUS_STAFF, SET_LOADING_STATUS, UPDATE_FORM_USER_STAFF, RESET_FORM_USER_STAFF, UPDATE_SEARCH_VALUE, POPULATE_USERS_STAFF, SET_ERROR_SEARCH, SET_VERTS_AND_MANGMTS, SET_AREAS_STAFF_LIST, SET_PROCESSES_STAFF_LIST} from '../bdb-types/index';
import axios from "axios";
import Swal from 'sweetalert2';
import { columnsProcessReport, translateStatusStaff, widthsColumnsProcessFile, columnsStaffReport, widthsColumnsStaffFile, idmRootAdminProd } from '../../constants';
import { getCtrlBrdInfoByCc, getMngmntsAndProcessList, getStaffUser, putStaffUser, deleteStaffPosition } from '../bdb-services/GeneralService/controlboardService';
import { exportFromJsonToExcelFileProcessOrStaff, getManagementByVerticalUtils, mapSelectedListToReducer } from '../bdb-helpers/utils';
import moment from 'moment';
import { getdecrypt, setSessionStorageEncrypted } from '../bdb-services/GeneralService/globalService';

export const resetPlantFile = ( event) => ({
    type: RESET_PLANT_FILE,
    payload:event
})

export const numberRecords = ( event ) => ({
    type:NUMBER_RECORDS,
    payload: event
})
export const updateLoading = ( state ) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
});

export const setDuplicateStaff = ( status ) => ({
    type: SET_DUPLICATE_STAFF,
    payload: status
});

export const setStatusStaff = ( status ) => ({
    type: SET_STATUS_STAFF,
    payload: status
});
export const setLoadingStatus = ( payload ) => ({
    type: SET_LOADING_STATUS,
    payload
});
export const updateFormUserStaff = ( property, value ) => ({
    type: UPDATE_FORM_USER_STAFF,
    payload: { [property]: value }
});
export const resetFormUserStaff = () => ({
    type: RESET_FORM_USER_STAFF
});
export const updateSearchValue = ( payload ) => ({
    type: UPDATE_SEARCH_VALUE,
    payload
});
export const populateUsersStaff = ( payload ) => ({
    type: POPULATE_USERS_STAFF,
    payload
});
export const setErrorSearch = ( payload ) => ({
    type: SET_ERROR_SEARCH,
    payload
});
export const setVertsAndMangmts = ( payload ) =>({
    type: SET_VERTS_AND_MANGMTS,
    payload
})
export const setAreasStaffList = ( payload ) =>({
    type: SET_AREAS_STAFF_LIST,
    payload
})
export const setProcessesStaffList = ( payload ) =>({
    type: SET_PROCESSES_STAFF_LIST,
    payload
})

export const uploadPlantFile = ( file, action, typeFile ) => {
    return async( dispatch , getState ) => {
        dispatch (updateLoading(true));
        let body = {
            confirmation : false,
            vertical: idmRootAdminProd.includes(getdecrypt('rolUser')) ? undefined : getdecrypt('vertical')
        };
        if ( action === 'upload'){
            body['confirmation'] = true;
        }
        const pathTypeFile = (typeFile === 'StaffUser')? 'staff/update': 'config/processes-confirm' ;
        const path =`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/${pathTypeFile}`;
        await axios.post( path, body )
            .then( () => {
                dispatch (updateLoading(false));
                if ( action === 'upload' ) Swal.fire({
                    icon: 'success',
                    title:'¡Listo!',
                    html: `El archivo ${file.name} <br>con ${getState().plant.numberRecords} registros, se subió con éxito. <br><br> <b>Los cambios se verán reflejados hasta el <br>siguiente día.</b>`,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                });    
                dispatch( resetPlantFile() );
                dispatch( numberRecords() );
            })
            .catch( error => {
                console.error(error);
            })
    }
};

export const documentRecords = ( file, typeFile ) => {
     return async(dispatch)=>{
        let numberRecor;
        dispatch (updateLoading(true));
        const fileRender = new FileReader();
        let stream = await new Promise( (resolve) => {
            fileRender.readAsDataURL( file );
            fileRender.onload = async function () {
                const completeBase64 = ( fileRender.result ).split(',');
                const base64 = completeBase64 ? completeBase64[ 1 ] : undefined;
                resolve(base64);
            }
        })
        try {
            const path = `${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/validate/plant-file`;
            const contexFile = typeFile;
            const pathUpdataTabletTmp = (contexFile === 'StaffUser')? 'staff': 'config/processes/load' ;
            const pathGetStatusTabletTmp = (contexFile === 'StaffUser')? 'staff/status': 'config/load/status' ;
            const vertical = idmRootAdminProd.includes(getdecrypt('rolUser')) ? undefined : getdecrypt('vertical');
            const body = { stream, contexFile };
            await axios.post( path, body ).then( async (response ) => {
                numberRecor = response.data.records;
                dispatch( numberRecords(numberRecor) );
                await axios.post( `${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/${pathUpdataTabletTmp}`, {stream, vertical,} );
                let isUploadFinished = false;
                dispatch( setLoadingStatus(true));
                do {
                    await new Promise( (resolve, reject) => {
                        setTimeout( () => {
                            axios.get( `${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/${pathGetStatusTabletTmp}`, { params: {vertical}} )
                            .then( ( response ) => {
                                if ( response.data?.FINISHED === 1) isUploadFinished = true;
                                dispatch( setStatusStaff(mapStatus(response.data, typeFile) ));
                                if(!idmRootAdminProd.includes(getdecrypt('rolUser')) && (contexFile === 'StaffUser')) dispatch(setDuplicateStaff(mapDuplicate(response.data.DuplicatedCodesData)))
                                dispatch(updateLoading(false));
                                resolve();
            
                            }).catch( error => {
                                console.error(error)
                                isUploadFinished = true;
                                reject();
                            })
            
                        }, 5000);
    
                    })
                } while (!isUploadFinished);
                dispatch( setLoadingStatus(false));
            }).catch( error => {
                dispatch (updateLoading(false));
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title:'Lo sentimos',
                    html: 'El nombre o la estructura del <br> archivo es incorrecto.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                });            
                dispatch( resetPlantFile(false) );
                dispatch( numberRecords() );
            });
        } catch (error) {
            console.error(error);
        }

    }
};
let messageStructure = {
    newUser: null,
    position: null,
    oldUser: null,
    setSucess: function (){
        this.icon = "success";
        this.tittle = "¡Listo!";
    },
    setError: function (){
        this.icon = "error";
        this.tittle = "Error";
        this.message = "Se ha presentado un error inesperado";
    },
    "PUT-200": function (){
        return `Se ha cambiado a ${this.oldUser} por ${this.newUser} para el código de posición ${this.position}`;
    },
    "PUT-200-NO-OLD-USER": function (){
        return `${this.newUser} ha ocupado la vacante del código de posición ${this.position}`;
    },
    "PUT-201": function (){
        return `Se ha creado el nuevo código de posición ${this.position} con el usuario ${this.newUser}`;            
    },
    "GET-200": function (){
        this.tittle = "¿Estás seguro que deseas reemplazar el usuario?";
        return `Se cambiará a ${this.oldUser} por ${this.newUser} para el código de posición ${this.position}`;
    },
    "GET-200-NO-OLD-USER": function (){
        this.tittle = "¿Estás seguro que deseas ocupar esta vacante?";
        return `${this.newUser} se asociará al código de posición ${this.position}`;
    },
    "GET-404": function (){
        this.tittle = "¿Estás seguro que deseas agregar un nuevo código de posición?";
        return `El usuario ${this.newUser} se asociará al código de posición ${this.position}`;
    },
};

export const putUserStaff = () => {
    return async (dispatch, getState ) => {
        const { userInfo } = getState().plant;

        const body = {
            positionCode: userInfo.positionCode,
            documentNumber: userInfo.documentUser,
            fullName: userInfo.nameUser.toUpperCase(),
            vertical: userInfo.vertical,
            management: userInfo.management.id,
            area: userInfo.area.id,
            process: userInfo.process.id,
            'isAdminRoot': idmRootAdminProd.includes(getdecrypt('rolUser'))
        };
        messageStructure["newUser"] = userInfo.nameUser;
        messageStructure["position"] = userInfo.positionCode;
        dispatch( updateLoading(true) );
        await putStaffUser( body )
            .then( resp => {
                messageStructure.setSucess();
                messageStructure["oldUser"] = resp.data.fullName;
                const positionWithoutUser = resp.status === 200 && !resp.data.fullName ? "-NO-OLD-USER" : "";
                messageStructure["message"] = messageStructure[ "PUT-" + resp.status + positionWithoutUser ]();
            })
            .catch( error => {
                console.error(error);
                messageStructure.setError();
            })

        dispatch( updateLoading(false) );
        dispatch( resetFormUserStaff() );
        Swal.fire({
            icon: messageStructure["icon"],
            title: messageStructure["tittle"],
            text: messageStructure["message"],
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76'
        });

    }
};

export const getUserStaff = () => {
    return async (dispatch, getState ) => {
        const { positionCode, nameUser } = getState().plant.userInfo;
        let continuePutUser = true;
        let currentVerticalUser;
        let currentEststus;
        const currentVertical = getdecrypt('vertical')
        messageStructure["newUser"] = nameUser
        messageStructure["position"] = positionCode;
        messageStructure["oldUser"] = null;
        dispatch( updateLoading(true) );
        let params = {
            'positionCode': positionCode,
            'vertical': idmRootAdminProd.includes(getdecrypt('rolUser')) ? null : currentVertical
        }
        await getStaffUser( params )
            .then( resp => {
                messageStructure["oldUser"] = resp.data.Items[0]["fullName"];
                const positionWithoutUser = !!resp.data.Items[0]["fullName"] ? "" : "-NO-OLD-USER"
                messageStructure["message"] = messageStructure[ "GET-" + resp.status + positionWithoutUser]();
            })
            .catch( error => {
                currentVerticalUser = error.response['data']['vertical']
                currentEststus=error.response.status
                if(error.response.status === 403){
                    messageStructure["message"] =`No es posible asociar a ${messageStructure["newUser"]}  al código de posición ${messageStructure["position"]}, ya que se ` +
                                `encuentra activo en la ${currentVerticalUser}`
                    continuePutUser = false;
                    return
                }
                if (error.response.status === 404) {
                    messageStructure["message"] = messageStructure[ "GET-404" ]();
                    return
                };
                messageStructure.setError();
                continuePutUser = false;
            })
        dispatch( updateLoading(false) );
        if(!continuePutUser && currentEststus === 403){
            Swal.fire({
                icon: "error",
                title: 'Lo sentimos',
                html: messageStructure["message"],
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            })
        }else{
            Swal.fire({
                icon: "info",
                title: messageStructure["tittle"],
                text: messageStructure["message"],
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                showCancelButton: continuePutUser,
                }).then( (result) => {
                if ( continuePutUser && result.isConfirmed ) dispatch( putUserStaff() );
            })
        }
    }
};

export const searchValueStaff = () => {
    const vertical = (idmRootAdminProd.includes(getdecrypt('rolUser'))) ? undefined : getdecrypt('vertical').replace(/"/g, '');
    return async(dispatch,getState) => {
        const { searchValue } = getState().plant;
        dispatch( updateLoading(true));
        await getCtrlBrdInfoByCc({ number: searchValue, vertical: vertical })
            .then( resp => {
                dispatch( populateUsersStaff( resp.data.Items ));
            }).catch( async (err) => {
                console.error(err);
                if (err.response.status === 404) {
                    await getStaffUser( { positionCode: searchValue, vertical: vertical } )
                        .then( resp => {
                            dispatch( populateUsersStaff( resp.data.Items ));
                        })
                        .catch( err => {
                            console.error(err);
                            dispatch( setErrorSearch(true) );
                        })
                }
            })
        dispatch( updateLoading(false));

    }
};
export const removeUserStaff = (user) => {
    return async(dispatch) => {
        
        dispatch( updateLoading(true));
        await putStaffUser( { positionCode: user.positionCode } )
            .then( () => {
                messageStructure.setSucess();
                messageStructure.message = `Se retiró a ${user.fullName} dejando vacante el código de posición ${user.positionCode}`;
                dispatch( updateSearchValue("") );
                dispatch( populateUsersStaff( [] ));
            })
            .catch( err => {
                console.error(err);
                messageStructure.setError();
            })
        dispatch( updateLoading(false));
        Swal.fire({
            icon: messageStructure["icon"],
            title: messageStructure["tittle"],
            html: messageStructure["message"],
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76'
        })
    }
};
export const removePositionStaff = (position) => {
    return async(dispatch) => {
        const text = position.fullName ? `Se retiró a ${position.fullName} y se eliminó el código de posición ${position.positionCode}` : `Se eliminó el código de posición ${position.positionCode}`
        dispatch( updateLoading(true));
        
        await deleteStaffPosition( position.id )
            .then( () => {
                messageStructure.setSucess();
                messageStructure.message = text;
                dispatch( updateSearchValue("") );
                dispatch( populateUsersStaff( [] ));
            })
            .catch( err => {
                console.error(err);
                messageStructure.setError();
            })
        dispatch( updateLoading(false));
        Swal.fire({
            icon: messageStructure["icon"],
            title: messageStructure["tittle"],
            html: messageStructure["message"],
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76'
        })
    }
};
const mapDuplicate = (data) =>{
    const response = data.map((i) =>({ 'position':i["positionCode"], "vertical":i['originVertical']}))
    return response
}
const mapStatus = ( status,typeFile ) => {
    let newArray = [];
    for (const oldStatus in translateStatusStaff[typeFile]) {
        let newObj = {};
        newObj['label'] = [ translateStatusStaff[typeFile][oldStatus] ];
        newObj['count'] = status[oldStatus];
        newArray.push( newObj );
    }
    return newArray;
}
export const downloadFileAction = (typeFile) =>{
    return async( dispatch ) => {
        dispatch (updateLoading(true));
        const pathTypeFile = (typeFile === 'Planta DNO')? 'config/staff': 'config/process' ;
        const nameSheet = (typeFile === 'Planta DNO')? 'Planta':"Procesos";
        const path =`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/${pathTypeFile}`;
        const columns=(typeFile === 'Planta DNO')? columnsStaffReport:columnsProcessReport;
        const widthColumns=(typeFile === 'Planta DNO')? widthsColumnsStaffFile:widthsColumnsProcessFile;
        const vertical = idmRootAdminProd.includes(getdecrypt('rolUser')) ? undefined : getdecrypt('vertical');
        axios.get( path, { params: { Vertical:  vertical }})
            .then( (response) => {
                const nameFile = `${nameSheet}_${ moment(  ).format("DD_MM_YYYY")}.xlsx`;
                exportFromJsonToExcelFileProcessOrStaff(response.data,columns, nameSheet, nameFile, widthColumns );
                dispatch(updateLoading(false))
            })
            .catch( error => {
                dispatch(updateLoading(false))
                console.error(error);
            })
 
    }
}

export const getManagementsByVertical = () => {
    return async(dispatch) => {
        dispatch(updateLoading(true))
        const saved = sessionStorage.getItem('verticalsAndManagements')
        const verticalsAndManagementsStoraged = saved ? JSON.parse(getdecrypt('verticalsAndManagements')) : [];
        const verticalsAndManagements = verticalsAndManagementsStoraged.length <= 0 ? await getManagementByVerticalUtils() : verticalsAndManagementsStoraged;
        if (verticalsAndManagements.length > 0) setSessionStorageEncrypted('verticalsAndManagements',verticalsAndManagements);
        dispatch(setVertsAndMangmts(verticalsAndManagements))
        dispatch(updateLoading(false))

    }
}

export const getAreasAndProcesses = (vertical, management, area) => {
    return async(dispatch) => {
        const mainPath = `${encodeURIComponent(vertical)}/${encodeURIComponent(management)}`;
        const proxyPath = !area ? mainPath : `${mainPath}/${encodeURIComponent(area)}`
        const response = await getMngmntsAndProcessList(proxyPath)
        .then((resp) => {
            if (!area){
                const areas = mapSelectedListToReducer(resp.data.Items, "area", 32);
                dispatch(setAreasStaffList(areas))
                dispatch(setProcessesStaffList([]))
            }else {
                const processes = mapSelectedListToReducer(resp.data.Items, "process", 32);
                dispatch(setProcessesStaffList(processes))
            }
        })
        .catch((err) => console.error(err))
    }
}