import {
    DOWNLOAD_RESULT_CASES,
    CLEAN_RESULT_CASES,
    STAR_CASES,
    LOADING_TOTAL_CASES,
    SEARCH_SPECIFIC_CASE_FAIL,
    SEARCH_SPECIFIC_CASE,
    SEARCH_SPECIFIC_CASE_SUCCESS,
    SEARCH_SPECIFIC_CASE_NOT_FOUND,
    SELECT_CASE_TO_PRIORITIZE,
    CASE_PRIORITIZE_ERROR,
    CASE_PRIORITIZE_SUCCESSS,
    CASE_PRIORITIZE,
    CASE_REASSIGN_ERROR,
    CASE_REASSIGN_SUCCESSS,
    CASE_REASSIGN,
    SELECT_CASE_TO_REASSING,
    CLEAN_SPECIFIC_CASE,
    CASE_PRIORITIZE_ALERT,
    CASE_REASSIGN_ALERT
} from '../bdb-types/index';

import Swal from 'sweetalert2';
import axios from 'axios';
import * as CryptoJS from "crypto-js";
import {processesByManagements, emptyStatus, idmAnalystRol} from '../../constants';
import { getUserInfoIDM } from '../bdb-services/GeneralService/staffService';
import interceptor from '../../interceptor';

function decryptUsingAES256(value, iv, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
}

function getRangesDates() {
    let lastDate = new Date();
    lastDate = lastDate.toLocaleDateString().replace(/\//g, '-');
    let firstDate = `1-${lastDate.split('-')[1]}-${lastDate.split('-')[2]}`;
    lastDate = lastDate.split('-');
    firstDate = firstDate.split('-');

    return `${firstDate[2]}-${firstDate[1].length === 1 ? '0' + firstDate[1] : firstDate[1]}`
        + `-${firstDate[0].length === 1 ? '0' + firstDate[0] : firstDate[0]}`
        + `&${lastDate[2]}-${lastDate[1].length === 1 ? '0' + lastDate[1] : lastDate[1]}`
        + `-${lastDate[0].length === 1 ? '0' + lastDate[0] : lastDate[0]}`;
}

function initCountsProcess(processes){
    let initCounts = {}
    for (const process of processes){
        initCounts[process] = emptyStatus
    }
    return initCounts
}
/**** refresh Case Specific ****/

export function cleanSpecifictCase() {
    return async (dispatch) => {
        dispatch(cleanCase([]));
    }
}

const cleanCase = status => ({
    type: CLEAN_SPECIFIC_CASE,
    payload: status
});

/**** Download Cases ****/

export function loadCasesAction(management) {
    return async (dispatch) => {
        dispatch( startCases(true));
        dispatch( loadingTotalCases(true));
        let processes = Object.keys(processesByManagements[management]);
        if ( global.isGroupDisbursement() ) processes = [ ...processes, "reviewDoc", ...Object.keys(processesByManagements["reviewDoc"])]
        let result = {[management]: initCountsProcess(processes) };
        for (const processId of processes) {
            try {
                const url = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/count/${management}/${processId}/${getRangesDates()}`;
                await axios.get(url).then(function (response) {
                    result[management][processId] = response.data;              
                });
            } catch (error) {
                console.log(error);
                result[management][processId] = emptyStatus;
            }
            dispatch( cleanResult(null) );
            dispatch( downloadResult(result) ); 
        };
        dispatch( loadingTotalCases(false));
    }
}

const modalFail = () => {
    Swal.fire({
        icon: 'error',
        title: '¡Lo sentimos!',
        text: 'Error obteniendo los casos',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
        
    })
}

const startCases = status => ({
    type: STAR_CASES,
    payload: status
});
const loadingTotalCases = status => ({
    type: LOADING_TOTAL_CASES,
    payload: status
});

const downloadResult = response => ({
    type: DOWNLOAD_RESULT_CASES,
    payload: response
});

const cleanResult = response => ({
    type: CLEAN_RESULT_CASES,
    payload: response
});

/**** Search Case ****/

export function searchSpecificCase(id) {

        return async (dispatch) => { 
            if(!id) {
                dispatch( searchSpecificCaseFail(false) );
                return;
            }
            dispatch(searcheSpecifict(true));
            const path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/request-number/${id}`;
            try {
                const response = await axios.get(path);
                dispatch( searcheSpecifictSuccess(response));

            } catch (error) {
                if(id !== null && id !== '' && error && error.response) {
                    const status = error.response.status ? error.response.status : undefined;
                    if (error.response.status === 404 && status) {
                        dispatch (searchSpecificCaseNotFound(true))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: '¡Lo sentimos!',
                            text: 'Error consultando el caso',
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: '#002c76'
                        })
                    }
                } else {
                    dispatch( searchSpecificCaseFail(false) );
                }
        }
    }
}

const searcheSpecifict = state => ({
    type: SEARCH_SPECIFIC_CASE,
    payload: state
});

const searcheSpecifictSuccess = response => ({
    type: SEARCH_SPECIFIC_CASE_SUCCESS,
    payload: response
});

const searchSpecificCaseFail = state => ({
    type: SEARCH_SPECIFIC_CASE_FAIL,
    payload: state
});

const searchSpecificCaseNotFound = state => ({
    type: SEARCH_SPECIFIC_CASE_NOT_FOUND,
    payload: state
});

/**** Prioritize Cases ****/

export function caseToPrioritize(selectedCase) {
    selectedCase.document = selectedCase.document.startsWith('N/A') ? 'N/A' : selectedCase.document;
    return async (dispatch) => {
        dispatch( selectCaseToPrioritize(selectedCase) )
    }
}

export function casePrioritize(casePrioritize) {

    return async (dispatch) => {
        dispatch(casePrioritizeInit());
        const defaultPriority = casePrioritize.skRequest.substring(0,1) === '1' 
        ? 'Alta' : 'Media';
        const path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/change/priority/${casePrioritize.priorityFlag !==
            undefined ? casePrioritize.priorityFlag : defaultPriority}`;
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        const response = await axios.put(
            path,
            {
                "pkRequest": casePrioritize.pkRequest,
                "skRequest": casePrioritize.skRequest,
                "leaderPriorityChangeUser": userLoginDecrypt
            }
        );
        if(response.status === 200) {
            casePrioritize.skRequest = defaultPriority === 'Alta' ?
                casePrioritize.skRequest.replace('1#', '5#') : casePrioritize.skRequest.replace('5#', '1#');
            dispatch(casePrioritizeSuccess(casePrioritize));
            dispatch(casePrioritizeAlert(`El caso  ${casePrioritize.requestNumber} se priorizó con éxito`));
            setTimeout(() => dispatch(casePrioritizeAlert(null)), 4000);
        } else {
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: `No se pudo priorizar el caso ${casePrioritize.requestNumber}`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
            dispatch(casePrioritizeError());
        }
    }
}

const selectCaseToPrioritize = caseSelected => ({
    type: SELECT_CASE_TO_PRIORITIZE,
    payload: caseSelected
});

const casePrioritizeInit = () => ({
    type: CASE_PRIORITIZE,
    payload: true
});

const casePrioritizeSuccess = casePrioritize => ({
    type: CASE_PRIORITIZE_SUCCESSS,
    payload: casePrioritize
});

const casePrioritizeAlert = state => ({
    type: CASE_PRIORITIZE_ALERT,
    payload: state
});

const casePrioritizeError = () => ({
    type: CASE_PRIORITIZE_ERROR,
    payload: true
});

/**** Reassign Cases ****/

export function caseToReassigned(selectedCase) {
    selectedCase.document = selectedCase.document.startsWith('N/A') ? 'N/A' : selectedCase.document;
    return async (dispatch) => {
        dispatch( selectCaseToReassigned(selectedCase) )
    }
}

export function caseReassigned(caseReassign) {
    return async (dispatch) => {
        dispatch(caseReassignInit());
        const result = await getUserInfoIDM(caseReassign.reqUser).catch(err => {
            return err.response;
        });
        if (result.data.codigo !== "0") {
            dispatch(caseReassignAlert(`Usuario de red incorrecto`));
            setTimeout(() => dispatch(caseReassignAlert(null)), 4000);
            dispatch(caseReassignError());
            return;
        }
        const groupsExpComercial = result.data.atributos[0].userGroups.filter((group) => group.includes("EXP_COMERCIAL"))
        let isRolValid = false
        groupsExpComercial.forEach((group) => {
          const idmGroup = group.split(',')[0].replace('cn=','')
          if (idmAnalystRol.includes(idmGroup)) isRolValid = true
        })
        if (!isRolValid) {
            dispatch(caseReassignAlert(`Usuario de red incorrecto`));
            setTimeout(() => dispatch(caseReassignAlert(null)), 4000);
            dispatch(caseReassignError());
            return;
        }

/*         const idmRol = result.data.atributos[0].userGroups[0].split(',')[0].replace('cn=','');
        if (idmAnalystRol.indexOf(idmRol) === -1) {
            dispatch(caseReassignAlert(`Usuario de red incorrecto`));
            setTimeout(() => dispatch(caseReassignAlert(null)), 4000);
            dispatch(caseReassignError());
            return;
        } */

        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        const path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/change-owner`;
        const response = await axios.post(
            path,
            {
                "pkRequest": caseReassign.pkRequest,
                "skRequest": caseReassign.skRequest,
                "leader":userLoginDecrypt,
                "Requser":caseReassign.reqUser,
                "status": "OPEN"
            }
        );
        if(response.status === 200) {
            caseReassign.reqStatus = 'OPEN';
            dispatch(caseReassignSuccess(caseReassign));
            dispatch(caseReassignAlert('La reasignación fue exitosa'));
            setTimeout(() => dispatch(caseReassignAlert(null)), 4000);
        } else {
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: `No se pudo reasignar el caso ${caseReassign.requestNumber}`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
            dispatch(caseReassignError());
        }
    }
}

const selectCaseToReassigned = caseSelected => ({
    type: SELECT_CASE_TO_REASSING,
    payload: caseSelected
});

const caseReassignInit = () => ({
    type: CASE_REASSIGN,
    payload: true
});

const caseReassignSuccess = caseReassign => ({
    type: CASE_REASSIGN_SUCCESSS,
    payload: caseReassign
});

const caseReassignAlert = state => ({
    type: CASE_REASSIGN_ALERT,
    payload: state
});


const caseReassignError = () => ({
    type: CASE_REASSIGN_ERROR,
    payload: true
});

