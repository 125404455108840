import moment from 'moment';
import { getCtrlBrdInfoByCc, getCtrlBrdInfoByName, postCtrlBrdNews, getProductivityNewByUser, getProductivityNewByGroup, getLeaderGroup, deleteNewsById, putNewsCard, postMassiveNew, postGroupMassive, putGroupMassive, getMngmntsAndProcessList, getCtrlBrdInfoByUser } from '../bdb-services/GeneralService/controlboardService';
import { REPOBLATE_NEWS, LINK_BACK_VIEW, GET_LIST_STAFF, ACTIVE_EVENT_TEMPORAL_LEADER, EDIT_MODE, UPDATE_ACTIVE_EVENT, RESET_ACTIVE_MESSAGE_PART, UPDATE_ENABLE_BOARD_NEWS, CHANGE_LOADING_VISIBILITY, SUCCESS_SAVE_TOAST, RESET_ACTIVE_MESSAGE, UPDATE_BUTTON_PRODUCTIVITY, ADD_MEMBER_TO_GROUP, DELETE_MEMBER_TO_GROUP, DELETE_ALL_MEMBERS_TO_GROUP, UPDATE_NAME_MEMBER, UPDATE_NAME_GROUP, SET_GROUP_MEMBERS, SET_GROUP_INFO, UPDATE_REDIRECT, UPDATE_ACTIVE_EVENT_LEADER, SET_MANAGEMENT_LIST_LEADER } from "../bdb-types";
import { getdecrypt } from "../bdb-services/GeneralService/globalService";
import Swal from 'sweetalert2';
import { updateCards } from "./productivityControlBoardActions";
import { formattedNames, b64_to_utf8_Util, decryptUsingAES256utils } from "../bdb-helpers/utils";
import '../../index.css';
import { newsByHours } from '../../constants';
import { getManagementByVerticalUtils } from "../bdb-helpers/utils";

export const setManagementList = (event) => ({
    type: SET_MANAGEMENT_LIST_LEADER,
    payload:event

})
export const repoblateNews = (event) => ({
    type: REPOBLATE_NEWS,
    payload: event
})

export const updateActiveEvent = (event) => ({
    type: UPDATE_ACTIVE_EVENT_LEADER,
    payload: event
})

export const linkBackView = (event) => ({
    type: LINK_BACK_VIEW,
    payload: event
})

export const updateLoading = (state) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
})
export const updateToastView = (event) => ({
    type: SUCCESS_SAVE_TOAST,
    payload: event
})
export const updateEnableButton = (event) => ({
    type: UPDATE_BUTTON_PRODUCTIVITY,
    payload: event
})
export const updateEnableBoardNews = (event) => ({
    type: UPDATE_ENABLE_BOARD_NEWS,
    payload: event
})
export const resetActveEvent = () => ({
    type: RESET_ACTIVE_MESSAGE,
})
export const resetActveEventPart = (event) => ({
    type: RESET_ACTIVE_MESSAGE_PART,
    payload: event
})
export const editModeLeader = (event) => ({
    type: EDIT_MODE,
    payload: event
})

export const leaderTemporal = (event) => ({
    type: ACTIVE_EVENT_TEMPORAL_LEADER,
    payload: event
})

export const dataListStaff = (event) => ({
    type: GET_LIST_STAFF,
    payload: event
})
export const groupMembers = (event) => ({
    type: SET_GROUP_MEMBERS,
    payload: event
})
export const groupInfo = (event) => ({
    type: SET_GROUP_INFO,
    payload: event
})


export const addMember = (payload) => ({
    type: ADD_MEMBER_TO_GROUP,
    payload
});

export const deleteMember = (payload) => ({
    type: DELETE_MEMBER_TO_GROUP,
    payload
});

export const deleteAllMembers = () => ({
    type: DELETE_ALL_MEMBERS_TO_GROUP
});

export const updateNameMember = (payload) => ({
    type: UPDATE_NAME_MEMBER,
    payload
});
export const updateNameGroup = (payload) => ({
    type: UPDATE_NAME_GROUP,
    payload
});

export const changeRedirect = (payload) => ({
    type: UPDATE_REDIRECT,
    payload
});


export function getDatalistStaff(event) {
    return async (dispatch) => {
        let datapp = event.map(function (e) {
            return {
                id: e['fullName'],
                label: e['fullName'],
                documentNumber: e['documentNumber'],
                user: e['user'],
                isAdministrative: e['isAdministrative']
            }
        }
        );
        dispatch(dataListStaff(datapp))
    }
}

export function selectUserOfList(data) {
    return (dispatch, getState) => {
        dispatch(getNewsManager());
        const responseDataStaff = data.data.Items[0];
        const tempActiveEvent = { ...getState().leaderProdBoard.activeEvent, gestorName: responseDataStaff.fullName }
        dispatch(updateActiveEvent(tempActiveEvent))
    }
}


export function postInsertNews(dataInsert) {
    return async (dispatch, getState) => {
        let response;
        let validation = getState().prodContBoard.prodCards.filter(card => {
            if (card.processType != 'new') {
                return false
            }
            let cardBeginDate = moment(card.beginDate);
            let cardEndDate = moment(card.endDate);
            let insertBeginDate = dataInsert.timeInit ? dataInsert.timeInit : moment(dataInsert.dateInit.toISOString().split('T')[0] + 'T08:00:00');
            let insertEndDate = dataInsert.timeEnd ? dataInsert.timeEnd : moment(dataInsert.dateEnd.toISOString().split('T')[0] + 'T17:30:00');

            if ((cardBeginDate > insertEndDate || cardEndDate < insertBeginDate) || (card.enabled && getState().leaderProdBoard.editMode)) {
                return false
            }
            return true
        })
        if (validation.length >= 1) {
            Swal.fire({
                icon: 'error',
                iconColor: '#FF5733 ',
                title: '<h3>Lo sentimos</h3>',
                html: `Ya registraste una novedad en el rango <br> de fechas seleccionadas. `,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            });
            return
        }
        let documentNumber = !!dataInsert && dataInsert.gestorId ? dataInsert.gestorId : dataInsert.gestorName?.documentNumber;
        const user = !!dataInsert && dataInsert.gestorId ? dataInsert.gestorId : dataInsert.gestorName?.user;
        const newWatchedByManager = !!newsByHours.find((newByHour => newByHour.id === dataInsert.newsProcess.id));
        const responseInfoUser = await getCtrlBrdInfoByUser(user);
        const data = responseInfoUser.data;
        const isAdministrative = data.Items[0];
        const body = {
            "documentNumber": documentNumber,
            "fullName": dataInsert.gestorName.label,
            "new": dataInsert.newsProcess.id,
            "area": dataInsert.area?.id,
            "vertical": getdecrypt('vertical').replace(/"/g, ''),
            "management": dataInsert.management.id,
            "observations": dataInsert.observations,
            "caseSmax": dataInsert.caseSmax,
            "beginDate": dataInsert.timeInit ? String(moment(dataInsert.timeInit).format("YYYY-MM-DDTHH:mm:ss")).slice(0, 19) : String(moment(dataInsert.dateInit).format("YYYY-MM-DDT08:00:00")).slice(0, 19),
            "endDate": dataInsert.timeEnd ? String(moment(dataInsert.timeEnd).format("YYYY-MM-DDTHH:mm:ss")).slice(0, 19) : String(moment(dataInsert.dateEnd).format("YYYY-MM-DDT17:30:00")).slice(0, 19),
            "userLeader": getdecrypt('userLogin'),
            "isAdministrative": isAdministrative.isAdministrative,
            "user": dataInsert.gestorName.user,
            "isLeaderReported": newWatchedByManager,
            "date": moment().format('YYYY/MM/DD'),
        }
        dispatch(updateLoading(true));
        const key = getState().leaderProdBoard.editMode;
        if (key) {
            response = await putNewsCard(body, key).catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        } else {
            response = await postCtrlBrdNews(body).catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        }

        dispatch(updateLoading(false));
        let activeModal = {}
        if (response.status === 200) {
            activeModal = { icon: 'success', tittle: '¡Listo!', 
            text: 'La novedad se registró exitosamente. <br> <br> <b>¿Deseas registrar otra novedad <br> para este usuario? </br> ' }
        }
        else {
            activeModal = { icon: 'error', tittle: '<div>¡Lo sentimos!</div>', text: 'La novedad no se pudo registrar,<br>  intenta de nuevo.' }
        }
        const styles_Button = Swal.mixin({
            customClass: {
                cancelButton: 'btn-cancel-Button',
                confirmButton: 'btn-notCancel-Button'
            },
            buttonsStyling: false
        })
        await styles_Button.fire({
            icon: activeModal.icon,
            title: activeModal.tittle,
            html: activeModal.text,
            showCancelButton: Boolean(activeModal.icon !== 'error'),
            showConfirmButton: true,
            confirmButtonText: (activeModal.icon !== 'error') ? ' Si ' : 'Aceptar',
            cancelButtonText: ' No ',
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(getNewsManager());
                dispatch(resetActveEventPart(dataInsert));

            } else {
                dispatch(resetActveEvent());
                dispatch(updateEnableBoardNews(false));
            }
            dispatch(editModeLeader(undefined));
        });
    }
}

export function getNameById(id) {
    return async (dispatch, getState) => {
        let response = undefined;
            if (!!id && (id.hasOwnProperty('number') || id.hasOwnProperty('name'))) {
                id['vertical'] = getdecrypt('vertical') ? getdecrypt('vertical').replace(/"/g,'') : null
                response = await getCtrlBrdInfoByCc(id).catch(err => {
                    console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        }
        dispatch(updateLoading(false));
        if (!!response && response.status === 200) {
            dispatch(updateEnableBoardNews(true));
            let dataListStaff = !!response.data && !!response.data.Items ? response.data.Items : undefined;
            if (dataListStaff?.length >= 0 && !!dataListStaff[0]) {
                dispatch(getDatalistStaff(dataListStaff))
            } else {
                dispatch(updateToastView('warning'))
                dispatch(getDatalistStaff(dataListStaff))
            }
        }
        else if (!!response) {
            if (response.status === 404) {
                dispatch(updateToastView('warning'))
                dispatch(updateEnableBoardNews(false))
            }
            else await Swal.fire({
                icon: 'error',
                title: '<div>¡Lo sentimos!</div>',
                html: 'Presentamos inconvenientes técnicos,<br> intenta de nuevo',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            })
        }
    }
}

export function getNewsManager() {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true))
        let actualPage = 1;
        let response;
        const gestorId = getState().leaderProdBoard.activeEvent.gestorName?.documentNumber ?? getState().leaderProdBoard.activeEvent.gestorId;
        const beginDateInput = moment().subtract(30, 'days').toISOString().split('.')[0];
        const endDateInput = moment().toISOString().split('.')[0];

        while (true) {
            response = await getProductivityNewByUser(gestorId, beginDateInput, endDateInput, actualPage)
                .catch(err => {
                    dispatch(updateCards([]))
                    dispatch(updateLoading(false))
                    console.log('Error', err)
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
                });
            if (response.status !== 200) return;
            if (getpageUtils(response.data.lastPage) === actualPage) break
            else actualPage += 1
        }
        let temporalCard = [];
        response.data.news.forEach(item => {
            temporalCard.splice(0, 0, { ...item, enabled: true })
        })
        dispatch(updateCards(temporalCard))
        dispatch(updateLoading(false))
    }
}
export function getNewsMassive() {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true))
        let actualPage = 1;
        let response;
        const group = getState().leaderProdBoard.infoGroup.id;
        const beginDateInput = moment().subtract(30, 'days').toISOString().split('.')[0];
        const endDateInput = moment().toISOString().split('.')[0];
        while (true) {
            response = await getProductivityNewByGroup(group, beginDateInput, endDateInput, actualPage)
                .catch(err => {
                    dispatch(updateCards([]))
                    dispatch(updateLoading(false))
                    console.log('Error', err)
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
                });
            if (response.status !== 200) return;
            if (getpageUtils(response.data.lastPage) === actualPage) break
            else actualPage += 1
        }
        let temporalCard = [];
        response.data.news.forEach(item => {
            temporalCard.splice(0, 0, { ...item, enabled: true })
        })
        dispatch(updateCards(temporalCard))
        dispatch(updateLoading(false))
    }
}

export function getOwnGroup() {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true))
        const documentNumberAdviserDecrypt = b64_to_utf8_Util(sessionStorage.getItem('documentNumberAdviser'));
        const leader = decryptUsingAES256utils(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8_Util(process.env.REACT_APP_AES_KEY));
        await getLeaderGroup(leader)
            .then(res => {
                const { members, ...data } = res.data
                const membersMapped = members.map(function (e) {
                    return {
                        id: e['name'],
                        label: e['name'],
                        documentNumber: e['documentNumber'],
                        user: e['user'],
                        isAdministrative: !!e['isAdministrative'] ? e['isAdministrative'] : false
                    }
                })
                dispatch(groupInfo({ ...data, membersCount: members.length }))
                dispatch(groupMembers(membersMapped))
                sessionStorage.setItem("members",JSON.stringify(membersMapped))
                sessionStorage.setItem("infoGroup",JSON.stringify({ ...data, membersCount: members.length }))
                dispatch(getNewsMassive())
            })
            .catch(err => {
                if(err.response.status == 404){
                    dispatch(changeRedirect("/leader-productivity/group-settings"))
                }
                dispatch(updateLoading(false))
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
    }

}

export const getpageUtils = (event) => {
    return parseInt(event.split('=')[1].split('&')[0])
}

export function deleteNewCard(card) {

    return async (dispatch) => {
        let modalDelete = await Swal.fire({
            icon: 'info',
            html: '¿Estás seguro de <br> eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
        })
        if (modalDelete.isConfirmed) {
            dispatch(updateLoading(true));
            deleteNewsById(card).catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            })
                .then(() => {
                    dispatch(getNewsManager())
                })
            dispatch(updateLoading(false));
        }
    }
}

export function reploblateNews(dataInsert) {
    return async (dispatch) => {
        const updata_1 = dataInsert.management;
        const updata_2 = dataInsert.new;
        const fullNameInsert = !!dataInsert && !!dataInsert.fullName?.label ? dataInsert?.fullName.label : dataInsert.fullName;
        let repoblationEvent = {}
        let temporal = {}
        repoblationEvent = {
            gestorId: dataInsert.documentNumber,
            gestorName: { id: "gestorName", label: fullNameInsert },
            management: { id: "management", id: updata_1, label: formattedNames(updata_1, 26)},
            newsProcess: { id: "newsProcess", id: updata_2, label: formattedNames(updata_2, 26) },
            dateInit: moment(dataInsert.beginDate).toDate(),
            dateEnd: moment(dataInsert.endDate).toDate(),
            timeInit: moment(dataInsert.beginDate).toDate(),
            timeEnd: moment(dataInsert.endDate).toDate(),
            observations: dataInsert.observations,
            caseSmax: dataInsert.caseSmax,
            endDateLowerBound: moment(dataInsert.beginDate).toDate(),
        }
        temporal = {...repoblationEvent, miTemporal: "Este si"}
        dispatch(updateActiveEvent({...repoblationEvent}));
        dispatch(leaderTemporal({...temporal}));


    }
}

export function postInsertMassiveNews(dataInsert) {
    return async (dispatch, getState) => {
        let response;
        let validation = getState().prodContBoard.prodCards.filter(card => {
            let cardBeginDate = moment(card.beginDate).format("YYYY-MM-DDTHH:mm:ss")
            let cardEndDate = moment(card.endDate).format("YYYY-MM-DDTHH:mm:ss")
            let insertBeginDate = moment(dataInsert.timeInit).format("YYYY-MM-DDTHH:mm:ss")
            let insertEndDate = moment(dataInsert.timeEnd).format("YYYY-MM-DDTHH:mm:ss")

            if (cardBeginDate > insertEndDate || cardEndDate < insertBeginDate) {
                return false
            }
            return true
        })

        if (validation.length >= 1) {
            Swal.fire({
                icon: 'error',
                iconColor: '#FF5733 ',
                title: '<h3>Lo sentimos</h3>',
                html: `Ya registraste una novedad en el rango <br> de horas seleccionadas. `,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            });
            return
        }
        const { infoGroup, membersGroup } = getState().leaderProdBoard;
        const body = {
            "new": dataInsert.newsProcess.id,
            "vertical": getdecrypt('vertical').replace(/"/g, ''),
            "management": dataInsert.management.id,
            "area": dataInsert.area.id,
            "observations": dataInsert.observations,
            "beginDate": moment(dataInsert.timeInit).format("HH:mm:ss"),
            "endDate": moment(dataInsert.timeEnd).format("HH:mm:ss"),
            "userLeader": getdecrypt('userLogin'),
            "group": infoGroup.id,
            "members": membersGroup.map(({id,documentNumber,user, isAdministrative})=>({
                    name:id,
                    documentNumber,
                    user,
                    isAdministrative: !!isAdministrative? isAdministrative : false
            })),
            "isLeaderReported": true,
            "isMassiveNew":true,
            "caseSmax": dataInsert.caseSmax,
        }
        dispatch(updateLoading(true));

        response = await postMassiveNew(body).then((res)=>{
            return {...res,status:200}
        })
        .catch(err => {
            console.log('Error', err)
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });


        dispatch(updateLoading(false));
        let activeModal = {}
        if (response.status === 200) {
            activeModal = { icon: 'success', tittle: '¡Listo!', text: 'La novedad se registró exitosamente.' }
        }
        else {
            activeModal = { icon: 'error', tittle: '<div>¡Lo sentimos!</div>', text: 'La novedad no se pudo registrar,<br>  intenta de nuevo.' }
        }
        await Swal.fire({
            icon: activeModal.icon,
            title: activeModal.tittle,
            html: activeModal.text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
        }).then(result => {
            dispatch(resetActveEvent());
            dispatch(updateEnableBoardNews(false));
            dispatch(getNewsMassive());
        });
 
    }
}
export function createGroupMassive( method ) {
    return async (dispatch, getState) => {
        const { membersGroup, infoGroup } = getState().leaderProdBoard;
        dispatch(updateLoading(true));
        let messageModal = {};
        try {
            const body = {
                name: infoGroup.name.trim(),
                leader: getdecrypt('userLogin'),
                members: membersGroup.map( ({id, user, documentNumber, isAdministrative}) => ({
                    name: id,
                    user,
                    documentNumber,
                    isAdministrative: !!isAdministrative ? isAdministrative : false
                }))
            }
            const serviceFunction = method === 'PUT' && !!infoGroup.id ? putGroupMassive : postGroupMassive;
            await serviceFunction(body, infoGroup.id).then(() => {
                messageModal = { icon: 'success', tittle: '¡Listo!', text: 'Se ha creado el grupo exitosamente' };
       
                dispatch(groupInfo({ ...infoGroup, membersCount: membersGroup.length }))
                sessionStorage.setItem("members",JSON.stringify(membersGroup))
                sessionStorage.setItem("infoGroup",JSON.stringify({ ...infoGroup, membersCount: membersGroup.length }))
            });
        } catch (error) {
            console.error(error);
            let errorMessage = "Se ha presentado un error en la creación del grupo";
            if (error.response.status === 400) errorMessage = `El grupo con el nombre ${infoGroup.name.trim()} ya existe`;
            messageModal = { icon: 'error', tittle: '¡Error!', text: errorMessage };
        }
        dispatch(updateLoading(false));
        Swal.fire({
            icon: messageModal.icon,
            title: messageModal.tittle,
            html: messageModal.text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
        }).then(()=>{
            dispatch(changeRedirect("/leader-productivity/massive-news"))
        })
    }
}

export function getManagementByVertical(){
    return async(dispatch, getState) => {
        dispatch(updateLoading(true))
        const dataMapped = await  getManagementByVerticalUtils ()
        dispatch(setManagementList(dataMapped))
        const currentManagement = JSON.parse(sessionStorage.getItem("currentManagement"))
        if(!!currentManagement){
            const {activeEvent} = getState().leaderProdBoard
            let temporalActiveEvent = { ...activeEvent };
            temporalActiveEvent.management = currentManagement;
            dispatch(updateActiveEvent(temporalActiveEvent));
        }
        dispatch(updateLoading(false))

    }
}
