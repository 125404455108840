import { 
    UPDATE_FILE,
    UPDATE_FILE_TYPE,
    CHANGE_LOADING_VISIBILITY,
    ABORT_CASE_SUCCESS,
    UPDATE_PREVIEW_PROGRESS,
    SET_HISTORY_FILES
 } from '../bdb-types';

const initialState = {
    loading: false,
    selectedFile: null,
    selectedFileType: null,
    abortStatus: false,
    progressBar: 0,
    historyFiles: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload              
            }
        case UPDATE_FILE:
            return {
                ...state,
                selectedFile: action.payload
            }
        case UPDATE_FILE_TYPE:
            return {
                ...state,
                selectedFileType: action.payload
            }
        case ABORT_CASE_SUCCESS:
            return {
                ...state,
                abortStatus: action.payload              
            }
        case UPDATE_PREVIEW_PROGRESS:
            return {
                ...state,
                progressBar: action.payload              
            }
        case SET_HISTORY_FILES:
            return {
                ...state,
                historyFiles: action.payload
            }
        default:
            return state;
    }
}