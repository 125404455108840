import {
    QUERY_GUARANTEE,
    RESET_QUERY_GUARANTEE,
    RESERVE_GUARANTEE,
    CHANGE_LOADING_VISIBILITY,
    RESERVES_GUARANTEES_ALL,
    RESERVES_GUARANTEES_STATUS
} from '../bdb-types'
import axios from 'axios';
import moment from 'moment';
import { STATUS_RESERVE_REJECT, STATUS_RESERVE_REJECTED, STATUS_RESERVE_REPLACED, STATUS_RESERVE_SUCCESS } from '../../constants';
import Swal from 'sweetalert2';

export function queryGuarantee( guaranteeId ) {
    return async ( dispatch ) => {
        const url = `${ process.env.REACT_APP_FNG_API_ENDPOINT }scg/${ guaranteeId }`;
        await axios.get( url )
            .then( response => {
                const scgResponse = response.data;
                dispatch( getGuaranteeInfo( scgResponse && scgResponse.object ? scgResponse.object : {} ) );
                dispatch( getChangeLoadingVisibility( false ) );
            })
            .catch( error => {
                let errorMessage = error.response.data.description || error.response.data;
                errorMessage = errorMessage.indexOf( 'EJB Exception:' ) !== -1 ? 'Ocurrió un error intentando consultar la garantía' : errorMessage;
                console.error ( errorMessage );
                if ( error.response.status === 404 ) {
                    dispatch( getGuaranteeInfo({} ) );
                } else {
                    dispatch( getGuaranteeInfo({ error : true, errorDescription : 'Ocurrió un error consultando la garantía' } ) );
                }
                dispatch( getChangeLoadingVisibility( false ) );
            })
    }
}

export function resetQueryGuarantee() {
    return async ( dispatch ) => {
        dispatch( getResetQueryGuarantee() );
    }
}

export function reserveGuarantee( payload ) {
    return async ( dispatch ) => {
        const url = `${ process.env.REACT_APP_FNG_API_ENDPOINT }fng/reserve`;
        await axios.post( url, payload )
            .then( response => {
                const fngResponse = response.data;
                const obj =fngResponse.object 
                obj.productReplaced=fngResponse.productReplaced
                obj.replacedFNGProduct=fngResponse.replacedFNGProduct
                obj.replacedFNGMessage=fngResponse.replacedFNGMessage
                
                dispatch( getReserveGuarantee( fngResponse && fngResponse.object ? obj : {} ) );
                dispatch( getChangeLoadingVisibility( false ) );
            })
            .catch( error => {
                console.error ( error );
                let errorMessage = error.response.data.description || error.response.data;
                errorMessage = errorMessage.indexOf( 'EJB Exception:' ) !== -1 ? 'Ocurrió un error intentando solicitar la garantía' : errorMessage;
                console.error ( errorMessage );
                dispatch( getReserveGuarantee({ error : true, errorDescription : errorMessage } ) );
                dispatch( getChangeLoadingVisibility( false ) );
            })
    }
}

export function getReserves( dateRef ) {
    return async ( dispatch ) => {
        const url = `${ process.env.REACT_APP_FNG_API_ENDPOINT }fng/fng-get-by-dates`;
        const beginDate = moment( dateRef ).format("YYYY-MM-DD");
        const endDate = moment( dateRef ).format("YYYY-MM-DD");
        let page = 0;
        let responsePagination = [];
        let resp;
        
        dispatch( changeLoadingVisibility(true));
        do {
            page += 1;
            const params = { 
                page,
                beginDate,
                endDate
            };
            resp = await axios.post( url, null, { params } )
                .then( response => {
                    responsePagination = responsePagination.concat( response.data.reserveLogs )
                    return response
                })
                .catch( error => {
                    console.error ( error );
                    Swal.fire({
                        icon: 'error',
                        title: '¡Lo sentimos!',
                        text: 'Error obteniendo las reservas',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#002c76'
                    })
                    
                })
            if ( !resp ) break;
        } while ( getpageUtils( resp.data.lastPage ) !== page );

        dispatch( reservesGuaranteesAll( responsePagination ));
        dispatch( reservesGuaranteesStatus( { "fng": { "fngReserves": countStatus(responsePagination) }} ) )
        dispatch( changeLoadingVisibility(false));

    }
};

export const getpageUtils = ( e ) => {
    return parseInt(e.split('=')[1].split('&')[0])
};

export function changeLoadingVisibility( show ) {
    return async ( dispatch ) => {
        dispatch( getChangeLoadingVisibility( show ) )
    }
}
function countStatus( dataArray ) {
    let init = { 
        [STATUS_RESERVE_SUCCESS]: 0,
        [STATUS_RESERVE_REJECT]: 0,
        [STATUS_RESERVE_REPLACED]: 0
    };

    const counts = dataArray.reduce( (prev,current ) => {
       let approved = prev[STATUS_RESERVE_SUCCESS];
       let reject = prev[STATUS_RESERVE_REJECT];
       let replaced = prev[STATUS_RESERVE_REPLACED];
       if ( current.statusReserve === STATUS_RESERVE_SUCCESS){
           approved += 1
       }
        if ( current.statusReserve === STATUS_RESERVE_REJECT){
           reject += 1
       }
        if ( current.statusReserve === STATUS_RESERVE_REPLACED){
           replaced += 1
       }

       return { [STATUS_RESERVE_SUCCESS]: approved,
                [STATUS_RESERVE_REJECT]: reject,
                [STATUS_RESERVE_REPLACED]: replaced
       }
        
    }, init)
    
    return counts;
}
const getGuaranteeInfo = guaranteeInfo => ({
    type: QUERY_GUARANTEE,
    payload: guaranteeInfo
});

const getResetQueryGuarantee = () => ({
    type: RESET_QUERY_GUARANTEE,
    payload: undefined
});

const getReserveGuarantee = guaranteeInfo => ({
    type: RESERVE_GUARANTEE,
    payload: guaranteeInfo
});

const getChangeLoadingVisibility = show => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: show
});

const reservesGuaranteesAll = payload => ({
    type: RESERVES_GUARANTEES_ALL,
    payload: payload
});

const reservesGuaranteesStatus = payload => ({
    type: RESERVES_GUARANTEES_STATUS,
    payload: payload
});
