import {
    GET_CASE,
    GET_CASE_ERROR,
    GET_CASE_SUCCESS,
    PUT_CASE,
    PUT_CASE_SUCCESS,
    PUT_CASE_ERROR,
    OPEN_CASE_SUCCESS,
    PUT_CASE_PAUSE,
    GET_CASE_RESUME,
    PREPOPULATE_SPECIFIC_CASE,
    GET_CASES_OPEN_SUCCESS, 
    GET_CASES_PAUSE_SUCCESS,
    PUT_CASE_PAUSED,
    REMOVE_CASE_PAUSED,
    ABORT_CASE_SUCCESS,
    FILTER_PAUSED_CASES,
    RESET_PAUSED_CASES,
    GET_CASES_OPEN_SUCCESS_DOCUMENTS,
    CHANGE_LOADING_VISIBILITY,
    UPDATE_MANAGEMENT,
    PREVIEW_DOCUMENT,
    DOWNLOAD_DOCUMENT_DISBURSE,
    VIEW_LOGIN_CLOSE,
    SET_TIME_CASE_LOAD,
    GET_CASES_PAUSED_GPO,
    OPEN_CASE_PAUSED_GPO,
    SET_INDICATORS_PRODUCTIVITY,
    CHANGE_REQUESTED_PRODUCTS
} from '../bdb-types/index';

import Swal from 'sweetalert2';
import axios from 'axios';
import * as CryptoJS from "crypto-js";
import { processToTransfer, processes, mngmntAreaProcessCtrlBrd, STATUS_RESERVE_REJECTED, STATUS_RESERVE_APPROVED, processList, typesDocuments, genericRole, processesByManagements,idmRootAdminProd } from '../../constants';
import {postCtrlBrd, getCtrlBrdInfoByCc, getMngmntsAndProcessList, getCtrlBrdInfoByUser} from '../bdb-services/GeneralService/controlboardService';
import { getDocumentVews, transferDisbursementDocuments, updateDocument } from '../bdb-services/GeneralService/analystDisbursementService';
import { getCaseGpoPaused, getdecrypt, setSessionStorageEncrypted } from '../bdb-services/GeneralService/globalService';
import uuid from "uuid";
import moment from 'moment';
import { flujoRedescuentoName } from '../../constants';


function decryptUsingAES256(value, iv, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
}

export function getCasePending(management, processCase, indexName) {
    return async (dispatch) => { 
        dispatch( getCase(false) );
        const beginDateParam = encodeURIComponent((new Date()).toISOString().split('.')[0])
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        let path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/status/PENDING/10?process=${processCase}&management=${management}&indexName=${indexName}&reqUser=${userLoginDecrypt}&beginDate=${beginDateParam}`;
        if((processCase === 'vobo') || (processCase === 'voboPagaduria') ){
            path = `${ process.env.REACT_APP_SELF_API_ENDPOINT }requests/status-vobo/PENDING/10?process=${processCase}&management=${management}&userLogin=${userLoginDecrypt}&indexName=${indexName}&beginDate=${beginDateParam}`;
        }
        try {
            const response = await axios.get(path);
            if (!!response.data.Items) {
                response.data.Items[0].document = response.data.Items[0].document.startsWith('N/A') ? 'N/A' : response.data.Items[0].document;
                response.data.Items[0].beginDate = (new Date()).toISOString().split('.')[0];
                response.data.Items[0].requestedProducts = 1;

                let especifictNew = response.data.Items[0];
                especifictNew = {...especifictNew, typeCustomer: especifictNew.typeCustomer ? especifictNew.typeCustomer :  typesDocuments[especifictNew.typeDocument]}
                let  especifict = {...response}
                especifict.data.Items[0]= especifictNew;
                dispatch(getCausesOpenSuccess(response));
                dispatch( openSuccessOpen(false));
            }
            dispatch( getCaseSuccess(response) );

        } catch (error) {
            console.log('Cases error', error);
            dispatch( getCaseError(true) );
            const status = !!error.response ? error.response.status : 404;
        }
    }
}

export function getCaseOpen(especifict) {
    especifict.document = especifict.document.startsWith('N/A') ? 'N/A' : especifict.document;
    especifict.beginDate = especifict.beginDate ? especifict.beginDate : (new Date()).toISOString().split('.')[0]
    if(!especifict.requestedProducts){
        especifict.requestedProducts  = 1    
    }
    return async (dispatch) => { 
        dispatch( getCase(false) );
        dispatch( getCaseOpenSuccess(especifict));
    }
}
export function putApproved(especifict) {
    return async (dispatch,getState) => { 
        dispatch( put() )
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/change/status/APPROVED';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        const body = {
            pkRequest: especifict.pkRequest,
            skRequest: especifict.skRequest,
            reqUser: userLoginDecrypt
        };
        const moreInfo = {
            cc : b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')),
            userLogin: userLoginDecrypt,
            management: b64_to_utf8(sessionStorage.getItem('management'))
        }
        let bodyCtrlBrd;
        try {
            bodyCtrlBrd = await getCtrlBrdBody(especifict, moreInfo, STATUS_RESERVE_APPROVED);
        } catch (error) {
            console.error(error);
            await new Promise( (resolve, reject) => {
                Swal.fire({
                    icon: 'info',
                    title: null,
                    html: `Para continuar es necesario que<br>verifiques con tu líder que tu usuario se<br>encuentre relacionado en el archivo de <br>planta de la herramienta.`,
                    confirmButtonColor: '#002c76',
                    confirmButtonText: 'Aceptar'
                    
                }).then( () => {
                    resolve();
                })
            })
        };
        try {
            if ((especifict.libranza === '1' && especifict.currentProcess === 'Control de Documentos')
                || processToTransfer.includes(especifict.process)) {
                const pathTransfer = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/case/case-transfer';
                const responseTransfer = await axios.put(pathTransfer, body);
                const status = responseTransfer.status !== 200;
                console.log(status ? 'Transfer case error' : 'success', responseTransfer);
            }
            const response = await axios.put(path, body);
            const status = response.status !== 200;
            if (!status && !!bodyCtrlBrd) {
                await postCtrlBrd(bodyCtrlBrd);
            }
            dispatch( putSuccess(especifict) )
            
            Swal.fire({
                icon: status ? 'warning' : 'success',
                title: status ? 'Lo sentimos' : '¡Listo!',
                text: status ? `Ha ocurrido un error aprobando el caso ${especifict.requestNumber}` : 
                    `Ha sido aprobado el caso ${especifict.requestNumber}`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            });
            
        } catch (error) {
            console.log('Case approved error', error);
            dispatch( putError() )
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error aprobando el caso',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
        if(especifict.process === 'Orden de Desembolsos'){
            try{
                const transferDocumentsBody = {
                    controlNumber:especifict.numberControl,
                    creditProject : especifict.creditProject,
                    userRole : getdecrypt('rolUser') ?? genericRole
                }
                let documentDisbursement = sessionStorage.getItem("documentDisbursement");

                if(especifict.flow === flujoRedescuentoName){
                    const customerInfo = {
                        "name": especifict.flow,
                        "custDocType": especifict.typeDocument,
                        "custIdentNum": especifict.document
                    }
                    var jsonDocumentDisbursement = JSON.parse(documentDisbursement);
                    const responseUpdate =  await updateDocument(customerInfo, jsonDocumentDisbursement);
                    const statusUpdate = responseUpdate.status !== 200;
                    if (statusUpdate) {
                        Swal.fire({
                            icon: 'error',
                            title: '¡Lo sentimos!',
                            text: 'Error al cargar los documentos',
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: '#002c76'
                        })
                    }
                }else{
                    const responseTransfer = await transferDisbursementDocuments(transferDocumentsBody);
                    const statusTransfer = responseTransfer.status !== 200;
                    if (statusTransfer) {
                        Swal.fire({
                            icon: 'error',
                            title: '¡Lo sentimos!',
                            text: 'Error al cargar los documentos',
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: '#002c76'
                        })
                    }
                }
            }
            catch (error){
                console.log('transfer documents error', error);
                Swal.fire({
                    icon: 'error',
                    title: '¡Lo sentimos!',
                    text: 'Error al cargar los documentos',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                })
            }
        }
    }
}

export function putReject(especifict) {
    return async (dispatch, getState) => { 
        dispatch( put() )
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/change/status/REJECT';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        const body = {
            pkRequest: especifict.pkRequest,
            skRequest: especifict.skRequest,
            reqUser: userLoginDecrypt,
            reasonReturn: especifict.reasonReturn??null

        };
        const moreInfo = {
            cc : b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')),
            userLogin: userLoginDecrypt,
            management: b64_to_utf8(sessionStorage.getItem('management'))
        }
        let bodyCtrlBrd;
        try {
            bodyCtrlBrd = await getCtrlBrdBody(especifict, moreInfo, STATUS_RESERVE_REJECTED);
        } catch (error) {
            console.error(error);
            await new Promise( (resolve, reject) => {
                Swal.fire({
                    icon: 'info',
                    title: null,
                    html: `Para continuar es necesario que<br>verifiques con tu líder que tu usuario se<br>encuentre relacionado en el archivo de <br>planta de la herramienta.`,
                    confirmButtonColor: '#002c76',
                    confirmButtonText: 'Aceptar'
                    
                }).then( () => {
                    resolve();

                })
    
            })
        }
        try {
            const response = await axios.put(path, body);
            const status = response.status !== 200;
            if (!status && !!bodyCtrlBrd) {
                await postCtrlBrd(bodyCtrlBrd);
            }
            dispatch( putSuccess(especifict) )
            Swal.fire({
                icon: status ? 'warning' : 'success',
                title: status ? 'Lo sentimos' : '¡Listo!',
                text: status ? `Ha ocurrido un error devolviendo el caso ${especifict.requestNumber}` : 
                    `Ha sido devuelto el caso ${especifict.requestNumber}`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            });
        } catch (error) {
            console.log('Case reject error', error);
            dispatch( putError() )
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error rechazando el caso',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

export function putOpenCasePaused(especifict) {
    return async (dispatch) => {
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/change/status/OPEN';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        const body = {
            pkRequest: especifict.pkRequest,
            skRequest: especifict.skRequest,
            reqUser: userLoginDecrypt
        };
        
        try {
            const response = await axios.put(path, body);
            dispatch( openSuccessOpen(false) );
        } catch (error) {
            console.log('Case paused error', error);
            dispatch( putError() )
        }
    }
}

export function getCaseResume(caseToResume) {
    caseToResume.document = caseToResume.document.startsWith('N/A') ? 'N/A' : caseToResume.document;
    caseToResume.beginDate = (new Date()).toISOString().split('.')[0];
    return async (dispatch) => { 
        dispatch( getCase(false) );
        dispatch( getToCaseResume(caseToResume) );
        dispatch( putOpenCasePaused(caseToResume) );
    }
}

export function putPause () {
    return (dispatch) => { 
        dispatch( putPauseSuccess() );
    }
 }

export const timeCaseLoad = option => ({
    type: SET_TIME_CASE_LOAD,
    payload: option
});

const putPauseSuccess = () => ({
    type: PUT_CASE_PAUSE,
    payload: false
});

const getCase = value => ({
    type: GET_CASE,
    payload: value
});

const getCaseSuccess = response => ({
    type: GET_CASE_SUCCESS,
    payload: response
});

const setIndicator = option => ({
    type: SET_INDICATORS_PRODUCTIVITY,
    payload: option
});
const getCaseOpenSuccess = response => ({
    type: PREPOPULATE_SPECIFIC_CASE,
    payload: response
});

const getToCaseResume = response => ({
    type: GET_CASE_RESUME,
    payload: response
});

const getCaseError = response => ({
    type: GET_CASE_ERROR,
    payload: response
});

const putSuccess = especifict => ({
    type: PUT_CASE_SUCCESS,
    payload: especifict
});

const openSuccessOpen = value => ({
    type: OPEN_CASE_SUCCESS,
    payload: value
});

const put = state => ({
    type: PUT_CASE,
    payload: state
});

const putError = state => ({
    type: PUT_CASE_ERROR,
    payload: state
});

const changeRequestedProductsAction = state => ({
    type: CHANGE_REQUESTED_PRODUCTS,
    payload: state
});

export function changeRequestedProducts(especifict, state) {
    const data = {...especifict,requestedProducts:state}
    return async (dispatch) => {
        dispatch( changeRequestedProductsAction(data));
    }
}

export const getBodyCasesOpenDocuments =(event) => ({
    type:GET_CASES_OPEN_SUCCESS_DOCUMENTS,
    payload:event
})
export const updateLoadingAnalist = ( state ) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
})

export const updateManagement = ( state ) => ({
    type: UPDATE_MANAGEMENT,
    payload: state
})

export const downloadDocumentDisburse = ( state ) => ({
    type: DOWNLOAD_DOCUMENT_DISBURSE,
    payload: state
})

export const viewLoginClose = ( state ) => ({
    type:VIEW_LOGIN_CLOSE,
    payload: state
})

/***** GET CASES OPEN *****/

export function loadCasesInOpen() {
    return async (dispatch) => {
        dispatch( getCase(true) );
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/status-user/OPEN/';
        try {
            const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
            const userLogin =decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))

            await axios.get(path + userLogin)
                .then(function (response) {
                    dispatch( getCausesOpenSuccess(response) );
                });
        } catch(error) {
            dispatch( getCaseError(false) );
            const status = !!error.response ? error.response.status : 404;
            if (status !== 404) {
                Swal.fire({
                    icon: 'error',
                    title: '¡Lo sentimos!',
                    text: 'Error obteniendo los casos abiertos',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                    
                });
            }
        }
    }
}

const getCausesOpenSuccess = response => ({
    type: GET_CASES_OPEN_SUCCESS,
    payload: response
});

/**** Pause Cases For User ****/
export function loadCasesInPause() {
    return async (dispatch) => {
        dispatch( getCase(true) );

        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/status-user/PAUSED/';
        try {
            const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
            await axios.get(path +
                decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY)))
                .then(function (response) {
                    dispatch( getCausesPauseSuccess( filterPausedCaseByManagement( response.data.Items ) ) );
                });
        } catch(error) {
            dispatch( getCaseError(false) );
            const status = !!error.response ? error.response.status : 404;
            if (status !== 404) {
                Swal.fire({
                    icon: 'error',
                    title: '¡Lo sentimos!',
                    text: 'Error obteniendo los casos pausados',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                    
                });
            }
        }
    }
}

const getCausesPauseSuccess = response => ({
    type: GET_CASES_PAUSE_SUCCESS,
    payload: response
});
 
export function putPaused(especifict, reason) {
    return async (dispatch,getState) => {
        dispatch( put() );
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/change/status/PAUSED';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        const moreInfo = {
             cc : b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')),
             userLogin: userLoginDecrypt,
             management: b64_to_utf8(sessionStorage.getItem('management'))
         }
        const id = uuid.v4();
        let putBody = {
            pkRequest: especifict.pkRequest,
            skRequest: especifict.skRequest,
            reqUser: userLoginDecrypt,
            reasonReturn: reason,
            requestedProducts: especifict.requestedProducts
        }
        if ( !especifict['idProductivity']){
            putBody['idProductivity'] = id;
        };

        const response = await axios.put(
            path,
            putBody
            );
            if(response.status === 200) {
                especifict.reqStatus = 'PAUSED';
                let temporalEspecifict = { ...especifict };
                temporalEspecifict['idProductivity'] = response.data['idProductivity'];
                let bodyCtrlBrd;
                try {
                    bodyCtrlBrd = await getCtrlBrdBody(temporalEspecifict, moreInfo, false, id);                  
                } catch (error) {
                    console.error(error);
                    await new Promise( (resolve, reject) => {
                        Swal.fire({
                            icon: 'info',
                            title: null,
                            html: `Para continuar es necesario que<br>verifiques con tu líder que tu usuario se<br>encuentre relacionado en el archivo de <br>planta de la herramienta.`,
                            confirmButtonColor: '#002c76',
                            confirmButtonText: 'Aceptar'
                            
                        }).then( () => {
                            resolve();
                        })
    
                    })
                }
                !!bodyCtrlBrd && await postCtrlBrd(bodyCtrlBrd);
                dispatch( putPausedSuccess(temporalEspecifict));
        } else {
            dispatch( putError() );
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error pausando el caso',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

const putPausedSuccess = response => ({
    type: PUT_CASE_PAUSED,
    payload: response
});

export function removeCasePaused(idToDelete) {
    return async (dispatch) => {
        dispatch (deleteCasePaused(idToDelete));
    }
}

const deleteCasePaused = id => ({
    type: REMOVE_CASE_PAUSED,
    payload: id
});

export function putAbort(especifict, reason) {
    return async (dispatch) => {
        dispatch( put() );
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/change/status/ABORT';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        const response = await axios.put(
            path,
            {
                pkRequest: especifict.pkRequest,
                skRequest: especifict.skRequest,
                reqUser: userLoginDecrypt,
                reasonReturn: reason
            }
        );
        if(response.status === 200) {
            especifict.reqStatus = 'ABORT';
            dispatch( setAbortSuccess(especifict));
        } else {
            dispatch( putError() );
            Swal.fire({
                icon: 'error',
                title: '¡Lo sentimos!',
                text: 'Error rechazando el caso',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            })
        }
    }
}

const setAbortSuccess = value => ({
    type: ABORT_CASE_SUCCESS,
    payload: value
});

export function filterPausedCases( filter ) {
    return async ( dispatch ) => {
        dispatch( filterPausedCase( filter ) );
    };
}

const filterPausedCase = value => ({
    type: FILTER_PAUSED_CASES,
    payload: value
});

export function resetPausedCases() {
    return async ( dispatch ) => {
        dispatch( resetPausedCase() );
    };
}
export const getPreviewDocument = type => ({
    type: PREVIEW_DOCUMENT,
    payload: type
});

const resetPausedCase = () => ({
    type: RESET_PAUSED_CASES
});

export const messageLoadi = ()  =>{
    return async (dispatch) =>{
        dispatch( setIndicator('Cargando') );
    }
}


export async function getCtrlBrdBody(data, moreInfo,STATUS, id){
    const dateNow = moment();
    let constanProcess = processes.filter(item => item.label === data.process); 
    constanProcess = constanProcess.length === 0 ? processList.filter(item => item.label === data.process)[0].id : constanProcess[0].id;
    const management = mngmntAreaProcessCtrlBrd[moreInfo.management][constanProcess].management;
    const area = mngmntAreaProcessCtrlBrd[moreInfo.management][constanProcess].area;
    const processCtrlBrd = mngmntAreaProcessCtrlBrd[moreInfo.management][constanProcess].process;
    let dataReques = {
        'number': moreInfo.cc ? moreInfo.cc : undefined,
        'vertical': getdecrypt('vertical').replace(/"/g, '')
    }
    const responseStaff = await getCtrlBrdInfoByCc(dataReques).catch(err => {
        console.log('Error',err)
        return {...err.response, status:(err.response && err.response.status ? err.response.status:500)};
        });
    let dataStaff;
    if ( responseStaff.status === 200 ) {
        dataStaff = responseStaff.data.Items[0];
    }else {
        throw "Exception getting user in staff"
    }
    const vertical = getdecrypt('vertical')
    const responseProcess = await getMngmntsAndProcessList(`${vertical}/${encodeURIComponent(management)}/${encodeURIComponent(area)}`).catch(err => { 
        console.log('Error',err)
        return {...err.response, status:(err.response && err.response.status ? err.response.status:500)};
        });
    const dataProcess = responseProcess.status === 200 ? responseProcess.data.Items.filter(item=>item.process===processCtrlBrd)[0] : {};
    if (!dataProcess) {
        throw "Exception getting process data";
    }
    
    const uuidP = uuid.v4();
    let body = {
        "id": !!id ? id : uuidP,
        "idFirstPaused": !!data['idProductivity'] ? data['idProductivity'] : uuidP,
        "createAt": dateNow.format('YYYY-MM-DDTHH:mm:ss'),
        "date": dateNow.format('YYYY/MM/DD'),
        "beginDate": moment(data.beginDate).subtract(5,'hours').format('HH:mm:ss'),
        "endDate": dateNow.format('HH:mm:ss'),
        "pausedLeabel": data.reqStatus === 'REASSIGNED' ? 'REASSIGNED': !STATUS ? 'PAUSED': 'RESTORED',
        "management" : management,
        "area" : area,
        "new" : processCtrlBrd,
        "aprovedCount" : STATUS === STATUS_RESERVE_APPROVED ? (`${data.requestedProducts}` || "1") : "0",
        "returnedCount" : STATUS === STATUS_RESERVE_REJECTED ? (`${data.requestedProducts}` || "1") : "0",
        "othersCount" : "1",
        "ammount" : Number(data.amountApproved) || 0,
        "comments" : data['requestNumber'],
        "rrhhManagement" : dataStaff['management'],
        "user" : moreInfo.userLogin,
        "documentNumber": process.env.REACT_APP_ENABLE_CHARACTER_NUMBER ? moreInfo.cc : (!!moreInfo.cc ?  moreInfo.cc.replace(/\D/g,'') : moreInfo.cc ),
        "fullName" : dataStaff['fullName'],
        "frequency" : dataProcess.frequency,
        "daylyGoal" : (`${dataProcess.daylyGoal}`==='undefined' || `${dataProcess.daylyGoal}`==='0'||`${dataProcess.daylyGoal}`==='') ? "1" : `${dataProcess.daylyGoal}`,
        "assignedPeople" : Number(dataProcess.assignedPeople) === NaN || !Number(dataProcess.assignedPeople) ? 1 : Number(dataProcess.assignedPeople),
        "hasAmount" : dataProcess.hasAmount,
        "isAutomatic": true,
        "isAdministrative": 0,
        "isStrategic": 0,
        "approvedRegisters":"",
        "returnedRegisters":"",
        "isMasiveProccess":0,
        "vertical":getdecrypt('vertical') ? getdecrypt('vertical') : null,
    }
    if (!!moreInfo?.requestDate) body['requestDate'] = moreInfo.requestDate;
    return body;
}

export const getDocumentDisbursement  = (body) =>{
    return async (dispatch) =>{
        const {cloudPath} = body
        const splitKey = cloudPath.split( '/' );
        const lastKey = splitKey[ splitKey.length - 1 ].split( '.' );
        const ext = lastKey[lastKey.length-1]
        dispatch (updateLoadingAnalist(true));        
         await getDocumentVews(encodeURIComponent(cloudPath)).catch(err => {
             console.log('error',err);
         })
         .then(response =>{
             if(!!response && !!response.data){
                let file={
                    file:body.body.fileName, 
                    friendlyName:body.body.typeName, 
                    stream:response.data,
                    fileType:ext,
                    fileCloud: cloudPath
                    };
                if(body.id==="Orden de desembolso" || body.id === "Archivo plano"){
                    let fileNameArray = file.file.split( '.' );
                    fileNameArray.pop()
                    fileNameArray.push('xls')
                    const fileNameXLS = fileNameArray.join('.')
                    file.fileType = 'xls'
                    file.file = fileNameXLS
                    dispatch(downloadDocumentDisburse(file));
                }else if(!!file.file && !file.file.toUpperCase().includes('.XLS')){
                    dispatch(previewDocument(file));
                }
             }
          });
         dispatch (updateLoadingAnalist(false));
    }
}
export const getSelectDocumentDisbursement = (data) =>{
    return async (dispatch) =>{
        const inputData = data[0].option.id;
        const listDocumenDisbursement = JSON.parse(sessionStorage.getItem('documentDisbursement'));
        const idDocumentSelec = await listDocumenDisbursement.filter((e)=>e.typeName===inputData);
        dispatch(getDocumentDisbursement(idDocumentSelec[0]));
    }
}
export const getBase64ByDocHandle = (docHandle, isXls) => {
    return async(dispatch) => {
        await axios.get(`${process.env.REACT_APP_TRANSVCMP_COMPONEN}get-doc-stream/${docHandle}?userRole=ROL`)
            .then((response) => {
                const resp = response.data
                let file={
                    file:resp.docName, 
                    friendlyName:resp.typeName, 
                    stream:resp.stream,
                    fileType:resp.fileType,
                    fileCloud: docHandle
                    };
                if (isXls) {
                    let fileNameArray = file.file.split( '.' );
                    fileNameArray.pop()
                    fileNameArray.push('xls')
                    const fileNameXLS = fileNameArray.join('.')
                    file.fileType = 'xls'
                    file.file = fileNameXLS
                    dispatch(downloadDocumentDisburse(file));
                }
                if (!isXls) dispatch(previewDocument(file));
            })
            .catch((err) => {
                console.error(err)
            })
    }
}

export function previewDocument( file ) {
    return async( dispatch ) => {
        if ( file){
            const dataBlob = b64ToBlob(file.stream,'image/tiff');
            const fileObject = new File([dataBlob], file.file,{ type: "image/tiff" })
            if(file.fileType==='tiff' || file.fileType==='tif'){
                dispatch(previewTiffDocument(file, fileObject));
            }else{
                const reader = new FileReader();
                reader.readAsDataURL( fileObject );
                reader.onload = () => {
                    file.src = reader.result.toString();
                    dispatch( getPreviewDocument( file ) );
                
            }

            }
        }
    }
}

function b64ToBlob(base64, contentType) {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}
export function previewTiffDocument( file, fileObject) {
    return async( dispatch ) => {
        dispatch (updateLoadingAnalist(true));
        let filename = file.file ? file.file.split( '.' ) : [];
        filename.splice( filename.length - 1 );
        filename = filename.join( '.' );
        const reader = new FileReader();
        reader.readAsDataURL( fileObject );
        reader.onload = async () => {
            const tiffBase64 = reader.result.toString().split( 'base64,' )[ 1 ];
            const url = `${process.env.REACT_APP_TRANSVCMP_COMPONEN}convert-tiff-to-png`;
            const body = {
                'stream' : tiffBase64
            };
            await axios.post( url, body )
                .then( response => {
                    const blob = b64ToBlob( response.data.stream, ['data:image/png'] );
                    const filePng = new File( [ blob ], `${ filename }.png`, { type: 'image/png', lastModified: Date.now() } );
                    const reader = new FileReader();
                    reader.readAsDataURL( filePng );
                    reader.onload = () => {
                        file.src = reader.result.toString();
                        dispatch( getPreviewDocument( file ) );
                    }
                    } )
                .catch( error => {
                        console.error( 'An error has ocurred attempting to convert tif to png', error );
                } );
                    
            }
    }
}


export const getCausesGpo = response => ({
    type: GET_CASES_PAUSED_GPO,
    payload: response
});

export const openCasesGpo = response => ({
    type: OPEN_CASE_PAUSED_GPO,
    payload: response
});

export function getCasesPaused () {
    return async (dispatch) => {
        let userLogin = getdecrypt('userLogin')
        let caseMapp = null;
        try {
             await getCaseGpoPaused(userLogin)
            .then(function(response){
                if(!response ){
                    caseMapp= null;
                }else{
                    caseMapp = response.data.Items
                }
            });
            
        } catch (error) {
            console.log('Case reject error', error);
        }
        if(!!caseMapp){
            await mappeoPaused(caseMapp)
            .then(function(response){
                dispatch(getCausesGpo(response))
            })
        }else{
            dispatch(getCausesGpo([]))
        }
    }

}

export  async function mappeoPaused (data){
        let newMapp =[];
        if( !!data && data.length >0){
            for (let i=0; i <= (data.length-1) ; i++){
                newMapp.push({
                    id: data[i]?.id,
                    idProductivity: data[i]?.idProductivity,
                    beingUserDate: data[i].beingUserDate,
                    fgnReserve: false,
                    process: data[i].process,      
                    product: data[i].product,
                    requestDate: data[i].requestDate ,  
                    requestNumber: data[i].requestNumber,          
                    requestUser: data[i].requestUser,
                    reqStatus: data[i].statusCase,
                    statusCase: data[i].statusCase,    
                    subProcess: data[i].subProcess ? data[i].subProcess : null
                })
            }
            return newMapp
        }
}

export function putReassigned() {
    return async (dispatch,getState) => {
        dispatch( put() );
        const especifict = getState().cases.selectedCase
        const responseStaff = await getCtrlBrdInfoByUser(especifict.reqUser).catch(err => {
            console.log('Error',err)
            return {...err.response, status:(err.response && err.response.status ? err.response.status:500)};
            });
        const {documentNumber,user} = responseStaff.data.Items[0]

        const moreInfo = {
             cc : documentNumber,
             userLogin: user,
             management: b64_to_utf8(sessionStorage.getItem('management'))
         }
        const id = uuid.v4();

        let temporalEspecifict = { 
            ...especifict,
            reqStatus:'REASSIGNED'
        };  

        try {
            const bodyCtrlBrd = await getCtrlBrdBody(temporalEspecifict, moreInfo, false, id);  
            postCtrlBrd(bodyCtrlBrd);
        } catch (error) {
            console.error(error);
        }

    }
}

function filterPausedCaseByManagement( dataArray ){
    const management = b64_to_utf8(sessionStorage.getItem('management'));
    if ( !["groupDisbursement", "reviewDoc"].includes( management) ) return dataArray;
    const validProcesses = Object.values(processesByManagements[management]).map( proc => proc.value );
    return dataArray.filter( item => validProcesses.includes( item.process ) );
};