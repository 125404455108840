import * as CryptoJS from "crypto-js";

export const encryptUsingAES256 = (value, iv, key) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.encrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
}

export const decryptUsingAES256 = (value, iv, key) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}
export const b64_to_utf8 = str => {
    return decodeURIComponent(escape(window.atob( str )));
}
export const cleanSessionStorageInfo = () => {
    sessionStorage.removeItem('typeCustomer');
    sessionStorage.removeItem('numberCustomer');

}
export const sessionStorageSetItem = ( name, data ) => {
    sessionStorage.setItem( name, JSON.stringify(data) );
}
export const sessionStorageGetItem = ( name ) => {
    return JSON.parse( sessionStorage.getItem( name ) );
}
export const getdecrypt = (data) => {
    const documentNumberAdviserDecrypt = b64_to_utf8 (sessionStorage.getItem('documentNumberAdviser'));
    return decryptUsingAES256 (sessionStorage.getItem(data), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
} 