import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment'

import Loading from '../LoadingComponent/Loading';
import DatePickerCustomInput from '../../bdb-components/ProductivityFormComponent/DatePickerCustomInput';
import { getCtrlBrdBackupCsv } from '../../bdb-services/GeneralService/controlboardService';

const formatDate = "yyyy-MM"
const DownloadCtrlBrdCSV = ({titleProp ,warningProp}) =>{
    
    const [loading, setloading] = useState(false);
    const [date, setdate] = useState(null);

    const handleClick = async ( e ) => {
        const dateString = moment(date).format(formatDate);
        setloading(true)
        await getCtrlBrdBackupCsv(dateString)
            .then(res => {
                console.log(res)
                for (const indexName of Object.keys(res.data.Items)){
                    res.data.Items[indexName] = res.data.Items[indexName].map(function(elem){
                    return `<a href=${elem.fileURL}> ${elem.Key}</a>`
                  })
                }
                console.log(res.data)
                const jsonStr = JSON.stringify(res.data, null, 2);
                const newWindow = window.open('');
                newWindow.document.write(`<pre>${jsonStr}</pre>`);
                newWindow.document.close();
            })
            .catch(err => {
                console.log('Error', err)
            });
        setloading(false)
    };

    const handleSelectDate = ( e ) => {
        console.log(e)
        setdate(moment(e).toDate())
    };

    return ( 
        <div className='InsertData_DNO font-OpenSans-Regular'>  
            {loading?<Loading></Loading>:null}
            <>
            <div className='InsertData_DNO-tittle '>{titleProp}</div>
            <div className='InsertData_DNO-sub-tittle'>
                <div className='insert-data-dno-text-icon'></div>
                {warningProp}
            </div>
            </>
            <div className='d-flex justify-content-center flex-column align-items-center mt-10'>
                <DatePicker
                    showMonthYearPicker
                    selected={ date }
                    onChange={handleSelectDate}
                    placeholderText="Selecciona"
                    dateFormat={formatDate}
                    customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='downloadCSV-select-time leaderProd-input-content' styleArrow='downloadCSV-arrow-left leader-arrow-select' />}
                />
                <button
                    className='_updateUser-search-button'
                    disabled={!Boolean(date)}
                    onClick={ handleClick }
                >
                    Buscar
                </button>
            </div>
        </div>
    );
}
export default DownloadCtrlBrdCSV;