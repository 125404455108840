import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCaseReportAction } from '../../bdb-actions/reportDisbursementActions';
import moment from 'moment';
import uuid from "uuid";
import '../../../assets/styles/tableBodyStyle.css';
import Swal from 'sweetalert2';
import { getCaseGpoOpen, getdecrypt } from "../../bdb-services/GeneralService/globalService";

const retake='Retomar';

const Paused = ({row}) => {
    const { requestNumber, requestDate,
        reqStatus, process, product, id } = row;

    const history = useHistory();

    const dispatch = useDispatch();
    const [hourAssignmentLis, setHourAssignmentLis] = useState( false );
    const [dateAssignmentLis, setDateAssignmentLis] = useState( false );

    
    useEffect(()=>{
        setHourAssignmentLis(moment(requestDate).format('LTS'));
        setDateAssignmentLis(moment(requestDate).format('YYYY/MM/DD'));

    })

    const resumeCase = row => {
            getCaseGpoOpen(getdecrypt('userLogin')).then(resp=>{let caseOpen=resp;
            if(!!caseOpen && caseOpen.data.Items.length>0){
              Swal.fire({
                  icon: 'info',
                  html: `Recuerda que es necesario que <b>pauses, apruebes o devuelvas</b> el caso ${caseOpen.data.Items[0].requestNumber}<br>para que puedas retomar otro caso`,
                  showCancelButton: false,
                  confirmButtonText: 'Aceptar',
                  confirmButtonColor: '#002c76',
                }).then(result => {
                  
                });
              }else{
                let temp = { ...row}
                temp['beingUserDate'] = global.getDateFormater();
                temp['statusCase'] = null;
                temp['id'] = uuid();
                dispatch( setCaseReportAction(temp));
                history.push('/report-cases/disbursement');  
              }
            }); 
    }

    return ( 
        <tr>
            {  global.isGroupDisbursement() ? <td className="td-component unset-align">{requestNumber}</td> : null }
            {  global.isGroupDisbursement() ? <td className="td-component unset-align">{global.translateCopies(product)}</td> : null }
            {  global.isGroupDisbursement() ? <td className="td-component unset-align">{process}</td> : null }
            {  !global.isGroupDisbursement() ? <td className="td-component unset-align">{requestNumber}</td> : null }
            <td className="td-component unset-align">{dateAssignmentLis}</td>
            <td className="td-component unset-align">{hourAssignmentLis}</td>
            <td className="td-component unset-align">{reqStatus}</td>
            {  !global.isGroupDisbursement() ? <td className="td-component unset-align">{process}</td> : null }
            <td className="td-component unset-align">
            { reqStatus === 'PAUSED' || reqStatus === 'Pausado'?
                (
                <button 
                type="button"
                className="btn button-resume"
                onClick={() => resumeCase(row)}
                >{retake}</button> 
                ) : null 
            }
            </td>
        </tr>
     );

}
export default Paused;