import React ,{useState, useRef, useEffect} from 'react';
import './DynamicTableComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateDynamicTable, updateIndexDynamicTable, 
    setEditTableComponent, setAddTableComponent, setEmtyTableComponent } from '../../bdb-actions/dynamicTableActions';
import DynamicModalComponent from '../DynamicModalComponent/DynamicModalComponent';
import { VoBoCentralizedVector, VoBoPaymentVector } from '../../../constants';
import { startDynamicModal, finalizeDynamicModal, editDynamicTable } from '../../bdb-actions/dynamicModalActions';
import { VoBoCentralizedVectorStructure, VoBoPaymentVectorStructure } from '../../../structures';
import { getSelectedConfig } from '../../bdb-actions/libPrioritizationActions';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';


const DynamicTableComponent = (dataTableComponent) => {
    
    const dispatch = useDispatch();

    const { dataSet, selectAll, lock, edit, trash, headers, add} = dataTableComponent['data'];
    const idTab = dataTableComponent['id'];
    const indexSelected = dataTableComponent['indexSelected'];

    const show = useSelector(state => state.dynamicModal.show);
    const vector = useSelector(state => state.dynamicModal.vector);
    const filter = useSelector(state => state.dynamicTable.filter );
    const editCode = useSelector(state => state.dynamicModal.edit);
    const selected = useSelector(state => state.prioritizationLib.selected);
    const [selectVector, setSelectVector] = useState(selected);
    const [filterValue, setFilterValue] = useState(filter);

    useEffect(() => {
        const initIndexSelected = () => {
            setSelectVector(selected);
        }
        initIndexSelected();
    }, [selected]);

    useEffect(() => {
        setFilterValue(filter);
    }, [filter]);

    useEffect(() => {
        setShowModal(show);
      }, [show]);

    useEffect(() => {
        if (!!vector) {
            setShowModal(show);
            let updateDataSet = dataSet;
            let editInQuery = null;
            let updateDataComponent = dataTableComponent;
            if (!!editCode) { 
                updateDataSet = updateDataSet.filter(element => element['code'] !== editCode['code']);
                editInQuery = editCode;
                dispatch(editDynamicTable(null));
                dispatch(setEditTableComponent());
            } else {
                dispatch(setAddTableComponent());
            }
            if (idTab === 'VoBoCentralized') {
                updateDataSet = [...updateDataSet, vector];
                updateDataComponent = newMapperAdd(editInQuery, vector, updateDataComponent);
            }
            if (idTab === 'VoBoPayment') {
                let usersArrayNew = [];
                usersArrayNew = !!vector['firstUser'] ? [...usersArrayNew, vector['firstUser']] : usersArrayNew;
                usersArrayNew = !!vector['secondUser'] ? [...usersArrayNew, vector['secondUser']] : usersArrayNew;
                usersArrayNew = !!vector['thirdUser'] ? [...usersArrayNew, vector['thirdUser']] : usersArrayNew;
                usersArrayNew = !!vector['fourthdUser'] ? [...usersArrayNew, vector['fourthdUser']] : usersArrayNew;
                updateDataSet = [
                    ...updateDataSet, 
                    { code: vector['code'], name: vector['name'], usersArray: usersArrayNew }
                ];
            }
            updateDataComponent['dataDefault'][idTab]['dataSet'] = updateDataSet;
            dispatch( finalizeDynamicModal(false));
            dispatch( updateDynamicTable(updateDataComponent['dataDefault']));
        }
    }, [vector]);

    const newMapperAdd = (oldData, updateData, updateDataComponent) => {
        let VoBoPayment = updateDataComponent['dataDefault']['VoBoPayment']['dataSet'];
        if (!!oldData && !!updateData) {
            let newDataComponent = updateDataComponent;
            const oldVector = VoBoPayment.filter(element => element['code'] === oldData['code']);
            if (oldVector.length > 0) {
                const newVector = {code: updateData['code'], name: updateData['name'], usersArray: oldVector[0]['usersArray']};
                VoBoPayment = VoBoPayment.filter(element => element['code'] !== oldData['code']);
                VoBoPayment = [...VoBoPayment, newVector];
                newDataComponent['dataDefault']['VoBoPayment']['dataSet'] = VoBoPayment;
                return newDataComponent;
            }
            return updateDataComponent;
        }
        return updateDataComponent;
    }

    const [selectRows, setSelectRows] = useState(indexSelected);
    const [selectAllRows, setSelectAllRows] = useState(selectRows.length === dataSet.length);
    const [showModal, setShowModal] = useState(show);

    const lockRow = index => {
        const updateDataSet = dataSet;
        updateDataSet[index]['active'] = !updateDataSet[index]['active'];
        let updateDataComponent = dataTableComponent;
        updateDataComponent['dataDefault'][idTab]['dataSet'] = updateDataSet;
        let selects = selectRows;
        selects = selects.filter(select => select !== updateDataSet[index]['code']);
        setSelectRows(selects);
        generalIndexSelected(selects);
        dispatch( updateDynamicTable(updateDataComponent['dataDefault']));
    }

    const lockRowStyle = (flagLock, row) => {
        if (!lock) {
            return `row-dynamic-table row-dynamic-table-white`;
        }
        const color = flagLock ? 'white' : 'grey';
        return `row-dynamic-table row-dynamic-table-${color}`; 
    }

    const selectAllRowsTable = () => {
        let selected = [];
        if (!selectAllRows) {
            setSelectAllRows(true);
            dataSet.forEach(key => {
                selected = [...selected, key['code']];
            });
            setSelectRows(selected);
        } else {
            setSelectAllRows(false);
            setSelectRows(selected);
        }
        dispatch( getSelectedConfig(selected));
        dispatch( updateIndexDynamicTable(selected));
    }

    const selectRowId = id => {
        setSelectAllRows(false);
        if (selectRows.indexOf(id) === -1) {
            setSelectRows([...selectRows, id]);
            generalIndexSelected([...selectRows, id]);
        } else {
            const newsSelects = selectRows.filter(row => row !== id);
            setSelectRows(newsSelects);
            generalIndexSelected(newsSelects);
        }
    }

    const deleteRow = code => {
        let updateDataSet = dataSet;
        updateDataSet = updateDataSet.filter(element => element['code'] !== code);
        let updateDataComponent = dataTableComponent;
        updateDataComponent['dataDefault'][idTab]['dataSet'] = updateDataSet;
        dispatch( updateDynamicTable(updateDataComponent['dataDefault']));
    }

    const selectRowIdStyle = id => { 
        return selectRows.indexOf(id) === -1 ? 'check-icon-dynamic-table' : 'check-ok-icon-dynamic-table';
    }

    const selectAllRowsTableStyle = () => {
        return selectRows.length === dataSet.length  ?  'check-ok-icon-dynamic-table' : 'check-icon-dynamic-table';
    }

    const rowToShow = (id, value) => {
        
        if (id.toLowerCase().includes('array')) {
            let arrayToString = '';
            value.forEach((element, index) => {
                arrayToString += ` ${index < value.length ? (index === 0 ? '' : '-') : ''} ${element}`; 
            });
            
            return arrayToString;
        } 
        return value;
    }

    const lockStyle = unlock => {
        const style = unlock ? 'openlock' : 'clousedlock';
        return `${style}-icon-dynamic-table`;
    }

    const showModalAdd = () => {
        dispatch(startDynamicModal(true)); 
    }

    const structureToAdd = () => {
        if (idTab === 'VoBoCentralized') {
            return VoBoCentralizedVectorStructure;
        }
        if (idTab === 'VoBoPayment') {
            let addOptions = VoBoPaymentVectorStructure;
            let optionsList = [];
            const dataSetConvents = dataTableComponent['dataDefault']['VoBoCentralized']['dataSet'];
            dataSetConvents.forEach((data, index) => {
            optionsList[index] = {
                id: data['name'],
                code: data['code'],
                label: `${data['code']} - ${data['name']}`
            }
            });
            addOptions['structure']['row1'][0]['options'] = optionsList;
            return addOptions;
        }
    }

    const vectorToAdd = () => {
        if (idTab === 'VoBoCentralized') {
            return VoBoCentralizedVector;
        }
        if (idTab === 'VoBoPayment') {
            return VoBoPaymentVector;
        } 
    }

    const showModalEdit = row => {
        if (idTab === 'VoBoCentralized') {
            dispatch(editDynamicTable(row));
        }
        if (idTab === 'VoBoPayment') {
            const vectorToEdit = {
                code: row['code'],
                name: row['name'],
                firstUser: !!row['usersArray'][0] ? row['usersArray'][0] : null,
                secondUser: !!row['usersArray'][1] ? row['usersArray'][1] : null,
                thirdUser: !!row['usersArray'][2] ? row['usersArray'][2] : null,
                fourthdUser: !!row['usersArray'][3] ? row['usersArray'][3] : null,
            };
            dispatch(editDynamicTable(vectorToEdit));
        } 
        dispatch(startDynamicModal(true)); 
    }

    const rowFilter = row => {
        validateEmptyAlert();
        if (!(!!filterValue)) {
            return true;
        }
        if (idTab === 'VoBoCentralized') {
            return row['name'].toLowerCase().includes(filter.toLowerCase())
                || row['code'].includes(filterValue);
        } else {
            return row['name'].toLowerCase().includes(filter.toLowerCase())
                || row['code'].includes(filterValue) 
                || row['usersArray'].filter(user =>
                    user.toLowerCase().includes(filterValue.toLowerCase())).length > 0;
        }
    }

    const validateEmptyAlert = () => {
        if (!!filterValue) {
            let showAlertEmpty = 0;
            if (idTab === 'VoBoCentralized') {
                dataSet.forEach( row => {
                    const validRow = row['name'].toLowerCase().includes(filter.toLowerCase()) || row['code'].includes(filterValue);
                    showAlertEmpty = !validRow ? showAlertEmpty + 1 : showAlertEmpty;
                });
            } else {
                dataSet.forEach( row => {
                    const validRow = row['name'].toLowerCase().includes(filter.toLowerCase()) || row['code'].includes(filterValue) 
                    || row['usersArray'].filter(user => user.toLowerCase().includes(filterValue.toLowerCase())).length > 0;
                    showAlertEmpty = !validRow ? showAlertEmpty + 1 : showAlertEmpty;
                });
            }
            if (showAlertEmpty === dataSet.length) { dispatch(setEmtyTableComponent()); }
        }
        
    }

    function codesNotRepeated(data) {
        let result = data.filter((item,index)=>{
            return data.indexOf(item) === index;
        });
        return result;
    }

    const generalIndexSelected = (newIndex) => {
        let newSelects = [...selectVector];
        if (newIndex.length > 0) {
            newIndex.forEach(index => {
                newSelects = [...newSelects, index];
            });
        }
        newSelects = codesNotRepeated(newSelects);
        dispatch( getSelectedConfig(newSelects));
        dispatch( updateIndexDynamicTable(newSelects));
    }

        return (
            <div>
                { showModal ? 
                    <DynamicModalComponent 
                        modalStructure={structureToAdd()}
                        vectorModal={vectorToAdd()}
                        selecBotton={idTab} />
                    : null
                }
                <div className="header-dynamic-table">
                    <div>
                        { selectAll ? 
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Seleccionar <br></br>todos
                                    </Tooltip>
                                }>
                            <div className={selectAllRowsTableStyle()}
                                onClick={() => {selectAllRowsTable()}}></div>
                            </OverlayTrigger> : null
                        }
                    </div>
                    <div className="center-content-dynamic-table">
                        { headers ?
                            headers.map((header, index1) => (
                                <div key={index1} style={{width: header['width']}}>{header['name']}</div>
                                )) : null
                        }
                    </div>
                    <div className="last-options-dynamic-table">
                        { add ?
                            <div>
                                <div className="button-add-dynamic-table"
                                 onClick={() => {showModalAdd()}}>Agregar</div>
                            </div> : null
                        }
                    </div>
                </div>
                <div id="content-body" className="content-body-dynamic-table">
                    {
                    dataSet.map((row, index) => (
                            rowFilter(row) ? 
                            <div key={index} id="row" className={lockRowStyle(row['active'], row)}>
                                <div>
                                    { selectAll ?
                                        <div className={selectRowIdStyle(row['code'])} 
                                        style={!row['active'] ? {'backgroundImage': 'none'} : null}
                                        onClick={() => {selectRowId(row['code'])}}></div> : null
                                    }
                                </div>
                                <div className="center-content-dynamic-table">
                                    {
                                    headers.map((header, index2) => (
                                        <div key={index2} style={{width: header['width']}}>{rowToShow(header['id'], row[header['id']])}</div>
                                        ))
                                    }
                                </div>
                                <div className="last-options-dynamic-table">
                                    { edit ?
                                        <div>
                                            <div className="edit-icon-dynamic-table"
                                            onClick={() => showModalEdit(row)}></div>
                                        </div> : null
                                    }
                                    { lock ?
                                        <div>
                                            <div className={lockStyle(row['active'])}
                                                onClick={() => { lockRow(index)}}></div>
                                        </div> : null
                                    }
                                    { trash ?
                                        <div>
                                            <div className="trash-icon-dynamic-table"
                                                onClick={() => { deleteRow(row['code'])}}></div>
                                        </div> : null
                                    }
                                </div>
                            </div> : null
                    ))}
                </div>
            </div>
        );
}
 
export default DynamicTableComponent;