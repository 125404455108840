export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'UPDATE_FILE_ERROR';
export const UPDATE_FILE_TYPE = 'UPDATE_FILE_TYPE';

export const STAR_CASES = 'STAR_CASES';
export const LOADING_TOTAL_CASES = 'LOADING_TOTAL_CASES';
export const DOWNLOAD_RESULT_CASES = 'DOWNLOAD_RESULT_CASES';
export const CLEAN_RESULT_CASES = 'CLEAN_RESULT_CASES';

export const GET_CASE = 'GET_CASE';
export const GET_CASE_SUCCESS = 'GET_CASE_SUCCESS';
export const GET_CASE_ERROR = 'GET_CASE_ERROR';
export const GET_CASE_RESUME = 'GET_CASE_RESUME';

export const PUT_CASE = 'PUT_CASE';
export const PUT_CASE_SUCCESS = 'PUT_CASE_SUCCESS';
export const PUT_CASE_PAUSED = 'PUT_CASE_PAUSED';
export const PUT_CASE_PAUSE = 'PUT_CASE_PAUSE';
export const PUT_CASE_ERROR = 'PUT_CASE_ERROR';
export const OPEN_CASE_SUCCESS = 'OPEN_CASE_SUCCESS';
export const ABORT_CASE_SUCCESS = 'ABORT_CASE_SUCCESS';
export const FILTER_PAUSED_CASES = 'FILTER_PAUSED_CASES';
export const RESET_PAUSED_CASES = 'RESET_PAUSED_CASES';

export const SEARCH_SPECIFIC_CASE = 'SEARCH_SPECIFIC_CASE';
export const SEARCH_SPECIFIC_CASE_SUCCESS = 'SEARCH_SPECIFIC_CASE_SUCCESS';
export const SEARCH_SPECIFIC_CASE_FAIL = 'SEARCH_SPECIFIC_CASE_FAIL';
export const SEARCH_SPECIFIC_CASE_NOT_FOUND = 'SEARCH_SPECIFIC_CASE_NOT_FOUND';
export const CLEAN_SPECIFIC_CASE = 'CLEAN_SPECIFIC_CASE'

export const SELECT_CASE_TO_PRIORITIZE = 'SELECT_CASE_TO_PRIORITIZE';
export const CASE_PRIORITIZE = 'CASE_PRIORITIZE';
export const CASE_PRIORITIZE_SUCCESSS = 'CASE_PRIORITIZE_SUCCESSS';
export const CASE_PRIORITIZE_ERROR = 'CASE_PRIORITIZE_ERROR';
export const CASE_PRIORITIZE_ALERT = 'CASE_PRIORITIZE_ALERT';

export const SELECT_CASE_TO_REASSING = 'SELECT_CASE_TO_REASSING';
export const CASE_REASSIGN = 'CASE_REASING';
export const CASE_REASSIGN_SUCCESSS = 'CASE_REASING_SUCCESSS';
export const CASE_REASSIGN_ERROR = 'CASE_REASING_ERROR';
export const CASE_REASSIGN_ALERT = 'CASE_REASSIGN_ALERT';

export const LOGOUT = "LOGOUT";

export const GET_CASES_PAUSE_SUCCESS = 'GET_CASES_PAUSE_SUCCESS';

export const REMOVE_CASE_PAUSED = 'REMOVE_CASE_PAUSED';

export const GET_CASES_OPEN_SUCCESS = 'GET_CASES_OPEN_SUCCESS';
export const PREPOPULATE_SPECIFIC_CASE= 'PREPOPULATE_SPECIFIC_CASE';

export const GET_ITEMS_COMPONENT_START = 'GET_ITEMS_COMPONENT_START';
export const GET_ITEMS_COMPONENT_SUCCESS = 'GET_ITEMS_COMPONENT_SUCCESS';
export const GET_ITEMS_COMPONENT_FAIL = 'GET_ITEMS_COMPONENT_FAIL';
export const GET_ITEMS_COMPONENT_REDIRECT = 'GET_ITEMS_COMPONENT_REDIRECT';

export const UPDATE_PRIORITY_START = 'UPDATE_PRIORITY_START';
export const UPDATE_PRIORITY_SUCCESS = 'UPDATE_PRIORITY_SUCCESS';
export const UPDATE_PRIORITY_FAIL = 'UPDATE_PRIORITY_FAIL';

export const ININT_SELECT_COMPONENT = 'ININT_SELECT_COMPONENT';
export const OPTION_SELECTED_COMPONENT = 'OPTION_SELECTED_COMPONENT';

export const ININT_SELECT_SIMPLY_COMPONENT = 'ININT_SELECT_SIMPLY_COMPONENT';
export const OPTION_SELECTED_SIMPLY_COMPONENT = 'OPTION_SELECTED_SIMPLY_COMPONENT';
export const OPTION_MULTI_SELECTED_SIMPLY_COMPONENT = 'OPTION_MULTI_SELECTED_SIMPLY_COMPONENT';
export const OPTION_MULTI_REMOVE_SIMPLY_COMPONENT = 'OPTION_MULTI_REMOVE_SIMPLY_COMPONENT';

export const START_GET_CONVENTIONS_CONFIG = 'START_CONVENTIONS_CONFIG';
export const GET_CONVENTIONS_CONFIG_SUCCESS = 'GET_CONVENTIONS_CONFIG_SUCCESS';
export const SELECTED_CONVENTIONS_CONFIG = 'SELECTED_CONVENTIONS_CONFIG';
export const GET_CONVENTIONS_CONFIG_FAILED = 'GET_CONVENTIONS_CONFIG_FAILED';

export const START_UPDATE_CONVENTIONS_CONFIG = 'START_UPDATE_CONVENTIONS_CONFIG';
export const SUCCESS_UPDATE_CONVENTIONS_CONFIG = 'SUCCESS_UPDATE_CONVENTIONS_CONFIG';
export const FAIL_UPDATE_CONVENTIONS_CONFIG = 'FAIL_UPDATE_CONVENTIONS_CONFIG';

export const START_UPDATE_CONVENTIONS_USER = 'START_UPDATE_CONVENTIONS_CONFIG';
export const SUCCESS_UPDATE_CONVENTIONS_USER = 'SUCCESS_UPDATE_CONVENTIONS_CONFIG';
export const FAIL_UPDATE_CONVENTIONS_USER = 'FAIL_UPDATE_CONVENTIONS_CONFIG';

export const START_GET_CONVENTIONS_USER = 'START_CONVENTIONS_USER';
export const GET_CONVENTIONS_USER_SUCCESS = 'GET_CONVENTIONS_USER_SUCCESS';
export const GET_CONVENTIONS_USER_FAILED = 'GET_CONVENTIONS_USER_FAILED';

export const START_DYNAMIC_TABLE_COMPONENT = 'START_DYNAMIC_TABLE_COMPONENT';
export const UPDATE_DYNAMIC_TABLE_COMPONENT = 'UPDATE_DYNAMIC_TABLE_COMPONENT';
export const UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT = 'UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT';
export const UPDATE_BTN_DYNAMIC_TABLE_COMPONENT = 'UPDATE_BTN_DYNAMIC_TABLE_COMPONENT';
export const UPDATE_FILTER_TABLE_COMPONENT = "UPDATE_FILTER_TABLE_COMPONENT";
export const DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT = "DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT";

export const ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT = 'ACTIVE_BUTTON_DYNAMIC_MODAL_COMPONENT';
export const START_DYNAMIC_MODAL_COMPONENT = 'START_DYNAMIC_MODAL_COMPONENT';
export const FINALIZE_DYNAMIC_MODAL_COMPONENT = 'FINALIZE_DYNAMIC_MODAL_COMPONENT';
export const SEND_VECTOR_DYNAMIC_MODAL_COMPONENT = 'SEND_VECTOR_DYNAMIC_MODAL_COMPONENT';
export const EDIT_DYNAMIC_MODAL_COMPONENT = 'EDIT_DYNAMIC_MODAL_COMPONENT';

export const SET_EDIT_ALERT_TABLE_COMPONENT = 'SET_EDIT_ALERT_TABLE_COMPONENT';
export const SET_ADD_ALERT_TABLE_COMPONENT = 'SET_ADD_ALERT_TABLE_COMPONENT';
export const SET_EMPTY_ALERT_TABLE_COMPONENT = 'SET_EMPTY_ALERT_TABLE_COMPONENT';

export const GET_PRODUCTIVITY_PRODUCT = 'GET_PRODUCTIVITY_PRODUCT';
export const GET_PRODUCTIVITY_PRODUCT_FAIL = 'GET_PRODUCTIVITY_PRODUCT_FAIL';
export const GET_PRODUCTIVITY_PRODUCT_START = 'GET_PRODUCTIVITY_PRODUCT_START';
export const CHANGE_PRODUCTIVITY_PRODUCT = 'CHANGE_PRODUCTIVITY_PRODUCT';
export const APPLY_PRODUCTIVITY = 'APPLY_PRODUCTIVITY';
export const APPLY_PRODUCTIVITY_SUCCESS = 'APPLY_PRODUCTIVITY_SUCCESS';
export const APPLY_PRODUCTIVITY_FAIL = 'APPLY_PRODUCTIVITY_FAIL';
export const CLEAN_PRODUCTIVITY_PRODUCT = 'CLEAN_PRODUCTIVITY_PRODUCT';

export const SET_INDICATORS_PRODUCTIVITY = 'SET_INDICATORS_PRODUCTIVITY';
export const SET_INDICATORS_PRODUCTIVITY_SUCCESS = 'SET_INDICATORS_PRODUCTIVITY_SUCCESS';
export const SET_INDICATORS_PRODUCTIVITY_RESET = 'SET_INDICATORS_PRODUCTIVITY_RESET';
export const SET_INDICATORS_PRODUCTIVITY_ERROR = 'SET_INDICATORS_PRODUCTIVITY_ERROR';

export const CREATE_CASE_PREPOPULATE_REPORT = 'CREATE_CASE_PREPOPULATE_REPORT';
export const SEARCH_CASE_PREPOPULATE_REPORT = 'SEARCH_CASE_PREPOPULATE_REPORT';
export const DELETE_CASE_PREPOPULATE_REPORT = 'DELETE_CASE_PREPOPULATE_REPORT';

export const SUCCESS_SEARCH_REPORT = 'SUCCESS_SEARCH_REPORT';
export const ERROR_SEARCH_REPORT = 'ERROR_SEARCH_REPORT';

export const SUCCESS_CREATE_CASE_REPORT = 'SUCCESS_CREATE_CASE_REPORT';
export const ERROR_CREATE_CASE_REPORT = 'ERROR_CREATE_CASE_REPORT';

export const SUCCESS_DELETE_CASE_REPORT = 'SUCCESS_DELETE_CASE_REPORT';
export const ERROR_DELETE_CASE_REPORT = 'ERROR_DELETE_CASE_REPORT';

export const REDIRECT_BACK_LINK_INIT = 'REDIRECT_BACK_LINK_INIT';
export const REDIRECT_BACK_LINK = 'REDIRECT_BACK_LINK';

export const REQUEST_RESERVE_SUCESS = 'REQUEST_RESERVE_SUCESS';

export const QUERY_GUARANTEE = 'QUERY_GUARANTEE';
export const RESET_QUERY_GUARANTEE = 'RESET_QUERY_GUARANTEE';
export const RESERVE_GUARANTEE = 'RESERVE_GUARANTEE';
export const RESERVES_GUARANTEES_ALL = 'RESERVES_GUARANTEES_ALL';
export const RESERVES_GUARANTEES_STATUS = 'RESERVES_GUARANTEES_STATUS';

export const CHANGE_LOADING_VISIBILITY = 'CHANGE_LOADING_VISIBILITY';

export const UPDATE_ACTIVE_EVENT = 'UPDATE_ACTIVE_EVENT';
export const UPDATE_ACTIVE_EVENT_LEADER = 'UPDATE_ACTIVE_EVENT_LEADER';
export const SET_AREA_LIST = 'SET_AREA_LIST';
export const SET_PROCESS_LIST = 'SET_PROCESS_LIST';
export const SUCCESS_SAVE_TOAST = 'SUCCESS_SAVE_TOAST';
export const RESET_ACTIVE_EVENT = 'RESET_ACTIVE_EVENT';
export const RESET_ACTIVE_EVENT_NO_STORED = 'RESET_ACTIVE_EVENT_NO_STORED';

export const UPDATE_DAILY_MNGMNT_COUNTERS = 'GET_DAILY_MNGMNT_COUNTERS';

export const UPDATE_DAILY_CARDS = 'UPDATE_DAILY_CARDS';
export const RESET_ACTIVE_MESSAGE = 'RESET_ACTIVE_MESSAGE';
export const UPDATE_REPOBLATE ='UPDATE_REPOBLATE';
export const ACTIVE_EVENT_TEMPORAL ='ACTIVE_EVENT_TEMPORAL';
export const EDIT_CARDS ='EDIT_CARDS';
export const REPLOBLATE_TIMEINIT ='REPLOBLATE_TIMEINIT';
export const UPDATE_ACTIVE_EDIT ='UPDATE_ACTIVE_EDIT';
export const CHANGE_AREA = 'CHANGE_AREA';

export const UPDATE_BUTTON_PRODUCTIVITY = 'UPDATE_BUTTON_PRODUCTIVITY'
export const RESET_PLANT_FILE = 'RESET_PLANT_FILE';
export const NUMBER_RECORDS = 'NUMBER_RECORDS';
export const ENABLE_MANAGE_AREA= 'ENABLE_MANAGE_AREA';
export const UPDATE_ENABLE_BOARD_NEWS= 'UPDATE_ENABLE_BOARD_NEWS'; 
export const RESET_ACTIVE_MESSAGE_PART= 'RESET_ACTIVE_MESSAGE_PART'; 
export const EDIT_MODE= 'EDIT_MODE'; 
export const ACTIVE_EVENT_TEMPORAL_LEADER = 'ACTIVE_EVENT_TEMPORAL_LEADER';
export const GET_CASES_OPEN_SUCCESS_DOCUMENTS = 'GET_CASES_OPEN_SUCCESS_DOCUMENTS';
export const UPDATE_MANAGEMENT = 'UPDATE_MANAGEMENT';
//export const PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT';
export const DOWNLOAD_DOCUMENT_DISBURSE = 'DOWNLOAD_DOCUMENT_DISBURSE';
export const VIEW_LOGIN_CLOSE = 'VIEW_LOGIN_CLOSE';
export const GET_LIST_STAFF = 'GET_LIST_STAFF';
export const LINK_BACK_VIEW = 'LINK_BACK_VIEW';

export const STATUS_CASE = 'STATUS_CASE';
export const SET_TIME_CASE_LOAD = 'SET_TIME_CASE_LOAD';
export const GET_CASES_PAUSED_GPO = 'GET_CASES_PAUSED_GPO';
export const OPEN_CASE_PAUSED_GPO = 'OPEN_CASE_PAUSED_GPO';
export const SAVE_CASE_PAUSED = 'SAVE_CASE_PAUSED';

export const FINISH_REQUESTS = 'FINISH_REQUESTS';
export const UPDATE_ALERT_TOAST = 'UPDATE_ALERT_TOAST';

export const CHANGE_REQUESTED_PRODUCTS = 'CHANGE_REQUESTED_PRODUCTS';

export const SET_GROUP_MEMBERS = 'SET_GROUP_MEMBERS'
export const SET_GROUP_INFO = 'SET_GROUP_INFO'
export const UPDATE_NAME_MEMBER = 'UPDATE_NAME_MEMBER';
export const UPDATE_NAME_GROUP = 'UPDATE_NAME_GROUP';
export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const DELETE_MEMBER_TO_GROUP = 'DELETE_MEMBER_TO_GROUP';
export const DELETE_ALL_MEMBERS_TO_GROUP = 'DELETE_ALL_MEMBERS_TO_GROUP';

export const UPDATE_REDIRECT = 'UPDATE_REDIRECT';
export const SET_STATUS_STAFF = 'SET_STATUS_STAFF';
export const SET_DUPLICATE_STAFF = 'SET_DUPLICATE_STAFF';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const UPDATE_FORM_USER_STAFF = 'UPDATE_FORM_USER_STAFF';
export const RESET_FORM_USER_STAFF = 'RESET_FORM_USER_STAFF';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const POPULATE_USERS_STAFF = 'POPULATE_USERS_STAFF';
export const SET_ERROR_SEARCH = 'SET_ERROR_SEARCH';
export const REPOBLATE_NEWS = 'REPOBLATE_NEWS';
export const SET_VERTS_AND_MANGMTS = 'SET_VERTS_AND_MANGMTS';
export const SET_AREAS_STAFF_LIST = 'SET_AREAS_STAFF_LIST';
export const SET_PROCESSES_STAFF_LIST = 'SET_PROCESSES_STAFF_LIST';

export const SET_HISTORY_FILES = 'SET_HISTORY_FILES'

//ORDER TYPES
export const GET_DOCUMENT_TYPE = 'GET_DOCUMENT_TYPE';
export const GET_DOCUMENT_TYPE_SUCCESS = 'GET_DOCUMENT_TYPE_SUCCESS';
export const GET_DOCUMENT_TYPE_FAIL = 'GET_DOCUMENT_TYPE_FAIL';

export const POST_DOCUMENT_TYPE = 'POST_DOCUMENT_TYPE';
export const POST_DOCUMENT_TYPE_SUCCESS = 'POST_DOCUMENT_TYPE_SUCCESS';
export const POST_DOCUMENT_TYPE_FAIL = 'POST_DOCUMENT_TYPE_FAIL';

export const GET_DOCUMENT_REQUEST_NUMBER = 'GET_DOCUMENT_REQUEST_NUMBER';
export const GET_DOCUMENT_REQUEST_NUMBER_SUCESS = 'GET_DOCUMENT_REQUEST_NUMBER_SUCESS';
export const GET_DOCUMENT_REQUEST_NUMBER_FAIL = 'GET_DOCUMENT_REQUEST_NUMBER_FAIL';

export const SEND_DOCUMENTS_SUCCESS = 'SEND_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_CACHE = 'UPLOAD_DOCUMENTS_CACHE';

export const POST_ORDER_START = 'POST_ORDER_START';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL';

export const PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT';
export const RESET_DOCUMENT_PREVIEW = 'RESET_DOCUMENT_PREVIEW';
export const UPDATE_PREVIEW_PROGRESS = 'UPDATE_PREVIEW_PROGRESS';

export const RESET_MODAL = 'RESET_MODAL';
export const RESET_INFO = 'RESET_INFO';
export const SET_DELETE_MODAL = 'SET_DELETE_MODAL';
export const SET_INIT_FORM_VALUES = 'SET_INIT_FORM_VALUES';
export const SET_INIT_DOCUMENTS_TYPES = 'SET_INIT_DOCUMENTS_TYPES';

export const FINISH_LOADING = 'FINISH_LOADING';
export const START_LOADING = 'START_LOADING';
export const SHOW_MODAL_ERROR = 'SHOW_MODAL_ERROR';
export const HIDE_MODAL_ERROR = 'HIDE_MODAL_ERROR';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const ORDERS_DISBURSEMENT_SUCESS = 'ORDERS_DISBURSEMENT_SUCESS';

export const SET_KARDEX_PROCESSES = 'SET_KARDEX_PROCESSES';
export const UPDATE_ACTIVE_EVENT_KARDEX = 'UPDATE_ACTIVE_EVENT_KARDEX';
export const RESET_ACTIVE_MESSAGE_KARDEX = 'RESET_ACTIVE_MESSAGE_KARDEX';
export const SET_SELECT_ROWS_KARDEX = 'SET_SELECT_ROWS_KARDEX';
export const KARDEX_DATECHANGE = 'KARDEX_DATECHANGE';
export const SET_MANAGEMENT_LIST_LEADER = 'SET_MANAGEMENT_LIST_LEADER'
export const SET_MANAGEMENT_LIST_MANAGER = 'SET_MANAGEMENT_LIST_MANAGER'
export const SET_MANAGEMENT_LIST_KARDEX = 'SET_MANAGEMENT_LIST_KARDEX'
export const RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG = 'RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG'
export const DELETE_DATAFORM = 'DELETE_DATAFORM'
export const REMOVE_KEY_PAIR = 'REMOVE_KEY_PAIR'
export const SET_KEY_PAIR = 'SET_KEY_PAIR'
export const SET_GEOMETRIES = 'SET_GEOMETRIES'
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER'
export const SET_MAX_PAGES = 'SET_MAX_PAGES'
export const SET_IS_DRAWING = 'SET_IS_DRAWING'
export const SET_CURRENT_KEY_PAIR = 'SET_CURRENT_KEY_PAIR'
export const SET_ONBASE_TYPE = 'SET_ONBASE_TYPE'
