import * as React from 'react';
import { pdfjs } from 'react-pdf';
import '../../../assets/styles/ComponentsStyles/preview.scss'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/styles.css";
import Inline from "yet-another-react-lightbox/dist//plugins/inline";
import { useEffect } from 'react';
pdfjs.GlobalWorkerOptions.workerSrc =`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const disable = false;

const Preview = ( { documentInfo } ) => {
    
    const { src, file, stream } = documentInfo;
    const  name  = file;
    const [openImage, setOpenImage] = React.useState(false)
    const [openPDF, setOpenPDF] = React.useState(false)
    const [hideZoom, setHideZoom] = React.useState({in:false, out:true})
    const zoomRef = React.useRef(null)
    
    useEffect(() => {
        if (name.indexOf( '.pdf' ) === -1){
            setOpenImage(true)
            setOpenPDF(false)
        } else if (name.indexOf( '.pdf' ) > 0){
            setOpenImage(false)
            setOpenPDF(true)
        }

    }, [documentInfo]);

    const openDeleteModal = () => {
    };
    const getBlob = (base64) => {
        const byteString = atob(base64);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var file = window.URL.createObjectURL(new Blob([ab], { type: 'application/pdf' }));
        return file;
    }


    return <>
        <div className='preview-main-container'>
            {
                openPDF ?
                    <object 
                        data={getBlob(stream)}
                        type="application/pdf" 
                        width="1000"
                        height="750"
                    >

                    </object>                
                : 
                    <Lightbox
                        styles={{ container: { backgroundColor: "rgba(255, 255, 255, 1)" }}}
                        open={openImage} 
                        plugins={[Zoom, Inline]}
                        inline={{ style: { width: "1000px", aspectRatio: "3 / 2" } }}
                        zoom={{ maxZoomPixelRatio: 10}}
                        on={{
                            click: () => zoomRef.current?.zoomIn(),
                            zoom: (zoom) => {
                                setHideZoom(zoom.zoom > 9 ? 
                                                {in:true,out:false}
                                            : 
                                                zoom.zoom === 1 ? 
                                                    {in:false,out:true}
                                                :
                                                    {in:false,out:false}
                                )
                            }
                        }}
                        slides={[
                            { src: src} 
                        ]}
                        carousel={{ finite: true}}
                        render={{
                            buttonPrev: () => null,
                            buttonNext: () => null,
                            iconZoomIn: hideZoom.in? () => null : undefined,
                            iconZoomOut: hideZoom.out? () => null : undefined,
                        }}
                    />
            }  
            <div className='preview-footer'>
                {
                    disable &&
                        <div className='preview-delete-container'>
                            <i
                                className='preview-delete-icon'
                                onClick={ () => { openDeleteModal() } }
                            >
                                <span className='preview-delete-icon-label'>Eliminar</span> 
                            </i>
                        </div>
                }
            </div>
        </div>
    </>
}

export default Preview;
