import React, { useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { documentRecords, uploadPlantFile, resetPlantFile, numberRecords, downloadFileAction } from '../../bdb-actions/plantActions';
import Loading from '../LoadingComponent/Loading';
import './styles.scss';

const UploadFileStaff = ({titleProp ,warningProp, typeFileProp, sendTypeFileProp }) =>{
    
    const dispatch = useDispatch();
    const [ plantFileExtAllow ] = useState( '.xlsx' );
    const [ plantFileIsUploaded, setPlantFileIsUploaded ] = useState( false );
    const [ fileUploaded, setFileUploaded ] = useState( undefined );
    const hiddenInputFileUploadPlantFileRef = useRef( undefined );
    const loading = useSelector( state => state.prodContBoard.loading );
    const { hideUploadedFile, numberRecords, statusStaff, duplicatesStaff, loadingStatus } = useSelector( state => state.plant );

    useEffect( ()=> {
        if ( hideUploadedFile ) {
            setFileUploaded( false );
        }
    }, [ hideUploadedFile ] )

    useEffect(()=>{
        if(fileUploaded){
            dispatch(documentRecords(fileUploaded,sendTypeFileProp));
        }
    },[fileUploaded])

    const handleChangeFile = ( event ) => {
        const file = event.target.files[0];
        if ( file ) {
            setFileUploaded( file );
            setPlantFileIsUploaded( true );
        }
    };

    const handleUploadPlantFileButton = ( action ) => {
        dispatch( uploadPlantFile( fileUploaded, action , sendTypeFileProp) );

    };

    const handleReplaceInputUploadFile = () => {
        hiddenInputFileUploadPlantFileRef.current.click();
    };

    const downloadFile = () => {
        dispatch(downloadFileAction(typeFileProp));
    };

    return ( 
        <div className='InsertData_DNO font-OpenSans-Regular'>  
            {loading?<Loading></Loading>:null}
            <>
            <div className='InsertData_DNO-tittle '>{titleProp}</div>
            <div className='InsertData_DNO-sub-tittle'>
                <div className='insert-data-dno-text-icon'></div>
                {warningProp}
            </div>
            </>

            {
                plantFileIsUploaded && numberRecords ? (
                    <div className='InsertData_DNO-Container'>
                        <div className='info_data'>
                            <div className="fileUploaded">
                                <div className='insert-data-dno-document-xlxs-icon'></div>
                            </div>
                            <div className='info_data_container font-OpenSans-Regular'>
                            <div className='documentUploaded font-OpenSans-Regular'>{ fileUploaded ? fileUploaded.name : '' }</div>
                            <div className="data_InfoFileUploaded font-OpenSans-Regular">
                                <p className='data_InfoFileUploaded_container font-OpenSans-Regular'>Contiene   { numberRecords ? ` ${numberRecords} registros` : ' No tiene registros' }</p>
                            </div>
                            <div className='_manage-files-table'>
                                {
                                    loadingStatus &&
                                    <div className='container-spinner'>
                                        <span className='loading-spinner'>Estamos procesando la información</span>
                                        <div className="spinner-border text-primary box-spinner" role="status"></div>
                                    </div>
                                }
                                {   
                                    statusStaff.map( (s, idx) => (
                                        <div key={idx} className='_manage-files-row-table'>
                                            <div className='_manage-files-col-1-table'>{s.label}</div>
                                            <div className='_manage-files-col-2-table'>{s.count}</div>

                                        </div>
                                        ))
                                }
                                {
                                duplicatesStaff.length > 0 ? 
                                (
                                    <div className='container_Warning'>
                                        <div className='container_text'>
                                            <p className='container_message'>
                                                Los siguientes códigos de posición están en uso y no se actualizarán en el archivo:
                                            </p>
                                        </div>
                                        <div className='container_duplicate'>
                                            <ul>
                                                {duplicatesStaff.map((numero, index) => (
                                                <li key={index} className='texto_list' >{numero["position"]} / {numero["vertical"]}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) 
                                : null }
                               
                            </div>
                            
                            </div>
                        </div>
                        <div className="Line"></div>
                        <div className='InsertData_DNO-Container-buttons'>
                            <button className='uploade-button' onClick={(e) => handleUploadPlantFileButton('upload')} disabled={loadingStatus}>
                                <div className='imgButton'></div>
                                <div className='texButton'>Subir</div>
                            </button>
                            <button  className='cancel-button font-OpenSans-Regular' disabled={loadingStatus} onClick={ (e) => handleUploadPlantFileButton('cancel') }>Cancelar</button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='InsertData_DNO-sub-box'>
                            <input className='input_data_plant'
                                type="file"
                                ref={ hiddenInputFileUploadPlantFileRef }
                                name="file"
                                accept={ plantFileExtAllow }
                                onChange={ ( e ) => handleChangeFile( e ) }
                                onClick={(event)=> {event.target.value = null}}
                            />
                            <div className="Planta_DNO font-OpenSans-Regular">
                                <div className='insert-data-dno-document-icon font-OpenSans-Regular'></div>
                                {typeFileProp}
                            </div>
                            <div className="button-upload " onClick= { handleReplaceInputUploadFile } >
                                <div className='insert-data-dno-button-icon '></div>
                                Seleccionar archivo
                            </div>
                        </div>
                        <div className='download-button-file' onClick= { downloadFile }>
                            <div className='download-data-dno-button '>
                                Descargar archivo actual &nbsp;
                                <div className='download-data-dno-button-icon '></div>
                            </div>
                        </div>
                    </>

                )
            }
        </div>
    );
}
export default UploadFileStaff;