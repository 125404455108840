import React ,{useState, useEffect} from 'react';
import './DynamicModalComponent.css';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { startDynamicModal, activeBtnDynamicModal, sendVectorDynamicModal, finalizeDynamicModal} from '../../bdb-actions/dynamicModalActions';
import SimpleSelectComponent from '../SimpleSelectComponent/SimpleSelectComponent';
import { setOptionSelectedSimplyComponent } from '../../bdb-actions/simplySelectActions';
import { getUserInfoIDM } from '../../bdb-services/GeneralService/staffService';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { updateLoadingAnalist} from '../../bdb-actions/analystActions';
import { id } from 'date-fns/locale';
import { secondsToMilliseconds } from 'date-fns';

export const DIV = 'div';
export const INPUT = 'input';
export const TEXTAREA = 'textarea';
export const SELECT = 'select';

const DynamicModalComponent = (dataModalComponent) => {
    
    const dispatch = useDispatch();
    let { modalStructure, vectorModal, selecBotton } = dataModalComponent;
    const disable = useSelector(state => state.dynamicModal.disable);
    const edit = useSelector(state => state.dynamicModal.edit);
    const option = useSelector (state => state.simplySelect.option );
    const [selected, setSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const [controlAlert, setControlAlert] = useState(false);
    const [modalStruc, setModalStruc] = useState(modalStructure['structure']);
    const [lisUserFound, setLisUserFound] =useState({firstUser:true, secondUser:true,thirdUser:true, fourthdUser: true});

    useEffect(() => {
        if(!!modalStructure['structure']){
            setDisabledButton(disable);
        }else{
            setDisabledButton(false);
        }
      }, [disable]);

      useEffect(() => {
        setEditVector(edit);
      }, [edit]);
    
    useEffect(() => {
        const getSelect = () => {
            if (!!option) {
                myChangeHandler(option['id'], selected);
                myChangeHandler(option['code'], 'code');
            }
        }
        getSelect();
    }, [option]);

    const [vector, setVector] = useState(vectorModal); 
    const [disabledButton, setDisabledButton] = useState(disable);
    const [editVector, setEditVector] = useState(edit);
    const [showModal, setShowModal] = useState(true);
    
    const keysStructure = () => {
        let modalStructureObject = Object.keys(modalStructure['structure']);
        return modalStructureObject;
    }
    
    const aditionalMarginTop = (index, length) => {
        return length > 1 && index !== 0 ? {marginTop: '2%'} : null;
    }

    const myChangeHandler = (event, field) => {
        if(!field)return
        let newVector = vector;
        let skipUserValidation =['name', 'code','pausedReason','rejectReason'];
        newVector[field] = event;
        setVector(newVector);
        setEditVector(null);
        if(!skipUserValidation.includes(field) && selecBotton !== 'VoBoCentralized'){
            dispatch(validationUser(event, field));
        }
        else{
            validateButton(modalStructure['structure']['row2']);
        }
    }

    const validationUser = (event, nameStructrure) =>{
        return async (dispatch) => {
            let dataWarning;
            if (event.length === 0){
                dataWarning = modalStructure['structure']['row2']
                dataWarning.forEach(item =>{
                    if(item['id'] === nameStructrure){
                        setLisUserFound({...lisUserFound ,[nameStructrure]:true});
                       item['alert']=null;
                        item['title_alert_active']=null;
                        item['title_alert']= null ;   
                    }
               });
               setModalStruc({'row1':modalStructure['structure']['row1'],'row2':dataWarning})
               validateButton(dataWarning)
               return
            }
            else if (event.length < 5){
                setControlAlert(true)
                setLoading(false);
                dataWarning = modalStructure['structure']['row2']
                dataWarning.forEach(item =>{
                     if(item['id'] === nameStructrure){
                        setLisUserFound({...lisUserFound ,[nameStructrure]:false});
                        item['alert']='alert_input';
                         item['title_alert_active']=null;
				         item['title_alert']= null ;   
                     }else{
                        setDisabledButton(disable);
                     }
                });
                setModalStruc({'row1':modalStructure['structure']['row1'],'row2':dataWarning})
                validateButton(dataWarning)
                return              
            }
            dataWarning = undefined;
            setLoading(true);
            await getUserInfoIDM(event)
            .then(response =>{
                setLoading(false);
                if(response.status === 200){
                    dataWarning = modalStructure['structure']['row2']
                    dataWarning.forEach(item =>{
                        if(item['id'] === nameStructrure){
                            setLisUserFound({...lisUserFound ,[nameStructrure]:true});
                            item['alert']=null;
                            item['title_alert_active']=null;
                            item['title_alert']= null ;   
                        }
                   });
                }
                setModalStruc({'row1':modalStructure['structure']['row1'],'row2':dataWarning})
                validateButton(dataWarning)
            })
            .catch((erro)=>{
                setControlAlert(true)
                setLoading(false);
                dataWarning = modalStructure['structure']['row2']
                dataWarning.forEach(item =>{
                     if(item['id'] === nameStructrure){
                        setLisUserFound({...lisUserFound ,[nameStructrure]:false});
                        item['alert']='alert_input';
                        item['title_alert_active']='Usuario-de-red-incor';
				        item['title_alert']= 'Usuario de red incorrecto' ;   
                     }else{
                        setDisabledButton(disable);
                     }
                });
                setModalStruc({'row1':modalStructure['structure']['row1'],'row2':dataWarning})
                validateButton(dataWarning)
            });
            
        }
    }

    const validateButton = (_structure) => {
        let userValidation = true;
        if(_structure){
            _structure.map(item=>{
                if(item["alert"]){
                    userValidation = false
                    return
                }
            })
        }
        let keysVector = Object.keys(vector);
        let arrayResponse = [];
        keysVector.forEach(key => {
            if(!["secondUser", "thirdUser", "fourthUser"].includes(key))
                arrayResponse = [...arrayResponse, vector[key] ];
        });
        const invalid = arrayResponse.filter(response => response.length < 1 );
        if (invalid.length > 0 || !userValidation) {
            dispatch(activeBtnDynamicModal(true));
        } else {
            dispatch(activeBtnDynamicModal(false));
        }
    }

    const sendVector = () => {
        setModalStruc(modalStructure['structure'])
        dispatch(sendVectorDynamicModal(vector));
        setVector(null);
    }

    const getValueDefault = (key, placeholder) => { 
        return !!edit ? edit[key] : placeholder;
    }

    const cancelAction = () => {
        setModalStruc(modalStructure['structure'])
        dispatch(finalizeDynamicModal(false)); 
        dispatch(startDynamicModal(false));
        dispatch(setOptionSelectedSimplyComponent(null))
        setVector(null);
    }
        return (
            <Modal show={showModal} centered onHide={()=>{}} dialogClassName={modalStructure.dialogClassName || ''}>
                { loading ? <Loading /> : null}
                <Modal.Header>
                    <div className='w-100'>
                        { modalStructure['iconInfo'] ? <div className="icon-info-dynamic-modal"></div> : null }
                        <div className="title-dynamic-modal">{modalStructure['title']}</div>
                        <div className="subtitle-dynamic-modal">{modalStructure['subtitle']}</div>
                        { !!modalStructure['infoLabel'] ? 
                            <div className="info-label-dynamic-modal">
                            {modalStructure['infoLabel'][0]}<br />{modalStructure['infoLabel'][1] ? modalStructure['infoLabel'][1] : null }</div>
                            : null
                        }
                        
                    </div>
                </Modal.Header>
                {!!modalStructure['structure'] &&
                    <Modal.Body>
                        <form>
                            { 
                                keysStructure().map((key, indexKey) => (
                                    <div key={indexKey} className={modalStructure['structure'][key].length > 1 ?
                                        'input-container-user' : modalStructure['smallModal'] ? 'd-flex small-dynamic-modal' : 'd-flex'} 
                                        style={aditionalMarginTop(indexKey, key.length)}>
                                        {
                                            modalStructure['structure'][key].map((row, index) => (
                                                <div key={index} className={modalStructure['structure'][key].length > 1 ? 'small-width-dynamic-modal' : 'w-100'}>
                                                    { row['type'] === DIV ?
                                                        <div>
                                                            <div className="span-dynamic-modal">{row['span']}</div>
                                                            <div id={row['id']}>
                                                            </div> 
                                                        </div> : null
                                                    }
                                                    { row['type'] === SELECT ?
                                                        <div className="modal-dynamic-select-padding"
                                                        onClick={() => setSelected(row['name'])}>
                                                            <SimpleSelectComponent
                                                                absolute={true}
                                                                width={'94%'}
                                                                small={modalStructure['smallModal']} 
                                                                options={row['options']} 
                                                                initSelect={row['value']}
                                                                autocomplete={row['autocomplete']}
                                                                placeholder={row['placeholder']}
                                                                arrow={modalStructure['arrowStyle']}
                                                                />
                                                        </div> : null
                                                    }
                                                    { row['type'] === INPUT ?
                                                        <div>
                                                            <div 
                                                                className="span-dynamic-modal">{row['span']}</div>
                                                            <input
                                                                id={row['id']}
                                                                name="codConvent"
                                                                placeholder={getValueDefault(row['name'],row['placeholder'])}
                                                                className={  (row['alert'] === 'alert_input')? row['alert'] :row['styleClass']}
                                                                onChange={e => { myChangeHandler(e.target.value, row['name'],); }}
                                                                value={undefined}
                                                                required>
                                                            </input> 
                                                            {
                                                                controlAlert   ?
                                                                <div className={'Usuario-de-red-incor'}>{row['title_alert']}</div> : null
                                                            }
                                                        </div> : null
                                                    }
                                                    { row['type'] === TEXTAREA ?
                                                        <div>
                                                            <div 
                                                                className="span-dynamic-modal">{row['span']}</div>
                                                            <textarea
                                                                data-cy="dynamicModal-textarea"
                                                                id={row['id']}
                                                                rows="8"
                                                                cols="50"
                                                                name="codConvent"
                                                                style={{width:'85%', resize: 'none'}}
                                                                placeholder={getValueDefault(row['name'],row['placeholder'])}
                                                                className={ 'mx-auto '+ ((row['alert'] === 'alert_input')? row['alert'] :row['styleClass'])}
                                                                onChange={e => { myChangeHandler(e.target.value, row['name'],); }}
                                                                value={undefined}
                                                                maxLength="2000"
                                                                required /> 
                                                            {
                                                                controlAlert   ?
                                                                <div className={'Usuario-de-red-incor'}>{row['title_alert']}</div> : null
                                                            }
                                                        </div> : null
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    ))
                                }
                            </form>
                    </Modal.Body>
                }
                <Modal.Footer>
                    {  modalStructure['buttonCancel'] ?
                        <button
                        data-cy="dynamicModal-cancel"
                        className={(modalStructure.buttonsClass || 'button-dynamic-modal') } type="button"
                        style={!!modalStructure['buttonCancelColor'] ? {backgroundColor: modalStructure['buttonCancelColor']} : null}
                        onClick={() => cancelAction()}>{!!modalStructure['buttonCancelText'] ? modalStructure['buttonCancelText'] : 'Cancelar'}</button> : null
                    }
                    { modalStructure['buttonOk'] ?
                        <button
                        data-cy="dynamicModal-action"
                        className={(modalStructure.buttonsClass || 'button-dynamic-modal')} type="submit" onClick={() => sendVector()} 
                        style={!!modalStructure['buttonOkColor'] ? {backgroundColor: modalStructure['buttonOkColor']} : null}
                        disabled={disabledButton}>{!!modalStructure['buttonOkText'] ? modalStructure['buttonOkText'] : 'Agregar'}</button> : null
                    }
                </Modal.Footer>
            </Modal>
        );
}
 
export default DynamicModalComponent;