import { useState } from 'react'

export const useForm = ( initialState = {} ) => {
    const [ formValues, setFormValues ] = useState(initialState);
    const handleInputChange = ( {target}, validationNumbers ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : validationNumbers ? target.value.replace(/[^\d]/g, '') : target.value
        })
    }
    return [ formValues, handleInputChange ]
}
