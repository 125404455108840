import moment  from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductivityCard, identificationCard, updateCards, updateRepoblate, getDailyMngmnt, resetActveEvent, reploblate, enableManageArea, updateActiveEdit,saveChangeArea } from '../../bdb-actions/productivityControlBoardActions';
import { deleteNewCard, leaderTemporal, reploblateNews, editModeLeader, repoblateNews } from '../../bdb-actions/leaderProductivityCtrlBrdActions';
import { getdecrypt } from "../../bdb-services/GeneralService/globalService";
import { formattedNames } from '../../bdb-helpers/utils';
import { excludeDateEndNews, HAS_NO_RECORD, leaderNewsListControlBoard, newsByHours, newsByHoursWithoutMassives, roleCards } from '../../../constants';
import './ProductivitySumarize.scss';
import { ConsoleView } from 'react-device-detect';


const ProductivityCard = ({ card, role }) => {
    const dispatch = useDispatch();
    const [enableButtons, setEnableButtons] = useState(null)
    const seletlistaCard = useSelector(state =>state.prodContBoard.prodCards)
    const activeEvent = useSelector(state =>state.leaderProdBoard.activeEvent)
    const {aprovedCount, returnedCount , userLeader, partitions, } = card;

    useEffect(()=>{
        if(userLeader === getdecrypt('userLogin')){
            setEnableButtons('')
            return
        }else{
            setEnableButtons(' disabled-background-buttons')
        }
    
        if(!!partitions && (aprovedCount === 0 && returnedCount === 0) && !userLeader && card.processType === "Productivo"){
            setEnableButtons(' disabled-background-buttons')      
        }else if(card.processType !== "new"){
            setEnableButtons('')                    
        }
    })

    function editCardEvent(){
        let cardTempo = {
            ...card,
        } 
        dispatch(saveChangeArea(cardTempo.area))
        if(!card.enabled) return
        if( card.processType != 'new' ){
            cardTempo['beginDate']= !!partitions ? partitions[0].beginDate : card.beginDate ;
            editCardEventProductivity(cardTempo); 
            return   
        }
        editCardEventNews(card);
    }

    function editCardEventNews(card){
        if(card.userLeader === getdecrypt('userLogin')){
            let key={ 
                number: card.documentNumber, 
                beginDate: card.beginDate,
                endDate: card.endDate,
                caseSmax: !!card.caseSmax ? card.caseSmax : null,
                observations: card.observations
            }
            dispatch(updateActiveEdit(true))
            dispatch(reploblateNews(card))
            dispatch(updateCards(seletlistaCard.map(item => {
                if (card.id !== item.id) {
                    return { ...item, enabled: false }
                }
                else {
                    return { ...item }
                }
            })));
            dispatch(identificationCard(seletlistaCard));
            dispatch(editModeLeader(key));
            dispatch(repoblateNews(true));

        }
     }
    
    function editCardEventProductivity(data){
            dispatch(enableManageArea(data.conflict));
            dispatch(updateActiveEdit(true))
            dispatch(reploblate(data))
            dispatch(updateCards(seletlistaCard.map( item =>{
                if(data.id!==item.id){
                    return {...item, enabled:false}
                }
                else{
                    return{...item}
                }                
            })));
        dispatch(identificationCard(seletlistaCard))
    }

    function deleteCardEvent() {
        if (!card.enabled) return
        if (card.processType != 'new') { deleteCardEventProductivity(card); return }
        deleteCardEventNews(card)
    }

    function deleteCardEventNews(card) {
        if (card.userLeader === getdecrypt('userLogin')) {
            dispatch(updateRepoblate(true))
            dispatch(deleteNewCard(card));
        }
        dispatch(updateRepoblate(false))
    }

    function deleteCardEventProductivity(card){
        let deleteCard='deleteCard'
        let cardTempo = {
            'id':card.id,
            'processType':card.processType,
            'partitions': card.partitions,
            'isAdministrative':Boolean(sessionStorage.getItem('isAdministrative') === 'true')
        }
        dispatch(deleteProductivityCard(cardTempo, deleteCard))
        dispatch(resetActveEvent());       
    }
    const isNew = () => {
        return card.processType === 'new';
    }
    const formatDates = () => {
        return isNewByHours() ? "HH:mm" : "DD/MM/YY";
    }
    const isNewByHours = () => {
        return !!newsByHours.find(e => e.id === card.new);
    }
    const isNewByHoursWithoutMassives = () => {
        if(!!card.isMassiveNew) return false
        return !!newsByHoursWithoutMassives.find(e => e.id === card.new);
    }
    const editLeave = () => {
        if(role === 'massive'){
            return false
        }
        return role === 'leader'? isNewByHoursWithoutMassives() :!card.isLeaderReported;
    }

    const beginDate = !isNew() ? (card.beginDate ? card.beginDate.split(":") : '0') : (card.beginDate ? moment(card.beginDate).format(formatDates()) : '0');
    const endDate = !isNew() ? ( card.endDate ? card.endDate.split(":"):'0' ): (card.endDate ? moment(card.endDate).format(formatDates()) : '0');
    const totalDays = (moment(card?.endDate).diff(moment(card.beginDate), 'days')) + 1;
    const mins = card.hours ? Math.round((card.hours % 1) * 60) : 0;
    const totalDate = !isNew() || isNewByHours() ? (`${Math.trunc(card.hours) !== 0 ? Math.trunc(card.hours) + 'h' : ''}${mins !== 0 ? mins + 'm' : '1m'}`) : totalDays + 'd';
    const conflictBeginHour = `${card.conflict && card.conflict.includes('beginDate') ? [beginDate[0], beginDate[1]].join(':') : [beginDate[0], beginDate[1]].join(':')}`;
    const conflictEndHour = `${card.conflict && card.conflict.includes('endDate') ? [endDate[0], endDate[1]].join(':') : [endDate[0], endDate[1]].join(':')}`;
    const formattedDays = excludeDateEndNews.includes(card.new) ? `${beginDate}` : `${beginDate} - ${endDate} | ${totalDate}`;

    const cardProcess = card.new ? card.new : (card.new ? card.new : 'NULL');
    return (
        <Fragment>
            {!card.typeTime ?
                <Fragment>
                    <div className={'prd-sumze-card flex-column font-OpenSans-Bold font-14 ' + (!card.enabled ? 'disabled-background' : '')}>
                        <div className='flex-row-title'>
                            <div>{formattedNames(cardProcess, 35)}</div>
                            {card.processType === "No Productivo" ? <div className={'prd-cardAd prd-novedad font-OpenSans-Regular ' + (!card.enabled ? 'disabled-novedad' : '')}>Ausentismo</div> : null}
                            {card.isAutomatic || card.processType == 'new' ? <div className={'prd-cardAd font-OpenSans-Regular ' + (!card.enabled ? 'disabled-novedad' : '') + (isNewByHours() ? editLeave() ? 'prd-automatic' : 'prd-massive-new' : 'prd-automatic')}>{!card.isAutomatic ? (card.isAutomatic ? '' : card.userLeader) : 'Automático'}</div> : null}
                            {card.conflict ? <div className={'prd-cardAd prd-conflict font-OpenSans-Regular ' + (!card.enabled ? 'disabled-novedad' : '')}>Conflicto</div> : null}
                        </div>
                        <div className='flex-row font-OpenSans-Regular'>
                            <div>
                                <div className='container-hours'>
                                    {!isNew() && role != 'massive' ?
                                        <>
                                            <div className={!(card.conflict && card.conflict.includes('beginDate')) ? 'cart-Hours' : 'cart-conflict-Hours'}> {conflictBeginHour}</div>
                                            <div>-</div>
                                            <div className={!(card.conflict && card.conflict.includes('endDate')) ? 'cart-Hours' : 'cart-conflict-Hours'}> {conflictEndHour}</div>
                                        </>
                                        :
                                        role == 'massive' ?
                                            <>
                                                <div className='cart-Hours'>
                                                    {moment(card.beginDate).format("DD/MM/YY HH:mm")}
                                                </div>
                                                <div> - </div>
                                                <div className='cart-Hours'>
                                                    {moment(card.endDate).format("HH:mm")}
                                                </div>
                                            </>
                                            :
                                            <>
                                                {formattedDays}
                                            </>
                                    }
                                    <div> {!isNew() ? `|${totalDate}` : ''}</div>
                                </div>
                            </div>
                            { !card.isAutomatic && (!isNewByHours() || editLeave())?
                            <div className='flex-row'>
                                <div className={'edit-card-btn card-btn' + (enableButtons)} onClick={(enableButtons === '' )? editCardEvent: null}></div>
                                <div className= {'delete-card-btn card-btn'  + (enableButtons)} onClick={(enableButtons === '' )? deleteCardEvent : null}></div>
                            </div>:null
                            }
                        </div>
                    </div>
                </Fragment>
                : <Fragment>
                    <div className={card.enabled ? 'disabled-novedad' : (card.typeTime === HAS_NO_RECORD ? 'Time-without-registration' : '')}>
                        <div className={card.enabled ? 'disabled-novedad' : (card.typeTime === HAS_NO_RECORD ? 'circle' : '')}>
                            {card.enabled ? '' : (card.typeTime === HAS_NO_RECORD ? 'i' : '')}
                           <span>{card.enabled ? '' : (card.typeTime === HAS_NO_RECORD ? 'Tiempo sin registrar' : '')}</span> 
                         </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

export default ProductivityCard;
