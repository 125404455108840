import {
    CREATE_CASE_PREPOPULATE_REPORT,
    SEARCH_CASE_PREPOPULATE_REPORT,
    DELETE_CASE_PREPOPULATE_REPORT,
    SUCCESS_SEARCH_REPORT,
    ERROR_SEARCH_REPORT,
    SUCCESS_CREATE_CASE_REPORT,
    ERROR_CREATE_CASE_REPORT,
    SUCCESS_DELETE_CASE_REPORT,
    ERROR_DELETE_CASE_REPORT,
    SET_INDICATORS_PRODUCTIVITY,
    SET_INDICATORS_PRODUCTIVITY_SUCCESS,
    SET_INDICATORS_PRODUCTIVITY_ERROR,
    SET_INDICATORS_PRODUCTIVITY_RESET,
    STATUS_CASE,
    SAVE_CASE_PAUSED,
    FINISH_REQUESTS,
    UPDATE_ALERT_TOAST,
} from '../bdb-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import uuid from "uuid";
import * as CryptoJS from "crypto-js";
import interceptor from '../../interceptor';
import { getCtrlBrdBody } from './analystActions';
import { STATE_ACCEPTED, STATE_PAUSED, STATUS_RESERVE_APPROVED, STATUS_RESERVE_REJECTED } from '../../constants';
import { postCtrlBrd } from '../bdb-services/GeneralService/controlboardService';
function decryptUsingAES256(value, iv, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
}

function showModalError(message) {
    Swal.fire({
        icon: 'error',
        title: '¡Lo sentimos!',
        text: message,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
    });
}

export function getCaseReportAction(numberCase) {
    return async (dispatch) => {
        dispatch( getCaseReport(`Consultando caso ${numberCase}`) );
        const path = 'requests/get-productivity-by-request';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        
        try {
            await axios.get(`${ process.env.REACT_APP_SELF_API_ENDPOINT }${path}/${userLoginDecrypt}/${numberCase}`) 
            .then(function (response) {
                const dataGPO = !!response && response.data.Items[0] ? response.data.Items[0] : '';
                let newDataGpo ={
                    Items:[
                        {   beingUserDate: dataGPO['beingUserDate'],
                            fgnReserve: dataGPO['fgnReserve'],
                            process: dataGPO['process'],
                            product: dataGPO['product'],
                            requestDate: dataGPO['requestDate'],
                            requestNumber: dataGPO['requestNumber'],
                            requestUser: dataGPO['requestUser'],
                            statusCase: null,
                            subProcess: dataGPO['subProcess'],
                        }
                    ]
                }
                dispatch( setCaseReportAction(newDataGpo.Items[0]))
            });
        } catch (error) {
            console.log('Report case error', error);
            dispatch( getCaseReportError(false) )
        }

    }
}

const getCaseReport = option => ({
    type: SEARCH_CASE_PREPOPULATE_REPORT,
    payload: option
});

export const getCaseReportSuccess = option => ({
    type: SUCCESS_SEARCH_REPORT,
    payload: option
});

const getCaseReportError = option => ({
    type: ERROR_SEARCH_REPORT,
    payload: option
});

export const saveCasePausedGpo = option => ({
    type: SAVE_CASE_PAUSED,
    payload: option
});

export function setCaseReportAction(caseDto) {

    return async (dispatch) => {
        dispatch( setCaseReport(`Caso ${caseDto['requestNumber']}`) );
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'requests/create-productivity ';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
        let body = {
            id: !!caseDto['id'] ? caseDto['id'] : uuid(),
            requestNumber: caseDto['requestNumber'],
            requestDate: caseDto['requestDate'],
            beingUserDate: caseDto['beingUserDate'],
            requestUser: userLoginDecrypt,
            product: caseDto['product'],
            process: caseDto['process'],
            subProcess: caseDto['subProcess'],
            fgnReserve: false,
            statusCase:caseDto['statusCase']
        }
        body['idProductivity'] = !!caseDto['idProductivity'] ? caseDto['idProductivity'] : body['id'];
        try {
            await axios.post(path, body) .then(function (response) {
                    dispatch( setCaseReportSuccess(body) )  
                    
            });

        } catch (error) {
            console.error('Case reject error', error);
            showModalError('No sé pudo completar la acción "Enviar Caso"');
            dispatch( setCasesReportError(null) )
        }
    
    }
}

const setCaseReport = option => ({
    type: CREATE_CASE_PREPOPULATE_REPORT,
    payload: option
});

const setCaseReportSuccess = option => ({
    type: SUCCESS_CREATE_CASE_REPORT,
    payload: option
});

const setCasesReportError = option => ({
    type: ERROR_CREATE_CASE_REPORT,
    payload: option
});


export function deleteCaseReportAction(numberCase) {
    return async (dispatch) => {
        dispatch( deleteCaseReport(null) );
        const path = 'requests/delete-productivity';
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        
        try {
            await axios.delete(`${ process.env.REACT_APP_SELF_API_ENDPOINT }${path}/${userLoginDecrypt}/${numberCase}`) .then(function (response) {

                dispatch( deleteCaseReportSuccess(null) ); 
                 
            });
        } catch (error) {
            console.log('Case reject error', error);
            showModalError('No sé pudo completar la acción "Eliminar Caso"');
            dispatch( deleteCasesReportError(false) )
        }

    }
}

const deleteCaseReport = option => ({
    type: DELETE_CASE_PREPOPULATE_REPORT,
    payload: option
});

export const deleteCaseReportSuccess = option => ({
    type: SUCCESS_DELETE_CASE_REPORT,
    payload: option
});

const deleteCasesReportError = option => ({
    type: ERROR_DELETE_CASE_REPORT,
    payload: option
});

export function setIndicatorsAction (dtoIndicators,key) {
    
    return async (dispatch, getState) => {
        dispatch( setIndicator('Cargando') );
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        const userLoginDecrypt = decryptUsingAES256(sessionStorage.getItem('userLogin'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY))
        
        const path = process.env.REACT_APP_SELF_API_ENDPOINT + "disbursement-cloud-indicators";

        const body = {
            caso: dtoIndicators['requestNumber'],
            finiciocaso: dtoIndicators['starCase'],
            finiciogestor: dtoIndicators['starCaseAdviser'],
            ffinalgestor: global.getDateFormater(),
            estado: dtoIndicators['state'],
            producto: dtoIndicators['product'],
            proceso: dtoIndicators['process'],
            subproceso: dtoIndicators['subprocess'],
            usuario: userLoginDecrypt   
        }
        const mainInfo = { 
            process: dtoIndicators.process,
            beginDate: dtoIndicators['starCaseAdviser'],
            requestNumber: dtoIndicators['requestNumber'],
            idProductivity: dtoIndicators['idProductivity']
         };
         const moreInfo = {
            cc : b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')),
            userLogin: userLoginDecrypt,
            management: 'gpo',
            requestDate: dtoIndicators['starCase']
        };
        let status;
        switch (dtoIndicators['state']) {
            case STATE_ACCEPTED:
                status = STATUS_RESERVE_APPROVED;
                break;
            case STATE_PAUSED:
                status = false;
                break
            default:
                status = STATUS_RESERVE_REJECTED;
                break;
        };

        try {
            
            await axios.post(path, body)
                .then(function (response) {
                    const result = {
                        message: `El caso fué ${body['estado'].toLowerCase()} exitosamente`,
                        key: dtoIndicators['requestNumber']
                    }
                    dispatch(setIndicatorSuccess(result));
                    if(dtoIndicators['state'] === 'Pausado'){
                        dispatch(saveCasePausedGpo('Pausado'));
                    } else {
                        dispatch( deleteCaseReportAction(dtoIndicators['requestNumber']))

                    }
                    
                    dispatch( setCaseReport(`Caso ${dtoIndicators['requestNumber']}`) );
                }).then(()=>
                getCtrlBrdBody( mainInfo, moreInfo, status, dtoIndicators['id']).then( response =>
                    {
                        dispatch(setIndicator('Cargando'))
                        postCtrlBrd( response ).then(()=>{
                            dispatch(setFinishRequests(true));
                            dispatch( setIndicator(false));
                            dispatch(resetIndicator(null))
                            dispatch(deleteCaseReportSuccess(null))
                            
                        }).catch( err => {
                            showModalError(`Se presentó un error al reportar el caso en Gestión Diaria`);
                            dispatch( setIndicatorError(false) )
                            dispatch(resetIndicator(null))
                            dispatch(deleteCaseReportSuccess(null))
                        });
                    })
                )
                } catch (error) {
                    console.log('Case reject error', error);
                    showModalError(`No sé pudo actualizar el estado: ${body['estado']}`);
                    dispatch( setIndicatorError(false) )
                }

    }
}

export const setFinishRequests= option => ({
    type: FINISH_REQUESTS,
    payload: option
});
export const setIndicator = option => ({
    type: SET_INDICATORS_PRODUCTIVITY,
    payload: option
});
const setIndicatorSuccess = option => ({
    type: SET_INDICATORS_PRODUCTIVITY_SUCCESS,
    payload: option
});
const setIndicatorError = option => ({
    type: SET_INDICATORS_PRODUCTIVITY_ERROR,
    payload: option
});

export function updateDisabledAction () {
    return async (dispatch) => {
        dispatch( resetIndicator(null) );
    }
}

export const saveSatusCase = option => ({
    type: STATUS_CASE,
    payload: option
});

export const resetIndicator = option => ({
    type: SET_INDICATORS_PRODUCTIVITY_RESET,
    payload: option
});
export const updateAlertToast = option => ({
    type: UPDATE_ALERT_TOAST,
    payload: option
});


