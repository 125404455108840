import { 
    START_DYNAMIC_TABLE_COMPONENT,
    UPDATE_DYNAMIC_TABLE_COMPONENT,
    UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT,
    UPDATE_BTN_DYNAMIC_TABLE_COMPONENT,
    UPDATE_FILTER_TABLE_COMPONENT,
    SET_EDIT_ALERT_TABLE_COMPONENT,
    SET_ADD_ALERT_TABLE_COMPONENT,
    SET_EMPTY_ALERT_TABLE_COMPONENT,
    DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT
} from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    dataSet: null,
    indexSelected: [],
    message: null,
    activeButton: false,
    filter: null,
    alertAdd: false,
    alertEdit: false,
    alertEmpty: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case START_DYNAMIC_TABLE_COMPONENT:
            return {
                ...state,
                dataSet: action.payload,
                message: null,
                activeButton: false,
                filter: null,
                indexSelected: []
            }
        case UPDATE_DYNAMIC_TABLE_COMPONENT:
            return {
                ...state,
                dataSet: action.payload,
                message: null,
                activeButton: true
            }
        case UPDATE_INDEX_DYNAMIC_TABLE_COMPONENT: 
            return {
                ...state,
                indexSelected: action.payload,
                activeButton: true
            }
        case UPDATE_BTN_DYNAMIC_TABLE_COMPONENT: 
            return {
                ...state,
                activeButton: true
            }
        case UPDATE_FILTER_TABLE_COMPONENT: 
            return {
                ...state,
                filter: action.payload
            }
        case SET_EDIT_ALERT_TABLE_COMPONENT: 
            return {
                ...state,
                alertEdit: action.payload
            }
        case SET_ADD_ALERT_TABLE_COMPONENT: 
            return {
                ...state,
                alertAdd: action.payload
            }
        case SET_EMPTY_ALERT_TABLE_COMPONENT: 
            return {
                ...state,
                alertEmpty: action.payload
            }
        case DISABLE_BUTTON_ACTIVE_TABLE_COMPONENT: 
            return {
                ...state,
                activeButton: action.payload
            }
        default:
            return state;
    }
}