import { REDIRECT_BACK_LINK, REDIRECT_BACK_LINK_INIT } from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    url: null,
    redirect: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case REDIRECT_BACK_LINK_INIT:
            return {
                ...state,
                url: null,
                redirect: action.payload
            }
        case REDIRECT_BACK_LINK:
            return {
                ...state,
                url: action.payload,
                redirect: true,
            }
        default:
            return state;
    }
}