import { REPOBLATE_NEWS, LINK_BACK_VIEW, GET_LIST_STAFF, ACTIVE_EVENT_TEMPORAL_LEADER, EDIT_MODE, UPDATE_ACTIVE_EVENT, RESET_ACTIVE_MESSAGE_PART, UPDATE_ENABLE_BOARD_NEWS, CHANGE_LOADING_VISIBILITY, SUCCESS_SAVE_TOAST, RESET_ACTIVE_MESSAGE, UPDATE_BUTTON_PRODUCTIVITY, ADD_MEMBER_TO_GROUP, DELETE_MEMBER_TO_GROUP, DELETE_ALL_MEMBERS_TO_GROUP, UPDATE_NAME_MEMBER, UPDATE_NAME_GROUP, SET_GROUP_MEMBERS, SET_GROUP_INFO, SET_PROCESS_LIST, UPDATE_REDIRECT, UPDATE_ACTIVE_EVENT_LEADER, SET_MANAGEMENT_LIST_LEADER } from "../bdb-types";
import moment from 'moment';
const initialState = {
    activeEvent: {
        gestorId: "",
        gestorName: {},
        management: {},
        newsProcess: {},
        dateInit: "",
        dateEnd: "",
        timeInit: "",
        timeEnd: "",
        observations: '',
        caseSmax:"",
        endDateLowerBound: moment().subtract(7, "days").toDate()
    },
    activeEventTempoLeader: {
        gestorId: "",
        gestorName: {},
        management: {},
        newsProcess: {},
        dateInit: "",
        dateEnd: "",
        timeInit: "",
        timeEnd: "",
        observations: '',
        caseSmax:"",
        endDateLowerBound: moment().subtract(7, "days").toDate()
    },
    managementList:[],
    gestorName: "",
    loading: false,
    toastView: '',
    enableButton: false,
    enableBoardNews: false,
    editMode: false,
    dataListStaff: [],
    backLink: undefined,
    membersGroup: [],
    infoGroup: {
        name: ""
    },
    redirect: "",
    repoblate: undefined
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case LINK_BACK_VIEW:
            return {
                ...state,
                backLink: action.payload
            }
        case GET_LIST_STAFF:
            return {
                ...state,
                dataListStaff: [...action.payload]
            }
        case ACTIVE_EVENT_TEMPORAL_LEADER:
                return {
                    ...state,
                    activeEventTempoLeader: { ...action.payload }
            }
        case UPDATE_ACTIVE_EVENT_LEADER:
            return {
                ...state,
                activeEvent: { ...action.payload }
            }
        case SET_PROCESS_LIST:
            return {
                ...state,
                processList: [...action.payload]
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload
            }
        case SUCCESS_SAVE_TOAST:
            return {
                ...state,
                toastView: action.payload
            }
        case UPDATE_BUTTON_PRODUCTIVITY:
            return {
                ...state,
                enableButton: action.payload
            }
        case UPDATE_ENABLE_BOARD_NEWS:
            return {
                ...state,
                enableBoardNews: action.payload
            }
        case RESET_ACTIVE_MESSAGE_PART:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                    gestorId: action.payload ? action.payload.gestorId : '',
                    gestorName: action.payload ? action.payload.gestorName : '',
                }
            }
        case RESET_ACTIVE_MESSAGE:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                    // management: {
                    //     id: 'management',
                    //     option:
                    //         (sessionStorage.getItem("currentManagement") ?
                    //             JSON.parse(sessionStorage.getItem("currentManagement"))
                    //             : initialState.activeEvent.management),
                    // }
                }
            }
        case EDIT_MODE:
            return {
                ...state,
                editMode: action.payload
            }
        case SET_GROUP_MEMBERS:
            return {
                ...state,
                membersGroup: [...action.payload]
            }
        case SET_GROUP_INFO:
            return {
                ...state,
                infoGroup: { ...action.payload }
            }
        case ADD_MEMBER_TO_GROUP:
            return {
                ...state,
                membersGroup: [...state.membersGroup, action.payload]
            }
        case DELETE_MEMBER_TO_GROUP:
            return {
                ...state,
                membersGroup: state.membersGroup.filter((member) => member.id !== action.payload.id)
            }
        case DELETE_ALL_MEMBERS_TO_GROUP:
            return {
                ...state,
                membersGroup: []
            };
        case UPDATE_NAME_MEMBER:
            return {
                ...state,
                gestorName: action.payload
            }
        case UPDATE_NAME_GROUP:
            return {
                ...state,
                infoGroup: { ...state.infoGroup, name: action.payload }
            }
        case UPDATE_REDIRECT:
            return {
                ...state,
                redirect: action.payload
            }
        case REPOBLATE_NEWS:
            return {
                ...state,
                repoblate: action.payload
            }
        case SET_MANAGEMENT_LIST_LEADER:
            return {
                ...state,
                managementList:action.payload
            }
        default:
            return state;
    }
}
