import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";


import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import LeaderKardex from '../../bdb-components/LeaderProductivityComponent/LeaderKardex'
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';
import { idmAdminProd, idmRootAdminProd } from '../../../constants';
import './EditKardex.scss';

const EditKardex = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const rol = getdecrypt('rolUser')

    const handleButton = () =>{
        if(idmAdminProd.includes(rol) || idmRootAdminProd.includes(rol)) {
            history.push(`/admin-kardex`);
        }
        
    }

    const reporDyle = () =>{
        history.push('/report-productivity');
    }

    return ( 
        <Fragment>
            <div className='container-button'>
                <div className={' dly-relative dly-novedadesAdmimn'} onClick={ handleButton }>
                    <div className='button-edit-kardex'></div>
                    <span className='dly-text-kardex'>
                        Editar kardex
                    </span>
                </div>
                <div className={' dly-relative dly-novedadesAdmimn'} onClick={ reporDyle }>
                    <div className='button-dly-mngmnt'></div>
                    <span className='dly-text-kardex'>
                        Gestión diaria
                    </span>
                </div>
            </div>
             
        </Fragment>
    );
}

export default EditKardex;