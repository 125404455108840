import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Loading from '../LoadingComponent/Loading';
import Swal from 'sweetalert2';
import { deleteToken, openCasePaused, b64_to_utf8_encrypt, b64_to_utf8, decryptUsingAES256,getdecrypt} from '../../bdb-services/GeneralService/globalService';
import { logout } from '../../bdb-actions/alertaActions';

const IdleTimeOutHandler = (props)=>{
    let timer=undefined;
    let timer_2= process.env.REACT_APP_TIMEHANDLEROUT;
    const[showModal, setShowModal] =  useState(false)
    const[isLogout, setLogout] = useState(false)
    const[counter, setCounter] = useState(false)
    const dispatch = useDispatch();
    const events= ['click','load','keydown','scroll']
    
    const eventHandler =(eventType)=>{
        if(!isLogout){
            localStorage.setItem('lastInteractionTime', moment() )
            if(timer){
                props.onActive();
                startTimer();                
            }
        }
    };
  

    useEffect(()=>{
        addEvents();
        return (()=>{
            removeEvents();
            clearTimeout(timer);
        })
    },[])
   
    const startTimer=()=>{
        if(timer){
            clearTimeout(timer)
        }
        timer = setTimeout( () => {
            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)))
            let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : timer_2; 
            if( isLogout ) {
                clearTimeout(timer)
            } else{
                if(diff._milliseconds < timeOutInterval){
                    startTimer();
                    props.onActive();
                } else {
                    props.onIdle();
                    setShowModal(true)
                    showModalDyplay();

                }
            }
        },props.timeOutInterval ? props.timeOutInterval : timer_2 )
    }

    


    const showModalDyplay = ( async ()=>{
        props.onShowModal();
        sessionStorage.setItem('closeSession', moment());
        const styles_Button = Swal.mixin({
            customClass: {
              cancelButton: 'btn-Button-Close-Session',
              confirmButton: 'btn-notCancel-Button'
            },
            buttonsStyling: false
          })
        await styles_Button.fire({
            icon: 'info',
            title: 'Tu sesión se cerrará',
            html: 'Estás a punto de superar el tiempo <br/> máximo de inactividad.',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: '¡Sigo aquí!',
            cancelButtonText: 'Cerrar sesiòn',
          }).then( result => {
            localStorage.setItem('closeSession', '--' ); 
            if(result.isConfirmed || result.dismiss === 'backdrop'){     
                handleContinueSession();  
                props.offShowModal();     
            }else if( result.dismiss === 'cancel' ) {
                handleLogout();
                openCasePaused();
            }
          });
    });

    const addEvents=()=>{
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        startTimer();
    }
  
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    
    const handleContinueSession = ()=>{ 
        setShowModal(false)
        setLogout(false)
    }

    const handleLogout = ()=>{
        console.log('se ejecuto handleLogout')
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        props.onLogout();
        setShowModal(false)
        deleteKeyToken();
    }
    

    const deleteKeyToken = async () =>{
        const DocumentNumberAdviserSessionStorage = sessionStorage.getItem('documentNumberAdviser');
        const KeyTokenSessionStorage = sessionStorage.getItem('keyTokenRenew');
        let decodeKeyToken= decryptUsingAES256(b64_to_utf8(KeyTokenSessionStorage), b64_to_utf8(DocumentNumberAdviserSessionStorage), b64_to_utf8(process.env.REACT_APP_AES_KEY) )
        let cryptKeyToken = b64_to_utf8_encrypt(decodeKeyToken);
        await deleteToken(cryptKeyToken);
        sessionStorage.clear();
        localStorage.clear();
        dispatch( logout() );
    }
  
    return(
        <div>
        </div>
    )
        
}
    
export default IdleTimeOutHandler;