import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AdministrativeNews.scss';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink';
import { idmLeaderProdRol, leaderRolManagement } from '../../../constants';
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import { updateCards } from '../../bdb-actions/productivityControlBoardActions';



import LeaderProductivityUser from '../../bdb-views/LeaderProductivityView/LeaderProductivity';
import LeaderKardex from '../../bdb-components/LeaderProductivityComponent/LeaderKardex'
import { GroupSettings } from '../../bdb-components/LeaderProductivityComponent/GroupSettings';
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';
import { BrowserRouter as Router, generatePath, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { resetActveEvent } from '../../bdb-actions/leaderProductivityCtrlBrdActions';


const ROUTES = [
    {
        path: '/kardex',
        name: 'Novedades Kardex',
        coincidences:['','/','/kardex']
    },
    {
        path: '/news',
        name: 'Novedades por usuario',
        coincidences:['/news']
    },
    {
        path: '/massive-news',
        name: 'Novedades masivas',
        coincidences:['/massive-news','/group-settings']
    }

]
const AdministrativeNewsViews = ({ history }) => {
    const rol = getdecrypt('rolUser')
    const accessDailyManagement = idmLeaderProdRol.some(el => el === rol) 
    const { url } = useRouteMatch()
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { editMode } = useSelector(state => state.leaderProdBoard);
    const enableManagerLeader = Boolean(leaderRolManagement.indexOf( rol ) !== -1 );
    const enableBack = () => {
        return (idmLeaderProdRol.indexOf(getdecrypt('rolUser')) === -1 || pathname.includes('group-settings') && editMode)
    }
    const changeView = (path,coincidences) => {
        if(!coincidences.some(path => pathname === `${url}${path}` )){   
            dispatch(resetActveEvent())
            dispatch(updateCards([]))
        }
        history.push(`${url}${path}`);
    }
    const getActiveNav = (coincidences  ) => {
        return coincidences.some(path => pathname===`${url}${path}`)
    }

    const goBack = () => {
        if (pathname.includes('group-settings') && editMode) {
            return "-1"
        }
        return "/finantial-leader"
    }
    return (
        <Fragment>
            <div>
                {   
                    accessDailyManagement ?
                    <DailyManagement />:
                    null
                }
            </div>
            <div className="my-5 content-option-administrative-news">
                {
                    enableBack() &&
                    <BackLink url={goBack()} />
                }
                <div className="d-flex w-100 justify-content-center">
                    {
                        ROUTES.map(({ path, name, coincidences }, index) => (
                            <Fragment key={index}>
                                <div data-cy={`option-new-${index}`} onClick={() => { changeView(path,coincidences) }} className={"options " + (getActiveNav(coincidences) ? 'active-option' : '')}>{name}</div>
                                {index < ROUTES.length - 1 ? <Fragment>&nbsp; | &nbsp;</Fragment> : null}
                            </Fragment>

                        ))
                    }
                </div>
            </div>
            <Switch>
                <Route path={`${url}/news`} component={LeaderProductivityUser} />
                <Route path={`${url}/massive-news`} component={LeaderProductivityUser} />
                <Route exact path={`${url}/group-settings`} component={GroupSettings} />
                <Route exact path={`${url}*`} component={LeaderKardex} /> 

            </Switch>
        </Fragment>
    );
}

export default AdministrativeNewsViews;