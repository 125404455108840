import React from 'react'
import Modal from "react-bootstrap/Modal";
import '../../../assets/styles/ComponentsStyles/modals.scss'

export const DeleteModal = ( { show = false, bodyContent = null, handleButton }) => {
    return (
        <Modal show={ show } centered>
            <Modal.Header bsPrefix="header-modal">
                <span className="delete-icon"></span>
            </Modal.Header>
            <Modal.Body bsPrefix="body-modal">
                <p className="tittle-modal mb-2">¿Quieres eliminar el archivo adjunto?</p>
                <div className="mb-4">
                    {
                        bodyContent
                    }
                </div>
            </Modal.Body>
            <Modal.Footer bsPrefix="footer-modal">
                <button
                    className="footer-button-modal"
                    id="Cancel"
                    onClick={ handleButton }>Cancelar</button>
                <button
                    className="footer-button-modal"
                    id="Delete"
                    onClick={ handleButton }>Eliminar</button>
            </Modal.Footer>
        </Modal>
    )
}
