import React, { Fragment, useEffect, useState } from 'react';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import { useDispatch, useSelector } from 'react-redux';
import '../../bdb-views/AnalystView/Analyst.css'
import { caseReassigned } from '../../bdb-actions/casesActions';
import { useHistory } from 'react-router-dom';
import './Reasignate.css';
import { putReassigned } from '../../bdb-actions/analystActions';

const Reasignate = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [ReassignCase, saveReassignCase] = useState({
        document: '',
        requestNumber: '',
        dateAssignment: '',
        hourAssignment: '',          
        reqStatus: '',
        assignedTo: '',
        skRequest: '',
        priorityFlag: '',
        reqUser: ''
    });

    const alertReasing = useSelector(state => state.cases.alertReasing);

    const [fixedPriorityFlag, saveFixedPriorityFlag] = useState('');

    const [disabledButton, setDisabledButton] = useState(true);

    const selectedCase = useSelector(state => state.cases.selectedCase);

    useEffect(() => {
      saveReassignCase(selectedCase, process);
      const priority = selectedCase?.skRequest.substring(0,1) === '1' 
        ? 'Alta' : 'Media';
      saveFixedPriorityFlag(priority);
    }, [selectedCase]);

    useEffect(() => {
      if (alertReasing === 'La reasignación fue exitosa') {
        history.push('/finantial-leader/cases');
      }
    }, [alertReasing]);

    const onChangeForm = e => {
      saveReassignCase({
        ...ReassignCase,
        [e.target.name] : e.target.name === 'reqUser' ? e.target.value.toUpperCase() : e.target.value
      })
    }

    const submitReassignedCaase = e => {
      e.preventDefault();
      if(ReassignCase.reqStatus === 'OPEN'){
        dispatch( putReassigned())
      }
      dispatch( caseReassigned(ReassignCase) );
      setDisabledButton(true);
    }

    const cancelPriorizate = () => {
      history.push('/finantial-leader/cases');
    }

    const getStyleInput = () => {
      return !!alertReasing ? 'alert-input-user form-control' : 'form-control';
    }

    const getStyleAlert = () => {

      return null;
    }
    
    return ( 
      <Fragment>
        <LeaderOptions /> 
        <div>
          { alertReasing ?  <p className="alert alert-danger text-center mt-2 alert-color-reasign">{alertReasing}</p> : null }
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={submitReassignedCaase}>
                  <div className="form-group">
                      <div className="case-label-reasign">{`Caso ${ReassignCase.requestNumber}`}</div>
                      <div className="case-process-reasign">{ReassignCase.process}</div>
                      <label>Documento cliente</label>
                      <input type="text" className="form-control" disabled={true}
                      placeholder="Nombre Producto" value={ReassignCase.document}
                    />
                    </div>
                    <div className="form-group">
                      <label>Gestor</label>
                      <input type="text" maxLength='10' className={getStyleInput()}  name="reqUser" value={ReassignCase.reqUser} 
                        onChange={e => {onChangeForm(e); setDisabledButton(!e.target.value)}} />
                    </div>
                    <div className="form-group">
                      <label>Prioridad</label>
                      <input type="text" className="form-control" name="priorityFlag"
                            value={ReassignCase.priorityFlag === '' || ReassignCase.priorityFlag === undefined
                            || ReassignCase.priorityFlag === null ? fixedPriorityFlag : ReassignCase.priorityFlag}
                            onChange={onChangeForm} disabled={true} id="priorityFlag"
                      />
                    </div>
                      <div class="row d-flex">
                        <div class="btn-footer col-md-12 space-button">
                          <button type="button" className="btn-primary color-blue btn-style" onClick={cancelPriorizate}>Cancelar</button>
                          <button type="submit" className={`btn-primary color-blue ${disabledButton ? 'btn-disabled-style':'btn-style'}`} 
                          disabled={disabledButton}>Guardar cambios</button>
                        </div>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      
      </Fragment>
     );
}
 
export default Reasignate;