import React, { Fragment, useEffect, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/es"
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import './ProductivitySettingsByProduct.css';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductivityProduct, changeDataParameters, applyChanges, cleanDataParameters } from '../../bdb-actions/productivityByProductActions';
import { productsList, gapList, wrongStateList } from '../../../constants';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import Swal from 'sweetalert2';
import Modal from "react-bootstrap/Modal";

registerLocale("el", el);

const ProductivitySettingsByProduct = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const data = useSelector(state => state.productivityProduct.data);
    const loading = useSelector(state => state.productivityProduct.loading);
    const alert = useSelector(state => state.productivityProduct.alert);
    const error = useSelector(state => state.productivityProduct.error);

    const [dataLocal, setDataLocal] = useState(data);
    const [showProducts, setShowProducts] = useState(false);
    const [showGaps, setShowGaps] = useState(false);
    const [showWrongState, setShowWrongStates] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedGap, setSelectedGap] = useState(null);
    const [selectedWrongState, setSelectedWrongState] = useState(null);
    const [radioValue, setRadioValue] = useState();
    const [addDate, setAddDate] = useState(true);
    const [dateException, setDateException] = useState(null);

    const isWeekday = (date) => {
        const day = date.getDay()
        return day !== 0 && day !== 6
    }
      
    useEffect(() => {
        if(!!data){
            setDataLocal(data);
            setRadioValue(data.applyPostDisbursement ? '1' : '0');
            gapList.forEach(item => {
                if (data.gap === item.id) {
                    setSelectedGap(item.label);
                }
            });
        }
    }, [data]);

    function getOptionsRadio () {
        return [{name:"Si", value:'1'}, {name:"No", value:'0'}];
    }

    function shortOption(option) {
        if (!!option && option.length > 22) {
            const short = option.substring(0,22);
            return `${short}...`;
        } else {
            return option;
        }
    }

    const redirectToHome = () => {
        Swal.fire({
            icon: 'info',
            title: '<div>¡Lo sentimos!</div>',
            html: 'Presentamos inconvenientes técnicos,<br> intenta de nuevo',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76'
        }).then( result => {
            if(result.isConfirmed){
                dispatch(cleanDataParameters());
                history.push('/finantial-leader');
            }
        });
    }

    const successChange = () => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            html: `Parámetros han sido actualizados satisfactoriamente 
                para el producto<br>${selectedProduct}.`,
            showConfirmButton: false,
            timer: 2500
        });
        dispatch(cleanDataParameters());
        setSelectedProduct(null);
    }

    const getOptions = ( options ) => {
        let optionsList = [];
        options.forEach((element, index) => {
          optionsList[index] = {id: element.id, label: element.label}
        });
        return optionsList;
    }

    const onChangeSelectProduct = ( option ) => {
        dispatch(getProductivityProduct(option));
        setSelectedProduct(option);
        setShowProducts(false);
        setAddDate(false);
    }

    const onChangeSelectGap = ( option ) => {
        setShowGaps(false);
        setSelectedGap(option.label);

        dataLocal.gap = option.id;
        dispatch(changeDataParameters(dataLocal));
    }

    const onChangeWrongStates = ( option ) => {
        setShowWrongStates(false);
        if (!dataLocal.wrongState.includes(option.label)) {
            dataLocal.wrongState.push(option.label);
        }
        setSelectedWrongState(option.label);

        dispatch(changeDataParameters(dataLocal));
    }

    const deleteWrongStates = ( index )  => {
        dataLocal.wrongState.splice(index, 1);
        setShowWrongStates(false);
        setSelectedWrongState(null);

        dispatch(changeDataParameters(dataLocal));
    }

    const getExceptionDate = () => {
        let result = [];
        if (!!dataLocal && !!dataLocal.exceptionDate) {
            dataLocal.exceptionDate.forEach((element, index) => {
                result.push(`${element} ${dataLocal.ansHour[index]}`)
            });
            return result;
        }
        return result;
    }

    const getWrongState = () => {
        if (!!dataLocal && !!dataLocal.wrongState) {
            return dataLocal.wrongState;
        }
        return [];
    }

    const addDateException = ()  => {
        const datePush = `${dateException.toISOString().split('T')[0].replace(/-/g, '/')}`;
        const hour = dateException.getHours().toString().length === 1 ? `0${dateException.getHours()}` : dateException.getHours();
        const minutes = dateException.getMinutes().toString().length === 1 ? `0${dateException.getMinutes()}` : dateException.getMinutes();
        dataLocal.exceptionDate.push(datePush);
        dataLocal.ansHour.push(`${hour}:${minutes}:00`);
        setDateException(null);

        dispatch(changeDataParameters(dataLocal));
    }

    const deleteDateException = ( index )  => {
        dataLocal.exceptionDate.splice(index, 1);
        dataLocal.ansHour.splice(index, 1);
        setDateException(null);

        dispatch(changeDataParameters(dataLocal));
    }

    const postDisbursementChange = ( value )  => {
        dataLocal.applyPostDisbursement = value === '1';
        setDateException(null);
        setRadioValue(value);

        dispatch(changeDataParameters(dataLocal));
    }

    return (
    <Fragment>
        <div class="container">
            <LeaderOptions />
            { loading ? <Loading /> : null }
            { error ? redirectToHome() : null }
            { alert ? successChange() : null }
            <div className="flex-center">
                <div className="tittle">Parametrizaciones Productividad</div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="flex-center-disbursement">
                    <div className="width-box">
                        <div className='priority-box priority-label enable-box enable-label'>Selecciona el producto
                            <div onClick={() => {}} className='display-none'></div>
                        </div>                        
                        <div className="dropdown pointer">
                            <div className='dropbtn droplabel-enable' onClick={() => {setShowProducts(!showProducts)}}>
                                {selectedProduct !== null ? shortOption(selectedProduct) : 'Selecciona'} 
                                <div className='icon-drop'></div>
                            </div>
                            {
                                showProducts ?
                                <div className="dropdown-content">
                                    {getOptions(productsList).map((option, index) => (
                                        <a className={selectedProduct === option.label ? 'dropdown-selected' : null} 
                                            onClick={() => {onChangeSelectProduct(option.label);}}>{option.label}</a>
                                    ))}
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedProduct === null ? null :
                <div className="card">
                    <div className="card-content">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className='form-group'>
                                    <label className="label">Excepciones de ANS</label>
                                        { getExceptionDate().map((element, index) => (
                                                <div className="date-exception-disable">
                                                    <input disabled={true} type="text" className="form-control ans-exceptions" value={element} />
                                                    <button className="btn btn-danger" onClick={e => {deleteDateException(index)}}>X</button>
                                                </div>
                                            ))
                                        }
                                    <div className="date-exception-disable">
                                        <DatePicker className="form-control"
                                            selected={dateException} onChange={(date) => setDateException(date)}
                                            locale="el" showTimeSelect timeIntervals={15}
                                            timeFormat="p" placeholderText="YYYY/MM/DD HH:mm:ss"
                                            filterDate={isWeekday} dateFormat="yyyy/MM/dd HH:mm:ss"
                                            />
                                        <button disabled={dateException === null} className="btn btn-primary" onClick={e => {addDateException()}}>+</button>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="label">Intervalo de cumplimiento</label>
                                    <div className="dropdown dropdown-form pointer">
                                        <div className='dropbtn dropbtn-form droplabel-enable' onClick={() => {setShowGaps(!showGaps)}}>
                                            {selectedGap !== null ? shortOption(selectedGap) : 'Selecciona'} 
                                            <div className='icon-drop'></div>
                                        </div>
                                        {
                                            showGaps ?
                                            <div className="dropdown-content">
                                                {getOptions(gapList).map((option, index) => (
                                                    <a className={selectedProduct === option.label ? 'dropdown-selected' : null} 
                                                        onClick={() => {onChangeSelectGap(option)}}>{option.label}</a>
                                                ))}
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='form-group'>
                                    <label className="label">Estados que no aportan al tiempo de ciclo</label>
                                    { getWrongState().map((element, index) => (
                                            <div className="date-exception-disable">
                                                <input disabled={true} type="text" className="form-control ans-exceptions" value={element} />
                                                <button className="btn btn-danger" onClick={e => {deleteWrongStates(index)}}>X</button>
                                            </div>
                                        ))
                                    }
                                    <div className="dropdown dropdown-form pointer">
                                        <div className='dropbtn dropbtn-form droplabel-enable' onClick={() => {setShowWrongStates(!showWrongState)}}>
                                            {selectedWrongState !== null ? shortOption(selectedWrongState) : 'Selecciona'} 
                                            <div className='icon-drop'></div>
                                        </div>
                                        {
                                            showWrongState ?
                                            <div className="dropdown-content">
                                                {getOptions(wrongStateList).map((option, index) => (
                                                    <a className={selectedProduct === option.label ? 'dropdown-selected' : null} 
                                                        onClick={() => {onChangeWrongStates(option)}}>{option.label}</a>
                                                ))}
                                            </div> : null
                                        }
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="label">Aplica postdesembolso</label>
                                    <ButtonGroup toggle>
                                        { getOptionsRadio().map((row, idx) => (
                                            <ToggleButton key={idx} id={`radio-${idx}`} type="radio"
                                            variant="outline-success" name="radio" checked={radioValue == row.value}
                                            value={row.value} onChange={(e) => {postDisbursementChange(e.currentTarget.value)}}>
                                            {row.name}
                                            </ToggleButton>
                                        ))}
                                    </ButtonGroup> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
            <div class="row justify-content-center mt-5">
                {
                    !!data && selectedProduct !== null ? 
                    <button className="btn btn-primary" onClick={e => {dispatch(applyChanges(dataLocal, selectedProduct))}}>Aplicar cambios</button>
                    : null
                }
            </div>
        </div>
    </Fragment>
    );
}
 
export default ProductivitySettingsByProduct;