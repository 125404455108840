import React, { useRef } from 'react';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { useDispatch, useSelector } from 'react-redux';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink'
import SearchForListComponent from '../../bdb-components/SearchForListComponent/SearchForListComponent';
import { getHistoryFiles, uploadOCRFile, uptdAbortStatus, uptdFile, uptdFileType } from '../../bdb-actions/OCRAnalyzeActions';
import { OCR_Analize_DOCS, OCRAnalyzeAllowedFormats } from '../../../constants'
import './OCRAnalyzeView.scss';
import HistoryOCRDocumentsComponent from '../../bdb-components/HistoryOCRDocuments/HistoryOCRDocumentsComponent';
import { useEffect } from 'react';
import Swal from "sweetalert2";

const selectStyle = {
    height: '40px',
    fontSize: '16px',
    zIndex: '5',
    padding: '9px 20px 9px 16px',
    color: '#4a4a4a',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHheight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #d6d6d6',
    backgroundColor: '#ffffff',
    width: '90%',
    textOverflow: 'ellipsis',
    paddingRight: '48px'
};

const styleArrow = {
    width: '15px',
    height: '15px',
    right: '12%',
    zIndex: '5',
};

const styleList = {
    maxHeight: '167px',
    width: '45%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const OCRAnalyzeView = ({ }) => {
    const { loading, selectedFile, selectedFileType, progressBar, historyFiles } = useSelector(state => state.OCRAnalyzeDocuments);
    const hiddenInputFileUploadPlantFileRef = useRef(undefined);
    const dispatch = useDispatch();

    const onChangeListSelect = (e) => {
        let dataSelect = e?.target?.value;
        let selectedOptioList = { id: e?.target?.name, option: { ...dataSelect } };
        dispatch(uptdFileType(dataSelect ? selectedOptioList : null));
    }
    const onChangeFileUpload = (e) => {
        const fileUpload = e.target.files[0]
        const ext = fileUpload.name.split(".").pop().toLowerCase()
        if (!OCRAnalyzeAllowedFormats.includes(ext)){
            Swal.fire({
                title: "Formato erroneo",
                text: `Solo puedes subir archivos con las extensiones permitidas ${OCRAnalyzeAllowedFormats.join(", ")}`,
                icon: "error",
                showCloseButton: false,
                focusConfirm: false,
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#002c76",
              });
              return
        }
        dispatch(uptdFile(fileUpload))
    }

    useEffect(() => {
      dispatch(getHistoryFiles())
    }, [dispatch])
    

    return (
        <>
            <BackLink url="-1"/>
            {loading ? <Loading message={`Cargando <br>${selectedFile?.name}`} progressPercentage={progressBar} actionCancelButton={()=>dispatch(uptdAbortStatus(true))} ></Loading> : null}
            <div className='Insert_large_files_col'>
                <div style={{ fontSize: 'larger', marginTop: '15px', fontWeight: '900' }}>Carga el documento que quieres analizar</div>
                <div style={{ fontSize: 'smaller' }}>Selecciona el tipo de documento y posteriormente carga el archivo a adjuntar.</div>
                <div style={{ borderTopStyle: 'solid', width: '100%', borderTopColor: '#E6E6E6', margin: '15px 0 15px 0' }}></div>
                <div style={{ fontSize: 'smaller' }}>Tipo de documento</div>

                <div className='Insert_large_files_row'>
                    <div style={{ width: '65%' }}>
                        <SearchForListComponent
                            idClick={'large-file-type'}
                            selectedOption={selectedFileType?.option?.label}
                            onClickOption={onChangeListSelect}
                            options={OCR_Analize_DOCS}
                            selectStyle={selectStyle}
                            listStyle={styleList}
                            placeholder={'Selecciona'}
                            showArrow={true}
                            arrowStyles={styleArrow}
                            onActiveContent={false}
                            onChangeCustomInput={(data)=>onChangeListSelect()}
                        />
                    </div>
                    <input style={{ display: 'none' }}
                        type="file"
                        ref={hiddenInputFileUploadPlantFileRef}
                        name="file"
                        onChange={onChangeFileUpload}
                        onClick={(event) => { event.target.value = null }}
                    />
                    <div style={{ width: '35%', display: 'flex', justifyContent: 'space-evenly' }}>
                        {
                            selectedFile ? (
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', fontSize: 'small' }}>
                                    {selectedFile.name}
                                    <div
                                        className='btn-largeFileRemoveDoc'
                                        style={{ cursor: 'pointer' }}
                                        onClick={()=>dispatch(uptdFile(null))}
                                    >
                                    </div>
                                </div>
                            ) :
                                <div className="button-upload" onClick={()=>hiddenInputFileUploadPlantFileRef.current.click()} >
                                    <div className='insert-data-dno-button-icon '></div>
                                    Seleccionar archivo
                                </div>
                        }
                    </div>
                </div>
            </div>
            <button
                className="btn-largeFileDcs"
                onClick={()=>dispatch(uploadOCRFile())}
                disabled={!selectedFile || !selectedFileType}
            >
                Cargar
            </button>
            <HistoryOCRDocumentsComponent files={historyFiles}/>
        </>
    );
}

export default OCRAnalyzeView;