import {
    OPTION_SELECTED_SIMPLY_COMPONENT, 
    OPTION_MULTI_SELECTED_SIMPLY_COMPONENT,
    OPTION_MULTI_REMOVE_SIMPLY_COMPONENT
} from '../bdb-types/index';

export function setOptionSelectedSimplyComponent(optionList) {
    return async (dispatch) => {
        dispatch( optionSelected(optionList) );
    }
}

export function cleanOptionSelectedSimplyComponent() {
    return async (dispatch) => {
        dispatch( optionSelected(null) );
    }
}

const optionSelected = option => ({
    type: OPTION_SELECTED_SIMPLY_COMPONENT,
    payload: option
});

export function setOptionMultiSelectedSimplyComponent(option) {
    return async (dispatch) => {
        dispatch(optionRemoveSelected(option))
        dispatch(optionMultiSelected(option) );
    }
}

export function setOptionMultiRemoveSimplyComponent(option) {
    return async (dispatch) => {
        dispatch( optionRemoveSelected(option) );
    }
}

const optionMultiSelected = option => ({
    type: OPTION_MULTI_SELECTED_SIMPLY_COMPONENT,
    payload: option
});

const optionRemoveSelected = option => ({
    type: OPTION_MULTI_REMOVE_SIMPLY_COMPONENT,
    payload: option
});
