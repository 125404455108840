import { SET_MANAGEMENT_LIST_MANAGER, CHANGE_AREA, ENABLE_MANAGE_AREA, SET_AREA_LIST, ACTIVE_EVENT_TEMPORAL, REPLOBLATE_TIMEINIT, UPDATE_ACTIVE_EDIT, EDIT_CARDS, UPDATE_REPOBLATE, UPDATE_ACTIVE_EVENT, SET_PROCESS_LIST, CHANGE_LOADING_VISIBILITY, SUCCESS_SAVE_TOAST, RESET_ACTIVE_MESSAGE, UPDATE_DAILY_MNGMNT_COUNTERS, UPDATE_DAILY_CARDS } from "../bdb-types";


const initialState = {
    activeEvent: {
        id: "",
        management: { id: "management", option: null },
        area: { id: "area", option: null },
        process: { id: "process", option: null },
        date: "",
        timeInit: "",
        timeEnd: "",
        approveds: "",
        rejecteds: "",
        total: "",
        observations: "",
        amount: "",
        partitions: "",
        processType: "",
        pendingInsideAns: "",
        pendingOutsideAns: "",
        caseSmax: "",
        repoblate: false
    },
    
    activeEventTempo: {
        id: "",
        management: { id: "management", option: null },
        area: { id: "area", option: null },
        process: { id: "process", option: null },
        date: "",
        timeInit: "",
        timeEnd: "",
        approveds: "",
        rejecteds: "",
        total: "",
        observations: "",
        amount: "",
        partitions: "",
        processType: "",
        pendingInsideAns: "",
        pendingOutsideAns: "",
        caseSmax: ""
    },
    areaList: [],
    loading: false,
    processList: [],
    counters: {
        times: [
            { name: 'Productivas', counter: { hour: 0, minutes: 0 }, color: 'black' },
            { name: 'Novedades', counter: { hour: 0, minutes: 0 }, color: 'black' },
            { name: 'Total', counter: { hour: 0, minutes: 0 }, color: 'orange' }
        ],
        cases: [
            { name: 'Aprobados', counter: 0 },
            { name: 'Devueltos', counter: 0 },
            { name: 'Total casos', counter: 0 },
        ]
    },
    prodCards: [],
    restocking: false,
    cardsEdit: {},
    activeEdit: false,
    enableManager: false,
    changeArea: undefined,
    managementListManager:[],
    management:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACTIVE_EDIT:
            return {
                ...state,
                activeEdit: action.payload
            }
        case UPDATE_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: { ...action.payload }
            }
        case ACTIVE_EVENT_TEMPORAL:
            return {
                ...state,
                activeEventTempo: { ...action.payload }
            }
        case SET_AREA_LIST:
            return {
                ...state,
                areaList: [...action.payload]
            }
        case SET_PROCESS_LIST:
            return {
                ...state,
                processList: [...action.payload]
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload
            }
        case RESET_ACTIVE_MESSAGE:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                    // management: {
                    //     id: 'management',
                    //     option:
                    //         (sessionStorage.getItem("currentManagement") ?
                    //             JSON.parse(sessionStorage.getItem("currentManagement"))
                    //             : initialState.activeEvent.management),
                    // },
                    // timeInit: state.activeEvent.timeInit
                },
                processList: initialState.processList,
                areaList: [...state.areaList],
            }
        case UPDATE_DAILY_MNGMNT_COUNTERS:
            let counter = action.payload;
            if (!action.payload) {
                counter = initialState.counters
            }
            return {
                ...state,
                counters: counter
            }
        case UPDATE_DAILY_CARDS:
            return {
                ...state,
                prodCards: action.payload
            }
        case EDIT_CARDS:
            return {
                ...state,
                cardsEdit: { ...action.payload }
            }
        case UPDATE_REPOBLATE:
            return {
                ...state,
                restocking: action.payload
            }
        case REPLOBLATE_TIMEINIT:
            return {
                ...state,
                activeEvent: { ...state.activeEvent, timeInit: action.payload }
            }
        case ENABLE_MANAGE_AREA:
            return {
                ...state,
                enableManager: action.payload
            }
        case CHANGE_AREA:
            return{
                ...state,
                changeArea:action.payload
            }
        case SET_MANAGEMENT_LIST_MANAGER:
            return {
                ...state,
                managementListManager:action.payload
            }
        default:
            return state;
    }
}