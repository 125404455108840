import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../SimpleSelectComponent/SimpleSelectComponent.css';
import { setOptionSelectedSimplyComponent, setOptionMultiSelectedSimplyComponent } from '../../bdb-actions/simplySelectActions';

const SimpleSelectComponent = ({id, small, absolute, width, options, autocomplete, placeholder, arrow,  styleSelect,  listStyle, selected, disabled, showTooltip}) => {
    
    const dispatch = useDispatch();

    const [listOptions, setListOptions] = useState(options); 
    const [listOptionsTemp, setListOptionsTemp] = useState(options); 
    const [optionSelected, setOptionSelected] = useState(selected);
    const [showOptions, setShowOptions] = useState(false);
    const [positionArrow, setPositionArrow] = useState(arrow);
    const [styleDivSelect, setStyleDivSelect] = useState(styleSelect);
    const [idOption, setIdOption] = useState(id);
    
    useEffect(() => {
        setListOptions(options);
    }, [setListOptions, options])

    useEffect(() => {
        selectOptions(selected);
    }, [selected])

    useEffect(() => {
        setListOptionsTemp(options);
    }, [setListOptionsTemp, options])

    const filterOptions = filter => {
        setOptionSelected(filter);
        setShowOptions(true);
        if (filter === '' || !(!!filter)) {
            setListOptions(listOptionsTemp);
        } else {
            const listOptionsFilter = listOptions.filter( options =>
                options['label'].toLowerCase().indexOf(filter.toLowerCase()) > -1
                );
                setListOptions(listOptionsFilter);
            }
        }

        const selectOptions = option => {
            setShowOptions(false);
            setOptionSelected(option);
            setListOptions(options);
            !!idOption ? 
            dispatch(setOptionMultiSelectedSimplyComponent({id: `${idOption}`, option: option})) :
            dispatch(setOptionSelectedSimplyComponent(option));
        }

        const getOptionStyle = option => {
            return optionSelected ? 
            optionSelected.id === option ? 'options-style-simple-select option-color-simple-select'
            : 'options-style-simple-select' : 'options-style-simple-select';
        }

        function shortOption(option) {
            if (option.length > 70) {
                const short = option.substring(0,70);
                return `${short}...`;
            } else {
                return option;
            }
        }

        const showOptionList = () => {
            if (!disabled) {
                setShowOptions(!showOptions);
            }
        }

        const getStyleAbsolute = () => {
            return small ? `small-simple-select ${absolute ? 'position-absolute' : '' }` : `${absolute ? 'position-absolute' : '' }`;
        }

        const handleBlur = (e) => {
            e.persist();
            if ( e.target.id === "select-list") setShowOptions(false);
        };
        const handleFocus = (e) => {
            setShowOptions(true)
        };

        return (

        <div id="select-list" onBlur={ handleBlur } tabIndex="0" className={getStyleAbsolute()}
            style={!!width ? {width: `${width}`} : {}}>
            <div className="d-flex">
                { autocomplete ? 
                    <input data-cy = {`simple-select-${id}`} onFocus={ handleFocus } className={`${!!styleDivSelect ? '' : 'style-simple-select'}${showOptions ? 'simple-select-custom-border' : ''}`} placeholder="Selecciona" id="simpleSelectInput"
                    onChange={(e) => {filterOptions(e.target.value)}} style={!!styleDivSelect ? styleDivSelect : {}} 
                    value={optionSelected ? optionSelected.label : ""} disabled={disabled} autoComplete="off"></input> :
                        <div className={
                            optionSelected ? `style-div-simple-select ${disabled ? 'simply-select-desableg cursor-default' : ''}` 
                            : `style-div-simple-select style-placeholder-simple-select ${disabled ? 'simply-select-desableg cursor-default' : ''}`}
                        id="simpleSelectInput" onClick={() => showOptionList()}
                        style={!!styleDivSelect ? styleDivSelect : {}}>
                            {optionSelected ? optionSelected.label : (!!placeholder ? placeholder : 'Selecciona')}
                            { !absolute ? <span className={small ? 
                                `icon-arrow-simple-select ${disabled ? 'cursor-default' : ''}`
                                : `icon-arrow-simple-select ${disabled ? 'cursor-default' : ''}`}
                                style={!!positionArrow ? positionArrow : {}} onClick={() => showOptionList()}></span> : null}
                        </div>
                }
            </div>
            <div className="simple-select-div">
                { absolute ? <span className={small ? 
                     `icon-arrow-simple-select-autocomplete ${disabled ? 'cursor-default disabled-icon-arrow' : ''}` 
                     : `icon-arrow-simple-select-autocomplete ${disabled ? 'cursor-default disabled-icon-arrow' : ''}`}
                     style={!!positionArrow ? positionArrow : {}} 
                    onClick={() => showOptionList()}></span> : null }
            </div>
            {
                showOptions ?
                <div className="options-content-style-simple-select"
                style={!!listStyle ? listStyle : {}}>
                    { listOptions.map((option, index) => (
                        <div data-cy = {`simple-option-${index}`} key={index} className={getOptionStyle(option.id)} data-toggle="tooltip" data-placement="top" title={ showTooltip ? option.id:''}
                        onClick={() => {selectOptions(option)}}>
                            <span  className="span-option-simple-select">
                                {shortOption(option.label)}
                            </span>
                        </div>
                    ))}
                </div> : null
            }
        </div>
     );
}
export default SimpleSelectComponent;