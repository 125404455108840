import React from 'react';
import Modal from "react-bootstrap/Modal";
import '../../../assets/styles/ComponentsStyles/loading.scss'


const Loading = ({ show, message}) => {
    
    return ( 
        <Modal show={show} centered>
            <Modal.Body>
                <div>
                    <div className='loading-icon'>&nbsp;</div>
                    <div className='text-modal'>{!!message ? message : 'Cargando'}</div>
                </div>
            </Modal.Body>
      </Modal>
     );
}
 
export default Loading;