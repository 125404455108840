import React, { Fragment, useEffect, useState } from 'react';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import { searchSpecificCase, cleanSpecifictCase } from '../../bdb-actions/casesActions';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Case from '../../bdb-components/TableBodyComponent/Case';
import '../CasesView/Cases.css';
import { statusCaseUnit } from '../../../constants';


const sylesCSS = {
    input: {
        'width': '355px',
        'height': '40px',
        'margin': '9px 10px 0 0',
        'padding': '2px 69px 3px 13px',
        'border-radius': '4px',
        'box-shadow': 'inset 0 1px 3px 0 rgba(236, 236, 236, 0.5)',
        'border': 'solid 1px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff'
    },
    buttonSearch: {
        'width': '70px',
        'height': '40px',
        'margin': '9px 0 0 0px',
        'padding': '2px 63px 3px 13px',
        'border-radius': '4px',
        'border': 'solid 0.5px #002c76',
        backgroundColor: '#002c76',
        'color': '#ffffff'
    },
    label: {
        'height': '0px',
        'margin': '0 110px 9px 0',
        fontSize: '14px',
        fontWeight: 'normal',
        'font-stretch': 'normal',
        'font-style': 'normal',
        'lineHeight': 'normal',
        'letter-spacing': 'normal',
        'color': '#003993'
    },
    marginWithoutAlert: {
        'margin-top': '1%'
    },
    marginWithAlert: {
        'margin-top': '1%'
    },
    buttonPriority: {
        'width': '10px',
        'height': '25px',
        'padding': '1px 62px 0px 8px',
        'border-radius': '4px',
        'border': 'solid 0.5px #002c76',
        backgroundColor: '#002c76',
        'color': '#ffffff',
        fontSize: '14px'
    },
    headTbl: {
        backgroundColor: '#EAF1FA',
        'color': '#5c5c5c',
        fontSize: '12px'
    },
    td: {
        'color': 'rgba(0, 0, 0, 0.85)',
        backgroundColor: '#ffffff'
    },
    divTbl: {
        'padding-top': '5%'
    }, 
    errorColor: {
        color: '#e30008'
    },
    alertColor: {
        color: '#6d7278'
    },
    paddingAlert: {
        padding: '0% 20% 0% 20%'
    }
}

const Cases = () => {

    const [idCase, saveIdCase] = useState('');

    const [idtoConsult, saveIdtoConsult] = useState('');

    const dispatch = useDispatch();

    useEffect( ()=> {
        const cleanhEspecifict = () => dispatch( cleanSpecifictCase());
        cleanhEspecifict(idtoConsult);
    }, []);

    useEffect( ()=> {
        const searchEspecifict = id => dispatch( searchSpecificCase(id));
        searchEspecifict(idtoConsult);
    }, [idtoConsult]);

    const especifict = useSelector(state => state.cases.especifict );

    const loading = useSelector(state => state.cases.loading );

    const id = useSelector(state => state.cases.id);

    const error = useSelector(state => state.cases.error);

    const alert = useSelector(state => state.cases.alert);

    const alertPrioritize = useSelector(state => state.cases.alertPrioritize);

    const alertReasing = useSelector(state => state.cases.alertReasing);
    
    const search = () => {
        saveIdtoConsult(idCase);
    }

    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    return ( 
        <Fragment>
            <LeaderOptions /> 

            { loading ? <Loading /> : null}
            
            <div className="tittle text-center">Buscar caso</div>
            <div>
                { error ? <p className="alert alert-danger text-center mt-2"
                style={sylesCSS.errorColor}>Número de caso incorrecto</p> : null }
                { !!alertPrioritize ?  <p className="alert alert-success text-center mt-2" style={sylesCSS.alertColor}>{alertPrioritize}</p> : null }
                { !!alertReasing ?  <p className="alert alert-success text-center mt-2" style={sylesCSS.alertColor}>{alertReasing}</p> : null }
            </div>
            <div className="d-flex justify-content-center" style={ error || alert ? sylesCSS.marginWithoutAlert : sylesCSS.marginWithAlert}>
            <div>
                <label style={sylesCSS.label}>Número de caso</label>
                    <div>
                        <input placeholder="Nº de caso" 
                               style={sylesCSS.input} 
                               onChange={(e) => {saveIdCase(e.target.value)}}
                               data-cy="input-case"></input>
                        <button style={sylesCSS.buttonSearch} onClick={search} data-cy="button-case">Buscar</button>
                    </div>
                </div>
            </div>
            { especifict.length ===  0 ? null : (
                <div style={sylesCSS.divTbl}>
                    { especifict[0].reqStatus === 'PAUSED' && global.isGroupDisbursement() ? (
                        <div className="div-reason">
                            <div className="div-paused">
                                {statusCaseUnit[especifict[0].reqStatus]}
                            </div>
                            <div className="div-reason-paused"> 
                                {especifict[0].reasonReturn}
                            </div>
                        </div>
                        ) : null
                    }
                    <table className="table table-striped">
                        <thead className="bg-primaty table-blue" style={sylesCSS.headTbl}>
                            <tr>
                                <th scope="col">Nº Caso</th>
                                <th scope="col">Documento</th>
                                {  
                                    global.isGroupDisbursement() ? <th scope="col">Producto</th> : null 
                                }
                                <th scope="col">Proceso</th>
                                <th scope="col">Fecha recib.</th>
                                <th scope="col">Hora recib.</th>
                                <th scope="col">Gestor</th>
                                <th scope="col">Fecha gest.</th>
                                <th scope="col">Hora gest.</th>
                                {
                                    global.isLibrances() ? <th scope="col">Convenio</th> : null 
                                }
                                <th scope="col">Estado</th>
                                <th scope="col">
                                    {
                                        (especifict[0].process === 'Orden de Desembolsos' && 
                                        (especifict[0].reqStatus === 'APPROVED' ||
                                        especifict[0].reqStatus === 'REJECT'))
                                        ? 
                                        'Documentos' 
                                        : 
                                        null 
                                    }
                                </th>
                            </tr>
                        </thead>
                        { especifict.length ===  0 ? null : (
                            especifict.map(row => (
                        <tbody>
                        <Case key={row.requestNumber} row = {row} />
                        </tbody>
                            ))
                        ) }
                    </table>
                </div>
            ) }
        </Fragment>
     );
}
export default Cases;