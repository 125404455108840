import {
    MOSTRAR_ALERTA,
    OCULTAR_ALERTA,
    LOGOUT
} from '../bdb-types/index';
import interceptor from '../../interceptor';

// Muestra alerta
export function mostrarAlerta(alerta) {
    return (dispatch) => {
        dispatch( crearAlerta(alerta) )
    }
}

const crearAlerta = alerta => ({
    type: MOSTRAR_ALERTA,
    payload: alerta
})

// ocultar alerta
export function ocultarAlertaAction() {
    return (dispatch) => {
        dispatch( ocultarAlerta() )
    }
}

const ocultarAlerta = () => ({
    type: OCULTAR_ALERTA
})

// Muestra alerta
export function logout(alerta) {
    return (dispatch) => {
        window.location.href = process.env.REACT_APP_LOGOUT_ENDPOINT;
        dispatch( logoutAlerta() )
    }
}

const logoutAlerta = () => ({
    type: LOGOUT
})