import { 
    OPTION_SELECTED_SIMPLY_COMPONENT,
    OPTION_MULTI_SELECTED_SIMPLY_COMPONENT,
    OPTION_MULTI_REMOVE_SIMPLY_COMPONENT
 } from '../bdb-types';

const initialState = {
    option: null,
    multipleOption: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case OPTION_SELECTED_SIMPLY_COMPONENT:
            return {
                ...state,
                option: action.payload
            }
        case OPTION_MULTI_SELECTED_SIMPLY_COMPONENT:
            return {
                ...state,
                multipleOption: [...state.multipleOption, action.payload]
            }
        case OPTION_MULTI_REMOVE_SIMPLY_COMPONENT:
            return {
                ...state,
                multipleOption: state.multipleOption.filter( element => element.id !== action.payload.id )
            }
        default:
            return state;
    }
}