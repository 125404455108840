import React from 'react'
import '../../../assets/styles/flexbox.scss'
import '../../../assets/styles/ComponentsStyles/selectList.scss'
import { useState } from 'react'


export const SelectListSimple = ( { width='100%', placeholder='Selecciona' , optionsList=[], optionSelected={}, idName, handleForm, disabledList=false }) => {
    
    const [showOptionsList, setShowOptionsList] = useState(false)

    const toggleOptionList = () => {
        if (!disabledList) {
            setShowOptionsList( !showOptionsList)
        }
    }
    const handleOptionSelected = ( option ) => {
        setShowOptionsList( false)
        handleForm({ target : {
            name : idName,
            value : option
        }})
    }
    return (
        <>
            <div className="block-list-box"
            style={{width: `${width}`}}
            onClick={ toggleOptionList }
             >
                <input 
                    className="report-order-input"
                    style={ { cursor: 'pointer' }}
                    placeholder={placeholder}
                    name={idName}
                    value={optionSelected ? optionSelected.label : ''}
                    readOnly="readonly"
                    data-cy = {`bton-form-${idName}`}
                ></input>
                <span 
                    className="icon-arrow-simple-select-autocomplete-order"></span>

                { showOptionsList &&

                <div className="options-content-style-simple-select-order">
                      
                        {optionsList.map( ( option , index) => (
                            <div 
                            data-cy = {`bton-form-selec-${index}`}
                            key={index}
                            className="options-style-simple-select" 
                            onClick={() => handleOptionSelected( option ) }  
                              >
                                <span 
                                    key={index} 
                                    className="span-option-simple-select"
                                >{ option.label }</span>

                            </div>

                        ))
                        }
                </div>
                }
            </div>
        </>
    )
}
