import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCaseResume } from '../../bdb-actions/analystActions';
import { removeCasePaused } from '../../bdb-actions/analystActions';
import Swal from 'sweetalert2';
import '../../../assets/styles/tableBodyStyle.css'
import moment from 'moment';

const status = {
    'PENDING': 'Pendiente',
    'OPEN': 'Abierto',
    'REJECT': 'Devuelto',
    'APPROVED': 'Aprobado',
    'PAUSED': 'Pausado',
    'ABORT': 'Rechazado'
}

const Paused = ({row,actives, isDisbursement}) => {
    const { document, requestNumber, dateAssignment, hourAssignment, 
        reqStatus, reqUser, process, libranzaCode, product, line } = row;

    
    const history = useHistory();

    const dispatch = useDispatch();

    const caseResume = selectedCase => dispatch( getCaseResume(selectedCase));

    const removeCase = idToDelete => dispatch ( removeCasePaused(idToDelete));

    const resumeCase = (row,actives) => {
        if(actives.length>0){
        Swal.fire({
            icon: 'info',
            html: `Recuerda que es necesario que <b>pauses, apruebes o devuelvas</b> el caso ${actives[0].requestNumber} <br>para que puedas retomar otro caso`,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
          }).then(result => {
          });
        }else{
            removeCase(row.requestNumber);
            caseResume(row);
            history.push('/finantial-analyst');
        }
    }
    
    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    return ( 
        <tr>
            <td className="td-component unset-align">{requestNumber}</td>
            <td className="td-component unset-align">{!!document ? document.startsWith('N/A') ? 'N/A' : document : null}</td>
            <td className="td-component unset-align"> {process }</td>
            <td className="td-component unset-align">{ global.translateCopies(product) }</td>
            <td className="td-component unset-align">{moment(dateAssignment).format("DD/MM/YY")}</td>
            <td className="td-component unset-align">{moment(hourAssignment,"HH:mm:ss").format("h:mm:ss a")}</td>
            <td className="td-component unset-align">{status[reqStatus]}</td>
            {  global.isLibrances() ? <td className="td-component unset-align">{libranzaCode}</td> : null }
            <td className="td-component unset-align">
            { reqStatus === 'PAUSED' ?
                (
                <button 
                type="button"
                className="btn button-resume"
                onClick={() => resumeCase(row,actives)}
                >Retomar</button> 
                ) : null 
            }
            </td>
        </tr>
     );

}
export default Paused;