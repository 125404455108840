import React, { forwardRef } from 'react'

const DatePickerCustomInput = forwardRef (({ value, onChange, onClick, styleDiv, styleArrow, styleInput, disabled }, ref) => (
  <div className={styleDiv}>
      <input 
          className={styleInput}
          value={value} 
          onChange={onChange} 
          ref={ref} 
          placeholder='Selecciona'
          disabled={disabled}
          readOnly
      >
      </input>
      <span onClick={onClick} className={styleArrow}></span>

  </div>
));

export default DatePickerCustomInput;