import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { idmAnalystRol, idmReporterFng, leaderRolManagement,idmLeaderProdRol, idmAdminProd, idmRootAdminProd } from '../../../constants';
import { resetActveEvent } from '../../bdb-actions/productivityControlBoardActions';
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import './DailyManagement.scss';

const DailyManagement = ({ relative=true}) => {
    const dispatch = useDispatch();
    const rol = getdecrypt('rolUser')
    const history = useHistory();
    let isAnalystRol = Boolean(idmAnalystRol.indexOf( rol ) !== -1 || idmReporterFng.indexOf( rol ) !== -1 || (idmLeaderProdRol.indexOf( rol ) !== -1 ));
    let enableManagerLeader = Boolean((leaderRolManagement.indexOf( rol ) !== -1 ) );
    

    
    const enableButonManagerDay = () =>{
        return( !!isAnalystRol || !!enableManagerLeader)
    }

    const getControlBoard = (data) => {
        if (enableButonManagerDay()) {
            history.push('/report-productivity');
        }
        else{
            dispatch(resetActveEvent())
            history.push('/leader-productivity');
        }
    }

    const controlNews = () =>{
        history.push('/leader-productivity');
    }

    return ( 
        <div className='container-button'>
            {
                enableManagerLeader ?
                <div className={' dly-relative dly-novedadesLider'} data-cy='Novedades-user-cc' onClick={ controlNews }>
                    <div className='button-news'></div>
                    <span className='dly-text'>
                        Novedades
                    </span>
                </div>:
                null
            }
            <div className={'dly-mngmnt dly-'+(enableButonManagerDay() && relative ?'relative':'absolute')} onClick={ getControlBoard}>
                <span className='dly-text'>
                    { enableButonManagerDay() ?
                    <Fragment>Gestión diaria</Fragment>:
                    <Fragment>Novedades</Fragment>
                    }
                </span>
            </div>

        </div>
    );
}

export default DailyManagement;
