import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { v4 as uuid } from 'uuid';
import ProductivityCard from '../ProductivitySumarizeComponent/ProductivityCard';
import SearchForListComponent from '../SearchForListComponent/SearchForListComponent';
import './LeaderProdStyles.scss';
import { getNewsManager, postInsertNews, updateEnableBoardNews, updateActiveEvent, updateToastView, getNameById,
     updateEnableButton, resetActveEvent, editModeLeader, resetActveEventPart, linkBackView, postInsertMassiveNews, 
     getOwnGroup, changeRedirect,repoblateNews, getManagementByVertical } from '../../bdb-actions/leaderProductivityCtrlBrdActions';
import { excludeDateEndNews, leaderNewsListControlBoard, MassiveNews, newsByHours, SYSTEM_FAILURE_NEWS } from '../../../constants';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import DatePickerCustomInput from '../ProductivityFormComponent/DatePickerCustomInput';
import { getDateFormat,  } from '../../bdb-helpers/utils';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import '../../../index.css';

registerLocale('es', es)
setDefaultLocale('es');

let registerLeader = false;

const inputList = {
    maxHeight: '167px',
    width: '611px',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    position: 'absolute',
    fontSize: '16px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const pruebasaInput = {
    width: '210%',
    height: '40px',
    padding: '10px 15px 11px 15px',
    borderRadius: '3px',
    border: 'solid 1px #d3d1d3',
    backgroundColor: '#fff',
    margin: '3px 0px 5px 0px',
};

const inputStyles = {
    height: '40px',
    fontSize: '16px',
    zIndex: '5',
    padding: '9px 20px 9px 16px',
    color: '#4a4a4a',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHheight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #d6d6d6',
    backgroundColor: '#ffffff',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingRight: '48px'
};
const listStyles = {
    maxHeight: '167px', 
    width: '50%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};
const styleArrow = {
    width: '15px',
    height: '15px',
    right: '18px',
    zIndex: '5',
};


const LeaderProductivity = () => {
    const { pathname } = useLocation();
    const isMassive = pathname.includes("massive")
    const dispatch = useDispatch();
    const history = useHistory();

    const [enableHours, setEnableHours] = useState(true);
    const [managerDocumenName, setManagerDocumenName] = useState(undefined);

    const cards = useSelector(state => state.prodContBoard.prodCards);
    const getDataStaff = useSelector(state => state.leaderProdBoard.dataListStaff);
    const { name: group, membersCount } = useSelector(state => state.leaderProdBoard.infoGroup);
    const redirectSettings = useSelector(state => state.leaderProdBoard.redirect);
    const { managementList,activeEvent, loading, toastView, enableButton, enableBoardNews, activeEventTempoLeader,repoblate } = useSelector(state => state.leaderProdBoard);
    const { gestorName, newsProcess, management, dateInit, dateEnd, timeInit, timeEnd, endDateLowerBound, caseSmax, observations } = activeEvent;
    const dateLowestBound = moment().subtract(7, "days").toDate();

   
    useEffect(() => {
        if (isMassive ) {
            dispatch(getOwnGroup())
            setEnableHours(false)
        }
        dispatch(changeRedirect(''))
    }, [isMassive])

    useEffect(() => {
        let temporalActiveEvent = { ...activeEvent };
        const _resetDates = () => {
            temporalActiveEvent['dateInit'] = "";
            temporalActiveEvent['dateEnd'] = "";

            dispatch(updateActiveEvent(temporalActiveEvent));
        }
        const _resetHours = () => {
            temporalActiveEvent['timeInit'] = "";
            temporalActiveEvent['timeEnd'] = "";
            dispatch(updateActiveEvent(temporalActiveEvent));
        }
        if (enableHours) _resetDates()
        else _resetHours()
    }, [enableHours, dispatch])

    useEffect(()=>{
        if(!!redirectSettings){
            history.push(redirectSettings)
        }
    },[redirectSettings])
    const identifyNumberOrName = (dataSearch) => {
        let validationST = dataSearch.slice(0, 2) === 'ST' && process.env.REACT_APP_ENABLE_CHARACTER_NUMBER
        if (dataSearch.length >= 3) {
            const isNumber = /^-?\d+$/.test(dataSearch);
            if (isNumber || (validationST)) {
                setManagerDocumenName({
                    'number': validationST ? dataSearch.replace('ST', '') : dataSearch
                })
            } else {
                standardFullName(dataSearch)
            }
        }
    }

    const handleInputChange = (dataSearch) => {
        let target = (dataSearch && dataSearch.target) ? dataSearch.target : undefined;
        let value;
        let temporalActiveEvent = { ...activeEvent };
        if (target.name === 'gestorId') {
            identifyNumberOrName(target.value)
            if (gestorName) {
                temporalActiveEvent = { ...activeEvent, dateInit: "", dateEnd: "", gestorName: "", endDateLowerBound: dateLowestBound, newsProcess: null };
            }
            if (target.value.length > 0 && target.value.length < 3) dispatch(updateToastView('error'))
            else dispatch(updateToastView(''))
        }
        if (target.name === 'dateInit' || target.name === 'dateEnd') {
            value = moment(target.value).toDate();
            temporalActiveEvent['timeInit'] = null;
            temporalActiveEvent['timeEnd'] = null;
            if (target.name === 'dateInit') temporalActiveEvent['endDateLowerBound'] = value;

        }
        if (target.name === 'timeInit' || target.name === 'timeEnd') {
            temporalActiveEvent = { ...activeEvent };
            temporalActiveEvent['dateInit'] = null;
            temporalActiveEvent['dateEnd'] = null;
            let formatTime = moment(target.value).toDate();
            value = formatTime
        }
        temporalActiveEvent[target.name] = value;
        dispatch(updateActiveEvent(temporalActiveEvent));

    }

    const remarksTex = ({ target }) => {
        let value = target.value;
        const temporalActiveEvent = { ...activeEvent };
        if (target.name === "caseSmax") value = value.replace(/[^\d]/g, '');
        if (target.name === "newsProcess"){
            temporalActiveEvent["area"] = target.valueMainList ? 
                                            {id:target.valueMainList, label: target.valueMainList}
                                            :
                                            {id:'Ausentismos', label: 'Ausentismos'}
        }
        temporalActiveEvent[target.name] = value;
        dispatch(updateActiveEvent(temporalActiveEvent));
    }
    
    const requireNumberSmax = () => {
        return !!SYSTEM_FAILURE_NEWS.find(newFailure => newFailure.id === activeEvent.newsProcess?.id)
    }

    useEffect(() => {
        if(!!managerDocumenName){
            setTimeout(() => dispatch(getNameById(managerDocumenName)), 100);
        }
    }, [managerDocumenName])

     useEffect(() => {
        if (!!management?.id) return
        if (JSON.parse(sessionStorage.getItem("currentManagement"))?.id !== management?.id) {
            let temporalActiveEvent = { ...activeEvent };
            temporalActiveEvent.management = JSON.parse(sessionStorage.getItem("currentManagement"));
            dispatch(updateActiveEvent(temporalActiveEvent));
        }
    }, [management])
    useEffect(() => {
      if (activeEvent.newsProcess){
          const isNewByHour = !!newsByHours.find( newByHour => newByHour.id === activeEvent.newsProcess.id)
          setEnableHours((isNewByHour || isMassive))
      }
    }, [activeEvent])
    
    const showModal = async (modal) =>{
        let result;
        await Swal.fire({
            icon: modal.icon,
            title:modal.title,
            html: modal.text,
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#c61f01',
            cancelButtonText: 'Cancelar',
        }).then(resul =>{
            result = resul;
        })
        return result
    }

    const handleButtonSave = async (e) => {
        let modal
        let tempActiveEvent = { ...activeEvent };
        dispatch(repoblateNews(false));
        if (isMassive) {
            modal = { icon: 'info', 
            title: '¿Estás seguro de registrar  <br>  la  novedad?',
            text: 'Después de registrada no se podrá <br> editar o eliminar' }
            await showModal(modal)
            .then(resul=>{
                if(resul.isConfirmed){
                    tempActiveEvent["dateEnd"] = isNewExcluded() ? moment(tempActiveEvent.dateInit).add(30, 'd') : tempActiveEvent.dateEnd;
                    dispatch(postInsertMassiveNews(tempActiveEvent,));
                }
            }) 
        } else {
            tempActiveEvent["dateEnd"] = isNewExcluded() ? moment(tempActiveEvent.dateInit).add(30, 'd') : tempActiveEvent.dateEnd;
            dispatch(postInsertNews(tempActiveEvent,));
        }
        sessionStorage.setItem("currentManagement",JSON.stringify(tempActiveEvent.management));
    }
    const handleButtonCancel = (e) => {
        dispatch(repoblateNews(false));
        let temporalActiveEvent = {
            gestorName: null,
            management: null,
            newsProcess: null,
            dateEnd: "",
            dateInit: "",
            timeEnd: "",
            timeInit: "",
        };
        // setEnableContent(false);
        // setButtonSearch(false);
        dispatch(updateEnableBoardNews(false));
        dispatch(editModeLeader(undefined));
        dispatch(resetActveEvent());

        dispatch(updateActiveEvent(temporalActiveEvent));
        dispatch(linkBackView('finantial-leader'));
    }

    const initialRegisterLeader = () => {
        dispatch(repoblateNews(false));
        let tempActiveEvent = {
            ...activeEvent,
            dateInit: "",
            dateEnd: "",
            timeInit: "",
            timeEnd: "",
        };
        registerLeader = false;
        dispatch(getNewsManager());
        dispatch(editModeLeader(undefined));
        dispatch(resetActveEventPart(tempActiveEvent));
    }

    const isNewExcluded = () => {
        return ( !!newsProcess && !!newsProcess )? excludeDateEndNews.includes(newsProcess.id) : false;
    }

    useEffect(()=>{
        dispatch(getManagementByVertical())
        return () =>{
                dispatch(updateToastView(''))
            dispatch(resetActveEvent());
        }
    },[])

    useEffect(() => {
        let dataTemp = {
            'observationsTempo': activeEventTempoLeader.observations,
            'gestorNameTempo': (!!activeEventTempoLeader.gestorName && !!activeEventTempoLeader.gestorName
                && !!activeEventTempoLeader.gestorName.label ? activeEventTempoLeader.gestorName.label : ''),
            'dateEndTempo': activeEventTempoLeader.dateEnd,
            'dateInitTempo': activeEventTempoLeader.dateInit,
            'managementTempo': (activeEventTempoLeader.management && !!activeEventTempoLeader.management &&
                !!activeEventTempoLeader.management.id ? activeEventTempoLeader.management.id : ''),
            'newsProcessTempo': (!!activeEventTempoLeader.newsProcess && !!activeEventTempoLeader.newsProcess &&
                !!activeEventTempoLeader.newsProcess.id ? activeEventTempoLeader.newsProcess.id : ''),
            'timeEndTempo': activeEventTempoLeader.timeEnd,
            'timeInitTempo': activeEventTempoLeader.timeInit,

        }
        const { observationsTempo, dateEndTempo, gestorNameTempo, dateInitTempo, managementTempo, newsProcessTempo, timeEndTempo, timeInitTempo } = dataTemp
        const isValidSmax = (!requireNumberSmax() || !!caseSmax)
        if (gestorName && (dateEnd || isNewExcluded()) && dateInit) {
            if ((observationsTempo === observations) && (dateEndTempo === dateEnd) && (gestorNameTempo === gestorName.label)
                && (dateInitTempo === dateInit) && ((!!management && managementTempo === management.id)) &&
                (!!newsProcess && newsProcessTempo === newsProcess.id) && ((timeEndTempo === activeEvent.timeEnd) && (timeInitTempo === timeInit) && (!requireNumberSmax() || (caseSmax === activeEventTempoLeader.caseSmax)))) {
                registerLeader = true;
                dispatch(updateEnableButton(false))
                return
            } else if ((!!gestorName && !!newsProcess && !!management) && ((!!dateInit && !!dateEnd) && (!!timeInit && !!timeEnd))) {
                dispatch(updateEnableButton(true))
            }
        }
        else if (isMassive && (timeEnd || isNewExcluded()) && timeInit && isValidSmax) {
            dispatch(updateEnableButton(true))
        }
        else {
            dispatch(updateEnableButton(false))
        };
        // setEnableContent(!!gestorName ? true : false);
        if (!!enableBoardNews) {
            // setButtonSearch(false);
        }
        if ((((!!dateInit && !!dateEnd) || (!!dateInit && newsProcess?.id === 'Retiro')) || (!!timeEnd && !!timeInit)) &&
            (!!gestorName && !!gestorName.label) && (!!newsProcess && !!management) &&
            isValidSmax
        ) {
            dispatch(updateEnableButton(true))
        }
    }, [activeEvent]);

    useEffect(() => {
      if (!requireNumberSmax()) {
        let temporalActiveEvent = { ...activeEvent };
        temporalActiveEvent['caseSmax'] = "";

        const currentManagement = JSON.parse(sessionStorage.getItem("currentManagement"))
        if(!!currentManagement){
            temporalActiveEvent.management = currentManagement;
        }

        dispatch(updateActiveEvent(temporalActiveEvent));
      }
    }, [requireNumberSmax()])
    
    const onChange = (e) => {
        let dataSelect = !!e && e.target ? e.target.value : null;
        let temporalActiveEvent = {
            ...activeEvent,
            gestorName: { id: "gestorName", ...dataSelect },
        };

        dispatch(updateActiveEvent(temporalActiveEvent));
        dispatch(getNewsManager());
    }

    const standardFullName = (name) => {
        let data = name.split(' ')
        let newArai = []
        for (let i = 0; i <= (data.length - 1); i++) {
            if (data[i] !== '') {
                let nameUpperCase = data[i].charAt(0).toUpperCase() + data[i].toUpperCase().slice(1)
                newArai.push(nameUpperCase)
            }
        }
        if (newArai.length > 0) {
            name = newArai.join(' ')
        }
        setManagerDocumenName({
            'name': name
        })

    }
    const redirectEditGroup = () => {
        dispatch( editModeLeader(true) );
        history.push("/leader-productivity/group-settings")
    }


    return (
        <div className='container-max-box'>
            <div className='leaderProd-full-box font-OpenSans-Regular'>
                {loading && <Loading />}
                <div className='leaderProd-tittle'>Novedades administrativas</div>
                <div className='leaderProd-sub-tittle'>Diligencia la información solicitada para reportar una novedad {isMassive ? 'masiva' : 'por usuario'}.</div>
                <div className='leaderProd-form-box position-relative'>
                    {
                        (!isMassive) ?
                            <div className='leaderProd-row mb-9'>
                                <div className='leaderProd-search-user'>
                                    <span className='leaderProd-header-col'>Nombre completo o documento del Gestor</span>
                                    <SearchForListComponent
                                        idInput={"gestorId"}
                                        selectedOption={(!!gestorName && !!gestorName && !!gestorName.label ) ? gestorName.label : null}
                                        onChangeCustomInput={handleInputChange} 
                                        onClickOption={onChange} 
                                        options={getDataStaff}
                                        selectStyle={pruebasaInput}
                                        listStyle = {inputList}
                                        width={'100%'}
                                        placeholder={'Ingresa nombre completo o documento'}
                                        disabled={repoblate}
                                    />
                                    {
                                        toastView &&
                                        <div className={`ttoast`}>
                                            <p className={`font-13 ttoast-${toastView ? toastView : ''}`}>{(toastView === 'error') ?
                                                'El documento debe tener mínimo 3 dígitos' :
                                                (toastView === 'warning') ?
                                                'No se encontraron coincidencias ' : null }
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className='leaderProd-row mb-4'>
                                <div className='group-container-header'>
                                    <div className='group-members-count-container'>
                                        <span className='group-members-count-header'>Usuarios</span>
                                        <span className='badge badge-group-members-count'>{membersCount}</span>
                                    </div>
                                    <h3 className='group-name'>{group}</h3>
                                    <div onClick={redirectEditGroup} className='btn-edit-group'></div>
                                </div>
                            </div>
                    }
                    <div className='leaderProd-row mb-15'>
                        {
                            <>
                                <div className='leaderProd-column'>
                                    <span className='leaderProd-header-col'>Gerencia</span>
                                    <SearchForListComponent
                                        idClick={'management'}
                                        selectedOption={ management?.label }
                                        onClickOption={remarksTex} 
                                        options={ managementList }
                                        selectStyle={inputStyles}
                                        listStyle = {listStyles}
                                        placeholder={'Selecciona'}
                                        showArrow={true}
                                        arrowStyles={styleArrow}
                                        onActiveContent={false}
                                    />
                               </div>
                                <div className='leaderProd-column'>
                                    <span className='leaderProd-header-col'>Novedad</span>
                                    <SearchForListComponent
                                        idClick={'newsProcess'}
                                        selectedOption={ newsProcess?.label }
                                        onClickOption={remarksTex} 
                                        options={ isMassive ? MassiveNews : leaderNewsListControlBoard.sort((x, y) => x.label.localeCompare(y.label)) }
                                        selectStyle={inputStyles}
                                        listStyle = {listStyles}
                                        arrowStyles={styleArrow}
                                        placeholder={'Selecciona'}
                                        showArrow={true}
                                        onActiveContent={false}
                                    />
                            </div>
                            </>
                        }
                    </div>

                    {
                        (!enableHours) ?
                            <>
                                <div className='leaderProd-row mb-15 wt-middle'>
                                    <div className='leaderProd-column leaderProd-flex-col'>
                                        <span className='leaderProd-header-col'>Fecha inicio</span>
                                        <DatePicker
                                            selected={dateInit}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "dateInit", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={dateLowestBound}
                                            maxDate={dateEnd}
                                            customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='leaderProd-select-time leaderProd-input-content' styleArrow='leader-arrow-left leader-arrow-select' />}
                                        />
                                    </div>
                                    <div className='leaderProd-column leaderProd-flex-col'>
                                        <span className='leaderProd-header-col'>Fecha fin</span>
                                        <DatePicker
                                            selected={dateEnd}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "dateEnd", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            dateFormat="dd/MM/yyyy"
                                            disabled={isNewExcluded()}
                                            minDate={endDateLowerBound}
                                            customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='leaderProd-select-time leaderProd-input-content' styleArrow='leader-arrow-left leader-arrow-select' />}
                                        />
                                    </div>
                                </div>
                            </> :
                            <> 
                                <div className='leaderProd-flex-simple-row mb-15'>
                                    <div className='leaderProd-column leaderProd-flex-col'>
                                        <span className='leaderProd-header-col'>Hora inicio</span>
                                        <DatePicker
                                            selected={timeInit}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "timeInit", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            timeFormat="HH:mm"
                                            timeCaption="time"
                                            dateFormat="HH:mm"
                                            customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='leaderProd-select-time leaderProd-input-content' styleArrow='leader-arrow-right leader-arrow-select'  />}
                                            minTime={getDateFormat("00:00:00")}
                                            maxTime={!!timeEnd ? timeEnd : getDateFormat("23:59:00")}
                                            timeIntervals={1}
                                            showTimeSelectOnly
                                            showTimeSelect
                                        />
                                    </div>
                                    <div className='leaderProd-column leaderProd-flex-col gap-26'>
                                        <span className='leaderProd-header-col'>Hora fin</span>
                                        <DatePicker
                                            selected={timeEnd}
                                            onChange={
                                                (e) => handleInputChange({
                                                    target: { name: "timeEnd", value: e }
                                                })}
                                            placeholderText="Selecciona"
                                            timeFormat="HH:mm"
                                            timeCaption="time"
                                            dateFormat="HH:mm"
                                            customInput={<DatePickerCustomInput styleDiv='leader-dateInput' styleInput='leaderProd-select-time leaderProd-input-content' styleArrow='leader-arrow-right leader-arrow-select'  />}
                                            timeIntervals={1}
                                            minTime={!!timeInit ? timeInit : getDateFormat("00:00:00")}
                                            maxTime={getDateFormat("23:59:59")}
                                            showTimeSelectOnly
                                            showTimeSelect
                                        />
                                    </div>
                                    {requireNumberSmax() &&
                                        <div className='leaderProd-flex-col leaderProd_number-case-col'>
                                            <span className='leaderProd-header-col'>Nº caso SMAX</span>
                                            <input 
                                                data-cy="input-caseSmax"
                                                className='leaderProd_number-case-input'
                                                placeholder='Escribe el Nº del caso'
                                                maxLength={10}
                                                type="text"
                                                name="caseSmax"
                                                value={caseSmax}
                                                onChange={remarksTex}/>
                                        </div>
                                    }
                                </div>
                            </>
                    }
                     <div className={`leaderProd-column-big${isMassive ? '-added-margin':''} position-absolute`}>
                        <span className='leaderProd-header-col'>Observaciones</span>
                        <textarea
                            className={`leaderProd-input-box-big${!requireNumberSmax() ? '-bigger' : ''} leaderProd-input-box-option leaderProd-input-content`}
                            placeholder='Escribe las observaciones (máximo 200 caracteres).'
                            maxLength={200}
                            name="observations"
                            value={observations}
                            onChange={remarksTex}
                        ></textarea>
                    </div>
                </div>
                <div className={'leaderProd-row position-relative ' + (isMassive ? 'mg-prodbutton-massive-hide-content' : 'mg-prodbutton-hide-content')}>
                    {
                        <>
                            <button
                                data-cy="btn-leader-productivity"
                                className='leaderProd-button-box leaderProd-button-content'
                                disabled={!enableButton}
                                onClick={handleButtonSave}
                            >
                                Guardar
                            </button>
                            <button
                                data-cy="btn-cancel-leader-productivity"
                                className='leaderProd-button-box leaderProd-button-cancel'
                                onClick={registerLeader ? initialRegisterLeader : handleButtonCancel}
                            >
                                Cancelar
                            </button>
                        </>
                    }
                </div>
            </div>
            <>
                {
                    (!!gestorName?.id || isMassive) &&
                    <div className='container-reported-news'>
                        <div className='reported-news-tittle'>Novedades reportadas</div>
                        <div className='leader-sumze-general-box'>
                            {
                                cards.length === 0 ?
                                    <>
                                        <div className='not-register-empty-cards font-OpenSans-Regular'> No hay novedades registradas <br></br>para este {isMassive ? 'grupo' : 'usuario'}
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            cards.map((item) => <ProductivityCard key={uuid()} role={isMassive?'massive':'leader'} card={item} />)
                                        }

                                    </>
                            }
                        </div>
                    </div>
                }
            </>
        </div>
    )
}

export default LeaderProductivity