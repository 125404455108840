import React, { Fragment, useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './menuDisbursement.css';
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';
import { setEncryptSessionStorage } from '../../bdb-services/GeneralService/globalService';
import { tittleDisbursementManagement } from '../../../constants';
import {loadCasesInOpen} from '../../bdb-actions/analystActions';
import Swal from 'sweetalert2';
import { managements, processesByManagements } from '../../../constants';

const MenuDisbursement = ({history}) => {
    const dispatch = useDispatch();
    const casesOpen = useSelector(state => state.analyst.open);
    const option = useSelector(state => state.select.option);

    const [selectManager, setSelectManager] = useState(undefined);
    const [openProcess, setOpenProcess] = useState('');
    const [enableModal, setEnableModal] = useState(false);

    useEffect(()=>{
        getCasesOpen();
    },[])

    useEffect(() => {
        if(!!enableModal) showModalOpenCase(option,casesOpen)
    }, [openProcess]);
    
    const getCasesOpen = () =>{
        try {
            dispatch(loadCasesInOpen());
        } catch (error) {
            console.log(error)
        }
    }

    const getFinantialAnalyst = async (manag) => {
        setEnableModal(true)
        setSelectManager(manag)
        await setEncryptSessionStorage("management", manag);
        let manager;
        for(let i in managements) if(managements[i]['id'] === manag){
            manager = managements[i]['label']
        } 
        const keys = Object.keys(processesByManagements[manag]);
        let optionsList = {};
        keys.forEach((key) => {
          optionsList[processesByManagements[manag][key]['value']] = manager
        });
        setOpenProcess(optionsList);
    }

    const getFngQuery = () => {
        history.push('/fng');
    }

    const getTrainDocsOCR = () => {
        history.push('/train-docs-ocr');
    }

    const showModalOpenCase = () =>{
        let openCasePorcess = casesOpen[0]?.process;
        if(!openProcess[openCasePorcess] && casesOpen[0]?.process  ){
            let seletBtnCaseManager = selectManager === 'reviewDoc' ? "Revisión Doc" : "Desembolso";
            let openCaseManager = selectManager === 'reviewDoc'   ? "Desembolso" : "Revisión Doc" ;
            Swal.fire({
                icon: 'info',
                html: `<p> Recuerda que es necesario que <b>pauses</b>, <b>apruebes</b> o <b>devuelvas</b> el caso abierto en <b>
                        ${openCaseManager}</b> para que puedas gestionar casos de <b>${seletBtnCaseManager}</b> </p>`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            }).then(result => {
                if (result.isConfirmed) {
                    setEnableModal(false)
                }
              });
        }else{
            history.push('/finantial-analyst');
            
        }
      }

    return ( 
        <Fragment>
            <DailyManagement />
            <h2 className="text-center title-disbursement">{ tittleDisbursementManagement }</h2>

            <div className="row cards-container">
                <div onClick={ () =>getFinantialAnalyst ('groupDisbursement') }>
                    <div  className="card-default card-report-case">
                        <div className="card-text">Gestionar caso Desembolso</div>
                    </div>
                </div>
                <div data-cy = 'reviewDoc' onClick={ () => getFinantialAnalyst('reviewDoc')}>
                    <div className="card-default card-manage-case">
                        <div className="card-text">Gestionar caso Revisión Doc.</div>
                    </div>
                </div>
                <div onClick={ getFngQuery }>
                    <div className="card-default card-reserve-fng">
                        <div className="card-text">Solicitar reserva FNG</div>
                    </div>
                </div>
                <div onClick={ getTrainDocsOCR }>
                    <div className="card-default card-train-docs">
                        <div className="card-text">Clasificación Documental</div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}

export default MenuDisbursement;
