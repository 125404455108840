import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logoBanbogta,logoUpload, loadingIcon } from '../../../assets/icons';
import { updateFileAction, updateFileActionBasic } from '../../bdb-actions/fileActions';
import { logout } from '../../bdb-actions/alertaActions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../LoadingComponent/Loading';
import Swal from 'sweetalert2';
import { cleanOptionSelectedComponent } from '../../bdb-actions/selectActions';
import '../../bdb-views/AnalystView/Analyst.css';
import './Header.scss';
import { b64_to_utf8, decryptUsingAES256,openCasePaused, deleteToken, b64_to_utf8_encrypt } from '../../bdb-services/GeneralService/globalService';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const loading = useSelector( state => state.file.loading );
    const viewLoginDisable = useSelector ( state => state.analyst.viewLoginClose);

    const submitNewFile = file => {
        if (!!file) {
            const validName = validNameFile(file.name);
            if (validName) {
                UpdateUser(file);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Incorrecto',
                    text: 'El nombre o el formato del archivo es incorrecto',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#002c76'
                });
            }
        }
    }

    function optionsRenew() {
        window.location.reload();
        dispatch( cleanOptionSelectedComponent());
    }

    const UpdateUser = file => {
        const fileRender = new FileReader();
        fileRender.readAsDataURL(file);
        fileRender.onload = function () {
            const completeBase64 = (fileRender.result).split(',');
            const base64 = completeBase64[1];
            const MB = base64.length / 1e+6;
            if (MB >= 3.5) {
                const arrayBase64 = chunkString(base64);
                arrayBase64.forEach(element => {
                    dispatch( updateFileAction(
                        {
                            "part": arrayBase64.indexOf(element)+1,
                            "total": arrayBase64.length,
                            "note": "",
                            "fileType": "csv",
                            "fileName": file.name,
                            "arrayBytes": element
                        }
                    ));
                });
            } else {
                dispatch( updateFileActionBasic(
                    {
                        "part": 1,
                        "total": 1,
                        "note": "",
                        "fileType": "csv",
                        "fileName": file.name,
                        "arrayBytes": base64
                    }
                ));
            }
        }
    }

    const validNameFile = nameFile => {
        return nameFile.includes('GPBM') || nameFile.includes('GPOLI') 
            || nameFile.includes('GOPTC') || nameFile.includes('CPCV')
            || nameFile.includes('Demanda');
    }

    function chunkString(str) {
        return str.match(new RegExp('.{1,' + 2300000 + '}', 'g'));
    }

    const closedSession = async () => {
        
        Swal.fire({
          icon: 'info',
          html: '¿Estás seguro de cerrar sesión?',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          confirmButtonColor: '#002c76',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
        }).then( result => {
           
          if(result.isConfirmed){
            if ( sessionStorage.getItem('APP') !==  "1" ) openCasePaused();
            const DocumentNumberAdviserSessionStorage = sessionStorage.getItem('documentNumberAdviser');
            const KeyTokenSessionStorage = sessionStorage.getItem('keyTokenRenew');
            let decodeKeyToken= decryptUsingAES256(b64_to_utf8(KeyTokenSessionStorage), b64_to_utf8(DocumentNumberAdviserSessionStorage), b64_to_utf8(process.env.REACT_APP_AES_KEY) )
            let cryptKeyToken = b64_to_utf8_encrypt(decodeKeyToken);
            deleteToken(cryptKeyToken)
            setTimeout(()=>
                {
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch( logout() );
                }
            ,1000);
          }
        });
      }

    const getNameUser = () => {
        const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
        return decryptUsingAES256(sessionStorage.getItem('username'), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
    }  

    const sylesCSS = {
        logoUp: {
            background: `no-repeat ${logoUpload}`,
            backgroundSize: 'contain',
            height: '14px',
            width: '24px',
            alignSelf: 'center'
        },
        logoLoading: {
            background: `no-repeat ${loadingIcon}`,
            backgroundSize: 'contain',
            height: '24px',
            width: '29px'
        },
        logoBank: {
            background: `no-repeat ${logoBanbogta}`,
            backgroundSize: 'contain',
            height: '33px',
            width: '49px',
            marginLeft: '12%'
        },
        styleNav: {
            backgroundColor: '#0040a8',
            paddingRight: '2%'
        },
        styleFlex: {
            display: 'flex'
        },
        styleInput: {
            opacity: '0.0',
            position: 'absolute',
            filter: 'alpha(opacity=0)',
            width: '40%'
        },
        buttonUpload: {
            backgroundColor: '#ffaf00',
            borderColor: '#ffaf00',
            color: '#002c76' ,
            textTransform: 'initial',
            fontSize:'14px'
        }
    };

    return ( 
        <div className="row content header m-0" style={{position : "fixed", zIndex: "999", width:"100%"}}>
            <div className="col-12">
                <div className="row width-full">
                    <div className="header-col-custom div-link header-mr">
                        <h1>
                            <div className="header-font text-light" onClick={() => optionsRenew()}>
                                <div style={sylesCSS.styleFlex}>
                                    <div style={sylesCSS.logoBank}></div>
                                    <div>Demanda en línea</div>
                                </div>
                            </div> 
                        </h1>
                    </div>
                    {!viewLoginDisable && 
                        <>    
                            { (location.pathname.includes('/finantial-leader') && !global.isGroupVive()) ? 
                            <div hidden={!(location.pathname.includes('/finantial-leader')) || global.isGroupVive() } className="col-xl-2 col-auto div-file pl-0">
                                <div className="btn nuevo-post d-block d-md-inline-block upload-cases-btn" style={sylesCSS.buttonUpload}>
                                    <div style={sylesCSS.styleFlex}>
                                        <div hidden={loading} style={sylesCSS.logoUp}></div>
                                        <div hidden={!loading} style={sylesCSS.logoLoading}></div>
                                        { loading ?  'Subiendo...' : 'Subir archivo'}
                                        <input type="file" style={sylesCSS.styleInput} accept=".csv"
                                        onChange={(e) => {submitNewFile(e.target.files[0])}} disabled={loading}></input>
                                        { loading ? <Loading /> : null}
                                    </div>  
                                </div>
                            </div> : null}
                            <div className='header-col-custom-2 header-div-flex ml-auto'>
                                <div className='header-user-icon header-margins-user'></div>
                                <span className='header-name font-OpenSans-Regular header-margins-user header-username'>
                                <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip className="tooltip-header" id={`tooltip-bottom`}>
                                        {getNameUser()}
                                    </Tooltip>
                                }>
                        
                                <div className="header-username">
                                    {getNameUser()}
                                </div> 
                            </OverlayTrigger>
                                    </span>
                                <span className='header-name header-margins-user'>|</span>
                                <span className='header-close-sesion-icon header-margins-user'></span>
                            </div>
                            <div className="div-logout">
                                <button className="btn btn-link text-light header-close-sesion font-OpenSans-Regular" onClick={closedSession}>Cerrar Sesión</button>
                            </div>

                        </>
                    }
                </div>
            </div>
           
        </div>
     );
}
 
export default Header;