import { OPTION_SELECTED_COMPONENT } from '../bdb-types';

const initialState = {
    option: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case OPTION_SELECTED_COMPONENT:
            return {
                ...state,
                option: action.payload
            }
        default:
            return state;
    }
}