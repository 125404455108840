import React, { Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProductivitySumarize.scss';
import './../ProductivityFormComponent/styles.scss';
import { initCardSumze } from '../../bdb-actions/productivityControlBoardActions';
import ProductivityCard from './ProductivityCard';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import { v4 as uuid } from 'uuid';
import ButtonReportOrder from '../../bdb-views/ReportOrderView/ButtonReportOrder';

const ProductivitySumarize = () => {
    const IS_ADMINISTRATIVE = Boolean(sessionStorage.getItem('isAdministrative') === 'true')
    const dispatch = useDispatch();

    const counters = useSelector(state => state.prodContBoard.counters );
    const cards = useSelector(state => state.prodContBoard.prodCards );
    const loading = useSelector( state => state.prodContBoard.loading );
    const [enableDetails, setEnableDetails] = useState(false);

    const today = new Date().toLocaleString('es-US', {timeZone: 'America/Bogota', year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long"});
    useEffect(() => {
        dispatch(initCardSumze())
    },[])

    const handleClickDetails = (e) => {
        setEnableDetails(!enableDetails);
    }

    return ( 
        <Fragment>
            {loading?<Loading></Loading>:null}
            <div className='prd-sumze-full-box font-OpenSans-Regular'>
                <ButtonReportOrder/>
                <div className='prd-sumze-tittle blue-font'>{`${today[0].toUpperCase()}${today.slice(1).replace(',','')}`}</div>
                <div className='prd-sumze-sub-tittle blue-font'>Horas registradas.</div>
                <div className='flex-row'>
                    <div className={IS_ADMINISTRATIVE ? 'prd-sumze-resume-box-full-width ' : 'prd-sumze-resume-box'}>
                        {counters.times.map((item)=>
                        <Fragment key={uuid()}>
                            <div className='prd-resume-cont'>
                                <span className='font-12'>{item.name}</span>
                                <span className={'font-OpenSans-Bold font-20 font-' + item.color}>
                                    {item.counter.hour}
                                    <span className='font-14'>h</span>
                                    {item.counter.minutes !== 0?
                                        <Fragment>
                                        {item.counter.minutes}
                                        <span className='font-14'>m</span>
                                        </Fragment>:null
                                    }
                                </span>
                            </div>                        
                        </Fragment>)}
                    </div>
                    {
                    !IS_ADMINISTRATIVE && 
                    <div className='prd-sumze-resume-cases-box'>
                        <span className='font-12'>{counters.cases[2].name}</span>
                        <span className='font-OpenSans-Bold font-20 font-black'>{counters.cases[2].counter}</span>
                        <div className={counters.cases[2].counter>0?'arrow':''} tabIndex={0} onClick={ handleClickDetails } onBlur={ () => setEnableDetails(false) }></div>
                        {
                            (enableDetails && counters.cases[2].counter>0) &&
                                <div className='prd-sumze-resume-cases-detail'>
                                    <span className='font-12'>{`${counters.cases[0].name} : ${counters.cases[0].counter}`}</span>
                                    <span className='font-12'>{`${counters.cases[1].name} : ${counters.cases[1].counter}`}</span>
                                    <span className='font-12'>{`${counters.cases[3].name} : ${counters.cases[3].counter}`}</span>
                                </div>
                        }
                    </div>
                    }

                </div>
                <div className='prd-sumze-general-box'>
                    {cards.length === 0 ?
                        <Fragment>
                            <div className='empty-cards font-OpenSans-Regular'>No tienes actividades registradas</div>
                        </Fragment>
                    :
                       cards.map((item) => <ProductivityCard key={uuid()} role='analyst' card={item} />)}
                </div>
            </div>
        </Fragment>
    );
}

export default ProductivitySumarize;
