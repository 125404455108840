import React, {useEffect, useState} from 'react'
import { FngSearch } from '../FngSearch/FngSearch';
import { FngQuery } from '../FngQuery/FngQuery';
import BackLink from "../../../bdb-components/BackLinkComponent/BackLink";

export const FngHome = () => {

    const [ guaranteeInfo, setGuaranteeInfo ] = useState( undefined );

    useEffect( () => {
        setGuaranteeInfo( undefined );
    }, [] );

    return <>
        {
            guaranteeInfo ?
                <>
                    <BackLink url={ '/fng' } callback={ setGuaranteeInfo } />
                    <FngQuery guarantee={ guaranteeInfo } />
                </>
                :
                <>
                    <BackLink url={ '/menu-disbursement' }/>
                    <FngSearch setGuaranteeInfo={ setGuaranteeInfo } />
                </>
        }
    </>

}
