import React, { useEffect } from 'react';
import moment from 'moment';
import {decryptUsingAES256, encryptUsingAES256, b64_to_utf8, getTokenAndKeytoken, putNewToken} from '../../bdb-services/GeneralService/globalService';

const TimeNewToken = (props) => {
    let setTimerNewToken = undefined;
    let timer_2 = process.env.REACT_APP_GAPTIMETOKEN;
    useEffect(()=>{
        if ( !localStorage.getItem('timerNewToken')){
            console.log('No hay localStorage -->', localStorage.getItem('timerNewToken'));
            localStorage.setItem('timerNewToken', moment() );
        }
        newToken();
    },[])

    const newToken = (async () =>{
        setTimerNewToken = setTimeout(async ()=>{
            let InitialTimeToken = localStorage.getItem('timerNewToken');
            const gapTimeToken = moment.duration(moment().diff(moment(InitialTimeToken)))
            if( !props.closeSession ){
                if(gapTimeToken._milliseconds < timer_2){
                    newToken()
                }else {
                    localStorage.setItem('actualiToken', true );
                    const KeyTokenSessionStorage = sessionStorage.getItem('keyTokenRenew');
                    const tokenSessionStorage = sessionStorage.getItem('token');
                    const DocumentNumberAdviserSessionStorage = sessionStorage.getItem('documentNumberAdviser');
                    const keyToken = decryptUsingAES256(b64_to_utf8(KeyTokenSessionStorage), b64_to_utf8(DocumentNumberAdviserSessionStorage) , b64_to_utf8(process.env.REACT_APP_AES_KEY) );
                    const Token = decryptUsingAES256( tokenSessionStorage, b64_to_utf8(DocumentNumberAdviserSessionStorage), b64_to_utf8(process.env.REACT_APP_AES_KEY) );
                    const body = {
                        "oldToken":Token,
                        "key":keyToken
                    }
                    let newToken = await putNewToken(body);
                    if( !!newToken && (newToken.status !== 200) ){
                        console.error( 'key not found' );
                    }else if (newToken && (newToken.status === 200)){
                        let getNewToken = await getTokenAndKeytoken(keyToken);
                        if( !!getNewToken && !!getNewToken.data && !!getNewToken.data.object && !!getNewToken.data.object ){
                            renewToken(getNewToken.data.object);
                        }
                    }
                    await new Promise(resolve=>setTimeout(resolve, 1000))
                    localStorage.setItem('actualiToken', false );
                }
            }
        })
    });

    const renewToken = ((data) => {
        const DocumentNumberAdviserSessionStorage = sessionStorage.getItem('documentNumberAdviser');
        let cryptToken = encryptUsingAES256( data.token , b64_to_utf8(DocumentNumberAdviserSessionStorage) , b64_to_utf8(process.env.REACT_APP_AES_KEY));
        sessionStorage.setItem('token', cryptToken);
        sessionStorage.setItem('tokenRenew', cryptToken);
        localStorage.setItem('timerNewToken', moment() );
        newToken();
    });


    return(<div></div>)

}
export default TimeNewToken;