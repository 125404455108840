export const largeFileListTypes = [
    { id: 'datos_bi', label: 'Datos BI', job: 'job-opf-datos-bi' },
    { id: 'ajust_monet', label: 'Log Ajustes Monetarios', job: 'job-opf-ajust-monet' },
    { id: 'glatarex', label: 'Glatarex', job: 'job-opf-glatarex'},
    { id: 'logs16', label: 'Log Autorizaciones 16', job: 'job-opf-logs16' },
    { id: 'logs17', label: 'Log Autorizaciones 17', job: 'job-opf-logs17' },
    { id: 'logs18', label: 'Log Autorizaciones 18', job: 'job-opf-logs18' },
    { id: 'logs19', label: 'Log Autorizaciones 19', job: 'job-opf-logs19' },
    { id: 'logs21', label: 'Log Autorizaciones 21', job: 'job-opf-logs21' },
    { id: 'logs22', label: 'Log Autorizaciones 22', job: 'job-opf-logs22' },
    { id: 'cartera', label: 'Cartera Consolidada', job: 'job-opf-cartera' },
    { id: 'sis_ctrl_garantias', label: 'Sistema Control de Garantías', job: 'job-opf-sis-ctrl-garantias' },
    { id: 'transacciones', label: 'Transacciones ODS', job: 'job-opf-transacciones' }
]

export const whiteListLargeFiles = [
    "GEDESEM3",
    "GEVIVE1",
    "GEINTER1",
    "GEPROD1",
    "GEPROD2",
    "PALEJO",
    "JHERRE1",
    "DRODRIG",
    "CCARO1",
    "VCORTES",
    "JMOSQUE",
    "JMORE11",
    "IMEDIN2",
    "JPENARE"
]

export const caseStatuses = [ 
    { id: 'APPROVED', label: 'Aprobados' },
    { id: 'REJECT', label: 'Devueltos' },
    { id: 'PAUSED', label: 'Pausados' },
    { id: 'OPEN', label: 'Asignados' },
    { id: 'PENDING', label: 'Pendientes' },
];

export const statusCaseUnit = {
    'PENDING': 'Pendiente',
    'OPEN': 'Abierto',
    'REJECT': 'Devuelto',
    'APPROVED': 'Aprobado',
    'PAUSED': 'Pausado',
    'ABORT': 'Rechazado'
}

export const friendlyStatus = {
    "PENDING":"Enviado",
    "OPEN":"En trámite",
    "APPROVED":"Aprobado",
    "REJECT":"Devuelto",
    "PAUSED":"En trámite",
    "DEFAULT":"Enviado"
};

export const enableRol = ["LiderProductividad", "GestorProductividad"]
export const validationSearchCaseNumber = ["groupDisbursement", "groupVive", "housing"];

export const emptyStatus = {"PENDING":0,"APPROVED":0,"OPEN":0,"REJECT":0,"PAUSED":0};

export const idmAdminRol = ['LideresRevisionDocumental', 'LideresDesembolsoLib', 'LideresDesembolsoV', 'LideresDesembolsos', 'LideresVive'];

export const leaderRolManagement = [ 'LideresDesembolsos', 'LideresVive', 'LideresDesembolsoV'];

export const idmAnalystRol = ['VerificadorRevisionDocumental', 'VerificadorLibranzasDesembolso', 'VerificadorViviendaDesembolso', 'GestoresDesembolsos', 'GestoresVive'];

export const idmAnalystProdRol = [
    'GestorProductividad',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_NORMALIZACION',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_INTERNACIONAL',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_DNO',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_TRANSACCIONAL',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_CANALVIRTUAL',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_CANALFISICO',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_SOST',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_DIGITALIZACION'
];

export const idmLeaderProdRol = [
    'LiderProductividad', 'LíderProductividad',
    'LìderProductividad',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_INTERNACIONAL',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_DNO',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_TRANSACCIONAL',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_CANALVIRTUAL',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_CANALFISICO',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_SOST',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_DIGITALIZACION'
    ];

export const idmRootAdminProd = [
    'AdminProductividad'
]

export const idmAdminProd = [
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_INTERNACIONAL',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_TRANSACCIONAL',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_CANALVIRTUAL',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_CANALFISICO',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_ACTIVO',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_COE',
     'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_PROY'
    ];

export const idmReporterFng = ['GestorReporteFNG'];

export const arrayInconsistency = ['Fallas de Sistema', 'Inconveniente Conexión Funcionario', 'Sin Permisos para laborar']

export const verticalMapper = {
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_INTERNACIONAL':'Gerencia Inter de Gestión Mercado de Capitales',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_DNO':'Dirección Nacional de Operaciones',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_TRANSACCIONAL':'Dirección del Pasivo y Transaccional',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_CANALVIRTUAL':'Gerencia Operativa de Canales Virtuales',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_CANALFISICO':'Gerencia Operativa de Canales Físicos',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_SOST':'Jef. Sistemas de Gest. y Sost. de Procesos Operativos',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_INTERNACIONAL':'Gerencia Inter de Gestión Mercado de Capitales',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_DNO':'Dirección Nacional de Operaciones',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_TRANSACCIONAL':'Dirección del Pasivo y Transaccional',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_CANALVIRTUAL':'Gerencia Operativa de Canales Virtuales',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_CANALFISICO':'Gerencia Operativa de Canales Físicos',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_SOST':'Jef. Sistemas de Gest. y Sost. de Procesos Operativos',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_INTERNACIONAL':'Gerencia Inter de Gestión Mercado de Capitales',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_TRANSACCIONAL':'Dirección del Pasivo y Transaccional',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_CANALVIRTUAL':'Gerencia Operativa de Canales Virtuales',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_CANALFISICO':'Gerencia Operativa de Canales Físicos',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_ACTIVO':'Dirección del Activo',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_COE': 'COE de procesos',
    'USUARIOS_APL_EXP_COMERCIAL_ADM_PROD_PROY': 'Gerencia de proyectos',
    'USUARIOS_APL_EXP_COMERCIAL_GEST_PROD_DIGITALIZACION':'Proyecto de Transformación Digital',
    'USUARIOS_APL_EXP_COMERCIAL_LID_PROD_DIGITALIZACION':'Proyecto de Transformación Digital',
    'default':'Dirección del Activo'
}
export const verticals = [ 
    { id: 'Dirección del Activo', label: 'Dirección del Activo' },
    { id: 'Dirección del Pasivo y Transaccional', label: 'Dirección del Pasivo y Transaccional' },
    { id: 'Dirección Nacional de Operaciones', label: 'Dirección Nacional de Operaciones' },
    { id: 'Gerencia Inter de Gestión Mercado de Capitales', label: 'Gerencia Inter de Gestión Mercado de Capitales' },
    { id: 'Gerencia Operativa de Canales Físicos', label:'Gerencia Operativa de Canales Físicos'},
    { id: 'Gerencia Operativa de Canales Virtuales', label:'Gerencia Operativa de Canales Virtuales'},
    { id: 'Jef. Sistemas de Gest. y Sost. de Procesos Operativos', label:'Jef. Sistemas de Gest. y Sost. de Procesos Operativos'},
    { id: 'COE de procesos', label:'COE de procesos'},
    { id: 'Gerencia de proyectos', label:'Gerencia de proyectos'}
    
];
export const managements = [ 
    { id: 'massiveBanking', label: 'Banca Masiva' },
    { id: 'housing', label: 'Vivienda' },
    { id: 'librances', label: 'Libranzas' },
    { id: 'groupDisbursement', label: 'Gestionar caso Desembolso' },
    { id: 'groupVive', label:'Vehículo'},
    { id: 'fng', label:'Fng'},
    { id: 'reviewDoc', label:'Gestionar caso Revisión Documental'}
];
export const tittleDisbursementManagement = "Desembolso Centralizado";

export const processes = [
    { id: 'disbursement', label: 'Desembolso'},
    { id: 'iris10', label: 'Iris10'},
    { id: 'reviewDoc', label: 'Revisión Doc'},
    { id: 'reviewDocPn', label: 'Revisión doc. PN'},
    { id: 'reviewDocPj', label: 'Revisión doc. PJ'},
    { id: 'propertyAnalysis', label: 'Análisis inmueble'},
    { id: 'capture', label: 'Captura - vivienda'},
    { id: 'housingDisbursement', label: 'Vivienda - Desembolso'},
    { id: 'traders', label: 'Tradentes'},
    { id: 'vobo', label: 'VoBo Pagaduría'},
    { id: 'retentionReview', label: 'Revisión Retención'},
    { id: 'preDisbursement', label: 'Predesembolso'},
    { id: 'reviewDisbursement', label: 'Rev. Desembolso.'},
    { id: 'processDisbursement', label: 'Proc. Desembolso'},
    { id: 'disbursementOrder', label: 'Orden de Desembolsos'},
    { id: 'consumer', label: 'Consumo'},
    { id: 'draft', label: 'Libranza'},
    { id: 'disbursement_FlowWithApproval', label:'Flujo único con VoBo'},
    { id: 'disbursement_FlowWithoutApproval', label:'Flujo único sin VoBo'},
    { id: 'disbursement_Retention', label:'Retención'},
    { id: 'disbursement_NaturalMarket', label:'Mercado Natural'},
    { id: 'disbursement_PreApproved', label:'Preaprobados'},
    { id:'hqrunt', label:'HQRUNT'},
    { id:'fngReserves', label:'Reservas FNG'},
    { id:'viveDisbursement', label:'Desembolso - VIVE'},
    { id:'commercialDisbursement', label:'Desembolso Comercial'},
    { id:'voboPagaduria', label:'Libranza VoBo Pagaduría'}
];
export const subProcesses = [
    { id: 'disbursement_FlowWithApproval', label:'Flujo único con VoBo', initPriorization: 'preDisbursementGpoli' },
    { id: 'disbursement_FlowWithoutApproval', label:'Flujo único sin VoBo', initPriorization: 'preDisbursementGpoli' },
    { id: 'disbursement_Retention', label:'Retención', initPriorization: 'preDisbursementGpoli' },
    { id: 'disbursement_NaturalMarket', label:'Mercado Natural', initPriorization: 'VoBo' },
    { id: 'disbursement_PreApproved', label:'Preaprobados', initPriorization: 'VoBo' }
]
export const processToTransfer = ["Desembolso Flujo único con VoBo Pagaduría","Predesembolso","VoBo Pagaduría"];

export const processesByManagements = {
    massiveBanking: {
        reviewDocPn: { value: 'Revisión doc. PN', initPriorization: 'docReview'},
        reviewDocPj: { value: 'Revisión doc. PJ', initPriorization: 'massiveBankDisbmt'}
    },
    reviewDoc: {
        reviewDocPn: { value: 'Revisión doc. PN', initPriorization: 'docReview'},
        reviewDocPj: { value: 'Revisión doc. PJ', initPriorization: 'massiveBankDisbmt'},
        disbursementOrder: { value: 'Orden de Desembolsos', initPriorization: 'disbursementOrder' },
        capture: { value: 'Captura - vivienda', initPriorization: 'housingCaptur'},
        commercialDisbursement: {value: 'Desembolso Comercial', initPriorization: 'disbursementStage'}
    },
    housing: {
        propertyAnalysis: { value: 'Análisis inmueble', initPriorization: 'housingAnalysisProperty'},
        traders: { value: 'Tradentes', initPriorization: 'housingTradents'}
    },
    librances: {
        preDisbursement: { value: 'Predesembolso', initPriorization: 'preDisbursementGpoli'},
        vobo: { value: 'Visto Bueno Pagaduría', initPriorization: 'VoBo'},
        retentionReview: { value: 'Revisión Retención', initPriorization: 'preDisbursementGpoli'},
        disbursement: { value: 'Desembolso', initPriorization: ''},
        disbursement_FlowWithApproval: { value: 'Flujo único con VoBo', initPriorization: 'preDisbursementGpoli'},
        disbursement_FlowWithoutApproval: { value: 'Flujo único sin VoBo', initPriorization: 'preDisbursementGpoli'},
        disbursement_Retention: { value: 'Retención', initPriorization: 'preDisbursementGpoli'},
        disbursement_NaturalMarket: { value: 'Mercado Natural', initPriorization: 'VoBo'},
        disbursement_PreApproved: { value: 'Preaprobados', initPriorization: 'VoBo'},
    },
    groupDisbursement: {
        consumer: { value: 'Consumo', initPriorization: 'disbursementStage'},
        draft: { value: 'Libranza', initPriorization: 'disbursementStage'},
        housingDisbursement: { value: 'Vivienda - Desembolso', initPriorization: 'housingDisbursement'},
        vobo: { value: 'VoBo Pagaduría', initPriorization: 'disbursementStage'},
        viveDisbursement: { value: 'Desembolso - VIVE', initPriorization: 'vehicleHqrunt'},
        voboPagaduria: { value: 'Libranza VoBo Pagaduría', initPriorization: 'disbursementStage'},
    },
    groupVive: {
        hqrunt: { value: 'HQRUNT', initPriorization: 'vehicleHqrunt' },
    },
}
// si se hace un modificacion en estas constantes se debe hacer en la reques-factory constants MNGMNT_AREA_PROCESS_CTRLBRD
export const mngmntAreaProcessCtrlBrd = {
    reviewDoc: {
        reviewDocPn: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Originación PN", process:"Persona Natural - Revisión Documental"},
        reviewDocPj: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Originación PJ", process:"Persona Jurídica - Rev. Doc. F Único"},
        disbursementOrder: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Pre Desembolso", process:"Revision Orden desembolso" },
        capture: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Pre Desembolso", process:"Revision Vivienda"}, 
        commercialDisbursement: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Pre Desembolso", process:"Revisión Flujo Mantiz"},
    },
    housing: {
        propertyAnalysis: { management:"Gcia. Op. Garantías y Leasing", area:"Análisis Del Inmueble", process:"Revisión Avalúo Y Estudio De Títulos  / Revisión Avalúo Y Estudio De Títulos  LH"},
        traders: { management:"Gcia. Op. Garantías y Leasing", area:"Predesembolso", process:"Tradentes"}
    },
    librances: {
        preDisbursement: {management:"Gcia. Op. Desembolsos Integrales", area:"Desembolso Comercial", process:"PREDESEMBOLSO"},
        vobo: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        disbursement: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        disbursement_FlowWithApproval: {management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        disbursement_FlowWithoutApproval: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Sin VoBo"},
        disbursement_Retention: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        disbursement_NaturalMarket: {management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        disbursement_PreApproved: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"},
        retentionReview: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo"}
    },
    groupDisbursement: {
        consumer: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso consumo" },
        draft: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo" },
        vobo: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"VoBo Pagaduría - Libranzas" },
        housingDisbursement: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Tradicional", process:"Desembolso Vivienda"},
        viveDisbursement: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Tradicional", process:"Desembolso Vehiculo" },
        reviewDocPn: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Originación PN", process:"Persona Natural - Revisión Documental"},
        reviewDocPj: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Originación PJ", process:"Persona Jurídica - Rev. Doc. F Único"},
        disbursementOrder: { management:"Gcia. Op. Desembolsos Integrales", area:"Revisión Documental Pre Desembolso", process:"Revision Orden desembolso" },
        capture: { management:"Gcia. Op. Desembolsos Integrales", area:"Vivienda", process:"Captura Crédito Directo"}, 
        commercialDisbursement: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolso Comercial", process:"Revisión Mantiz"},
        voboPagaduria: { management:"Gcia. Op. Desembolsos Integrales", area:"Desembolsos Línea Express", process:"Desembolso Con VoBo" },
    },
    groupVive: {
        hqrunt: { management:"Gcia. Op. Garantías y Leasing", area:"HQRUNT", process:"Pre - inscripción HQRUNT" }
    }
}

export const VoBoCentralizedVector = { code: '', name: '', active: true };
export const VoBoPaymentVector = {code: '', name: '',  firstUser: '', secondUser: ''};
export const PausedReasonVector = { pausedReason: '' };
export const RejectReasonVector = { rejectReason: '' };
export const AbortReasonVector = { abortReason: '' };

export const processesByPriorities = {
    massiveBanking: {
    },
    housing: {
        housingAnalysisProperty: 'Análisis inmueble',
        housingCaptur: 'Captura',
        housingDisbursement: 'Desembolso'
    },
    librances: {
    },
    groupDisbursement: {
    }
}

export const mountsById = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
}

export const optionsCenterDisbursemet = [
    { id: 0, label: 'FNG', value: 'fng' },
    { id: 1, label: 'Línea', value: 'line'},
    { id: 2, label: 'Monto', value: 'amount' }
]

export const optionsLine = [
    { id: 'COM', label: 'Comercial', value: 'comercial' },
    { id: 'CSM', label: 'Consumo', value: 'intake'}
];

export const optionsLineDefault = [{ title: 'line', items: ['Comercial', 'Consumo', 'Otros']}];

export const itemsDefault = {
    "P1Amount": false, "P1FNG": false, "P1Line": true, "P1LineP2Amount": false,
    "P1LineP2FNG": false,"Default": true,"optionsLine": { "CSM": 1, "COM": 2, "Otros": 3 }
};

export const productsList = [
    {id: 'ordinaryWallet', label: 'Cartera Ordinaria'},
    {id: 'subsPremiumLiabilities',label: 'Sustitución de Pasivos Premium'},
    {id: 'microfinance', label: 'Microfinanzas'},
]

export const processList = [
    {id: 'edecan', label: 'Edecan'},
    {id: 'reviewDoc', label: 'Revisión Documental'},
    {id: 'disbursement', label: 'Desembolso'},
    {id: 'consumer', label: 'Consumo'},
    {id: 'draft', label: 'Libranza'},
    {id: 'ReserveFNG', label: 'Reserva FNG'}
]

export const subProcessList = [
    {id: 'VerificationGuarantees', label: 'Verificación de Garantías'},
    {id: 'reservationVerification', label: 'Verificación de Reserva'},
    {id: 'disbursementProcess', label: 'Proceso de Desembolso'}   
]

export const gapList = [
    {id: '30M', label: '30 Minutos'},
    {id: '1H',label: '1 Hora'},
    {id: '2H', label: '2 Horas'},
    {id: '3H', label: '3 Horas'},
]

export const STATE_ACCEPTED = 'Aceptado';
export const STATE_PAUSED = 'Pausado';

export const wrongStateList = [
    {id: 'returned', label: 'Devuelto'},
    {id: 'returnedReviewer',label: 'Devuelto al revisor'},
    {id: 'returnedCommercial', label: 'Devuelto al comercial'},
]

export const processBtnOpcionsList = {
    ordinaryWallet: {
        edecan: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedReviser', label: 'Devuelto al Revisor', state: 'Devuelto al Revisor'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        reviewDoc: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        ReserveFNG: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'Returned', label: 'Devuelto', state: 'Devuelto'},
        ],
        disbursement: {
            VerificationGuarantees: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
                {id: 'returnedReviser', label: 'Devuelto al Revisor', state: 'Devuelto al Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ],
            reservationVerification: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
                {id: 'returnedReviser', label: 'Devuelto Revisor', state: 'Devuelto Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ],
            disbursementProcess: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'disbursed', label: 'Desembolsado', state: 'disbursed'},
                {id: 'returnedReviser', label: 'Devuelto Revisor', state: 'Devuelto Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ]
        }
    },
    subsPremiumLiabilities: {
        edecan: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedReviser', label: 'Devuelto al Revisor', state: 'Devuelto al Revisor'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        reviewDoc: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        ReserveFNG: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
        ],
        disbursement: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'disbursed', label: 'Desembolsado', state: 'disbursed'},
            {id: 'returned', label: 'Devuelto', state: 'Devuelto'}
        ],
    },
    microfinance: {
        edecan: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedReviser', label: 'Devuelto al Revisor', state: 'Devuelto al Revisor'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        reviewDoc: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
            {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
        ],
        ReserveFNG: [
            {id: 'pause', label: 'Pausado', state: 'Pausado'},
            {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
        ],
        disbursement: {
            VerificationGuarantees: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
                {id: 'returnedReviser', label: 'Devuelto Revisor', state: 'Devuelto Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ],
            reservationVerification: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'accepted', label: 'Aceptado', state: 'Aceptado'},
                {id: 'returnedReviser', label: 'Devuelto Revisor', state: 'Devuelto Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ],
            disbursementProcess: [
                {id: 'pause', label: 'Pausado', state: 'Pausado'},
                {id: 'disbursed', label: 'Desembolsado', state: 'disbursed'},
                {id: 'returnedReviser', label: 'Devuelto Revisor', state: 'Devuelto Revisor'},
                {id: 'returnedCommercial', label: 'Devuelto Comercial', state: 'Devuelto Comercial'}
            ]
        }
    }
}
export const disbursementPreaproved = 'Desembolso Preaprobados';

export const STATUS_RESERVE_APPROVED = 'APPROVED';
export const STATUS_RESERVE_SUCCESS = 'SUCCESS';
export const STATUS_RESERVE_REJECTED = 'REJECTED';
export const STATUS_RESERVE_REJECT = 'REJECT';
export const STATUS_RESERVE_REPLACED = 'REPLACED';
export const HAS_NO_RECORD = 'NOT_REGISTER';
export const ABSENCE = ['Ausentismos','Otras actividades','Inconveniente Conexión Funcionario', 'Sin Permisos para laborar','Fallas de Sistema'];

export const managementListControlBoard = [
    { id: "Gcia. Op. Cartera", label: "Gcia. Op. Cartera"},
    { id: "Gcia. Op. Desembolsos Integrales", label: "Gcia. Op. Desembolsos Int..."},
    { id: "Gcia. Op. Tarjetas", label: "Gcia. Op. Tarjetas"},
    { id: "Gcia. Op. Garantías y Leasing", label: "Gcia. Op. Garantías y Leasing"}
]

export const managementListComplete = [
    ...managementListControlBoard,
    { id: "Gcia Implementacion De Proyectos", label: "Gcia Implementacion De Proyectos"},     
    { id: "Gcia Digitalizacion", label: "Gcia Digitalizacion"},     
    { id: "Dir Op.Productos Del Activo", label: "Dir Op.Productos Del Activo"},     
];

export const disbursementDocument = [
    { id: "Documento de identificación", label: "Documento de identificación"},
    { id: "Cámara de comercio", label: "Camara de comercio"},
    { id: "Pagaré", label: "Pagaré"},
    { id: "Tabla de tasas o princing", label: "Tabla de tasas o princing"},
    { id: "Anexo 2", label: "Anexo 2"}
]

export const SYSTEM_FAILURE_NEWS = [
    { id: "Caída de aplicativos", label: "Caída de aplicativos"},
    { id: "Conexión del banco", label: "Conexión del banco"},
    { id: "Configuraciones de equipo", label: "Configuraciones de equipo"},
    { id: "Falla técnica o tecnológica funcionario", label: "Falla técnica o tecnológica funcionario"},
    { id: "Lentitud en aplicativos", label: "Lentitud en aplicativos"},
    { id: "Problemas técnicos con el equipo", label: "Problemas técnicos con el equipo"},
    { id: "Sin permiso a aplicaciones", label: "Sin permiso a aplicaciones"}
]
export const CONNECTION_FAILURE_NEWS = [
    { id: "Falla Internet Funcionario", label: "Falla Internet Funcionario"},
    { id: "Lentitud en equipo del banco", label: "Lentitud en equipo del banco"},
    { id: "Bloqueo en equipo del banco", label: "Bloqueo en equipo del banco"},
    { id: "Daño en equipo", label: "Daño en equipo"},
    { id: "Sin luz", label: "Sin luz"},
    { id: "Reintengro Labores", label:"Reintengro Labores"}
]

export const leaderNewsListControlBoard = [
    { id: "Actividades culturales", label: "Actividades culturales"},
    { id: "Calamidad", label:"Calamidad"},
    { id: "Incapacidad", label:"Incapacidad"},
    { id: "Licencia", label: "Licencia"},
    { id: "Permiso", label:"Permiso"},
    { id: "Permiso Sindical", label:"Permiso Sindical"},
    { id: "Vacaciones", label:"Vacaciones"},
    ...SYSTEM_FAILURE_NEWS
]

export const MassiveNews = [
    { id: "Caída de aplicativos", label: "Caída de aplicativos"},
    { id: "Falla técnica o tecnológica funcionario", label: "Falla técnica o tecnológica funcionario"},
]

export const newsByHoursWithoutMassives = [
    ...leaderNewsListControlBoard.filter((e) => (e.id === 'Permiso' || e.id === 'Actividades culturales')),
    ...SYSTEM_FAILURE_NEWS,
    ...CONNECTION_FAILURE_NEWS
]
export const newsByHours = [
    ...MassiveNews,
    ...newsByHoursWithoutMassives
]
export const excludeDateEndNews = ["Retiro"];

export const columnsTableFng = [
    { id: 'SUCCESS', label: 'Aprobadas' },
    { id: 'REJECT', label: 'Rechazadas' },
    { id: 'REPLACED', label: 'Sustituídas' }
];

export const columnsFngReport = [
    "FECHA DE SOLICITUD",
    "N°GARANTÍA.SCG",
    "SUCURSAL",
    "NOMBRE SOLICITANTE",
    "TIPO ID",
    "NIT/CC SOL",
    "MUNICIPIO",
    "VALOR GARANTIA",
    "MONEDA",
    "DESTINO",
    "PRODUCTO FNG",
    "COBERTURA",
    "CIIU",
    "NIVEL DE VENTAS",
    "TIPO DE COMISIÓN",
    "TIPO DE CARTERA",
    "CALIFICACIÓN",
    "FECHA FISCAL",
    "RESERVA",
    "CODIGO RECHAZO",
    "NOMBRE DEL RECHAZO"
];
export const columnsProcessReport =
[   "Vertical",
    "Gerencia",
    "Área",
    "Proceso",
    "Campo Monetario",
    "Campo automático",
    "Proceso Masivo",
    "Meta diaria"
];
export const columnsStaffReport =
[   
    "Cod. Posición",
    "Número de identificación",
    "Vertical",
    "Gerencia",
    "Área",
    "Proceso",
    "Rol",
    "Nombre completo"
];
export const widthsColumnsExcelFile = [
    { wch: 17},
    { wch: 14},
    { wch: 9},
    { wch: 18},
    { wch: 6},
    { wch: 9},
    { wch: 10},
    { wch: 15},
    { wch: 8},
    { wch: 10},
    { wch: 13},
    { wch: 10},
    { wch: 10},
    { wch: 14},
    { wch: 15},
    { wch: 14},
    { wch: 11},
    { wch: 11},
    { wch: 10},
    { wch: 14},
    { wch: 18},
];
export const widthsColumnsProcessFile = [
    { wch: 47},
    { wch: 29},
    { wch: 58},
    { wch: 16},
    { wch: 16},
    { wch: 16},
    { wch: 16}
];
export const widthsColumnsStaffFile = [
    { wch: 16},
    { wch: 20},
    { wch: 50},
    { wch: 50},
    { wch: 50},
    { wch: 50},
    { wch: 40},
    { wch: 50}
];
export const excelDocumentsOrderDisbursment = [
    "Archivo plano",
    "Orden de desembolso"  
];
export const typesDocuments = {
     'C.C.':'C' ,
     'C.E.':'E' ,
     'T.I.':'T' ,
     'PAS.':'P',
     'R.C.':'R',
     'NIT. PN.':'L',
     'NIT.':'N',
     'NIT. EXT.':'NE'
}

export const translateStatusStaff = {
    'StaffUser':{
        'FILLED': 'Ingresos',
        'CHANGED': 'Reemplazo',
        'REMOVED': 'Retiros',
        'NEW': 'Nueva posición',
        'DUPLICATED': 'Código duplicado'
    },
    'ProcessConfig':{
        'CHANGED': 'Modificados',
        'REMOVED': 'Eliminados',
        'NEW': 'Nuevos'
    }

};
export const genericRole = 'genericRole';
//ORDER CONSTANTS
export const productsDocuments = [
    {"id": "BANCOLDEX", "label": "Bancoldex"},
    {"id": "CC02", "label": "Cartas Cupo"},
    {"id": "38", "label": "Cartera Refinanciada"},
    {"id": "CC01", "label": "Crediservice Comercial"},
    {"id": "CF01", "label": "Crediporvenir Fija"},
    {"id": "CV01", "label": "Crediporvenir Variable"},
    {"id": "DS01", "label": "Desarrollo Sostenible"},
    {"id": "FINAGRO", "label": "Finagro"},
    {"id": "FINDETER", "label": "Findeter"},
    {"id": "GB01", "label": "Garantías Bancarias"},
    {"id": "LIQUIDEZ", "label": "Liquidez"},
    {"id": "MICROCREDITO", "label": "Microcrédito"},
    {"id": "571", "label": "Rotativo Plus"},
    {"id": "570", "label": "Rotativo Pyme"},
    {"id": "01", "label": "Ordinaria"},
    {"id": "STIF01", "label": "Sustitución de Inversión Forzosa"},
    {"id": "100", "label": "Sustitución de Pasivos Comercial"}
]
export const typeIdentification = [
    {'id' : 'C', 'label' : 'Cédula de ciudadanía'},
    {'id' : 'E', 'label' : 'Cédula de extranjería'},
    {'id' : 'P', 'label' : 'Pasaporte'},
    {'id' : 'N', 'label' : 'NIT'}

]

export const othersItem = {
    "documentType": "IC - Orden de Desembolso",
    "friendlyName": "Otros (si aplica)",
    "checkExpeditionDate": false,
    "nameForDb": "Otros (si aplica)",
    "index": 0
}

export const documentsWithXls = [
    'Orden de desembolso',
    'Archivo plano'
]
export const documentsChangeUpload = [
    {
        "documentType": "IC - Certificado de Existencia y Representacion Legal",
        "friendlyName": "Certificado de existencia y representación legal",
        "checkExpeditionDate": false
    },
    {
        "documentType": "IC - Acta Posesion - Alcalde",
        "friendlyName": "Acta de posesión del alcalde",
        "checkExpeditionDate": false
    },
]

export const initFormValues = {
    productObj : '',
    identiCustomer : '',
    typeCustomerObj : '',
    numberControl : '',
    creditProject : ''
}

export const elasticRoleField = 'cnUserRol';

export const kardexHeaders = [
    {
        id: 'id',
        name: 'Proceso',
        width: '30%',
        isInput: false
    },
    {
        id: 'managed',
        name: 'Gestionado',
        width: '15%',
        isInput: true
    },
    {
        id: 'pendingInsideAns',
        name: 'Pend. dentro ANS',
        width: '20%',
        isInput: true
    },
    {
        id: 'pendingOutsideAns',
        name: 'Pend. fuera ANS',
        width: '20%',
        isInput: true
    },
    {
        id: 'totalCount',
        name: 'Total recibido',
        width: '20%',
        isInput: true
    },
    {
        id: 'comments',
        name: 'Observaciones',
        width: '30%',
        isInput: true
    },
]

export const excludedKardexAreas = [
    "Ausentismos",
    "Otras actividades",
    "Fallas de Sistema",
    "Inconveniente Conexión Funcionario",
    "Sin Permisos para laborar"
]

export const OCRAnalizeViewRol = [
    "GEINTER1",
    "GEDESEM3"
]

export const documentTrainDataRol = [
    "GEPROD2",
    "JOSOR28",
    "AMACHA1",
    "AVILLAL",
    "AMOREN22",
    "YAVENDA",
    "AAREVA1",
    "KMORAL9",
    "CALDANA",
    "JLONDO3",
    "YPENAL1",
    "RLOPEZ6",
    "CCUELLA",
    "GOROZC1",
    "ASUAR12",
    "JSANC19",
    "JLOMBAN",
    "HBELTR1",
    "LGOMEZ9",
    "SRODRI4",
    "SARIZA",
    "AMONTA7",
    "FMABAM",
    "LPACHO1",
    "DPARR25",
    "MCALDE2",
    "SCANON2",
    "JAGUDE5",
    "JRIVE10",
    "EBELLO1",
    "LRINCO2",
    "HLOPEZ2",
    "DROJAS",
    "JGONZAL9",
    "RMENDE1",
    "VPEREZ1",
    "KTORRE2",
    "JLEIVA2",
    "FCARRIL",
    "JPINIL3",
    "OCARDE3",
    "YDELGAD",
    "NSIERRA",
    "AESPIN3",
    "DRODR21",
    "DANGAR1",
    "YSARMIE",
    "DVILL13",
    "MPINZO3",
    "ARUIZ",
    "EMEDINA",
    "HLOPEZ1",
    "OFLORE1",
    "KMOREN1",
    "KROSERO",
    "NFERNA1",
    "CPERDO1",
    "AVARGA8",
    "AHILAR1",
    "DFANDIN",
    "MVEGA4",
    "AJIME22",
    "LCAMAR1",
    "ACONTR8",
    "LHERAZO",
    "VMORAL3",
    "ASUAR12",
    "DMARTI1",
    "FALVAR1"
]

export const trainDocsOCRKeys = {
    name:
    {
        label: 'Nombres (incluye empresas)',
        category: 'Datos Generales'
    },
    lastName:
    {
        label: 'Apellidos',
        category: 'Datos Generales'
    },
    numberId:
    {
        label: 'Numero Identificacion (Incluye NIT)',
        category: 'Datos Generales'
    },
    date:
    {
        label: 'Fecha',
        category: 'Datos Generales'
    },
    place:
    {
        label: 'Ciudad/Lugar',
        category: 'Datos Generales'
    },
    physicalAddress:
    {
        label: 'Dirección Física',
        category: 'Datos Generales'
    },
    productType:
    {
        label: 'Tipo de Producto Financiero',
        category: 'Datos Generales'
    },
    rate:
    {
        label: 'Tasa',
        category: 'Numericos'
    },
    currency:
    {
        label: 'Valor Monetario/Monto',
        category: 'Numericos'
    },
    installments:
    {
        label: 'Cuotas/Plazo',
        category: 'Numericos'
    },
    accountNnumber:
    {
        label: 'Número de Cuenta',
        category: 'Numericos'
    },
    phoneNumber:
    {
        label: 'Telefono',
        category: 'Numericos'
    },
    booleanField:
    {
        label: 'Si/No - Verdadero/Falso',
        category: 'Otros'
    },
    logoBussines:
    {
        label: 'Logo de empresa',
        category: 'Otros'
    },
    codeOffice:
    {
        label: 'Código de Oficina',
        category: 'Otros'
    },
    economicActivity:
    {
        label: 'Actividad Económica (Código)',
        category: 'Otros'
    },
    codeBarNumber:
    {
        label: 'No. Código de Barras',
        category: 'Otros'
    }
}

export const onBaseTypes = [

    { id: 'IC - Documento de identificacion (DI)', label: 'IC - Documento de identificacion (DI)' },
    { id: 'IC - Solicitud (SSF)', label: 'IC - Solicitud (SSF)' },
    { id: 'IC - Declaracion de Renta (DR)', label: 'IC - Declaracion de Renta (DR)' },
    { id: 'IC - Certificado Laboral (CL)', label: 'IC - Certificado Laboral (CL)' },
    { id: 'IC - Camara de Comercio', label: 'IC - Camara de Comercio' },
    { id: 'IC - Extractos Bancarios', label: 'IC - Extractos Bancarios' },
    { id: 'IC - Certificado de Ingresos y Retenciones (CIR)', label: 'IC - Certificado de Ingresos y Retenciones (CIR)' },
    { id: 'EC - Poliza de Seguro', label: 'EC - Poliza de Seguro' },
    { id: 'IC - Garantias', label: 'IC - Garantias' },
    { id: 'EC - Informe de Visita', label: 'EC - Informe de Visita' },
    { id: 'IC - RUT', label: 'IC - RUT' },
    { id: 'IC - Formato de aseguramiento', label: 'IC - Formato de aseguramiento' },

    { id: 'IC - Facturas', label: 'IC - Facturas' },
    { id: 'IC - Referencias Comerciales', label: 'IC - Referencias Comerciales' },

    { id: 'IC - Honorarios y Comisiones', label: 'IC - Honorarios y Comisiones' },
    { id: 'IC - Certificado de Libertad y Tradicion Inmuebles', label: 'IC - Certificado de Libertad y Tradicion Inmuebles' },
    { id: 'IC - Cartas de Afiliacion Vehiculos (CAV)', label: 'IC - Cartas de Afiliacion Vehiculos (CAV)' },
    { id: 'IC - Certificacion Ganaderos', label: 'IC - Certificacion Ganaderos' },

    { id: 'IC - Comprobante Nomina', label: 'IC - Comprobante Nomina' },
    { id: 'IC - Autorizacion Cliente', label: 'IC - Autorizacion Cliente' },

    { id: 'IC - Acuse Recibo', label: 'IC - Acuse Recibo' },

    { id: 'EC - Avaluo', label: 'EC - Avaluo' },
    { id: 'IC - Otros Ingresos', label: 'IC - Otros Ingresos' },
    { id: 'IC - Formato de Sustitucion de Pasivos', label: 'IC - Formato de Sustitucion de Pasivos' },
    { id: 'IC - Estados Financieros (EF)', label: 'IC - Estados Financieros (EF)' },
    { id: 'IC - Cotizaciones', label: 'IC - Cotizaciones' },
    { id: 'IC - Consulta de Centrales de Riesgo', label: 'IC - Consulta de Centrales de Riesgo' },
    { id: 'IC - Composicion Accionaria', label: 'IC - Composicion Accionaria' },
    { id: 'IC - Libranza', label: 'IC - Libranza' },
    { id: 'EC - Pagare', label: 'EC - Pagare' },
    { id: 'IC - Reglamentos Crediservice', label: 'IC - Reglamentos Crediservice' },
    { id: 'EC - Polizas de Seguros de Desempleo', label: 'EC - Polizas de Seguros de Desempleo' },
    { id: 'IC - Contrato de Arrendamiento', label: 'IC - Contrato de Arrendamiento' },
    { id: 'IC - Tarjeta de Propiedad', label: 'IC - Tarjeta de Propiedad' },
    { id: 'EC - Carta de Autorizacion Desembolso a Treceros', label: 'EC - Carta de Autorizacion Desembolso a Treceros' },
    { id: 'IC - Soportes Actividad', label: 'IC - Soportes Actividad' },
    { id: 'EC - Proyecto Crediticio', label: 'EC - Proyecto Crediticio' },
    { id: 'IC - Reglamentos Adelanto de Nomina', label: 'IC - Reglamentos Adelanto de Nomina' },
    { id: 'IC - Certificado de Exencion de GMF', label: 'IC - Certificado de Exencion de GMF' },
    { id: 'VoBo', label: 'VoBo' },
    { id: 'Certificado de tradición y libertad', label: 'Certificado de tradición y libertad' },
    { id: 'Acta de Asamblea o Junta Directiva', label: 'Acta de Asamblea o Junta Directiva' },
    { id: 'Formato de Colfuturo', label: 'Formato de Colfuturo' },
    { id: 'IC - Carta de Solicitud de Pignoracion', label: 'IC - Carta de Solicitud de Pignoracion' },
    
    /* Estos si???
    {id:'EC - Poliza de Seguro Vida Plus',label:'EC - Poliza de Seguro Vida Plus'},
    {id:'EC - Poliza de Seguro Vida Grupo',label:'EC - Poliza de Seguro Vida Grupo'},
    {id:'EC - Poliza de Seguro Todo Riesgo',label:'EC - Poliza de Seguro Todo Riesgo'},
    {id:'EC - Poliza de Seguro Accidentes Personales',label:'EC - Poliza de Seguro Accidentes Personales'},
    */
]

export const OCR_Analize_DOCS = [

    ///// Estos docs son externos -- API CLUJAN
    { id: 'IC - Certificado Laboral (CL)', label: 'IC - Certificado Laboral (CL)' },
    { id: 'IC - Camara de Comercio', label: 'IC - Camara de Comercio' },
    { id: 'IC - Declaracion de Renta (DR)', label: 'IC - Declaracion de Renta (DR)' },
    { id: 'IC - Comprobante Nomina', label: 'IC - Comprobante Nomina' },
    { id: 'IC - Cartas de Afiliacion Vehiculos (CAV)', label: 'Prenda Vehiculo' },

    ///// Estos docs son nuestros y label 1-1 con onBase
    { id: 'IC - Documento de identificacion (DI)', label: 'IC - Documento de identificacion (DI)' },
    { id: 'IC - Solicitud (SSF)', label: 'IC - Solicitud (SSF)' },
    { id: 'EC - Poliza de Seguro', label: 'EC - Poliza de Seguro' },
    { id: 'IC - Carta al Pagador', label: 'IC - Carta al Pagador' },
    { id: 'IC - RUT', label: 'IC - RUT' },
    { id: 'EC - Pagare', label: 'EC - Pagare' },
    { id: 'IC - Reglamentos Crediservice', label: 'IC - Reglamentos Crediservice' },
    { id: 'IC - Certificado de Libertad y Tradicion Inmuebles', label: 'IC - Certificado de Libertad y Tradicion Inmuebles' },
    { id: 'IC - Tarjeta de Propiedad', label: 'IC - Tarjeta de Propiedad' },
    { id: 'IC - Certificado de Exencion de GMF', label: 'IC - Certificado de Exencion de GMF' },
    { id: 'IC - Avance de Obra', label: 'IC - Avance de Obra' },
    { id: 'IC - Certificado de Ingresos y Retenciones (CIR)', label: 'IC - Certificado de Ingresos y Retenciones (CIR)' },

    ///// Estos docs tienen un label personalizado para que sea entendible por el stakeholder.
    { id: 'EC - Informe de Visita', label: 'Planilla Colpensiones' },
    { id: 'IC - Formato Cuenta de Deposito Pensionado', label: 'Comprobante de Pensión' },
    { id: 'IC - Lista de Control', label: 'Pagare - Control Fisico' },
    { id: 'IC - Cedula Militar Convenio', label: 'Hoja de Vida Militar' },
    { id: 'IC - Decisor', label: 'Certificado Seguro Vehiculo' },
    { id: 'IC - Carta de Autorizacion a tercero', label: 'Carta Coordinación Fomento' },
    { id: 'IC - Carta al Cliente', label: 'Carta Aprobación Vehiculo' },
    { id: 'EC - Carta de Autorizacion Desembolso a Terceros', label: 'Condiciones Desembolso Vehiculo' },
    { id: 'IC - Acuerdo Consorcial', label: 'Acuerdo Novacion' },
    { id: 'IC - Acta de Constitucion', label: 'Boleta de Registro' },
    { id: 'GA Contrato de Fiducia', label: 'Contrato Leasing' },
    { id: 'IC - Anexos Aportes Voluntarios Allianz', label: 'Anexo de Remodelacion' },
    { id: 'IC - Paz y Salvo Convenio', label: 'Solicitud de Redescuento' },
    { id: 'EC - Poliza de Seguro Todo Riesgo', label: 'Seguro Cuota Protegida' }
]

export const OCRAnalyzeAllowedFormats = ["pdf", "jpg", "jpeg", "png"]

export const onBaseTypesMissing = [
    { id: 'IG Paz y Salvo/Carta Aclaratoria', label: 'IG Paz y Salvo/Carta Aclaratoria' },
    { id: 'IC - Tarjeta Profesional Contador Publico', label: 'IC - Tarjeta Profesional Contador Publico' },
    
    { id: 'IC - Tarjeta de Firmas', label: 'IC - Tarjeta de Firmas' },
    { id: 'IC - Soportes Apertura', label: 'IC - Soportes Apertura' },
    { id: 'IC - Soporte Pago sustitucion', label: 'IC - Soporte Pago sustitucion' },
    { id: 'IC - Servicios Publicos', label: 'IC - Servicios Publicos' },
    { id: 'IC - Resolucion de Pension', label: 'IC - Resolucion de Pension' },
    { id: 'IC - Relacion de Accionistas', label: 'IC - Relacion de Accionistas' },
    { id: 'IC - Reglamentos Tarjeta de Credito', label: 'IC - Reglamentos Tarjeta de Credito' },
    { id: 'IC - Reglamentos', label: 'IC - Reglamentos' },
    { id: 'IC - Propuesta Comercial', label: 'IC - Propuesta Comercial' },
    { id: 'IC - Politica de Intercambio de Informacion', label: 'IC - Politica de Intercambio de Informacion' },
    { id: 'IC - Paz y Salvo Convenio', label: 'IC - Paz y Salvo Convenio' },
    { id: 'IC - Matricula Universitaria', label: 'IC - Matricula Universitaria' },
    { id: 'IC - Lista de Negativos', label: 'IC - Lista de Negativos' },
    { id: 'IC - Lista de Control', label: 'IC - Lista de Control' },
    
    { id: 'IC - Informe de Politicas de Conocimiento del Cliente', label: 'IC - Informe de Politicas de Conocimiento del Cliente' },
    { id: 'IC - Formatos para Utilizacion Portal Empresarial', label: 'IC - Formatos para Utilizacion Portal Empresarial' },
    { id: 'IC - Formato Resumen de Facturas', label: 'IC - Formato Resumen de Facturas' },
    { id: 'IC - Formato Presentacion Negocio', label: 'IC - Formato Presentacion Negocio' },
    { id: 'IC - Formato Cuenta de Deposito Pensionado', label: 'IC - Formato Cuenta de Deposito Pensionado' },
    { id: 'IC - Fondo Nacional de Garantia', label: 'IC - Fondo Nacional de Garantia' },
    { id: 'IC - Firma Autorizada Representante legal', label: 'IC - Firma Autorizada Representante legal' },
    { id: 'IC - Factura Proforma', label: 'IC - Factura Proforma' },
    { id: 'IC - Extractos Sustitucion', label: 'IC - Extractos Sustitucion' },
    { id: 'IC - Documentos Codeudor', label: 'IC - Documentos Codeudor' },
    { id: 'IC - Documento de Identificacion Convenio', label: 'IC - Documento de Identificacion Convenio' },
    { id: 'IC - Decisor', label: 'IC - Decisor' },
    { id: 'IC - Contratos', label: 'IC - Contratos' },

    { id: 'IC - Contrato Adjudicado', label: 'IC - Contrato Adjudicado' },
    { id: 'IC - Consulta Data Credito', label: 'IC - Consulta Data Credito' },
    { id: 'IC - Consulta Centrales de Informacion', label: 'IC - Consulta Centrales de Informacion' },
    { id: 'IC - Compra de Cartera', label: 'IC - Compra de Cartera' },
    { id: 'IC - Certificado Ingresos Contador Publico', label: 'IC - Certificado Ingresos Contador Publico' },
    { id: 'IC - Certificado de Personeria', label: 'IC - Certificado de Personeria' },
    { id: 'IC - Certificado de Libertad y Tradicion Vehiculos', label: 'IC - Certificado de Libertad y Tradicion Vehiculos' },
    { id: 'IC - Certificado de Exencion Retefuente', label: 'IC - Certificado de Exencion Retefuente' },
    { id: 'IC - Carta Resultado Estudio', label: 'IC - Carta Resultado Estudio' },
    { id: 'IC - Carta de Presentacion de la Empresa', label: 'IC - Carta de Presentacion de la Empresa' },
    { id: 'IC - Carta de Autorizacion a tercero', label: 'IC - Carta de Autorizacion a tercero' },
    { id: 'IC - Carta al Pagador', label: 'IC - Carta al Pagador' },
    { id: 'IC - Carta al Cliente', label: 'IC - Carta al Cliente' },
    { id: 'IC - Camara de Comercio', label: 'IC - Camara de Comercio' },
    { id: 'IC - Balance Inicial', label: 'IC - Balance Inicial' },
    { id: 'IC - Avance de Obra', label: 'IC - Avance de Obra' },
    { id: 'IC - Autorizacion para manejo de productos', label: 'IC - Autorizacion para manejo de productos' },
    { id: 'IC - Autorizacion de Constitucion', label: 'IC - Autorizacion de Constitucion' },
    { id: 'IC - Auto Descuento', label: 'IC - Auto Descuento' },
    { id: 'IC - Aprobaciones de Credito', label: 'IC - Aprobaciones de Credito' },
    { id: 'IC - Anexos Aportes Voluntarios Porvenir', label: 'IC - Anexos Aportes Voluntarios Porvenir' },
    { id: 'IC - Anexos Aportes Voluntarios Allianz', label: 'IC - Anexos Aportes Voluntarios Allianz' },
    { id: 'IC - Anexo Solicitud Vinculacion PJ', label: 'IC - Anexo Solicitud Vinculacion PJ' },
    { id: 'IC - Anexo Firmado por el Tesorero', label: 'IC - Anexo Firmado por el Tesorero' },
    
    { id: 'IC - Acuerdo Consorcial', label: 'IC - Acuerdo Consorcial' },
    { id: 'IC - Activos - Pasivos', label: 'IC - Activos - Pasivos' },
    { id: 'IC - Actas de Aprobacion', label: 'IC - Actas de Aprobacion' },
    { id: 'IC - Acta Posesion - Tesorero', label: 'IC - Acta Posesion - Tesorero' },
    { id: 'IC - Acta Posesion - Alcalde', label: 'IC - Acta Posesion - Alcalde' },
    { id: 'IC - Acta de Nombramiento', label: 'IC - Acta de Nombramiento' },
    { id: 'IC - Acta de Eleccion de Directivos', label: 'IC - Acta de Eleccion de Directivos' },
    { id: 'IC - Acta de Constitucion', label: 'IC - Acta de Constitucion' },
    { id: 'GA Contrato de Fiducia', label: 'GA Contrato de Fiducia' },
    { id: 'GA Contrato de Cesion de Derechos Economicos', label: 'GA Contrato de Cesion de Derechos Economicos' },
    { id: 'FT - Otro', label: 'FT - Otro' },
    
   
    { id: 'EC - Paz y Salvos', label: 'EC - Paz y Salvos' },
    { id: 'EC - Garantias', label: 'EC - Garantias' },
    { id: 'EC - Formulario de Vinculacion Tarjeta Joven', label: 'EC - Formulario de Vinculacion Tarjeta Joven' },
    { id: 'EC - Formulario de Vinculacion Tarjeta Amparada', label: 'EC - Formulario de Vinculacion Tarjeta Amparada' },
    { id: 'EC - Formulario de Vinculacion Consorciados', label: 'EC - Formulario de Vinculacion Consorciados' },
    { id: 'EC - Formulario de Vinculacion', label: 'EC - Formulario de Vinculacion' },
    { id: 'EC - Formato Actualizacion de Datos', label: 'EC - Formato Actualizacion de Datos' },
    { id: 'EC - Convenios', label: 'EC - Convenios' },
    { id: 'EC - Balance General', label: 'EC - Balance General' },
    { id: 'CC Memorandos', label: 'CC Memorandos' },
    { id: 'A Documentos Operaciones de Leasing', label: 'A Documentos Operaciones de Leasing' }
]
export const errorMessageFileSize = "El archivo que intentas subir supera el tamaño máximo permitido 5MB"
export const flujoRedescuentoName = "FLUJO REDESCUENTO"

export const OCRLabelsTranslated  = {
    "full_name": "Nombre completo",
    "name": "Nombre",
    "client_name": "Nombre Cliente",
    "last_name": "Apellido",
    "surname": "Apellido",
    "first_name": "Primer Nombre",
    "second_name": "Segundo Nombre",
    "first_surname": "Primer Apellido",
    "second_surname": "Segundo Apellido",
    "document_type": "Tipo de Documento de Identificación",
    "number_id": "Nro. de Documento",
    "document": "Nro. de Documento",
    "nit": "Nit",
    "expedition_date": "Fecha de Expedición",
    "novelty_type": "Tipo de Novedad",
    "expedition_place": "Lugar de Expedición",
    "address": "Dirección",
    "residential_city": "Ciudad de Residencia",
    "city": "Ciudad",
    "phone_number": "Nro. Telefono",
    "email": "Correo Electrónico",
    "birth_date_day": "Día Nacimiento",
    "birth_date_month": "Mes Nacimiento",
    "birth_date_year": "Año Nacimiento",
    "birth_date": "Fecha Nacimiento",
    "date_birth": "Fecha Nacimiento",
    "age": "Edad",
    "marital_status": "Estado Civil",
    "eps": "EPS",
    "prepaid_medicine": "Medicina Prepagada",
    "occupation": "Ocupación",
    "employer": "Empleador",
    "business_activity": "Actividad Comercial",
    "genre": "Genero",
    "weight": "Peso",
    "height": "Altura",
    "credit_line": "Línea Credito",
    "disbursement_amount": "Valor a Desembolsar",
    "loan_term": "Plazo - Cuotas",
    "cumulative_debt": "Deuda total a la Fecha",
    "medicines": "Medicamentos",
    "request_date": "Fecha Solicitud",
    "date": "Fecha",
    "payment_date": "Fecha de Pago",
    "update_date": "Fecha de Actualización",
    "office_code": "Código Oficina",
    "office": "Oficina",
    "requested_product": "Producto Solicitado",
    "requested_amount": "Valor Solicitado",
    "disbursement_method": "Método Desembolso",
    "account_type": "Tipo Cuenta",
    "account_number": "Nro. Cuenta",
    "product_number": "Nro. de Producto",
    "product_info_name": "Información Producto",
    "requested_quota": "Cupo Solicitado",
    "requested_date_day":  "Día de solicitud",  
    "requested_date_month":  "Mes de solicitud",  
    "requested_date_year":  "Año de solicitud",  
    "signed_number_id":  "Nro. de documento firmado",
    "signed_name": "Nombre firmado",
    "signed_last_name": "Apellido firmado",
    "start_acceptance_criteria": "Fecha de inicio - Condiciones de aceptación",
    "approved_insured_amount": "Valor asegurado aprobado",
    "rating": "Calificación",
    "responsibilities_qualities_attributes": "Responsabilidades, cualidades y atributos",
    "principal_activity_code": "Código - Actividad principal",
    "responsibilities_qualities_attributes_codes": "Códigos - Responsabilidades, cualidades y atributos",
    "pagination": "Paginación",
    "rate": "Tasa",
    "month_fee": "Valor Cuota",
    "month_fee_letter": "Valor Cuota en Texto",
    "total_ammount": "Monto",
    "total_ammount_letter": "Monto en Texto",
    "doc_version": "Versión del Documento",
    "doc_type": "Tipo de documento",
    "signature": "Firma",
    "company_signature": "Firma Entidad",
    "payer_signature": "Firma Pagador",
    "client_signature": "Firma Cliente",
    "employee_signature": "Firma Empleado",
    "signature_insured": "Firma Asegurado",
    "no_membership": "Nro. de Afiliación",
    "company": "Nombre de la Entidad",
    "subscribed_name": "Suscrito Nombres",
    "subscribed_last_name": "Suscrito Apellidos",
    "subscribed_document": "Suscrito Documento",
    "debt_name": "Deudor Nombre",
    "debt_last_name": "Deudor Apellido",
    "debt_number_id": "Deudor Documento",
    "signed_date": "Fecha Firmado",
    "document_title": "Título de documento",
    "responsible_code": "Centro de Costos del Responsable",
    "pagare_ref": "Referecia al Pagaré",
    "signed_role": "Cargo Firmado",
    "fixed_interest_rate": "Tasa de interés fija",
    "effective_interest_rate": "Tasa de interés efectiva",
    "frequency_effective_rate": "Frecuencia tasa efectiva",
    "frequency_fixed_rate": "Frecuencia tasa fija",
    "loan_amount": "Monto",
    "responsible_name": "Responsable Nombres",
    "responsible_last_name": "Responsable Apellidos",
    "responsible_id_type": "Responsable Tipo de documento",
    "responsible_number_id": "Responsable Documento",
    "loan_amount_letter": "Monto en letras",
    "capital_counted": "Contado a capital",
    "variable_interest_rate": "Tasa de interés variable",
    "frequency_variable_rate": "Frecuencia tada variable",
    "militar_code": "Código Militar",
    "militar_range": "Grado Militar",
    "capital_amount": "Valor capital",
    "current_interest_rate": "Tasa de interés corriente",
    "frequency_current_rate": "Frecuencia tasa corriente",
    "first_quota": "Fecha primera cuota",
    "last_quota": "Fecha última cuota",
    "print_date": "Fecha de impresión",
    "reg_number": "Nro. de matricula",
    "registral_circle": "Circulo Registral",
    "folio_status": "Estado del Folio",
    "anotation_number": "Anotación - Nro.",
    "anotation_date": "Anotación - Fecha",
    "anotation_espec": "Anotación - Especificación",
    "anotation_number_writting": "Anotación - Nro. de Escritura",
    "anotation_date_writting": "Anotación - Fecha Escritura",
    "anotation_notary_name": "Anotación - Nombre Notaria",
    "anotation_from": "Anotación - DE:",
    "anotation_to": "Anotación - A:",
    "anotation_number_id": "Anotación - Nro. de Documento",
    "catastral_code": "Código Catastral",
    "license_plate": "Placa",
    "make_vehicle": "Marca",
    "engine_number": "Número de motor",
    "chassis_number": "Número de chasis",
    "ownership_limitation": "Limitación a la propiedad",
    "policy_number": "Número de Poliza",
    "policy_effective_date": "Fecha inicio de poliza",
    "policy_rate_code": "Código tarifa",
    "policy_status": "Estado - Poliza",
    "engine_number_runt": "Número de motor - RUNT",
    "chassis_number_runt": "Número de chasis - RUNT",
    "VIN_runt": "VIN - RUNT",
    "owner_full_name": "Nombre completo - Propietario",
    "license_plate_runt": "Placa - RUNT",
    "make_vehicle_runt": "Marca - RUNT",
    "service_type": "Tipo de servicio",
    "vehicle_type": "Tipo de Vehículo",
    "registration_date": "Fecha Matrícula",
    "vehicle_model": "Modelo - Vehículo",
    "service_type_runt": "Tipo de servicio - RUNT",
    "vehicle_type_runt": "Tipo de Vehículo - RUNT",
    "vehicle_model_runt": "Modelo - RUNT",
    "ownership_limitation_runt": "Acreedor - RUNT",
    "ownership_limitation_id": "Identificación Acreedor - RUNT",
    " registration_date_runt": "Fecha de inscripción - RUNT",
    "filing_office_code": "Código - Oficina de radicación",
    "filing_office_name": "Oficina de radiación",
    "update_date": "Fecha de actualización",
    "rate_finagro": "Tasa - Finagro",
    "current_rate_finagro": "Tasa corriente - Finagro",
    "rate_months_finagro": "Tasa Meses - Finagro",
    "percentage_points": "Puntos porcentuales",
    "constitution_date": "Fecha de constitución",
    "legal_agent_name": "Nombres - Representante Legal",
    "legal_agent_last_name": "Apellidos - Representante Legal",
    "legal_agent_number_id": "Identificación - Representante Legal",
    "legal_agent_residential_city": "Ciudad de Residencia - Representante Legal",
    "legal_agent_expedition_place": "Lugar de expedición - Representante Legal",
    "legal_agent_capacity": "Calidad representante",
    "company_name": "Nombre de la compañia",
    "legal_agent_type_document": "Tipo de Identificación - Representante Legal",
    "approved_value": "Valor Aprobado",
    "plan": "Plan",
    "grace_period": "Periodo de Gracia",
    "loan_percentage": "Porcentaje de Financiación",
    "table_loan_term": "Tabla - Plazo",
    "table_ammount": "Tabla - Monto",
    "doc_title": "Título del documento",
    "client_number_id": "Nro. de Docuento - Cliente",
    "approving_bank": "Banco aprobador",
    "obligations_amount_letter": "Monto obligaciones en texto",
    "obligations_amount": "Monto obligaciones",
    "installment_frequency": "Frecuencia de pago",
    "interest_frequency": "Frecuencia tasa de interés",
    "signed_legal_agent_name": "Nombre RL firmado",
    "signed_legal_agent_number_id": "Nro. Identificación RL firmado",
    "signed_attorney_in_fact_name": "Nombre aproderado firmado",
    "signed_attorney_in_fact_number_id": "Nro. Identificación apoderado firmado",
    "quota_amount": "Monto cuota",
    "signed_client_name": "Nombre cliente firmado",
    "signed_codeudor_name": "Nombre codeudor firmado",
    "signed_client_number_id": "Nro. Identificación codeudor firmado",
    "expedition_date_soat": "Fecha de expedición - SOAT",
    "policy_effective_date_soat": "Fecha inicio de vigencia - SOAT",
    "policy_expiration_date_soat": "Fecha fin de vigencia - SOAT",
    "owner_full_name_soat": "Nombre completo propietario - SOAT",
    "document_type_soat": "Tipo de identificación - SOAT",
    "number_id_soat": "Nro. de Documento - SOAT",
    "policy_number_soat": "Número de poliza - SOAT",
    "license_plate_soat": "Placa - SOAT",
    "type": "Tipo",
    "number_writting": "Nro. de Escritura",
    "requester": "Solicitante",
    "project_name": "Nombre del Proyecto",
    "builder": "Constructor",
    "number_pat": "Identificación/PAT",
    "number_guarantee": "Nro. Garantia",
    "number_visit": "Nro. de Visita",
    "expiration_date": "Fecha de Vencimiento",
    "total_invest": "Total Invertido",
    "project_type": "Tipo de Proyecto",
    "tbl_name": "Nombre de Tabla",
    "property_type": "Tipo de inmueble",
    "neighborhood_area": "Barrio",
    "folio_number": "Nro. Folio",
    "public_records_office":"Lugar - Oficina de instrumentos públicos",
    "class": "Clasificación Documental",
    "total_providers_relation": "Total Anticipos a Proovedores",
    "ciiu_code": "Código CIIU",
    "company_type": "Tipo de Empresa",
    "reosurce_usage": "Uso de Recursos",
    "invest_sector": "Sector a Financiar",
    "sub_invest_sector": "Subsector a Financiar",
    "offered_guarantee": "Garantia Ofrecida",
    "pagare_total_ammount": "Monto Pagaré",
    "loan_term_amort": "Plazo - Cuotas",
    "final_rate": "Tasa Final de Interes",
    "rediscount_rate": "Tasa de Redescuento", 
    "doc_constitution": "Constitución",
    "signed_company_name": "Nombre compañía firmado",
    "signed_nit": "NIT firmado",
    "nationality": "Nacionalidad",
    "taxable_year": "Año gravable"
}
