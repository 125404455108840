import React ,{useState, useRef, useEffect} from 'react';
import './styles.css';

const Dragger = ({data, label, showIcon}) => {
    
    const [list, setList] = useState(data); 
    const [dragging, setDragging] = useState(false);
    const [showDragger, setShowDragger] = useState(true);

    const [showListDragger, setshowListDragger] = useState(false); 

    useEffect(() => {
        setList(data);
    }, [setList, data])

    const dragItem = useRef();
    const dragItemNode = useRef();
    
    const handletDragStart = (e, item) => {
        dragItemNode.current = e.target;
        dragItemNode.current.addEventListener('dragend', handleDragEnd)
        dragItem.current = item;

        setTimeout(() => {
            setDragging(true); 
        },0)       
    }

    const handleDragEnter = (e, targetItem) => {
        if (dragItemNode.current !== e.target) {
            setList(oldList => {
                let newList = JSON.parse(JSON.stringify(oldList));
                newList[targetItem.grpI].items.splice(targetItem.itemI, 0, newList[dragItem.current.grpI].items.splice(dragItem.current.itemI,1)[0]);
                dragItem.current = targetItem;
                localStorage.setItem(list[0].title, JSON.stringify(newList));
                return newList;
            })
        }
    }

    const handleDragEnd = (e) => {
        setDragging(false);
        dragItem.current = null;
        dragItemNode.current.removeEventListener('dragend', handleDragEnd)
        dragItemNode.current = null;
    }

    const getStyles = (index) => {
        const priorityStyle = index < 4 ? 'in-priority' : 'not-priority';
        return `dragger-item cursor-move ${priorityStyle}`;
    }

    const separatorStyle = (index) => {
        return index + 1 === 4 ? 'line' : 'not-line';

    }

    const itemsStyle = (index) => {
        return index < 4 ? 'dragger-item in-priority cursor-move' : 'dragger-item not-priority cursor-move';
    }

    const upperCaseFirst = text => {
        if (text !== null) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
        } else {
            return text;
        }
    }

    const iconShow = () => {
        return showListDragger ? 'icon-less relative' : 'icon-plus relative';
    }


    if (list) {
        return (
            <div>
                {
                showDragger ?
                    <div className="absolute">
                    { showIcon ? <div onClick={() => {setshowListDragger(!showListDragger)}} className={iconShow()}></div> : null }
                    { showListDragger ? 
                        <div className="dragger-drop">
                            <div className="dragger-label">{label ? label : null}</div>
                            {list.map((grp, grpI) => (
                                <div key={grp.title} onDragEnter={dragging && !grp.items.length?(e) => handleDragEnter(e,{grpI, itemI: 0}):null} className="dragger-group">
                                    { grp.items.map((item, itemI) => (
                                    <div>
                                    <div draggable key={item}  onDragStart={(e) => handletDragStart(e, {grpI, itemI})} 
                                        onDragEnter={dragging?(e) => {handleDragEnter(e, {grpI, itemI})}:null} className={dragging ? getStyles(itemI, grp.items) : itemsStyle(itemI, grp.items)}>
                                        <div className="icon-draw"></div>{upperCaseFirst(item)}
                                    </div>
                                    <hr className={separatorStyle(itemI, grp.items)}></hr>
                                    </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    : null }
                    </div>
                    : null
                }
            </div>
        )
    } else { return null}
}
 
export default Dragger;