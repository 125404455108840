import React, { Fragment, useEffect, useState } from 'react';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import './PrioritizationSettingsDisbursement.css';
import Dragger from '../../bdb-components/DraggerComponent/Dragger';
import { useDispatch, useSelector } from 'react-redux';
import { getPriorityCenterDisbursemet, updatePriorityCenterDisbursemet } from '../../bdb-actions/priotitizationActions';
import { optionsCenterDisbursemet, optionsLineDefault, optionsLine, itemsDefault } from '../../../constants';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";

const tittleOptions = 'Arrastra y ordena';
const line = 'Línea'

const PrioritizationSettingsDisbursement = () => {

      const dispatch = useDispatch();
      const history = useHistory();

      const data = useSelector(state => state.prioritization.data);
      const loading = useSelector(state => state.prioritization.loading);
      const alert = useSelector(state => state.prioritization.alert);
      const error = useSelector(state => state.prioritization.error);

      const [options, setOptions] = useState(optionsCenterDisbursemet);
      const [optionsLines, setOptionsLines] = useState(optionsLine);
      const [optionsDragger, setOptionsDragger] = useState([]);

      const [enableRestore, setEnableRestore] = useState(false);
      const [inactiveBtn, setInactiveBtn] = useState(false);

      const [labelPriority1, setlabelPriority1] = useState(null);
      const [labelPriority2, setlabelPriority2] = useState(null);

      const [valuePriority1, setValuePriority1] = useState(null);
      const [valuePriority2, setValuePriority2] = useState(null);

      const [showOptions1, setShowOptions1] = useState(false); 
      const [showOptions2, setShowOptions2] = useState(false);

      const [optionLine, setOptionLine] = useState(null);

      const [showModalRestore, setShowModalRestore] = useState(false);
      
      useEffect(() => {
            const getItems = () => dispatch(getPriorityCenterDisbursemet());
            getItems();
      }, []);

      useEffect(() => {

            if(data !== null) {
                  if (!data['Default']) { initValues(data); } else { initValues(itemsDefault); } 
            } 
      }, [data]);

      const initValues = data => {
            let option1 = null;
            let option2 = null;

            setEnableRestore(!data['Default']);
            setInactiveBtn(data['Default']);

            if (data['P1Amount']) { option1 = options.find(option => option.value === 'amount'); }
            if (data['P1FNG']) { option1 = options.find(option => option.value === 'fng'); }
            if (data['P1Line']) { option1 = options.find(option => option.value === 'line'); }
            if (data['P1LineP2Amount']) { 
                  option2 = data['P1Line'] && data['P1Amount'] ? 
                              options.find(option => option.value === 'amount') : null;
            }
            if (data['P1LineP2FNG']) { 
                  option2 = data['P1Line'] && data['P1FNG'] ? 
                        options.find(option => option.value === 'fng') : null;
            }

            setValuePriority1(!!option1 ? option1['value'] : null);
            setValuePriority2(!!option2 ? option2['value'] : null);
            
            setlabelPriority1(!!option1 ? option1['label'] : null);
            setlabelPriority2(!!option2 ? option2['label'] : null);
            
            if (localStorage.getItem('line')) {
                  setOptionsDragger(JSON.parse(localStorage.getItem('line')))
            } else {
                  setOptionsDragger(captureOptions(!!data['optionsLine'] ? data['optionsLine'] : [] ));
            }
      }

      function captureOptions (optionsLine) {
            const linesElement = [];
            if (optionsLine === []) { return optionsLineDefault;} 
            const keys = Object.keys(optionsLine);
            keys.forEach((key, index) => {
                  if (optionLine !== []) {
                        const label = optionsLines.find(option => option.id === key);
                        linesElement.push({ id: key, position: optionsLine[key], label: !!label ? label['label'] : 'N/A'});
                  }
            })
            linesElement.sort(function (a, b) { return a.position.toString().localeCompare(b.position.toString()); });
            return selectFieldsForList(linesElement);
      }

      const selectFieldsForList = completeList => {
            if (completeList === []) { return [];} 
            let compilationList = [{ title: 'line', items: []}];
            completeList.forEach(element => {
                  if (element.label !== 'N/A') {
                        compilationList[0].items.push(element.label);
                  }
            });
            return compilationList;
      }

      const disableBoxPriority = priority => {
            const style = 'priority-box priority-label';
            const enable = 'enable-box enable-label';
            const disable = 'disable-box disable-label';
            if (priority === 'priority1') {
              return `${style} ${enable}`;
            }
            if (priority === 'priority2') {
              return labelPriority1 === line ? `${style} ${enable}` : `${style} ${disable}`;
            }
            return;
      }

      const restorePriorityStyle = priority => {
            if (priority === 'priority1') {
              return 'display-none';
            }
            if (priority === 'priority2') {
              return labelPriority2 !== null ? 'icon-x-blue cursor-pointier' : 'display-none';
            }
      }

      const restorePriority = priority => {
            if (priority === 'priority2') { 
              setlabelPriority2(null);
              setValuePriority2(null);
            }
          }

      const priorityOptionsValidation = priority => {
            if (priority === 'priority1') { return showOptions1; }
            if (priority === 'priority2') { return showOptions2 && labelPriority1 === line; }
      }

      const optionSelected = (label) => {
            return labelPriority1 === label || labelPriority2 === label ? 'dropdown-selected' : null;
      }

      const selectOption = (index, priority) => {
            
            setInactiveBtn(false);
            if (priority === 'priority1' && options[index].value !== valuePriority2 
              && options[index].value !== valuePriority2) {
      
              if (labelPriority2 !== null) { setlabelPriority2(null); }
              setlabelPriority1(options[index].label);
              setValuePriority1(options[index].value);
              setShowOptions1(false);
            }
            if (priority === 'priority2' && options[index].value !== valuePriority1) {
              setlabelPriority2(options[index].label);
              setValuePriority2(options[index].value);
              setShowOptions2(false);
            }
      }

      const disableSelectPriority = priority => {
            const style = 'dropbtn';
            const enable = 'droplabel-enable';
            const disable = 'droplabel-disable';
      
            if (priority === 'priority1') {
              return labelPriority1 !== null ? `${style} ${enable}` : `${style} ${disable}`;
            }
            if (priority === 'priority2') {
              return labelPriority2 !== null ? `${style} ${enable}` : `${style} ${disable}`;
            }
      }
      const getPlaceholder = priority => {
            const label = 'Selecciona la categoría';
            if (priority === 'priority1') { return labelPriority1 !== null ? labelPriority1 : label; }
            if (priority === 'priority2') { return labelPriority2 !== null ? labelPriority2 : label; }
      }

      const disableIconSelect = priority => {
            if (priority === 'priority1') {
              return 'icon-drop';
            }
            if (priority === 'priority2') {
              return labelPriority1 !== line ? 'icon-drop-disable' : 'icon-drop';
            }
      }

      const initOptions = priority => {
            if (priority === 'priority1') {
              return labelPriority1 === null ? null : selectOptionForLabel(valuePriority1, !showOptions1);
            }
            if (priority === 'priority2') {
              return labelPriority2 === null ? null : selectOptionForLabel(valuePriority2, !showOptions2);
            } 
      }

      const selectOptionForLabel = (label, showOptions) => {
            if (label === 'line') {
              return <Dragger data={optionsDragger} label={tittleOptions} showIcon={showOptions}/>
            }
      }

      const oneButton = () => {
            return enableRestore ? 'flex-around' : 'flex-center';
      }

      const modalApply = () => {
            if (!inactiveBtn) {
                  Swal.fire({
                        icon: 'info',
                        title: `<p class="titel-modal">¿Estás seguro de cambiar\n la priorización?</p>`,
                        html:
                        'Los cambios se aplicarán para los <br> próximos cargues',
                        showCancelButton: true,
                        confirmButtonText: 'Aplicar',
                        confirmButtonColor: '#002c76',
                        cancelButtonColor: '#c61f01',
                        cancelButtonText: 'Cancelar',
                  }).then( result => {
                        if(result.isConfirmed){
                        applyChanges();
                        }
                  });
            }
      }

      const buttonApplyStyle = () => {
            return labelPriority1 === null || inactiveBtn ? 'button-apply-disable text-button' : 'button-apply-enable text-button';
      }

      const enableRestoreStyle = () => {
            return enableRestore ? 'button-restore label-restore' : 'display-none';
      }

      const applyChanges = () => {
            const sessionOptions = localStorage.getItem('line') ? JSON.parse(localStorage.getItem('line')) : null;
            const Item = {
                    "P1Amount": valuePriority1 === 'amount',
                    "P1FNG": valuePriority1 === 'fng',
                    "P1Line": valuePriority1 === 'line' && valuePriority2 === null,
                    "P1LineP2Amount": valuePriority1 === 'line' && valuePriority2 === 'amount',
                    "P1LineP2FNG": valuePriority1 === 'line' && valuePriority2 === 'fng',
                    "Default": false,
                    "optionsLine": 
                        mapperOptions( !!sessionOptions ? sessionOptions[0]['items'] : optionsLineDefault[0]['items'])
                };
            localStorage.removeItem('line');
            dispatch(updatePriorityCenterDisbursemet(Item))
            setEnableRestore(true);
      }

      const mapperOptions = options => {
            const optionsResult = {};
            options.forEach((option, index) => {
                  if(option !== 'Otros') {
                        optionsResult[optionsLine.find(optionLine => optionLine.label === option)['id']] = index + 1;
                  }
            });
            optionsResult['Otros'] = 3;
            return optionsResult;
      }

      const restorePriorityDefault = () => {
            setShowModalRestore(false);
            localStorage.removeItem('line');
            itemsDefault.Default = true;
            itemsDefault.P1Line = false;
            dispatch(updatePriorityCenterDisbursemet(itemsDefault));
            initValues(itemsDefault);
            setEnableRestore(false);
      }

      const redirectToHome = () => {
            Swal.fire({
              icon: 'info',
              title: '<div>¡Lo sentimos!</div>',
              html:
                'Presentamos inconvenientes técnicos,<br> intenta de nuevo',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#002c76'
            }).then( result => {
              if(result.isConfirmed){
                history.push('/finantial-leader');
              }
            });
      }

    return ( 
      <Fragment>
            <LeaderOptions />
            { loading ? <Loading /> : null}
            { error ? redirectToHome() : null}
            { alert ? <p className="alert alert-success text-center mt-4 alert-color">La priorización se configuró con éxito</p> : null }
            <div className="flex-center">
              <div className="tittle">Configuración de la demanda</div>
            </div>
            <div className="flex-center">
              <div className="div-subtittle">
                <div className="subtittle">Selecciona la(s) categoría(s) en el orden que deseas priorizar los próximos cargues.</div>
              </div>
            </div>
            <div className="padding-27">
              <div className={oneButton()}>
                <div>
                  <div onClick={() => {modalApply()}} className={buttonApplyStyle()}>Aplicar cambios</div>
                </div>
                <div>
                  <div onClick={() => {setShowModalRestore(true)}} className={enableRestoreStyle()}>Reiniciar config</div>
                </div>
              </div>
            </div>
            <div className="flex-center-disbursement">
                  <div className="width-box">
                        <div className={disableBoxPriority('priority1')}>Prioridad 1 
                              <div onClick={() => {restorePriority('priority1')}} className={restorePriorityStyle('priority1')}></div>
                        </div>                        
                        <div className="dropdown pointer">
                              <div className={disableSelectPriority('priority1')} onClick={() => {setShowOptions1(!showOptions1)}}>
                                    {getPlaceholder('priority1')}<div className={disableIconSelect('priority1')}></div>
                              </div>
                                    { priorityOptionsValidation('priority1') ? 
                                    <div className="dropdown-content">
                                    {options.map((option, index) => (
                                          <a className={optionSelected(option.label)} 
                                          onClick={() => {selectOption(index, 'priority1')}}>{option.label}</a>
                                    ))}
                                    </div>
                              : null} 
                        </div>
                        <div></div>
                        { initOptions('priority1') }
                  </div>
                  <div className="w-2"></div>
                  <div className="width-box">
                        <div className={disableBoxPriority('priority2')}>Prioridad 2 
                              <div onClick={() => {restorePriority('priority2')}} className={restorePriorityStyle('priority2')}></div>
                        </div> 
                        <div className="dropdown pointer">
                              <div className={disableSelectPriority('priority2')} onClick={() => {setShowOptions2(!showOptions2)}}>
                                    {getPlaceholder('priority2')}<div className={disableIconSelect('priority2')}></div>
                              </div>
                                    { priorityOptionsValidation('priority2') ? 
                                    <div className="dropdown-content">
                                    {options.map((option, index) => (
                                          option.label !== labelPriority1 ? 
                                          <a className={optionSelected(option.label)} 
                                          onClick={() => {selectOption(index, 'priority2')}}>{option.label}</a> : null
                                    ))}
                                    </div>
                              : null} 
                        </div>
                        <div></div>
                        { initOptions('priority2') }
                  </div>
            </div>
            {
              <Modal show={showModalRestore} centered onHide={!showModalRestore}>
                <Modal.Body>
                    <div className="paddin-modal">
                        <div className="modal-center">
                            <div className="modal-icon-info"></div>
                        </div>
                        <div className="modal-title-info modal-margin-title">¿Estás seguro de reiniciar la configuración?</div>
                        <div className="modal-subtitle-info modal-margin-subtitle">La priorización volverá a la predeterminada.</div>
                        <div>
                            <div className="prioritizeImageDisbursemet"></div>
                        </div>
                        <div className="flex-content">
                            <div onClick={() => {restorePriorityDefault()}} className="modal-button-blue modal-text-button cursor-pointer">Reiniciar</div>
                            <div onClick={() => {setShowModalRestore(false)}} className="modal-button-red modal-text-button cursor-pointer">Cancelar</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
          }
      </Fragment>
     );
}
 
export default PrioritizationSettingsDisbursement;