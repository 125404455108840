import { 
    GET_PRODUCTIVITY_PRODUCT,
    GET_PRODUCTIVITY_PRODUCT_FAIL,
    GET_PRODUCTIVITY_PRODUCT_START,
    CHANGE_PRODUCTIVITY_PRODUCT,
    APPLY_PRODUCTIVITY,
    APPLY_PRODUCTIVITY_SUCCESS,
    APPLY_PRODUCTIVITY_FAIL,
    CLEAN_PRODUCTIVITY_PRODUCT
} from '../bdb-types/index';
import axios from 'axios';

export function getProductivityProduct( productName ) {
    return async (dispatch) => {
        dispatch( itemsComponentStart(true));
        try {
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/productivity-product?productName=' + productName)
                .then(function (response) {
                    dispatch( productivityProductSuccess(response.data) );
                });
        } catch(error) {
            dispatch( productivityProductFail(false) );
        }
    }
}

export function changeDataParameters( data ) {
    return async (dispatch) => {
        dispatch( cleanData(null) );
        dispatch( changeDataParametersSuccess(data) );
    }
}

export function cleanDataParameters() {
    return async (dispatch) => {
        dispatch( cleanData(null) );
    }
}

export function applyChanges( data, productName ) {
    return async (dispatch) => {
        dispatch( applyChangesStart(true));
        data['productName'] = productName;
        try {
            await axios.put( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/productivity-product', data)
                .then(function (response) {
                    response.status === 200 ? dispatch( applyChangeSuccess(false) ) : dispatch( applyChangeFail(false));
                });
        } catch(error) {
            dispatch( productivityProductFail(false) );
        }
    }
}

const itemsComponentStart = state => ({
    type: GET_PRODUCTIVITY_PRODUCT_START,
    payload: state
});

const productivityProductSuccess = state => ({
    type: GET_PRODUCTIVITY_PRODUCT,
    payload: state
});

const productivityProductFail = state => ({
    type: GET_PRODUCTIVITY_PRODUCT_FAIL,
    payload: state
});

const changeDataParametersSuccess = state => ({
    type: CHANGE_PRODUCTIVITY_PRODUCT,
    payload: state
});

const applyChangeFail = state => ({
    type: APPLY_PRODUCTIVITY_FAIL,
    payload: state
})

const applyChangeSuccess = state => ({
    type: APPLY_PRODUCTIVITY_SUCCESS,
    payload: state
})

const applyChangesStart = state => ({
    type: APPLY_PRODUCTIVITY,
    payload: state
})

const cleanData = state => ({
    type: CLEAN_PRODUCTIVITY_PRODUCT,
    payload: state
})
