import React, { useState } from 'react';
import Analyst from './app/bdb-views/AnalystView/Analyst';
import DocumentsDCView from './app/bdb-views/AnalystView/DocumentsDCView/DocumentsDCView';
import Leader from './app/bdb-views/LeaderView/Leader';
import Header from './app/bdb-components/HeaderComponent/Header';
import IdleTimeOutHandler from './app/bdb-components/IdleTimeOutHandler/IdleTimeOutHandler';
import TimegapClose from './app/bdb-components/IdleTimeOutHandler/TimegapClose';
import Cases from './app/bdb-views/CasesView/Cases';
import LeaderKardex from './app/bdb-components/LeaderProductivityComponent/LeaderKardex';
import Prioritize from './app/bdb-components/PrioritizeComponent/Prioritize';
import PrivateRoute from './app/bdb-services/AuthService/PrivateRoute';
import Reasignate from './app/bdb-components/ReasignateComponent/Reasignate';
import PrioritizationSettings from './app/bdb-views/PrioritizationSettingsView/PrioritizationSettings';
import PrioritizationSettingsHousing from './app/bdb-views/PrioritizationSettingsViewHousing/PrioritizationSettingsHousing';
import PrioritizationSettingsLibrances from './app/bdb-views/PrioritizationSettingsLibrances/PrioritizationSettingsLibrances';
import PrioritizationSettingsDisbursement from './app/bdb-views/PrioritizationSettingsDisbursement/PrioritizationSettingsDisbursement';
import ProductivitySettingsByProduct from './app/bdb-views/ProductivitySettingsByProduct/ProductivitySettingsByProduct'
import MenuDisbursement from './app/bdb-views/MenuDisbursementView/MenuDisbursement';
import ReportCasesGpo from './app/bdb-views/ReportCasesGpo/reportCasesGpo';
import NewTokenComponent from './app/bdb-components/TimeNewTokenComponent/NewToken';
import { ReserveGuarantee } from './app/bdb-views/Fng/FngReserveResult/ReserveGuarantee';
import { FngHome } from './app/bdb-views/Fng/FngHome/FngHome';

import { getListCopies } from './app/bdb-services/GeneralService/globalService';
import DisbursementCases from './app/bdb-views/ReportCases/DisbursementReportCases/DisbursementReportCases';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import HomePage from './app/bdb-components/HomePageComponent/HomePage';
import * as CryptoJS from "crypto-js";
import ReportProductivity from './app/bdb-views/ReportProductivityView/ReportProductivity';
import StaffManagement from './app/bdb-views/StaffManagementView/StaffManagement';
import LeaderProductivity from './app/bdb-views/AdministrativosNewsViews/AdministrativeNews';
import TrainDocsOCR from './app/bdb-views/TrainDocsOCR/TrainDocsOCR';
import UploadLargeFiles from './app/bdb-views/UploadLargeFilesView/UploadLargeFilesView';
import OCRAnalyzeView from './app/bdb-views/OCRAnalyzeView/OCRAnalyzeView';
import FngReport from './app/bdb-views/Fng/FngReport/FngReport';
import { HomePageOrder } from './app/bdb-components/HomePageComponent/HomePageOrder';
import ReportOrder from './app/bdb-views/ReportOrderView/ReportOrder';
import DocumentsView from './app/bdb-views/Documents/DocumentsView';
import OrderSendsView from './app/bdb-views/OrderSends/OrderSendsView';
import { verticalMapper } from './constants';

  function getParam(sVar) {
    return unescape(window.location.search.replace(new RegExp('^(?:.*[&\\?]' +
        (escape(sVar) ? escape(sVar).replace(/[\.\+\*]/g, '\\$&') : '') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
  }
    function encryptUsingAES256(value, iv, key) {
        const _key = CryptoJS.enc.Utf8.parse(key);
        const _iv = CryptoJS.enc.Hex.parse(iv);
        return CryptoJS.AES.encrypt(
            value, _key, {
                keySize: 128,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
    }

    function decryptUsingAES256(value, iv, key) {
        const _key = CryptoJS.enc.Utf8.parse(key);
        const _iv = CryptoJS.enc.Hex.parse(iv);
        return CryptoJS.AES.decrypt(
            value, _key, {
                keySize: 128,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
    }

    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

  function initValues() {

    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    if (!!isAuthenticated || !isAuthenticated) {
        let keyToken = getParam('keyToken');
        let token = getParam('token');
        let documentNumberAdviser = getParam('documentNumberAdviser');
        let rolUser = getParam('rolUser');
        let username = getParam('username');
        let userLogin = getParam('userLogin');
        let management = getParam('management');
        const app = getParam('orderDisb');
        let AWSRole = getParam('awsrole');

        const cryptKeyToken = b64_to_utf8(keyToken);
        const cryptToken = b64_to_utf8(token);
        const cryptRolUser = b64_to_utf8(rolUser);
        const cryptUsername = b64_to_utf8(username);
        const cryptUserLogin = b64_to_utf8(userLogin);
        const cryptDocumentNumberAdviser = documentNumberAdviser;
        const cryptAWSRole = b64_to_utf8(AWSRole);
        
        keyToken = decryptUsingAES256(b64_to_utf8(keyToken), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        token = decryptUsingAES256(b64_to_utf8(token), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        rolUser = decryptUsingAES256(b64_to_utf8(rolUser), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        username = decryptUsingAES256(b64_to_utf8(username), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        userLogin = decryptUsingAES256(b64_to_utf8(userLogin), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        documentNumberAdviser = b64_to_utf8((documentNumberAdviser));  
        const vertical = verticalMapper[rolUser]|| verticalMapper['default']
        const cryptVertical =  encryptUsingAES256(vertical,documentNumberAdviser, b64_to_utf8(process.env.REACT_APP_AES_KEY));
            
      if (!!keyToken && !!token && !!documentNumberAdviser && !!rolUser && !(!!sessionStorage.getItem('token'))) {
        sessionStorage.clear();
        sessionStorage.setItem("APP",app); 
        sessionStorage.setItem('keyToken', cryptKeyToken);
        sessionStorage.setItem('keyTokenRenew', getParam('keyToken'));
        sessionStorage.setItem('token', cryptToken);
        sessionStorage.setItem('tokenRenew', getParam('token'));
        sessionStorage.setItem('documentNumberAdviser', cryptDocumentNumberAdviser);
        sessionStorage.setItem('rolUser', cryptRolUser);
        sessionStorage.setItem('username', cryptUsername);
        sessionStorage.setItem('userLogin', cryptUserLogin);
        sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', documentNumberAdviser, b64_to_utf8(process.env.REACT_APP_AES_KEY)));
        sessionStorage.setItem('management', management);
        sessionStorage.setItem('vertical', cryptVertical);
        sessionStorage.setItem('AWSRole', cryptAWSRole);

      } 
    } else {
      sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), b64_to_utf8(process.env.REACT_APP_AES_KEY)));

    }

  }
  function intitValuesOrder() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
        //pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        let keyToken = getParam('cryptKeyToken');
        let token = getParam('cryptToken');
        let documentNumberAdviser = getParam('cryptDocumentNumberAdviser');
        let rolUser = getParam('cryptRolUser');
        let username = getParam('cryptUsername');
        let userLogin = getParam('cryptUserLogin');
        let officeCode = getParam('cryptOfficeCode');
        let ip = getParam('cryptIp');
        let cnUserRol = getParam('cnUserRol');
        const app = getParam('orderDisb');

        const cryptKeyToken = b64_to_utf8(keyToken);
        const cryptToken = b64_to_utf8(token);
        const cryptRolUser = b64_to_utf8(rolUser);
        const cryptUsername = b64_to_utf8(username);
        const cryptUserLogin = b64_to_utf8(userLogin);
        const cryptDocumentNumberAdviser = documentNumberAdviser;
        const cryptOfficeCode = b64_to_utf8(officeCode);
        const cryptIp = b64_to_utf8(ip);
        const cryptCnUserRol = b64_to_utf8(cnUserRol);

        keyToken = decryptUsingAES256(b64_to_utf8(keyToken), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        token = decryptUsingAES256(b64_to_utf8(token), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        rolUser = decryptUsingAES256(b64_to_utf8(rolUser), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        username = decryptUsingAES256(b64_to_utf8(username), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        userLogin = decryptUsingAES256(b64_to_utf8(userLogin), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        officeCode = decryptUsingAES256(b64_to_utf8(officeCode), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        ip = decryptUsingAES256(b64_to_utf8(ip), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        cnUserRol = decryptUsingAES256(b64_to_utf8(cnUserRol), b64_to_utf8(documentNumberAdviser), b64_to_utf8(process.env.REACT_APP_AES_KEY));
        documentNumberAdviser = b64_to_utf8((documentNumberAdviser));
      if (!!keyToken && !!token && !!documentNumberAdviser && !!rolUser && !(!!sessionStorage.getItem('token'))) {
        sessionStorage.clear();
        sessionStorage.setItem("APP",app); 
        sessionStorage.setItem('keyToken', cryptKeyToken);
        sessionStorage.setItem('keyTokenRenew', getParam('cryptKeyToken'));
        sessionStorage.setItem('token', cryptToken);
        sessionStorage.setItem('tokenRenew', getParam('cryptToken'));
        sessionStorage.setItem('documentNumberAdviser', cryptDocumentNumberAdviser);
        sessionStorage.setItem('rolUser', cryptRolUser);
        sessionStorage.setItem('username', cryptUsername);
        sessionStorage.setItem('userLogin', cryptUserLogin);
        sessionStorage.setItem('officeCode', cryptOfficeCode);
        sessionStorage.setItem('ip', cryptIp);
        sessionStorage.setItem('cnUserRol', cryptCnUserRol);
        sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', documentNumberAdviser, b64_to_utf8(process.env.REACT_APP_AES_KEY)));
      } 
    } else {
      sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), b64_to_utf8(process.env.REACT_APP_AES_KEY)));
    }
  }

  global.isGroupDisbursement = function(){
    return b64_to_utf8(sessionStorage.getItem('management')) === 'groupDisbursement';
  }

  global.isLibrances = function(){
    return b64_to_utf8(sessionStorage.getItem('management')) === 'librances';
  }
  global.isGroupVive = function(){
    return b64_to_utf8(sessionStorage.getItem('management')) === 'groupVive';
  }

  global.translateCopies = function(copy){
    const listCopies = JSON.parse(sessionStorage.getItem('listCopies'));
    if (!listCopies || !copy) {
      return copy;
    }
    Object.keys(listCopies).forEach(element => {
      if(element.toUpperCase() === copy.toUpperCase()) {
        copy = listCopies[element];
      }
    });

    return copy;
  }

global.getDateFormater = () => {
  let dateFormat = `${new Date().toISOString()}`;
  dateFormat = dateFormat.split('.')[0];
  return dateFormat;
}

global.valitaDateFormater = (date) => {
  const regDate = RegExp(/^(19|20)\d\d-(0[1-9]|1[012])-([012]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/);
  return regDate.test(date);
}
global.formatTransformDate = (date) => {
  let year, month, day, hour, minute;

  date = date.replace(' ', 'T');
  let dateInput = date.split('T')[0];
  const hourDate = date.toLowerCase().split('t')[1];

  ['pm','p.m','p.m.'].forEach(typeAM => {
    if (hourDate.search(typeAM) >= 0) {
      hour = hourDate.split(':')[0];
      hour = parseInt(hour) + 12;
    }
  });

  if (!hour) {
    hour = hourDate.split(':')[0];
  }

  minute = hourDate.split(':')[1].substr(0,2);

  const separator = dateInput.search('-') >= 0 ? '-' : '/';
  if (dateInput.split(separator)[0].length >= 4) {
    year = dateInput.split(separator)[0];
  } else {
    day = dateInput.split(separator)[0];
  }
  month = dateInput.split(separator)[1];
  if (!!day) {
    year = dateInput.split(separator)[2];
  } else {
    day = dateInput.split(separator)[2];
  }

  return `${year}-${month}-${day}T${hour}:${minute}:00`
}

function App() {
  console.log(`Estoy en DEMANDA EN LINEA. Ambiente --> ${process.env.REACT_APP_ENV}`)
  const app = sessionStorage.getItem("APP") ?? getParam('orderDisb');
  app === '1' ? intitValuesOrder() : initValues();
  const cryptIsAuthenticated = sessionStorage.getItem('isAuthenticated');
  const isAuthenticated = decryptUsingAES256(cryptIsAuthenticated,  b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), b64_to_utf8(process.env.REACT_APP_AES_KEY));
  const isAuth = isAuthenticated === 'true';
  const[isActive,setIsActive]=useState(true)
  const[isLogout,setLogout]=useState(false)
  const[showModalP,setshowModalP]=useState(false)
  getListCopies();
  
  return (
    <Router>
      <Provider store={store}>
        <NewTokenComponent
          closeSession={isLogout}
        />
        <IdleTimeOutHandler
          onShowModal={()=>{setshowModalP(true)}}
          offShowModal={()=>{setshowModalP(false)}}
          onActive={()=>{setIsActive(true)}} 
          onIdle={()=>{setIsActive(false)}}
          onLogout={()=>{setLogout(true)}}
        /> 
        <TimegapClose
            showModal={showModalP}
        />
        <Header/>
          <div className="container" style={{paddingTop:"100px"}}>
            {
              app === '1' ?
              <Switch >
                    <Route exact path="/" component={HomePageOrder}/> 
                    <Route exact path="/orderDisbursement" component={ ReportOrder }/>
                    <Route exact path="/documents" component={DocumentsView}/>
                    <Route exact path="/order-sends" component={OrderSendsView}/>
                    <PrivateRoute authed={isAuth} path="/documentsDC" component={DocumentsDCView} />

              </Switch>
              :
              <Switch >
                <Route exact path="/" component={HomePage} />
                <PrivateRoute authed={true} path="/orderDisbursement" component={ ReportOrder }/>
                <PrivateRoute authed={true} path="/order-sends" component={ OrderSendsView }/>
                <PrivateRoute authed={true} path="/documents" component={ DocumentsView }/>
                <PrivateRoute exact authed={isAuth} path="/finantial-leader" component={Leader}/>
                <PrivateRoute authed={isAuth} path="/finantial-leader/cases" component={Cases} />
                <PrivateRoute authed={isAuth} path="/admin-kardex" component={LeaderKardex} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/settings-priority" component={PrioritizationSettings} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/settings-priority-housing" component={PrioritizationSettingsHousing} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/settings-librances" component={PrioritizationSettingsLibrances} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/settings-center-disbursement" component={PrioritizationSettingsDisbursement} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/settings-parameters-disbursement" component={ProductivitySettingsByProduct} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/prioritize/:id" component={Prioritize} />
                <PrivateRoute authed={isAuth} path="/finantial-leader/reasignate/:id" component={Reasignate} />
                <PrivateRoute authed={isAuth} path="/finantial-analyst" component={Analyst} />
                <PrivateRoute authed={isAuth} path="/documentsDC" component={DocumentsDCView} />
                <PrivateRoute exact authed={isAuth} path="/menu-disbursement" component={MenuDisbursement} />
                <PrivateRoute authed={isAuth} path="/report-cases/disbursement" component={DisbursementCases}/>
                <PrivateRoute authed={isAuth} path="/cases-gpo" component={ReportCasesGpo}/>
                <PrivateRoute authed={isAuth} path="/reserve-guarantee/reserve" component={ReserveGuarantee}/>
                <PrivateRoute authed={isAuth} path="/fng" component={ FngHome }/>
                <PrivateRoute authed={isAuth} path="/report-productivity" component={ ReportProductivity }/>
                <PrivateRoute authed={isAuth} path="/staff-admin" component={ StaffManagement }/>
                <PrivateRoute authed={isAuth} path="/leader-productivity" component={ LeaderProductivity }/>
                <PrivateRoute authed={isAuth} path="/train-docs-ocr" component={ TrainDocsOCR }/>
                <PrivateRoute authed={isAuth} path="/upload-lrg-files" component={ UploadLargeFiles }/>
                <PrivateRoute authed={isAuth} path="/analyze-ocr-docs" component={ OCRAnalyzeView }/>
                <PrivateRoute authed={isAuth} path="/fng-report" component={ FngReport }/>
                <PrivateRoute authed={isAuth} path="/" component/>

              </Switch>

            }
          </div>
      </Provider>
    </Router>
  );
}

export default App;
