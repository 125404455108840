import { REDIRECT_BACK_LINK, REDIRECT_BACK_LINK_INIT } from '../bdb-types/index';

export function redirectBackAction(event) {
    return async (dispatch) => {
        dispatch( redirectBack(event) );
    }
}

const redirectBack = option => ({
    type: REDIRECT_BACK_LINK,
    payload: option
});

export function redirectBackInitAction(event) {
    return async (dispatch) => {
        dispatch( redirectBackInit(event) );
    }
}

const redirectBackInit = option => ({
    type: REDIRECT_BACK_LINK_INIT,
    payload: option
});