import React, { Fragment, useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUser } from '../../bdb-components/StaffManagmentComponent/UpdateUserStaff';
import UploadFileStaff from '../../bdb-components/StaffManagmentComponent/UploadFileStaff';
import DownloadCtrlBrdCSV from '../../bdb-components/DownloadCtrlBrdCSVComponent/DownloadCSV';
import EditKardex from '../../bdb-components/EditKardexComponent/EditKardex';
import { dateChangeEdit } from '../../bdb-actions/kardexActions';
import { idmRootAdminProd } from '../../../constants';
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';

const actions = {
    user: <UpdateUser />,
    fileUpsStaff: <UploadFileStaff
        sendTypeFileProp = {'StaffUser'}
        titleProp={'Administración planta - archivo'}
        warningProp={'Recuerda, solo podrás subir archivos xlsx.'}
        typeFileProp={'Planta DNO'}
    />,
    fileUpProcess: <UploadFileStaff
        sendTypeFileProp = {'ProcessConfig'}
        titleProp={'Administración de procesos'}
        warningProp={'Recuerda, solo podrás subir archivos xlsx.'}
        typeFileProp={'Procesos DNO'}
    />,
    downloadCSV: <DownloadCtrlBrdCSV
        titleProp={'Descarga de Datos diarios'}
        warningProp={'Solo podrás obtener archivos en formato csv.'}
    />
};

const StaffManagement = () =>{
    const dispatch = useDispatch();
    const rolDecrypt = getdecrypt('rolUser');
    const [selectComponent, setSelectComponent] = useState( actions.user );

    const handleClick = ( {target }) => {
        setSelectComponent( actions[target.id]) 
    }
    useEffect (() =>{
        dispatch(dateChangeEdit(null))
    },[])

    
    return ( 
        <Fragment>
            <div className='d-flex flex-column font-OpenSans-Regular'>
                <div className='container-admin'>
                    <EditKardex></EditKardex>
                </div>
                <div className='m-auto'>
                    <span id="user" className={`select-component-staff ${selectComponent === actions.user && '_selected-link'}`} onClick={ handleClick } >Planta por usuario</span>
                    <span className='_updateUser-tittle-form separator-margin'>|</span>
                    <span id="fileUpsStaff" className={`select-component-staff ${selectComponent === actions.fileUpsStaff && '_selected-link'}`} onClick={ handleClick } >Planta por archivo</span>
                    <span className='_updateUser-tittle-form separator-margin'>|</span>
                    <span id="fileUpProcess" className={`select-component-staff ${selectComponent === actions.fileUpProcess && '_selected-link'}`} onClick={ handleClick } >Admin. procesos</span>
                    { idmRootAdminProd.includes(rolDecrypt)  ?
                        <><span className='_updateUser-tittle-form separator-margin'>|</span><span id="downloadCSV" className={`select-component-staff ${selectComponent === actions.downloadCSV && '_selected-link'}`} onClick={handleClick}>Descarga</span></>
                    : null }
                </div>
                    { selectComponent }
            </div>
        </Fragment>
    );
}
export default StaffManagement;