import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from "uuid";
import Loading from '../../../bdb-components/LoadingComponent/Loading';
import BackLink from '../../../bdb-components/BackLinkComponent/BackLink'
import {productsList, processList, subProcessList, processBtnOpcionsList} from '../../../../constants';
import SimpleSelectComponent from '../../../bdb-components/SimpleSelectComponent/SimpleSelectComponent';
import {getCaseReportAction, setCaseReportAction,saveSatusCase, deleteCaseReportSuccess,
    setIndicatorsAction, deleteCaseReportAction, updateDisabledAction,resetIndicator, setFinishRequests, updateAlertToast} from '../../../bdb-actions/reportDisbursementActions';
import { openCasesGpo } from '../../../bdb-actions/analystActions';
import { useHistory } from 'react-router-dom';
import {b64_to_utf8_encrypt, getCaseGpoOpen, getdecrypt} from '../../../bdb-services/GeneralService/globalService'
import '../reportCases.css';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Swal from 'sweetalert2';

const DisbursementReportCases = () => { 

    const dispatch = useDispatch();
    const history = useHistory();
    const options = useSelector (state => state.simplySelect.multipleOption );
    const caseData = useSelector(state => state.reportDisbursement.case );
    const loading = useSelector(state => state.reportDisbursement.messageLoading);
    const alert = useSelector(state => state.reportDisbursement.alert);
    const deleteCase = useSelector(state => state.reportDisbursement.keyToDelete);
    const statusCase = useSelector(state => state.reportDisbursement.statusCase);
    const disabled = useSelector(state => state.reportDisbursement.disabled);
    const saveCasePaused = useSelector(state => state.reportDisbursement.saveCasePaused);
    const redirect = useSelector(state => state.backLink.redirect);
    const casesPause = useSelector ( state => state.analyst.getCaseGpo );
    const finishReqs = useSelector( state => state.reportDisbursement.finishReqs)

    const [requestNumber, setRequestNumber] = useState(null);
    const [requestDate, setRequestDate] = useState('');
    const [product, setProduct] = useState(null);
    const [process, setProcess] = useState(null);
    const [subProcess, setSubProcess] = useState(null);
    const [beingUserDate, setBeingUserDate] = useState(null);

    const [showCalendar, setShowCalendar] = useState(false);
    const [errorDateLabel, setErrorDateLabel] = useState(null);
    

    const [showButtonStart, setShowButtonStart] = useState(true);
    const [disableBtnStart, setDisableBtnStart] = useState(true);
    const [showSubProcess, setShowSubProcess] = useState(false);
    const [buttonsList, setButtosList] = useState({});


    useEffect(() => {
        setShowButtonStart(!caseData);
        setRequestDate(!!caseData ? caseData.requestDate : '');
        setBeingUserDate(!!caseData ? caseData.beingUserDate : '');
    }, [caseData]);
    
    useEffect(()=>{
        if(finishReqs){
            if (statusCase === 'Pausado') history.push('/cases-gpo');
            setTimeout(() => {
                dispatch( updateAlertToast(null))
            }, 3000);
        };
    },[finishReqs]);

    
    useEffect(() => {
        dispatch(setFinishRequests(false));
        if(!!deleteCase || redirect) {
            setDisableBtnStart(true);
            setRequestNumber('');
            setRequestDate('');
            setProduct(null);
            setProcess(null);
            setSubProcess(null);
            setBeingUserDate(null);
            setButtosList(null);

            if (redirect) {
                dispatch(updateDisabledAction());
            }
        }
    });

    useEffect(() => {
        setDisableBtnStart(true);
        options.forEach(element => {
            if (element['id'] === 'productOption') {
                setProduct(element['option']);
            };
            if (element['id'] === 'processOption') {
                setProcess(element['option']);
            };
            if (element['id'] === 'subProcessOption') {
                setSubProcess(element['option']);
            };
        });
    }, [options]);

    useEffect(() => {

        setDisableBtnStart(true);
        setShowSubProcess(false);

        if (!!product && !!process && !!subProcess) {
            setButtosList(null);

            if (!!requestDate && !!requestNumber && !errorDateLabel) { 
                setDisableBtnStart(false); 
            }

            setShowSubProcess(process['id'] === 'disbursement' && product['id'] !== 'subsPremiumLiabilities');
            setButtosList(processBtnOpcionsList[product['id']][process['id']][subProcess['id']]);  
            return;

        } else if (!!product && !!process) {
            setShowSubProcess(false);

            if (!!requestDate && !!requestNumber && !errorDateLabel && (process['id']!=='disbursement' || product['id'] === 'subsPremiumLiabilities')) { 
                setDisableBtnStart(false); 
            }
            setShowSubProcess(process['id'] === 'disbursement' && product['id'] !== 'subsPremiumLiabilities');
            setButtosList(process['id'] === 'disbursement' && product['id'] !== 'subsPremiumLiabilities'
                 ? null : processBtnOpcionsList[product['id']][process['id']]);
            return;
        }
    });
    
    const onChangeForm = e => {
        if (e.target.name === 'reportCasesNumber') {
            setRequestNumber(e.target.value);
            setDisableBtnStart(true);
        };
        if (e.target.name === 'reportCasesDate') {
            let dateValue = e.target.value;
            if (dateValue.length === 16) dateValue = `${dateValue}:00`
            setRequestDate(dateValue);
        };
    }

    const onBlurForm = e => {
        if (e.target.name === 'reportCasesNumber' && !!e.target.value) {
            dispatch(getCaseReportAction(e.target.value));
        };
        if (e.target.name === 'reportCasesDate' && !!e.target.value ) {
            const value = global.formatTransformDate(e.target.value);
            setRequestDate(value);
            setErrorDateLabel(!global.valitaDateFormater(value) ? 
                `La fecha ${e.target.value} es inválida.` : null);
        };
        
    }
 
    const getElementByList = (field, list) => {
        if (list === 'productsList') {
            return !!caseData ?
            productsList.filter(element => element.label === caseData[field])[0] : null;
        }
        if (list === 'processList') {
            return !!caseData ?
            processList.filter(element => element.label === caseData[field])[0] : null;
        }
        if (list === 'subProcessList') {
            return !!caseData ?
            subProcessList.filter(element => element.label === caseData[field])[0] : null;
        }
    }

    const startCase = (statusCas) => {
            if(!disableBtnStart){
            getCaseGpoOpen(getdecrypt('userLogin')).then(resp=>{let caseOpen=resp;
                let showMod=true;
                if(!!caseData && !!caseOpen){
                    for(const comp in caseOpen.data.Items){
                        if(caseOpen.data.Items[comp].requestNumber===caseData.requestNumber){
                            showMod= false;
                            break;
                        }
                    }
                }
                if(!!caseOpen && caseOpen.data.Items.length>0 && showMod){
                  Swal.fire({
                      icon: 'info',
                      html: `Recuerda que es necesario que <b>pauses, apruebes o devuelvas</b> el caso ${caseOpen.data.Items[0].requestNumber}<br>para que puedas reportar un nuevo caso`,
                      showCancelButton: false,
                      confirmButtonText: 'Aceptar',
                      confirmButtonColor: '#002c76',
                    }).then(result => {
                      
                    });
                  }else{
                    if (!disableBtnStart || (!!caseData && caseData.statusCase === 'Pausado') || statusCas === 'Pausado') {     
                      let dto = {
                        requestNumber: !!requestNumber ? requestNumber : caseData.requestNumber,
                        requestDate: !!requestDate ? requestDate : caseData.requestDate ,
                        beingUserDate: !!caseData && caseData.reqStatus !== 'Pausado' ? caseData.beingUserDate: global.getDateFormater(),
                        requestUser: null,
                        product: !!product && !!product['label'] ? product['label'] :caseData.product,
                        process: !!process && !!process['label'] ? process['label'] : caseData.process,
                        subProcess: !!subProcess ? subProcess['label'] : !!caseData && caseData.subProcess ? caseData.subProcess : null,
                        fgnReserve: false,
                        statusCase: !!statusCas ? statusCas :null,
                        id: !!caseData?.id ? caseData['id'] :uuid()
                    };
                    dto['idProductivity'] = !!caseData?.idProductivity ? caseData['idProductivity'] : dto['id'];
                    setBeingUserDate(dto['beingUserDate']);
                    dispatch( setCaseReportAction(dto));
                    setShowButtonStart(false);
                  }}
                }); 
            
            }           
    }
    

    const   starIndicators = state => {

        const dto = {
            requestNumber: caseData.requestNumber,
            starCase: !!requestDate ? requestDate : openCasesGpo.requestDate,
            starCaseAdviser: beingUserDate,
            endCaseAdviser: global.getDateFormater(),
            state: state,
            product: product['label'],
            process: process['label'],
            subprocess: !!subProcess ? subProcess['label'] : '',
            user: null,
            id: !!caseData?.id ? caseData['id'] :uuid()
        }
        dto['idProductivity'] = !!caseData?.idProductivity ? caseData['idProductivity'] : dto['id'];

        dispatch(saveSatusCase(state))
        dispatch( setIndicatorsAction(dto));
        startCase(state);
        if( state !== 'Pausado') {
            const numberCase = b64_to_utf8_encrypt(requestNumber)
            sessionStorage.setItem('numberCase', numberCase);
        } 
    }
    const resetInfo = () =>{

        dispatch(resetIndicator(null))
        dispatch( deleteCaseReportSuccess(null) );
        setRequestNumber('')
    }

    return ( 
        <Fragment>
            <div onClick={resetInfo}>
                <BackLink url={ !!casesPause && casesPause.length > 0 ? '/cases-gpo':'/menu-disbursement'}/>
            </div>
            { !!loading ? <Loading message={loading}/> : null }
            <div className="report-case-content">
                <div className="report-case-header">
                    <div className="report-case-title">Reportar caso</div>
                </div>
                {
                    !!alert ?
                    <div className="case-report-content-alert">
                        <div className="case-report-alert case-report-text-alert">{alert}</div>
                    </div> : null
                }
                {
                    !!errorDateLabel ?
                    <div className="case-report-content-alert-error">
                        <div className="case-report-alert-error case-report-text-alert-error">{errorDateLabel}</div>
                    </div> : null
                }
                <div className="report-case-section">
                    <div className="report-cases-box">
                        <div className="d-flex">
                            <div className="report-cases-box-title">Desembolso</div>
                        </div>
                        <div className="report-case-content-row">
                            <div className="report-cases-box-full">
                                <div className="report-cases-box-middle report-cases-box-label">Número de caso</div>
                                <div className="report-cases-box-middle report-cases-box-label">Fecha y hora de entrada &nbsp;
                                <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Seleccionar en el calendario
                                    </Tooltip>
                                }>
                                     <label className="switch">
                                        <input type="checkbox" onChange={() => setShowCalendar(!showCalendar)}></input>
                                        <span className="slider round"></span>
                                    </label>
                                </OverlayTrigger> 
                               
                                </div>
                            </div>
                            <div className="report-cases-box-full">
                                <div className="report-cases-box-middle">
                                    <input name="reportCasesNumber" id="reportCasesNumber" className="report-cases-input" placeholder="Ingresa el nº de caso"
                                    onBlur={e => {onBlurForm(e)}} value={!!requestNumber ? requestNumber : !!caseData && caseData.requestNumber ? caseData.requestNumber : ""} onChange={e => {onChangeForm(e)}}></input>
                                </div>
                                <div className="report-cases-box-middle">
                                    { showCalendar ? 
                                        <input type="datetime-local" step="1" name="reportCasesDate" id="reportCasesDate" className="report-cases-input" placeholder="DD/MM/AAAA"
                                        value={requestDate} onChange={e => {onChangeForm(e)}} disabled={disabled}></input> : 
                                        <input name="reportCasesDate" id="reportCasesDate" className="report-cases-input" placeholder="AAAA-MM-DDTHH:mm:ss"
                                        value={requestDate ? requestDate:""} onChange={e => {onChangeForm(e)}} onBlur={e => {onBlurForm(e)}}
                                        style={!!errorDateLabel ? {'border':' solid 1px #e4060d'} : {} } disabled={disabled}></input> 
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="report-case-content-row">
                            <div className="report-cases-box-full">
                                <div className="report-cases-box-middle report-cases-box-label">Producto</div>
                                <div className="report-cases-box-middle report-cases-box-label">Proceso</div>
                            </div>
                            <div className="report-cases-box-full">
                                <div className="report-cases-box-middle">
                                    <SimpleSelectComponent 
                                        id={'productOption'}
                                        small={false}
                                        width={'45%'}
                                        absolute={true}
                                        options={productsList}
                                        initSelect={null}
                                        autocomplete={null}
                                        placeholder={'Selecciona'}
                                        disabled={disabled}
                                        arrow={{'zIndex': '2'}}
                                        styleSelect={{height: '35px', fontSize: '16px', 'zIndex': '2'}}
                                        selected={ getElementByList('product', 'productsList') }
                                        listStyle = {{
                                            width:'389px',
                                            margin: '17px 0px 0px 0px', 
                                            fontSize: '16px',
                                            top: '62%',
                                            left: '0%',
                                            'zIndex': '1',
                                            position: 'absolute'
                                        }}
                                    />
                                </div>
                                <div className="report-cases-box-middle">
                                    <SimpleSelectComponent 
                                        id={'processOption'}
                                        small={false}
                                        width={'45%'}
                                        absolute={true}
                                        height={'35px'}
                                        options={processList} 
                                        initSelect={null}
                                        autocomplete={null}
                                        disabled={disabled}
                                        placeholder={'Selecciona'}
                                        arrow={{'zIndex': '2'}}
                                        styleSelect={{height: '35px', fontSize: '16px', 'zIndex': '2'}}
                                        selected={ getElementByList('process', 'processList') }
                                        listStyle = {{
                                            width:'389px',
                                            margin: '17px 0px 0px 0px', 
                                            fontSize: '16px',
                                            top: '62%',
                                            left: '0%',
                                            position: 'absolute'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {   showSubProcess ?
                            <div className="report-case-content-row-lonely">
                                <div className="report-cases-box-full report-cases-box-full-lonely">
                                    <div className="report-cases-box-middle report-cases-box-label">Subproceso</div>
                                </div>
                                <div className="report-cases-box-full report-cases-box-full-lonely">
                                    <div className="report-cases-box-middle">
                                        <SimpleSelectComponent 
                                            id={'subProcessOption'}
                                            small={false}
                                            width={'45%'}
                                            absolute={true}
                                            options={subProcessList} 
                                            initSelect={null}
                                            disabled={disabled}
                                            autocomplete={null}
                                            placeholder={'Selecciona'}
                                            arrow={{bottom: '26px'}}
                                            styleSelect={{height: '35px', fontSize: '16px'}}    
                                            selected={ getElementByList('subProcess', 'subProcessList') }
                                            listStyle = {{
                                                width:'389px',
                                                margin: '17px 0px 0px 0px', 
                                                fontSize: '16px',
                                                top: '62%',
                                                left: '0%',
                                                position: 'absolute'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> : null
                        }
                        { !!showButtonStart && showButtonStart ? 
                            <div className="d-flex justify-content-center w-100 report-case-btn">
                                <div onClick={() => {startCase()}}
                                className={`report-case-button${disableBtnStart? '-disabled' : ''} report-case-button-font${disableBtnStart? '-disabled' : ''}`}
                                >Iniciar caso</div>
                            </div> : 
                            <div className="d-flex justify-content-between w-100 report-case-btn">
                                {
                                    buttonsList ? 
                                    Object.keys(buttonsList).map((element, key) => (
                                        <div key={key} className="report-case-button report-case-button-font" 
                                            id={buttonsList[element]['id']} onClick={() => {starIndicators(buttonsList[element]['label'])}}
                                            >{buttonsList[element]['label']}</div>
                                        )) : null
                                }
                            </div> 
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default DisbursementReportCases;