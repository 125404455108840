import React from 'react';
import Modal from "react-bootstrap/Modal";
import { iconLoading } from '../../../assets/iconLoading'
import '../../../assets/icons';


const Loading = ({message, progressPercentage, actionCancelButton}) => {
    
    const sylesCSS = {
        loadingIcon: {
            background: `no-repeat ${iconLoading}`,
            backgroundSize: 'contain',
            height: '100px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            margin: '5% 0% 5% 0%'
        },
        textModal: {
            'textAlign': 'center',
            'color': '#545454',
            fontSize: '1.125em',
            fontWeight: '400',
            lineHeight: 'normal',
            paddingBottom: '5%',
            whiteSpace: 'pre'
        },
        cancelButton: {
            display: 'inline-block',
            backgroundColor: 'rgb(221, 51, 51)',
            border: '0',
            borderRadius: '0.25em',
            color:'#fff',
            fontSize: '1em',
            cursor: 'pointer',
            width: '25%',
            height: '30px'
        }
    }

    return ( 
        <Modal show={true} centered>
            <Modal.Body>
                <div>
                    <div style={sylesCSS.loadingIcon}>&nbsp;</div>
                    <div
                        style={sylesCSS.textModal}
                        dangerouslySetInnerHTML={{ __html:(!!message ? message : 'Cargando')}}
                    >
                    </div>
                    {
                        progressPercentage ? <div style={sylesCSS.textModal}>{`${progressPercentage.toFixed(2)} %`}</div>
                        : null
                    }
                    {
                        actionCancelButton ? <div style={{display:'flex', justifyContent:'center'}}><button style={sylesCSS.cancelButton} onClick={actionCancelButton}>Cancelar</button></div>
                        :null
                    }
                </div>
            </Modal.Body>
      </Modal>
     );
}
 
export default Loading;