import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TrainDocsOCR.scss';
import { Document, Page } from 'react-pdf';
import { onBaseTypes, trainDocsOCRKeys } from '../../../constants';
import SearchForListComponent from '../../bdb-components/SearchForListComponent/SearchForListComponent';
import ListKeyPairs from './ListKeyPairs';
import { removeFile, uploadFile, setKeyPairs, setGeometries, setPageNumber, setmaxPages, setIsDrawing, setCurrentKeyPair, setonBaseType, handleNewKey, handleSendJSON } from '../../bdb-actions/trainDocsOCRActions';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import Swal from 'sweetalert2';

const TrainDocsOCR = ({ }) => {
    const { file, keyPairs, geometries, pageNumber, maxPages, isDrawing, currentKeyPair, onBaseType, loading } = useSelector(state => state.trainDocsOCR);
    const hiddenInputFileUploadPlantFileRef = useRef( undefined );
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        if (e?.target?.files && e.target.files.length > 0) {
            const _file = e.target.files[0];
            dispatch(uploadFile(_file));
            e.target.value = null;
        }
    };

    const handleCancelDoc = async (e) => {
        await Swal.fire({
            title: '¿Estás Seguro?',
            icon: 'info',
            html:
                'Perderás todos los cambios sobre el documento actual',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#c61f01',
        }).then(result => {
            if (result.isConfirmed) dispatch(removeFile(null))
        });
    }

    const handleHelp = async (e) => {
        let tblHTML = '<table border="4" style="margin:auto;width: 80%;"><thead><tr><th>Dato</th><th>Categoría</th></tr></thead><tbody id="table-body">'
        let elemAux;
        for (const element of Object.keys(trainDocsOCRKeys)) {
            elemAux = { ...trainDocsOCRKeys[element] }
            tblHTML = tblHTML + `<tr><td>${elemAux['label']}</td><td>${elemAux['category']}</td></tr>`
        }
        tblHTML = tblHTML + '</tbody></table>'

        await Swal.fire({
            title: 'Debes recolectar la siguiente información:',
            icon: 'info',
            html: 'Seleccionar el documetos que quieres subir, Seleccionar un tipo documetal correspodiente al documento, y dar click en enviar',//tblHTML,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#002c76',
            width: "80%"
        })
    }

    const handleMouseDown = (e) => {
        if (isDrawing) {
            dispatch(setIsDrawing(false));
            if (currentKeyPair) {
                let keyPrDraw = {
                    x: Math.min(currentKeyPair.startX, currentKeyPair.endX),
                    y: Math.min(currentKeyPair.startY, currentKeyPair.endY),
                    width: Math.abs(currentKeyPair.startX - currentKeyPair.endX),
                    height: Math.abs(currentKeyPair.startY - currentKeyPair.endY)
                }
                dispatch(handleNewKey(keyPrDraw))
            }
        }
        else {
            dispatch(setIsDrawing(true));
            const { pageX, pageY } = e;
            dispatch(setCurrentKeyPair({ startX: pageX, startY: pageY, endX: pageX, endY: pageY }));
        }
    };

    const handleMouseMove = (e) => {
        if (!isDrawing) return;
        const { pageX, pageY } = e;
        dispatch(setCurrentKeyPair({ ...currentKeyPair, endX: pageX, endY: pageY }));
    };

    const onDocumentLoadSuccess = (e) => {
        dispatch(setmaxPages(e.numPages));
        let arr = []
        for (let i = 0; i < e.numPages; i++)arr.push([])
        dispatch(setKeyPairs(arr));
        dispatch(setGeometries(arr.map((e) => { return {} })));
    }
    const onPageLoadSuccess = (page) => {
        let geoms = [...geometries];
        geoms[page.pageNumber - 1] = { height: page.height, width: page.width };
        dispatch(setGeometries([...geoms]));
    };
    return (
        <div>
            {loading ? <Loading></Loading> : null}
            <div className='productivity-tittle'>Subir documento y agregar etiquetas</div>

            {/** Cargue del archivo **/}
            {!file ?
                <div className="row-inputs-trndocs">
                    <input
                        style={{display:'None'}}
                        ref={ hiddenInputFileUploadPlantFileRef }
                        type="file"
                        accept=".pdf,.png,.jpg"
                        onChange={handleFileChange}
                    />
                    <div className="button-upload-trn-dcs" onClick={()=>hiddenInputFileUploadPlantFileRef.current.click()} >
                        <div className='insert-data-dno-button-icon '></div>
                        Subir Documento
                    </div>
                    <button className='blue-btn-trndcs' onClick={handleHelp}>Ayuda</button>
                </div> :
                <div>
                    <div className="row-inputs-trndocs">
                        <div className='productivity-sub-tittle'>Documento: {file.name}</div>
                        <div>
                            <button className='blue-btn-trndcs' onClick={() => dispatch(handleSendJSON())}>Enviar</button>
                            <button className='white-btn-trndcs' onClick={handleCancelDoc}>Cancelar</button>
                        </div>
                        <button className="blue-btn-trndcs" onClick={handleHelp}>Ayuda</button>
                    </div>
                    <div className="row-inputs-trndocs">
                        <div className='productivity-sub-tittle'>Tipo Documental:</div>
                        <div style={{ width: '80%' }}>
                            <SearchForListComponent
                                idClick={'onBaseType'}
                                selectedOption={onBaseType}
                                onClickOption={(sel) => { dispatch(setonBaseType(sel?.target?.value?.id)) }}
                                options={onBaseTypes}
                                selectStyle={{
                                    height: '40px',
                                    fontSize: '16px',
                                    zIndex: '5',
                                    padding: '9px 20px 9px 16px',
                                    color: '#4a4a4a',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHheight: 'normal',
                                    letterSpacing: 'normal',
                                    borderRadius: '4px',
                                    border: 'solid 1px #d6d6d6',
                                    backgroundColor: '#ffffff',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                    paddingRight: '48px'
                                }}
                                disabled={false}
                                listStyle={{
                                    maxHeight: '167px',
                                    width: '50%',
                                    zIndex: '30',
                                    padding: '4px 6px 4px 5px',
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '2.5',
                                    letterSpacing: 'normal',
                                    color: '#4a4a4a'
                                }}
                                placeholder={'Selecciona'}
                                showArrow={true}
                                arrowStyles={{
                                    width: '15px',
                                    height: '15px',
                                    right: '18px',
                                    zIndex: '5',
                                }}
                                onActiveContent={false}
                            />
                        </div>
                    </div>
                    {/** Start Rayar el archivo **/}
                    {/* <ListKeyPairs></ListKeyPairs>
                    {file.name && file.name.toLowerCase().endsWith('.pdf') ? (
                        <>
                            <div className='btns-trndocs-prev-next-page'>
                                <div className='trn-dcs-input'>
                                    <div className={'trndcs-especial-btn'} onClick={() => { if (pageNumber > 1) dispatch(setPageNumber(pageNumber - 1)) }}>
                                        <div className='icon-prevpage-trn-docs'></div>
                                        <span className='trndcs-text-especial-btn'>
                                            Anterior
                                        </span>
                                    </div>
                                    <div className={'trndcs-especial-btn'} onClick={() => { if (pageNumber < maxPages) dispatch(setPageNumber(pageNumber + 1)) }}>
                                        <span className='trndcs-text-especial-btn'>
                                            Siguiente
                                        </span>
                                        <div className='icon-nextpage-trn-docs'></div>
                                    </div>
                                </div>
                                <div>Pagina {pageNumber} de {maxPages}</div>
                            </div>
                            <div>
                                <Document className="container-pdf-viewr" file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page
                                        pageNumber={pageNumber}
                                        onLoadSuccess={onPageLoadSuccess}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleMouseMove}
                                    />
                                </Document>
                            </div>
                        </>
                    ) : (
                        <img
                            className="container-pdf-viewr"
                            src={URL.createObjectURL(file)}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                        />
                    )}
                    {keyPairs[pageNumber - 1].map((keyPr, index) => (
                        <div
                            onMouseMove={handleMouseMove}
                            key={index}
                            style={{
                                position: 'absolute',
                                left: keyPr.x,
                                top: keyPr.y,
                                width: keyPr.width,
                                height: keyPr.height,
                                border: '2px solid blue',
                                boxShadow: keyPr.selected ? '0px 0px 20px blue' : null
                            }}
                        ></div>
                    ))}
                    {currentKeyPair && (
                        <div
                            onMouseMove={handleMouseMove}
                            style={{
                                position: 'absolute',
                                left: Math.min(currentKeyPair.startX, currentKeyPair.endX),
                                top: Math.min(currentKeyPair.startY, currentKeyPair.endY),
                                width: Math.abs(currentKeyPair.startX - currentKeyPair.endX),
                                height: Math.abs(currentKeyPair.startY - currentKeyPair.endY),
                                border: '2px solid red'
                            }}
                        ></div>
                    )}*/}
                </div>
            } 
            {/** Fin Rayar el archivo **/}
        </div>
    );
}

export default TrainDocsOCR;