import React from 'react'
import { useHistory } from 'react-router-dom'
import '../../../assets/styles/margin.scss'

export const BackLink = ( {url} ) => {
    const history = useHistory();

    const comeBack = () => {
        if ( url === 'backHomeDigitalFoler'){
            window.location.href = `${process.env.REACT_APP_LOGOUT_ENDPOINT}home-adviser/false#no-back-button`;
        } else if( url = '-1' ) {
            history.goBack();
        } else {
            history.push(url);
        }
    }
    return (
        <>
        <div className='d-flex justify-content-start' style={{ "top" : "100px", "position": "absolute"}}>
            <div className="back-link-arrow" onClick={ comeBack }></div>
            <div className="report-order-box-label ml-8" onClick={ comeBack }>Volver</div>
        </div>
        </>
    )
}
