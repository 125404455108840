import {
  UPDATE_FILE,
  UPDATE_FILE_TYPE,
  CHANGE_LOADING_VISIBILITY,
  ABORT_CASE_SUCCESS,
  UPDATE_PREVIEW_PROGRESS,
  SET_HISTORY_FILES,
} from "../bdb-types/index";
import Swal from "sweetalert2";

import {
  getAWSSessionCreds
} from "../bdb-services/GeneralService/globalService";
import AWS, { Config } from "aws-sdk";
import { StartJobRunCommand, GetJobRunsCommand, GlueClient } from "@aws-sdk/client-glue";
import { largeFileListTypes } from "../../constants";

const jobs = largeFileListTypes.map((file) => file.job);
const opf_bucket = process.env.REACT_APP_S3_BUCKET_OPF;

export const updateLoading = (state) => ({
  type: CHANGE_LOADING_VISIBILITY,
  payload: state,
});

export const uptdFile = (_file) => ({
  type: UPDATE_FILE,
  payload: _file,
});

export const uptdFileType = (_fileType) => ({
  type: UPDATE_FILE_TYPE,
  payload: _fileType,
});

export const uptdAbortStatus = (_abortStatus) => ({
  type: ABORT_CASE_SUCCESS,
  payload: _abortStatus,
});

export const uptdProgressBar = (_progressBar) => ({
  type: UPDATE_PREVIEW_PROGRESS,
  payload: _progressBar,
});

export const setHistoryFiles = (files) => ({
  type: SET_HISTORY_FILES,
  payload: files,
});

const setModal = async (status, name) => {
  await Swal.fire({
    title: status === 200 ? "¡Listo!" : "Lo sentimos",
    text: `${name} ${
      status === 200
        ? "se cargó con exito"
        : "no se pudo cargar, por favor revisa tu conexión a internet y vuelve a intentarlo en unos minutos"
    }.`,
    icon: status === 200 ? "success" : "error",
    showCloseButton: false,
    focusConfirm: false,
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#002c76",
  });
};

export function moveFailedJobs() {

}

export function uploadLargeFile() {
  return async (dispatch, getState) => {
    dispatch(getHistoryFiles())
    const selectedFile = getState().uploadLargeFiles?.selectedFile;
    const selectedFileType = getState().uploadLargeFiles?.selectedFileType;

    let confirmUpload = false;
    let uploadSize = { size: selectedFile.size / 1000, unit: "k" };
    if (uploadSize.size > 1000)
      uploadSize = { size: uploadSize.size / 1000, unit: "m" };
    if (uploadSize.size > 1000)
      uploadSize = { size: uploadSize.size / 1000, unit: "g" };

    await Swal.fire({
      title: `¿Estás seguro de cargar ${selectedFile.name}?`,
      html: `Tipo de documento: <b>${
        selectedFileType.option.label
      }</b><br>Tamaño: <b>${uploadSize.size.toFixed(2)} ${
        uploadSize.unit
      }b</b>`,
      icon: "info",
      showCloseButton: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Cargar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#002c76",
      cancelButtonColor: "#d33",
      width: "50%",
    }).then((result) => {
      if (result.isConfirmed) confirmUpload = true;
    });
    if (!confirmUpload) return;

    const historyFiles = getState().uploadLargeFiles?.historyFiles;
    const lastExecution = historyFiles.find((job)=>job.name===selectedFileType?.option?.label);
    if(sameToday(lastExecution?.date) && !isFailedJobStatus(lastExecution?.state)){
      await Swal.fire({
        title: "Lo sentimos",
        html: `<b>${selectedFileType?.option?.label}</b> ya se ha cargado y ${
          "SUCCEEDED" === lastExecution?.state
            ? "procesado exitosamente el día de hoy"
            : "se está procesando en este momento"
        }.`,
        icon: "info",
        showCloseButton: false,
        focusConfirm: false,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#002c76",
      });
      if (["PRODUCTION"].includes(process.env.REACT_APP_ENV) || lastExecution?.state !== "SUCCEEDED")
        return
    }

    const tiempoInicio = new Date();
    // Configura el cliente de AWS S3
    const AWSCreds = await getAWSSessionCreds();

    dispatch(updateLoading(true));
    const creds = {
      accessKeyId: AWSCreds[0],
      secretAccessKey: AWSCreds[1],
      sessionToken: AWSCreds[2],
    }
    const s3 = new AWS.S3(creds);
    await moveFailedFiles(s3, selectedFileType, selectedFile.name)
    // Configura los parámetros para la carga del archivo
    const params = {
      Bucket: opf_bucket,
      Key: `large_files/${selectedFileType.option.id}/${Date.now()}_${
        selectedFile.name
      }`,
    };
    let status = 500;

    // Inicia la carga Normalita
    params.Body = selectedFile;
    const uploadRequest = s3.upload(params);
    uploadRequest
      .on("httpUploadProgress", (progressEvent) => {
        const uploadedBytes = progressEvent.loaded;
        const totalBytes = progressEvent.total;
        const uploadProgress = (uploadedBytes / totalBytes) * 100;

        let abortStatus = getState().uploadLargeFiles.abortStatus;
        dispatch(uptdProgressBar(uploadProgress));
        if (abortStatus) {
          uploadRequest.abort();
          dispatch(uptdAbortStatus(false));
          dispatch(uptdProgressBar(0));
        }
      })
      .send(async (err, data) => {
        if (err?.name === "RequestAbortedError") status = -1;
        else if (err) console.error(err);
        else status = 200;

        if (status !== -1) {
          setModal(status, selectedFile.name);
          if (status === 200)
            dispatch(runGLUEJob(selectedFileType?.option))
        }
        dispatch(updateLoading(false));
        dispatch(uptdFile(null));
        dispatch(uptdFileType(null));
        dispatch(uptdProgressBar(0));
        const tiempoFin = new Date();
        const tiempoTotal = tiempoFin - tiempoInicio;
        console.log(`Tiempo de ejecución: ${tiempoTotal / 1000} Segundos`);
      });
  };
}

export function getHistoryFiles() {
  return async (dispatch) => {

    const AWSCreds = await getAWSSessionCreds();
    const config = new Config({
      credentials: {
        accessKeyId: AWSCreds[0],
        secretAccessKey: AWSCreds[1],
        sessionToken: AWSCreds[2],
      },
      region: "us-east-1",
    });
    const client = new GlueClient(config);

    Promise.all(
      jobs.map((job) => {
        return new Promise((resolve, reject) => {
          const command = new GetJobRunsCommand({ JobName: job, MaxResults: 3 });
          client
            .send(command)
            .then((resp) => {
              //console.log("Response glue", resp);
              resolve(resp.JobRuns);
            })
            .catch((err) => {
              console.error(err);
              reject([]);
            });
        });
      })
    )
    .then((values) => {
        dispatch(setHistoryFiles( mapJobsRuns(values) ))
    })
    .catch((err) => {
        dispatch(setHistoryFiles([]))
    });
  };
}

export function runGLUEJob(jobInfo) {
  return async (dispatch) => {

    const AWSCreds = await getAWSSessionCreds()

    const config = new Config({
      credentials: {
        accessKeyId: AWSCreds[0],
        secretAccessKey: AWSCreds[1],
        sessionToken: AWSCreds[2],
      },
      region: "us-east-1",
    });
    const client = new GlueClient(config);

    const command = new StartJobRunCommand({
      JobName: jobInfo?.job
    });
    client
      .send(command)
      .then((resp) => {
        //console.log("Response Start Job", resp);
        dispatch(getHistoryFiles())
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

function mapJobsRuns(listJobs){
    let listJobsMapped = []
    for (const job of listJobs) {
        const jobMapped = job.map((info) => ({
            date: info?.StartedOn,
            state: info?.JobRunState,
            name: largeFileListTypes.filter((file) => file.job === info?.JobName)[0].label 
        }))
        listJobsMapped = [...listJobsMapped, ...jobMapped]
    }
    listJobsMapped.sort(function(a,b){
        return b.date - a.date;
      });
   return listJobsMapped
}

function sameToday(_date) {
  if (!_date) return false
  const today = new Date()
  return _date.getFullYear() === today.getFullYear() && _date.getMonth() === today.getMonth() && _date.getDate() === today.getDate()
}

async function moveFailedFiles(s3, selectedFileType, actualFileName) {
  try {
    const listaObjetos = await s3.listObjectsV2({ Bucket: opf_bucket, Prefix: `large_files/${selectedFileType.option.id}` }).promise();
    
    if (listaObjetos.Contents.length === 0) {
      return;
    }

    // Mueve cada archivo a la nueva ubicación
    await Promise.all(listaObjetos.Contents.map(async (objeto) => {
      const origenKey = objeto.Key;
      let origenFileName = origenKey.split("/")
      origenFileName = origenFileName[origenFileName.length-1]
      const destinoKey = `glue/failed_files/${origenFileName}`;

      if( actualFileName !== origenFileName){
        await s3.copyObject({
          Bucket: opf_bucket,
          CopySource: `${opf_bucket}/${encodeURI(origenKey)}`,
          Key: destinoKey,
        }).promise();
  
        // Elimina el archivo del path original si es necesario
        await s3.deleteObject({ Bucket: opf_bucket, Key: origenKey }).promise();
        console.log(`Archivo movido de ${origenKey} a ${destinoKey}`);
      }
    }));
  } catch (error) {
    console.error('Error al obtener o mover archivos:', error);
  }
}

function isFailedJobStatus(state){
  return ["FAILED", "TIMEOUT", "ERROR", "STOPPED"].includes(state)
}