import { OPTION_SELECTED_COMPONENT } from '../bdb-types/index';

export function setOptionSelectedComponent(optionList) {
    return async (dispatch) => {
        dispatch( optionSelected(optionList) );
    }
}

export function cleanOptionSelectedComponent() {
    return async (dispatch) => {
        dispatch( optionSelected(null) );
    }
}

const optionSelected = option => ({
    type: OPTION_SELECTED_COMPONENT,
    payload: option
});