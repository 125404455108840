import {
  UPDATE_FILE,
  UPDATE_FILE_TYPE,
  CHANGE_LOADING_VISIBILITY,
  ABORT_CASE_SUCCESS,
  UPDATE_PREVIEW_PROGRESS,
  SET_HISTORY_FILES,
} from "../bdb-types/index";
import Swal from "sweetalert2";
import { getAWSSessionCreds } from "../bdb-services/GeneralService/globalService";
import AWS, { Config } from "aws-sdk";
import { getOCRInfo } from '../bdb-services/GeneralService/globalService';

const opf_bucket = process.env.REACT_APP_S3_BUCKET_OPF;
const opf_ocr_dyn_table = process.env.REACT_APP_DYN_OCR_TABLE;

export const updateLoading = (state) => ({
  type: CHANGE_LOADING_VISIBILITY,
  payload: state,
});

export const uptdFile = (_file) => ({
  type: UPDATE_FILE,
  payload: _file,
});

export const uptdFileType = (_fileType) => ({
  type: UPDATE_FILE_TYPE,
  payload: _fileType,
});

export const uptdAbortStatus = (_abortStatus) => ({
  type: ABORT_CASE_SUCCESS,
  payload: _abortStatus,
});

export const uptdProgressBar = (_progressBar) => ({
  type: UPDATE_PREVIEW_PROGRESS,
  payload: _progressBar,
});

export const setHistoryFiles = (files) => ({
  type: SET_HISTORY_FILES,
  payload: files,
});

const setModal = async (status, name) => {
  await Swal.fire({
    title: status === 200 ? "¡Listo!" : "Lo sentimos",
    text: `${name} ${
      status === 200
        ? "se cargó con exito y será analizado."
        : "no se pudo cargar, por favor revisa tu conexión a internet y vuelve a intentarlo en unos minutos"
    }.`,
    icon: status === 200 ? "success" : "error",
    showCloseButton: false,
    focusConfirm: false,
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#002c76",
  });
};

export function uploadOCRFile() {
  return async (dispatch, getState) => {
    dispatch(getHistoryFiles())
    const selectedFile = getState().OCRAnalyzeDocuments?.selectedFile;
    const selectedFileType = getState().OCRAnalyzeDocuments?.selectedFileType;
    const normalizedFileName = selectedFile.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    let confirmUpload = false;
    let uploadSize = { size: selectedFile.size / 1000, unit: "k" };
    if (uploadSize.size > 1000)
      uploadSize = { size: uploadSize.size / 1000, unit: "m" };
    if (uploadSize.size > 1000)
      uploadSize = { size: uploadSize.size / 1000, unit: "g" };

    await Swal.fire({
      title: `¿Estás seguro de analizar ${normalizedFileName}?`,
      html: `Tipo de documento: <b>${
        selectedFileType.option.label
      }</b><br>Tamaño: <b>${uploadSize.size.toFixed(2)} ${
        uploadSize.unit
      }b</b>`,
      icon: "info",
      showCloseButton: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Cargar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#002c76",
      cancelButtonColor: "#d33",
      width: "50%",
    }).then((result) => {
      if (result.isConfirmed) confirmUpload = true;
    });
    if (!confirmUpload) return;

    const tiempoInicio = new Date();
    // Configura el cliente de AWS S3
    const AWSCreds = await getAWSSessionCreds();

    dispatch(updateLoading(true));
    const creds = {
      accessKeyId: AWSCreds[0],
      secretAccessKey: AWSCreds[1],
      sessionToken: AWSCreds[2],
    }
    const s3 = new AWS.S3(creds);
    // Configura los parámetros para la carga del archivo
    const params = {
      Bucket: opf_bucket,
      Key: `OCR/${selectedFileType.option.id.replaceAll("/","-_-")}_-_${Date.now()}_-_${normalizedFileName}`,
    };
    let status = 500;

    // Inicia la carga Normalita
    params.Body = selectedFile;
    const uploadRequest = s3.upload(params);
    uploadRequest
      .on("httpUploadProgress", (progressEvent) => {
        const uploadedBytes = progressEvent.loaded;
        const totalBytes = progressEvent.total;
        const uploadProgress = (uploadedBytes / totalBytes) * 100;

        let abortStatus = getState().OCRAnalyzeDocuments.abortStatus;
        dispatch(uptdProgressBar(uploadProgress));
        if (abortStatus) {
          uploadRequest.abort();
          dispatch(uptdAbortStatus(false));
          dispatch(uptdProgressBar(0));
        }
      })
      .send(async (err, data) => {
        if (err?.name === "RequestAbortedError") status = -1;
        else if (err) console.error(err);
        else status = 200;

        if (status !== -1) {
          setModal(status, normalizedFileName);
        }
        dispatch(updateLoading(false));
        dispatch(uptdFile(null));
        dispatch(uptdFileType(null));
        dispatch(uptdProgressBar(0));
        const tiempoFin = new Date();
        const tiempoTotal = tiempoFin - tiempoInicio;
        console.log(`Tiempo de ejecución: ${tiempoTotal / 1000} Segundos`);
      });
  };
}

export function getHistoryFiles() {
  return async (dispatch) => {

    const AWSCreds = await getAWSSessionCreds();
    const config = new Config({
      credentials: {
        accessKeyId: AWSCreds[0],
        secretAccessKey: AWSCreds[1],
        sessionToken: AWSCreds[2],
      },
      region: "us-east-1",
    });
    const dynamodb = new AWS.DynamoDB.DocumentClient(config);
    const params = {
      ScanIndexForward: false,
      TableName: opf_ocr_dyn_table,
      KeyConditionExpression: '#pk = :pk',
      ExpressionAttributeNames: {
        '#pk': 'pk'
      },
      ExpressionAttributeValues: {
        ':pk': '_'
      }
    };
    dynamodb.query(params, (err, data) => {
      if (err) {
        console.error('Error al consultar la tabla DynamoDB:', err);
        return;
      }
      const Items = data.Items.map(mp => {return {...mp, viewName: mp.fileName.split("_-_")[2]}})
      const processingItems = Items.filter(itm => itm.status === "PROCESSING")
      for (const pr of processingItems) getOCRInfo(pr.docHandle);
      dispatch(setHistoryFiles( Items ))
    });
  };
}