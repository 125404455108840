import React, { useCallback, useEffect, useState } from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { idmRootAdminProd } from '../../../constants';
import { getAreasAndProcesses, getManagementsByVertical, getUserStaff, populateUsersStaff, removeUserStaff, removePositionStaff, resetFormUserStaff, searchValueStaff, setErrorSearch, updateFormUserStaff, updateSearchValue } from '../../bdb-actions/plantActions';
import Loading from '../LoadingComponent/Loading';
import SearchForListComponent from '../SearchForListComponent/SearchForListComponent';
import "./_updateUser.scss";
import { getdecrypt } from '../../bdb-services/global/global';

const tittle = "Administración planta - usuario";
const infoNew = "Selecciona el tipo de novedad que quieres reportar";
const tittleForm = "Diligencia la información solicitada del usuario.";
const invalidDocumet = "El documento debe tener mínimo 6 dígitos";
const searchInfo = "Documento o código de posición del usuario";
const searchError = "Código de posición o documento incorrecto";
const removeInfo = "¿Estás seguro que deseas retirar este usuario?";
const removePosition = "¿Estás seguro que deseas eliminar este código de posición?";

const toggleOptions = [
    {value: 0, name: "Ingresar/modificar", component: <AddOrModifyUser /> },
    {value: 1, name: "Retirar", component: <RemoveUser /> },
    {value: 2, name: "Eliminar", component: <RemovePosition /> },
];

const inputsStructure = [
    {
        id: "nameUser",
        label: "Nombre completo del usuario",
        placeholder: "Ingresa nombre completo"
    },
    {
        id: "documentUser",
        label: "Documento del usuario",
        placeholder: "#",
        minLength: 5,
        maxLength: 15
    },
    {
        id: "positionCode",
        label: "Código de posición",
        placeholder: "Ingresa el código"
    }
];

const inputStyles = {
    height: '40px',
    fontSize: '16px',
    zIndex: '5',
    padding: '9px 20px 9px 16px',
    color: '#4a4a4a',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHheight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #d6d6d6',
    backgroundColor: '#ffffff',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingRight: '48px'
};

const styleArrow = {
    width: '15px',
    height: '15px',
    right: '18px',
    zIndex: '5',
};
const listStyles = {
    maxHeight: '167px', 
    width: '50%',
    zIndex: '30',
    padding: '4px 6px 4px 5px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.5',
    letterSpacing: 'normal',
    color: '#4a4a4a'
};

const enablesInitialState = {
    save: false,
    cancel: false
};
const buttonsStructure = [
    {
        id: "save",
        label: "Guardar",
        class: "_updateUser-save-button"
    },
    {
        id: "cancel",
        label: "Cancelar",
        class: "_updateUser-cancel-button"
    }
];

const propertiesUserTable = [
    { header: "Nombre", field: "fullName", width: "21%"},
    { header: "Documento", field: "documentNumber", width: "13%"},
    { header: "Código de posición", field: "positionCode", width: "16%"},
    { header: "Gerencia", field: "management", width: "21%"},
    { header: "Rol", field: "role", width: "17%"},
    { header: "", field: "button"},
]
export const UpdateUser = () => {
    const [selectComponent, setSelectComponent] = useState( null );
    const { loading } = useSelector( state => state.plant );

    const handleChange = ( e ) => setSelectComponent( toggleOptions[ e ] )
    
  return (
    <div>
        { loading && <Loading />}
        <h4 data-cy="tittle-admin-staff" className='_updateUser-grand-tittle mt-grand mb-34'>{tittle}</h4>
        <div className='_updateUser-box-select-new mb-34'>
            <span className='_updateUser-subtittle'>{infoNew}</span>
            <ButtonGroup className="w-100 bg-white mt-2 " size='sm' toggle>
                {toggleOptions.map((row, idx) => (
                    <ToggleButton 
                        key={idx}
                        id={idx}
                        type="radio"
                        variant="outline-success"
                        name="radio"
                        className='w-50'
                        checked={ selectComponent?.value === row.value}
                        value={row.value} 
                        onChange={ () => handleChange(row.value) }
                    >
                        {row.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>

        </div>
        {
            selectComponent?.component
        }

    </div>
  )
}

function AddOrModifyUser () {

    const actionsList = {
        save: saveChangesAction,
        cancel: resetFormAction
    };

    const dispatch = useDispatch();

    const [ enables, setEnables ] = useState(enablesInitialState)
    const { userInfo, verticalsAndManagements, areas, processes } = useSelector( state => state.plant );
    const { nameUser, documentUser, positionCode, vertical, management, area, process} = userInfo;

    const isInvalidDocumentUser = useCallback(
        ( pos ) => pos === 1 && documentUser.length >=1 && documentUser.length < 6
      ,[documentUser]);

    const rol = getdecrypt('rolUser');
    const rootAdminEditKardex = idmRootAdminProd.includes(rol);
    
    useEffect(() => {
        if (!rootAdminEditKardex) {
            dispatch(updateFormUserStaff('vertical', getdecrypt('vertical')))
        }
        dispatch(getManagementsByVertical())
      return () => {
          resetFormAction();
      }
    },[dispatch])

    useEffect(() => {
      if (!management.id) return;
      dispatch(updateFormUserStaff('area', ''))
      dispatch(updateFormUserStaff('process', ''))
      dispatch(getAreasAndProcesses(vertical, management.id,null))
    }, [management, vertical, dispatch])

    useEffect(() => {
        if (!management.id || !area.id) return;
        dispatch(updateFormUserStaff('process', ''))
        dispatch(getAreasAndProcesses(vertical, management.id,area.id))
      }, [area, dispatch])
    
    useEffect(() => {
        const isValidDocument = documentUser.length >= 6;
        const save = !!nameUser && isValidDocument && !!positionCode && !!management?.id && vertical && area.id && process.id;
        const cancel = !!nameUser || isValidDocument || !!positionCode || !!management?.id || vertical || area.id || process.id;

        setEnables({
            save,
            cancel
        })
    }, [nameUser, documentUser, positionCode, management, vertical, area, process])
    
    
    const handleInputChange = ( {target} ) => {
        const value = formatValue( target.name, target.value );
        if (!!target) dispatch( updateFormUserStaff( target.name, value ) );
    };
    const handleDoubleListChange = ({target}) => {
        if (!target) return;
        dispatch( updateFormUserStaff( target.name, target.value ) );
        if (rootAdminEditKardex) {
            dispatch( updateFormUserStaff( 'vertical', target.valueMainList ));
        }
    }
    const handleClick = ( e ) => {
        if (!!e.target) actionsList[ e.target.id ]();
        e.preventDefault();
    };

    function saveChangesAction(){
        dispatch(  getUserStaff() );

    };
    function resetFormAction(){
        dispatch( resetFormUserStaff() );
    };
    
  return (
    <div className='_updateUser-container'>
        <h5 className='_updateUser-tittle-form staff_ml-20'>{tittleForm}</h5>
        <form className='_updateUser-container-form'>
            {
                inputsStructure.map( (input, idx) => (
                    <label key={idx}>{input.label}
                        <input
                            data-cy={input.id}
                            name={input.id}
                            autoComplete="off"
                            type='text'
                            value={ userInfo[input.id] }
                            placeholder={input.placeholder}
                            onChange={ handleInputChange }
                            minLength={input?.minLength}
                            maxLength={input?.maxLength}
                        />
                    {
                        isInvalidDocumentUser( idx ) && 
                            <span className='_updateUser-error-text'>{invalidDocumet}</span>
                    }
                    </label>
                ))
            }
            <div className='_updateUser-custom-label'>{ rootAdminEditKardex ? 'Vertical y Gerencia' : 'Gerencia' }
                <SearchForListComponent
                    idClick={'management'}
                    idInput='vertAndMngmt'
                    selectedOption={ management?.label }
                    onClickOption={handleDoubleListChange} 
                    options={ verticalsAndManagements }
                    selectStyle={inputStyles}
                    listStyle = {listStyles}
                    placeholder={'Selecciona'}
                    showArrow={true}       
                    arrowStyles={styleArrow}
                />
            </div>
            <div className='_updateUser-custom-label'>Área
                <SearchForListComponent
                    idClick={'area'}
                    idInput='area'
                    selectedOption={ area?.label }
                    onClickOption={handleInputChange} 
                    options={ areas }
                    selectStyle={inputStyles}
                    listStyle = {listStyles}
                    placeholder={'Selecciona'}
                    showArrow={true}
                    arrowStyles={styleArrow}
                    disabled={!management.id}
                />
            </div>
            <div className='_updateUser-custom-label'>Proceso
                <SearchForListComponent
                    idClick={'process'}
                    idInput='process'
                    selectedOption={ process?.label }
                    onClickOption={handleInputChange} 
                    options={ processes }
                    selectStyle={inputStyles}
                    listStyle = {listStyles}
                    placeholder={'Selecciona'}
                    showArrow={true}
                    arrowStyles={styleArrow}
                    disabled={!area.id}
                />
            </div>
            <div className='_updateUser-buttons-group'>
                {
                    buttonsStructure.map( (button, idx) => (
                        <button
                            data-cy={button.id}
                            key={idx}
                            id={button.id}
                            className={button.class}
                            disabled={!enables[button.id]}
                            onClick={handleClick}
                        >
                            { button.label }
                        </button>
                    ))
                }
            </div>
        </form>
    </div>
  )
}
function RemoveUser () {
    
    const dispatch = useDispatch();
    const [enableSearch, setEnableSearch] = useState(false);

    const { searchValue, error, usersStaff } = useSelector( state => state.plant );

    const handleInputChange = ( {target} ) => {
        dispatch( updateSearchValue( target.value ) );
        if ( error ) dispatch( setErrorSearch(false));
    };
    const handleClick = ( e ) => {
        dispatch( searchValueStaff() );
        e.preventDefault();
    };
    const removeUserAction = (user) => {
        Swal.fire({
            icon: "info",
            title: removeInfo,
            text: `Se va a retirar a ${user.fullName} dejando vacante el código de posición ${user.positionCode}`,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            }).then( (result) => {
            if ( result.isConfirmed ) dispatch( removeUserStaff(user) );
        })

    }

    useEffect(() => {
        const _enableValidation = () => setEnableSearch( !!searchValue );
        _enableValidation();

    }, [searchValue])

    useEffect(() => {
    
      return () => {
        dispatch( updateSearchValue(""));
        dispatch( populateUsersStaff( [] ));
    }
    }, [])
    
    return (
        <div className='d-flex justify-content-center flex-column align-items-center mt-5'>
            <div className='_updateUser-container-search'>
                {
                    error && <div className='_updateUser-search-error'>{searchError}</div>
                }
                <label>{searchInfo}
                    <input
                        name='searchValue'
                        type='text'
                        placeholder='Ingresa el código'
                        value={searchValue}
                        onChange={handleInputChange}
                    />
                </label>
                <button
                    className='_updateUser-search-button'
                    disabled={!enableSearch}
                    onClick={ handleClick }
                >
                    Buscar
                </button>
            </div>
            {usersStaff.length > 0 &&
                <table className='_updateUser-box-table'>
                    <tbody>

                    
                    <tr>
                    {
                        propertiesUserTable.map( (prop, idx) => (
                            <th 
                                key={idx} 
                                style={{ width: prop.width}}
                            >
                                {prop.header}
                            </th>
                        ))
                    }
                    </tr>
                    {
                         usersStaff.map( (user,idx) =>(
                            <tr key={idx}>
                                {
                                    propertiesUserTable.map( (prop, idx) => (
                                        <td
                                            key={idx} 
                                        >
                                            { prop.field !== "button" 
                                                ? user[ prop.field ]
                                                    : 
                                                        <button
                                                            className='_updateUser-remove-button'
                                                            disabled={ !user.documentNumber }
                                                            onClick={ () => removeUserAction(user)}
                                                        >
                                                            Retirar
                                                        </button> 
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}

function RemovePosition() {
    const dispatch = useDispatch();
    const [enableSearch, setEnableSearch] = useState(false);

    const { searchValue, error, usersStaff } = useSelector( state => state.plant );

    const handleInputChange = ( {target} ) => {
        dispatch( updateSearchValue( target.value ) );
        if ( error ) dispatch( setErrorSearch(false));
    };
    const handleClick = ( e ) => {
        dispatch( searchValueStaff() );
        e.preventDefault();
    };
    const removePositionAction = (user) => {
        const text = user.fullName ? `Se va a retirar al usuario ${user.fullName} y se eliminará el código de posición ${user.positionCode}` : `Se eliminará el código de posición ${user.positionCode}`;
        Swal.fire({
            icon: "info",
            title: removePosition,
            text: text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            }).then( (result) => {
            if ( result.isConfirmed ) dispatch( removePositionStaff(user) );
        })

    }

    useEffect(() => {
        const _enableValidation = () => setEnableSearch( !!searchValue );
        _enableValidation();

    }, [searchValue])

    useEffect(() => {
    
      return () => {
        dispatch( updateSearchValue(""));
        dispatch( populateUsersStaff( [] ));
    }
    }, [])
    
    return (
        <div className='d-flex justify-content-center flex-column align-items-center mt-5'>
            <div className='_updateUser-container-search'>
                {
                    error && <div className='_updateUser-search-error'>{searchError}</div>
                }
                <label>{searchInfo}
                    <input
                        name='searchValue'
                        type='text'
                        placeholder='Ingresa el código'
                        value={searchValue}
                        onChange={handleInputChange}
                    />
                </label>
                <button
                    className='_updateUser-search-button'
                    disabled={!enableSearch}
                    onClick={ handleClick }
                >
                    Buscar
                </button>
            </div>
            {usersStaff.length > 0 &&
                <table className='_updateUser-box-table'>
                    <tbody>

                    
                    <tr>
                    {
                        propertiesUserTable.map( (prop, idx) => (
                            <th 
                                key={idx} 
                                style={{ width: prop.width}}
                            >
                                {prop.header}
                            </th>
                        ))
                    }
                    </tr>
                    {
                         usersStaff.map( (user,idx) =>(
                            <tr key={idx}>
                                {
                                    propertiesUserTable.map( (prop, idx) => (
                                        <td
                                            key={idx} 
                                        >
                                            { prop.field !== "button" 
                                                ? user[ prop.field ]
                                                    : 
                                                        <button
                                                            className='_updateUser-remove-button'
                                                            //disabled={ !user.documentNumber }
                                                            onClick={ () => removePositionAction(user)}
                                                        >
                                                            Eliminar
                                                        </button> 
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}

function formatValue( prop, value ){
    return prop !== "documentUser" || process.env.REACT_APP_ENABLE_CHARACTER_NUMBER ? value : value.replace(/[^\d]/g, '');
};