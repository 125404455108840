import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { deleteToken, openCasePaused , b64_to_utf8_encrypt, b64_to_utf8, decryptUsingAES256} from '../../bdb-services/GeneralService/globalService';
import { logout } from '../../bdb-actions/alertaActions';

const GapTimeToken = (props) => {
    const dispatch = useDispatch();
    
    const[isLogout, setLogout] = useState(true)
    let timer = undefined;
    let timeStart = process.env.REACT_APP_GAPCLOSESESSION;
    useEffect(()=>{
        if(!!props.showModal){
            setLogout(false)
            captureNewToken()
        }else{
            setLogout(true)
            clearTimeout(timer)
        }
    },[props.showModal])

    const startTimer=()=>{
        if(timer){
            clearTimeout(timer)
        }
        let closeSession;
        let gapTimeToken;
        closeSession = localStorage.getItem('closeSession');
        gapTimeToken = moment.duration(moment().diff(moment(closeSession)))._milliseconds
        timer = setTimeout( () => {
            closeSession = localStorage.getItem('closeSession');
            if (closeSession === '--'){
                clearTimeout(timer)
            }
            else{
                
                gapTimeToken = moment.duration(moment().diff(moment(closeSession)))._milliseconds
                handleLogout();
                openCasePaused();
            }
            
        }, timeStart)

    }

    const handleLogout = async()=>{
        const DocumentNumberAdviserSessionStorage = sessionStorage.getItem('documentNumberAdviser');
        const KeyTokenSessionStorage = sessionStorage.getItem('keyTokenRenew');
        let decodeKeyToken= decryptUsingAES256(b64_to_utf8(KeyTokenSessionStorage), b64_to_utf8(DocumentNumberAdviserSessionStorage), b64_to_utf8(process.env.REACT_APP_AES_KEY) )
        let cryptKeyToken = b64_to_utf8_encrypt(decodeKeyToken);
        await deleteToken(cryptKeyToken);
        sessionStorage.clear();
        localStorage.clear();
        dispatch( logout() );
    }

    const captureNewToken = () =>{
        if(props.showModal){
            localStorage.setItem('closeSession', moment() );
            startTimer();
        }
    };

    
    return(<div></div>)

}
export default GapTimeToken;

