import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import KardexForm from './KardexForm'
import KardexTableComponent from '../KardexTableComponent/KardexTableComponent';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink';

const ReportProductivity = () => {
    const { processList } = useSelector(state => state.kardex);
    const { adminEditKardex } = useSelector(state => state.kardex);
  
    return (
        <Fragment>
            {
                adminEditKardex &&
                <BackLink url={'/staff-admin'} />
            }
            <KardexForm />
            {processList.length !== 0 ?
                < KardexTableComponent />
                : null
            }
        </Fragment>
    )
}

export default ReportProductivity