import {
    GET_CASE,
    GET_CASE_ERROR,
    GET_CASE_SUCCESS,
    PREPOPULATE_SPECIFIC_CASE,
    GET_CASES_OPEN_SUCCESS,
    PUT_CASE,
    PUT_CASE_SUCCESS,
    PUT_CASE_ERROR,
    OPEN_CASE_SUCCESS,
    PUT_CASE_PAUSE,
    GET_CASE_RESUME,
    GET_CASES_PAUSE_SUCCESS,
    PUT_CASE_PAUSED,
    REMOVE_CASE_PAUSED,
    ABORT_CASE_SUCCESS,
    FILTER_PAUSED_CASES,
    RESET_PAUSED_CASES,
    GET_CASES_OPEN_SUCCESS_DOCUMENTS,
    CHANGE_LOADING_VISIBILITY,
    UPDATE_MANAGEMENT,
    PREVIEW_DOCUMENT,
    DOWNLOAD_DOCUMENT_DISBURSE,
    VIEW_LOGIN_CLOSE,
    SET_TIME_CASE_LOAD,
    GET_CASES_PAUSED_GPO,
    OPEN_CASE_PAUSED_GPO,
    CHANGE_REQUESTED_PRODUCTS
} from '../bdb-types';

const initialState = {
    management: { id: "management", option: null },
    changeView: true,
    especifict: {},
    loading: false,
    show: false,
    error: null,
    notFound: false,
    alert: false,
    open: [],
    inPause: [],
    initialPaused: [],
    openCasesDocumen:[],
    previewedDocument: undefined,
    downloadDocumentDisburse: undefined,
    viewLoginClose:false,
    caseloadTime:null,
    getCaseGpo:[],
    openCasesGpo: null,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_CASE:
            return {
                ...state,
                notFound: false,
                changeView: action.payload,
                loading: true,
            }
        case GET_CASE_SUCCESS:
            return {
                ...state,
                changeView: false,
                loading: false,
                notFound: false,
                especifict: action.payload.data.Items[0]
            }
        case PREPOPULATE_SPECIFIC_CASE:
            return {
                ...state,
                changeView: false,
                loading: false,
                especifict: action.payload
            }
        case GET_CASE_RESUME:
            return {
                ...state,
                notFound: false,
                changeView: false,
                loading: false,
                especifict: action.payload
            }
        case GET_CASE_ERROR:
            return {
                ...state,
                changeView: true,
                loading: false,
                alert: true,
                notFound: action.payload,
                show: false
            }
        case PUT_CASE_PAUSE:
            return {
                ...state,
                changeView: true,
                loading: false,
                notFound: false,
                alert: true,
                show: false,
            }
        case ABORT_CASE_SUCCESS:
            return {
                ...state,
                loading: false,
                show: true,
                notFound: false,
                open: state.open.filter( caseOpen => caseOpen.requestNumber !== action.payload.requestNumber )
            }
        case PUT_CASE:
            return {
                ...state,
                changeView: true,
                notFound: false,
                loading: true,
            }
        case PUT_CASE_SUCCESS:
            return {
                ...state,
                loading: false,
                notFound: false,
                show: true,
                open: state.open.filter( caseOpen => caseOpen.requestNumber !== action.payload.requestNumber )
            }
        case PUT_CASE_ERROR:
            return {
                ...state,
                loading: false,
                notFound: false,
                show: false
            }
        case OPEN_CASE_SUCCESS:
            return {
                ...state,
                changeView: action.payload,
                notFound: false,
                loading: false,
            }
        case GET_CASES_OPEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                notFound: false,
                open: action.payload.data.Items
            }
        case GET_CASES_PAUSE_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: false,
                notFound: false,
                inPause: action.payload,
                initialPaused: action.payload
            }
        case PUT_CASE_PAUSED:
            return {
                ...state,
                loading: false,
                show: true,
                notFound: false,
                inPause: [...state.inPause, action.payload],
                initialPaused: [...state.initialPaused, action.payload],
                open: state.open.filter( caseOpen => caseOpen.requestNumber !== action.payload.requestNumber )
            }
        case REMOVE_CASE_PAUSED:
            return {
                ...state,
                notFound: false,
                inPause: state.inPause.filter(casePaused => casePaused.requestNumber !== action.payload),
                initialPaused: state.initialPaused.filter(casePaused => casePaused.requestNumber !== action.payload),
            }
        case FILTER_PAUSED_CASES:
            return {
                ...state,
                inPause: state.initialPaused.filter( casePaused => casePaused.requestNumber.indexOf( action.payload ) !== -1 || casePaused.document.indexOf( action.payload ) !== -1 ),
            }
        case RESET_PAUSED_CASES:
            return {
                ...state,
                inPause: state.initialPaused,
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                 ...state,
                loading: action.payload
            }
        case GET_CASES_OPEN_SUCCESS_DOCUMENTS:
            return {
                ...state,
                loading: false,
                error: false,
                notFound: false,
                openCasesDocumen: action.payload
            }
        case UPDATE_MANAGEMENT:
            return {
                ...state,
                management: {...action.payload}
            }
        case PREVIEW_DOCUMENT:
            return {
                 ...state,
                previewedDocument: action.payload,
            }
        case DOWNLOAD_DOCUMENT_DISBURSE:
            return {
                ...state,
                downloadDocumentDisburse: action.payload,
            }
        case VIEW_LOGIN_CLOSE:
            return {
                ...state,
                viewLoginClose: action.payload,
            }
        case SET_TIME_CASE_LOAD:
            return {
                ...state,
                caseloadTime: action.payload
            } 
        case GET_CASES_PAUSED_GPO:
            return{
                ...state,
                getCaseGpo:action.payload
            }
        case OPEN_CASE_PAUSED_GPO:
            return{
                ...state,
                openCasesGpo:action.payload
            }
        case CHANGE_REQUESTED_PRODUCTS:
            return{
                ...state,
                especifict : action.payload
            }
        default:
            return state;
    }
}
