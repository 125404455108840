import { 
    START_GET_CONVENTIONS_CONFIG,
    GET_CONVENTIONS_CONFIG_SUCCESS,
    GET_CONVENTIONS_CONFIG_FAILED,
    START_UPDATE_CONVENTIONS_CONFIG,
    SUCCESS_UPDATE_CONVENTIONS_CONFIG,
    FAIL_UPDATE_CONVENTIONS_CONFIG,
    START_UPDATE_CONVENTIONS_USER,
    SUCCESS_UPDATE_CONVENTIONS_USER,
    FAIL_UPDATE_CONVENTIONS_USER,
    SELECTED_CONVENTIONS_CONFIG
} from '../bdb-types/index';
import axios from 'axios';
import interceptor from '../../interceptor';

function codesNotRepeated(data) {
    let result = data.filter((item,index)=>{
        return data.indexOf(item) === index;
    });
    return result;
}
function usersMapByCode(code, data) {
    const usersKeys = Object.keys(data);
    let usersArray = [];
    usersKeys.forEach(user => {
        if (data[user].indexOf(code) !== -1) {
            usersArray = [...usersArray, user];
        }
    });
    return usersArray;
}

const codeMapByUser = (data, user) => {
    let codes = [];
    data.forEach(element => {
        element['usersArray'].forEach(userArray => {
            if (user === userArray) {
                codes = [...codes, element['code']];
            }
        });
    });
    return codes;
}

function nameMapByCode(codeMap, data) {
    const filtered = data.filter(element => element.code === codeMap);
    return filtered.length > 0 ? filtered[0]['name'] : '';
}
function dataTableMapper(dataElement, idTab, dataSet, headers, selectAll, lock, trash, add, edit) {
    dataElement[idTab]['dataSet'] = dataSet;
    dataElement[idTab]['headers'] = headers;
    dataElement[idTab]['selectAll'] = selectAll;
    dataElement[idTab]['lock'] = lock;
    dataElement[idTab]['trash'] = trash;
    dataElement[idTab]['add'] = add;
    dataElement[idTab]['edit'] = edit;

    return dataElement;
}

export function getConventionsConfig() {
    return async (dispatch) => {
        dispatch( startConventionsConfig(true));
        let dynamicTable = {
            'VoBoCentralized': {},
            'VoBoPayment': {}
        };
        try {
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/conventions').then(function (response) {
                let data = response['data']['Item']['convCode'];
                data.sort(function (a, b) {
                    return a.code.localeCompare(b.code);
                });
                dynamicTable = dataTableMapper(
                    dynamicTable, 'VoBoCentralized', data,
                    [{id: 'code', name: 'Código', width: '20%'}, {id: 'name', name: 'Nombre convenio', width: '80%'}],
                    true, true, false, true, true );
                    dispatch( successSelectedConfig(response['data']['Item']['priorityConventions']));
            });
            await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/convent-users').then(function (response) {
                const results = response['data']['Item']['listUsers'];
                const users = Object.keys(results);
                let codes = [];
                users.forEach(user => {
                    results[user].forEach(result => {
                        codes = [...codes, result];
                    });
                });
                codes = codesNotRepeated(codes);
                let dataSet = [];
                codes.forEach((code, index) => {
                    dataSet[index] = {
                        code: code,
                        name: nameMapByCode(code, dynamicTable['VoBoCentralized']['dataSet']),
                        usersArray: usersMapByCode(code, results)
                    };
                });
                dynamicTable = dataTableMapper(
                    dynamicTable, 'VoBoPayment', dataSet,
                    [
                        {id: 'code', name: 'Código', width: '20%'}, 
                        {id: 'name', name: 'Nombre convenio', width: '50%'},
                        {id: 'usersArray', name: 'Gestor', width: '30%'}
                    ],
                    false, false, true, true, true );
            });
            dispatch( successConventionsConfig(dynamicTable));
        } catch (error) {
            console.log(error);
            dispatch( failedConventionsConfig(true));
        }
    }
}
const startConventionsConfig = status => ({
    type: START_GET_CONVENTIONS_CONFIG,
    payload: status
});
const successConventionsConfig = status => ({
    type: GET_CONVENTIONS_CONFIG_SUCCESS,
    payload: status
});
export function getSelectedConfig(selected) {
    return async (dispatch) => {
        dispatch( successSelectedConfig(null));
        dispatch( successSelectedConfig(selected));
    }
}
const successSelectedConfig = status => ({
    type: SELECTED_CONVENTIONS_CONFIG,
    payload: status
});

const failedConventionsConfig = status => ({
    type: GET_CONVENTIONS_CONFIG_FAILED,
    payload: status
});

export function updateConventionsConfig(data, indexes) {
    return async (dispatch) => {
        dispatch( startUpdateConventionsConfig(true));

        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'config/change-conventions';
        const response = await axios.put(
            path,
            {
                priorityConventions: indexes,
                convCode: data
            }
        );
        if(response.status === 200) {
            dispatch( successUpdateConventionsConfig(true));
        } else {
            dispatch( failUpdateConventionsConfig(true));
        }
    }
}

const startUpdateConventionsConfig = status => ({
    type: START_UPDATE_CONVENTIONS_CONFIG,
    payload: status
});
const successUpdateConventionsConfig = status => ({
    type: SUCCESS_UPDATE_CONVENTIONS_CONFIG,
    payload: status
});
const failUpdateConventionsConfig = status => ({
    type: FAIL_UPDATE_CONVENTIONS_CONFIG,
    payload: status
});

export function updateConventionsUser(data) {
    return async (dispatch) => {
        dispatch( startUpdateConventionsUser(true));

        let codes = [];
        let users = [];
        let vector = {};

        data.forEach(element => {
            codes = [...codes, element['code']];
            element['usersArray'].forEach(user => {
              users = [...users, user];
            });
        });
        users = codesNotRepeated(users);
        users.forEach(user => {
            vector[user] = codeMapByUser(data, user);
        });

        const path = process.env.REACT_APP_SELF_API_ENDPOINT + 'config/change-convent-users';
        const response = await axios.put(
            path,
            {
                listUsers: vector
            }
        );
        if(response.status === 200) {
            dispatch( successUpdateConventionsUser(true));
        } else {
            dispatch( failUpdateConventionsUser(true));
        }
    }
}

const startUpdateConventionsUser = status => ({
    type: START_UPDATE_CONVENTIONS_USER,
    payload: status
});
const successUpdateConventionsUser = status => ({
    type: SUCCESS_UPDATE_CONVENTIONS_USER,
    payload: status
});
const failUpdateConventionsUser = status => ({
    type: FAIL_UPDATE_CONVENTIONS_USER,
    payload: status
});
