import {
    STAR_CASES
} from '../../bdb-types/index';

import React, { Fragment, useEffect, useState } from 'react';
import { processes, mountsById} from '../../../constants';

import '../TableComponent/Table.css'
import { useSelector } from 'react-redux';

const startCases = status => ({
    type: STAR_CASES,
    payload: status
});

const Table = ({result, headers, group, showMonth=true, showTotals=true, onlyProcess, tittleComplement, container}) => {
    const [management, setManagement] = useState( group );
    const loadingCases = useSelector( state => state.cases.loadingCases );
    const getProcessName = process => {
        return !!process ? processes.find(row => row.id === process).label : null;

    }

    function getProcess() {
        let process = []
        if ( result[management] ){
            process = Object.keys(result[management]);
        }
        return process
    }

    function getAllNumberOfCases() {
        let amount = 0;
        if ( result[management] ) {

            const interators = Object.keys(result[management]); 
            interators.map(interator => (
                headers.forEach(status => {
                    amount = amount + result[management][interator][status.id];
                })
            ));
        }
        return amount;
    }

    function getNumberOfCases(specificStatus) {
        let amount = 0;
        if ( result[management] ) {
            const interatorsProcess = Object.keys(result[management]); 
            interatorsProcess.forEach(interator => {
                const filterStatus = result[management][interator][specificStatus];
                amount = amount + filterStatus;
            });
        }
        return amount;
    }

    function getMonth () {
        const date = new Date();
        return(mountsById[date.getMonth()]);
    }

    
    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    return ( 
            <div style={ container }>
            <div className="header-table-component d-flex">
                <div className="header-font-component font-16">Total { tittleComplement }
                <span className="weight-font-component font-16">{ showMonth && ' - ' + getMonth()}</span></div>
                {
                    loadingCases ? 
                    <div className="logoLoading"></div> : <div className="header-font-component font-22">{getAllNumberOfCases()}</div>

                    }
            </div>
            <div className="d-flex">
                <div className="column-titles-table-component padding-process-title-column font-title-process-column column-title-width">{ showTotals ? 'Procesos Desem' : getProcessName( onlyProcess )}</div>
                <div className="column-info-table-component column-info-width">
                    <div className="d-flex margin-info-title-table-component body-info-title-table-component">
                        {headers.map(status => (
                            <div className="font-general-body cell-table-component">{status.label}</div>
                        ))}
                    </div>      
                </div>
            </div>
            { getProcess().map((row, index) => (
                ['Desembolso','Revisión Doc'].includes(getProcessName(row)) && ( global.isLibrances() || global.isGroupDisbursement() ) ?
                <div key={index} className="d-flex">
                    <div className="column-titles-table-component padding-process-title-column font-title-process-column column-title-width">Procesos {getProcessName(row)}</div>
                    <div className="column-info-table-component column-info-width"></div>
                </div>
                :
                <div key={index} className="d-flex">
                <div className="column-titles-table-component padding-process-column font-general-body column-title-width">{ showTotals && getProcessName(row)}</div>
                    <div className="column-info-table-component column-info-width">
                        <div className="d-flex font-general-body body-info-table-component margin-info-body-table-component">
                            {headers.map((state, index) => (
                                <div className="cell-table-component">{result[management][row][state.id]}</div>
                            ))}
                        </div>      
                    </div>
                </div>
            ))}
            {
                showTotals &&
                    <div className="footer-table-component d-flex">
                    <div className="column-title-width padding-process-column footer-general-font">Total</div>
                        <div className="column-info-width">
                            <div className="d-flex body-info-title-table-component">
                                {headers.map((status, index) => (
                                    <div className="font-general-body cell-table-component footer-general-font">{getNumberOfCases(status.id)}</div>
                                ))}
                            </div>      
                        </div>
                    </div>

            }
        </div>
     );
}
export default Table;