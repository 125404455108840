import { SET_AREA_LIST, ACTIVE_EVENT_TEMPORAL,UPDATE_ACTIVE_EVENT_KARDEX, SET_PROCESS_LIST, CHANGE_LOADING_VISIBILITY, SET_KARDEX_PROCESSES,
         RESET_ACTIVE_MESSAGE_KARDEX, SET_SELECT_ROWS_KARDEX, KARDEX_DATECHANGE, SET_MANAGEMENT_LIST_KARDEX, RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG} from "../bdb-types";


const initialState = {
    activeEvent: {
        id: "",
        vertical: { id: "vertical", option: null },
        management: { id: "management", option: null },
        area: { id: "area", option: null },
    },
    activeEventTempo: {
        id: "",
        vertical: { id: "vertical", option: null },
        management: { id: "management", option: null },
        area: { id: "area", option: null },
    },
    managementList:[],
    kardexProcesses:[], 
    areaList: [],
    loading: false,
    processList: [],
    selectRows: [],
    date: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_ACTIVE_MESSAGE_KARDEX:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                    timeInit: state.activeEvent.timeInit
                },
                processList: initialState.processList,
                areaList: [...state.areaList],
            }
        case RESET_KARDEX_ACTIVE_EVENT_KEEP_MNG:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                    management: {
                        id: 'management',
                        option:
                            (sessionStorage.getItem("currentManagement") ?
                                JSON.parse(sessionStorage.getItem("currentManagement"))
                                : null),
                    },
                    timeInit: state.activeEvent.timeInit
                },
                processList: initialState.processList,
                areaList: [...state.areaList],
            }
        case UPDATE_ACTIVE_EVENT_KARDEX:
            return {
                ...state,
                activeEvent: { ...action.payload }
            }
        case ACTIVE_EVENT_TEMPORAL:
            return {
                ...state,
                activeEventTempo: { ...action.payload }
            }
        case SET_AREA_LIST:
            return {
                ...state,
                areaList: [...action.payload]
            }
        case SET_PROCESS_LIST:
            return {
                ...state,
                processList: [...action.payload]
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload
            }
        case SET_KARDEX_PROCESSES:
            return {
                ...state,
                kardexProcesses: action.payload
            }
        case SET_SELECT_ROWS_KARDEX:
            return {
                ...state,
                selectRows: action.payload
            }
        case KARDEX_DATECHANGE:
            return {
                ...state,
                date: action.payload
            }
        case SET_MANAGEMENT_LIST_KARDEX:
            return {
                ...state,
                managementList:action.payload
            }
        default:
            return state;
    }
}