import axios from 'axios';
import * as CryptoJS from "crypto-js";
import * as moment from 'moment';

export async function getListCopies() {
    try {
        await axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/copies')
            .then(function (response) {
                sessionStorage.setItem('listCopies', JSON.stringify(response.data.Item));
            });
    } catch(error) {
        console.log(error);
    }
}

export const encryptUsingAES256 = (value, iv, key) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.encrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
}

export const decryptUsingAES256 = (value, iv, key) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}
export const b64_to_utf8 = str => {
    return decodeURIComponent(escape(window.atob( str )));
}

export const b64_to_utf8_encrypt = str => {
    return  window.btoa(unescape(encodeURIComponent( str )))
}

export const getSecondaryKeysConfig =() => {
    const documentNumberAdviser = decodeURIComponent(escape(window.atob( sessionStorage.getItem('documentNumberAdviser') )));
    axios.get( process.env.REACT_APP_SELF_API_ENDPOINT + 'config/categories')
        .then( (response) => {
            sessionStorage.setItem('secondaryKeysConfig',encryptUsingAES256(JSON.stringify(response.data.Item), documentNumberAdviser, b64_to_utf8(process.env.REACT_APP_AES_KEY)) );
        })
        .catch( (error) => {
            console.error(error);
        })
}

export const setSessionStorageEncrypted = ( key, value ) => {
    const documentNumberAdviserDecrypt = b64_to_utf8 (sessionStorage.getItem('documentNumberAdviser'));
    sessionStorage.setItem(key,encryptUsingAES256(JSON.stringify(value), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY)) );
}
export const getdecrypt = (data) => {
    const documentNumberAdviserDecrypt = b64_to_utf8 (sessionStorage.getItem('documentNumberAdviser'));
    return decryptUsingAES256 (sessionStorage.getItem(data), documentNumberAdviserDecrypt, b64_to_utf8(process.env.REACT_APP_AES_KEY));
};

export const setEncryptSessionStorage = async ( key, data ) => {
    return sessionStorage.setItem(key,Buffer.from(data).toString('base64') );
};

export const getTokenAndKeytoken = async (data) =>{
    let resp ;
    await axios.get( `${process.env.REACT_APP_REDIS_ENDPOINT}token-get?key=${data}`) 
    .then( (response) => {
        return resp=response
    })
    .catch( (error) => {
        console.error(error);
    })
    return resp
}

export const putNewToken = async (body) =>{
    let resp ;
    await axios.put( `${process.env.REACT_APP_REDIS_ENDPOINT}token-renew`, body)
    .then( (response) => {
        return resp=response
    })
    .catch( (error) => {
        console.error(error);
    })
    return resp;

}

export const getFrontRole = async () =>{
    let resp ;
    const body = {
        "roleToAssume": process.env.REACT_APP_FRONT_ROLE,
        "sessionName": getdecrypt('userLogin'),
        "durationSeconds": 3600
    }
    await axios.post( `${process.env.REACT_APP_URL_IDM}get-front-role`, body)
    .then( (response) => {
        const documentNumberAdviserDecrypt = escape(
            window.atob(sessionStorage.getItem("documentNumberAdviser"))
          );
        const cryptAWSRole = encryptUsingAES256(`${response?.data?.accessKeyId}-.-.-${response?.data?.secretAccessKey}-.-.-${response?.data?.token}-.-.-${moment().format()}`, documentNumberAdviserDecrypt,  b64_to_utf8(process.env.REACT_APP_AES_KEY))
        sessionStorage.setItem('AWSRole', cryptAWSRole);
        return resp=response
    })
    .catch( (error) => {
        console.error(error);
    })
    return resp;

}

export const getAWSSessionCreds = async () =>{

    const documentNumberAdviserDecrypt = escape(
        window.atob(sessionStorage.getItem("documentNumberAdviser"))
      );
    const creds = decryptUsingAES256(
        sessionStorage.getItem("AWSRole"),
        documentNumberAdviserDecrypt,
        b64_to_utf8(process.env.REACT_APP_AES_KEY)
      ).split("-.-.-");
  
    const issuedTokenTime = creds[3]? moment(creds[3]) : undefined
    const timediff = moment().diff(issuedTokenTime, 'seconds');

    if (creds.includes("undefined") || !issuedTokenTime || timediff > 3000) {
        console.log("Invalid Credentials, these will be updated");
        await getFrontRole();
        return await getAWSSessionCreds()
    }
    else{
        return creds
    }
}

export const deleteToken = async (body) =>{
    let resp ;
    await axios.delete( `${process.env.REACT_APP_REDIS_ENDPOINT}token-delete`,{data:body})
    .then((response)=>{
        return resp=response;
    })
    .catch((error) =>{
        console.error(error);
    })
    return resp;
}
 export const getCaseGpoPaused = async (user) =>{
     let resp;
     await axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/case/${user}?status=PAUSED`) 
     .then((response)=>{
        return resp=response;
    })
    .catch((error) =>{
        console.error(error);
    })
    return resp;

 }

 export const getCaseGpoOpen = async (user) =>{
    let resp;
    await axios.get(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/case/${user}?status=OPEN`) 
    .then((response)=>{
       return resp=response;
   })
   .catch((error) =>{
       console.error(error);
   })
   return resp;

}

export const openCasePaused = async () =>{
    let user= getdecrypt('userLogin')
    const management = b64_to_utf8(sessionStorage.getItem('management'))
    const documentNumberAdviserDecrypt = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
    let documentNumberDecrypt  = Buffer.from(documentNumberAdviserDecrypt).toString('base64')

    let body ={
        'documentNumberDecrypt':documentNumberDecrypt,
        'management':management,
        'vertical':getdecrypt('vertical')
    }
    await axios.put(`${process.env.REACT_APP_SELF_API_ENDPOINT}control-board/case/pauseopen/${user}`,body);
}

export function postTrainOCRDocs(data, status) { 
    return axios.post(`${ process.env.REACT_APP_TRAIN_DATA_API }put-train-docs/${status}`, data, {headers:{'Content-Type' : 'text/plain'}});
}

export function getOCRInfo(dochandle) { 
    return axios.get(`${ process.env.REACT_APP_EMULATOR_ENDPOINT }ocr/${dochandle}`);
}