import XLSX from 'xlsx-js-style'; 
import path from "path";
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import * as CryptoJS from "crypto-js";
import moment from 'moment';
import * as holidays from 'colombia-holidays';
import { getdecrypt, setSessionStorageEncrypted } from '../bdb-services/GeneralService/globalService';
import { getMngmntsAndProcessList, getKardexByManagementDate } from '../bdb-services/GeneralService/controlboardService';
import { getCtrlBrdInfoByUser} from '../bdb-services/GeneralService/controlboardService';
import { idmRootAdminProd, verticals, enableRol } from '../../constants';

export const sumStrings = ( string1, string2 ) => {
    string1 = !!string1 ? string1 : '0';
    string2 = !!string2 ? string2 : '0';    
    return ( Number(string1) + Number(string2) ).toString();
}

export const getDateFormat = ( timeString ) => {
    if (!!timeString) {
        let dateNow = new Date();
        const timeSplit = timeString.split(':');
        const hours = Number(timeSplit[0]);
        const minutes = Number(timeSplit[1]);
        const seconds = Number(timeSplit[2]);
    
        dateNow.setHours(hours);
        dateNow.setMinutes(minutes);
        dateNow.setSeconds(seconds);
    
        return dateNow;
    };
    return "";
};
export const getTimeFormatted = ( date ) => {
    if ( !!date ) {
        const hours = date.getHours() < 10 ? `0${date.getHours()}`: date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}`: date.getMinutes();
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}`: date.getSeconds();
        return `${hours}:${minutes}:${seconds}`;
    }
    return "";
};
export const mapSelectedListToReducer = ( dataArray, key, maxCharacters ) => {
    let newArray = [];
    if( !dataArray || dataArray.length === 0) return newArray
    dataArray.forEach( (item ) => {
        let newItem = {};
        let itemFounded = newArray.find( (e) => e.id === item[key]);
        if (itemFounded === undefined && !item.isAutomatic) {
            newItem['id'] = item[key];
            newItem['hasAmount'] = item['hasAmount']
            newItem['daylyGoal']=item['daylyGoal']
            newItem['frequency']=item['frequency']
            newItem['assignedPeople']=item['assignedPeople']
            newItem['label'] =  item[key]==='Afectaciones'? 'Ausentismos': item[key].length >= maxCharacters ? `${item[key].slice(0,maxCharacters)}...` : item[key];
            !!item['isMasive']? newItem['isMasive']=item['isMasive'] : newItem['isMasive']= 0; 
            !!item['isAdministrative'] ? newItem['isAdministrative']=item['isAdministrative'] : newItem['isAdministrative']=0;
            !!item['isStrategic'] ? newItem['isStrategic']=item['isStrategic'] : newItem['isStrategic']=0;
            newArray.push( newItem );
        }
    });
    return newArray;
}

export const secondsToHour = ( dateInSeconds ) => {
    const newDate = new Date(dateInSeconds*1000 + 5*3600*1000);
    return {hour: newDate.getHours(), minutes: newDate.getMinutes()}
};

export const formattedNames = ( string, max ) => {
    return string.length >= max ? `${string.slice(0,max)}...` : string;
}

export const addMinutes = (inputHours) =>{   
    let arreyHours= inputHours.includes("T") ? inputHours.split("T")[1].split(":") : inputHours.split(":");
    arreyHours[0]= parseInt(arreyHours[1])+1 >59 ? (parseInt(arreyHours[0])+1 >23 ? 0  :parseInt(arreyHours[0])+1) :parseInt(arreyHours[0]);
    arreyHours[1] = parseInt(arreyHours[1])+1 >59 ? 0 :parseInt(arreyHours[1])+1;
    arreyHours[0]= arreyHours[0]<10 ? `0${arreyHours[0]}`:arreyHours[0] ;
    arreyHours[1]= arreyHours[1]<10 ? `0${arreyHours[1]}`:arreyHours[1] ;
    return arreyHours.join(':')   
};

export const exportFromJsonToExcelFile = ( dataArray, columns, sheetName, fileName, colsWidth ) => {
    const dataMap = dataArray.map( e => {
        return [ 
            e.requestDate,
            e.numberGuarantee,
            e.branchOffice,
            e.fullNameCustomer,
            e.typeId,
            e.numberDocument,
            e.daneCode,
            e.valueOriginalGuarantee,
            e.currency,
            e.destiny,
            e.productFng,
            e.coverage,
            e.ciiu,
            e.annualSales,
            e.typeCommission,
            e.typePortfolio,
            e.score,
            e.fiscalDate,
            e.reserveCode,
            e.codeReject,
            e.reasonReject,
        ];
    })
    const workBook = XLSX.utils.book_new();
    const workSheetData = [
        columns,
        ...dataMap
    ];
    let workSheet = XLSX.utils.aoa_to_sheet( workSheetData );
    applyStyles( workSheet, colsWidth );
    XLSX.utils.book_append_sheet( workBook, workSheet, sheetName);
    XLSX.writeFile( workBook, path.resolve(fileName)) 
    Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: 'El reporte se descargó exitosamente.',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
    });
    
};

const applyStyles = ( workSheet, colsWidth ) => {
    for ( let i in workSheet) {
        if (typeof(workSheet[i]) != "object") continue;
        let cell = XLSX.utils.decode_cell(i);
        if (cell.r == 0 ) { 
            workSheet[i].s = {
                font: {
                    sz: 11,
                    bold: true,
                    color: { rgb: "FFFFFFFF"}
                },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FF002C76"}
                }
            };
            if ( ["U1"].includes(i) ){
                workSheet[i].s.font.color.rgb = "FFFF3333";
                workSheet[i].s.fill.fgColor.rgb = "FFFFE5CC";
            };
        }
    };
    workSheet['!cols'] = colsWidth;
};

export const getBeforeWorkingDay = ( date, days = 1 ) => {
    const holidaysDays = holidays.getColombiaHolidaysByYear( date.year() )
    let dateBefore, isDayHoliday, isDayWeekEnd, countDays = 0;
    
    do {
        dateBefore = date.subtract(1, 'd');
        isDayHoliday = !!holidaysDays.find( d => d.holiday === dateBefore.format("YYYY-MM-DD"));
        isDayWeekEnd = [0,6].includes( dateBefore.day() );
        if(!isDayHoliday && !isDayWeekEnd)
          countDays++;
        
    } while ( isDayWeekEnd || isDayHoliday ||  countDays < days);
    return dateBefore;

}
export const getHolidays = ( date = moment() ) => {
    const data = holidays.getColombiaHolidaysByYear( date.year() )
    return data
}

export const exportFromBase64ExcelFile = (file) => {
    const file64 = file.stream;
    const file_XLSX = XLSX.read(file64);
    XLSX.writeFile( file_XLSX, path.resolve(file.file));
    
}

export const downloadExcel = (fileInput) => {
    const datafile = fileInput.fileType.toUpperCase() === 'XLXS' ? 'data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64 ': 'data:@file/msexcel;base64';
    const application = fileInput.fileType.toUpperCase() === 'XLXS' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.ms-excel';   
     const file64 = fileInput.stream;
     const linkSource = `${datafile}, ${file64}`;
    fetch(linkSource)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], fileInput.file,{ type: `${application}` })
      saveAs(file, `${fileInput.file}`)
    })
}

export const decryptUsingAES256utils = (value, iv, key) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

export const b64_to_utf8_Util = ( str ) => {
    return decodeURIComponent(escape(window.atob( str )));
}
export const exportFromJsonToExcelFileProcessOrStaff = ( dataArray, columns, sheetName, fileName, colsWidth ) => {
    const dataMap = dataArray.map( e => {
        if(sheetName==='Procesos'){
            return [ 
                e.vertical,
                e.management,
                e.area,
                e.process,
                e.hasAmount?'Si':'No',
                e.isAutomatic?'Si':'No',
                e.isMasive?'Si':'No',
                e.daylyGoal,         
            ];
        }else{
            return [
                e.positionCode,
                e.documentNumber?e.documentNumber:'Vte',
                e.vertical,
                e.management,
                e.area,
                e.process,
                e.plantFileRole ? e.plantFileRole : (e.role ? e.role :'Vte'),
                e.fullName?e.fullName:'Vte Vte Vte Vte'     
            ];
        }
    })
    const workBook = XLSX.utils.book_new();
    const workSheetData = [
        columns,
        ...dataMap
    ];
    let workSheet = XLSX.utils.aoa_to_sheet( workSheetData );
    applyStyles( workSheet, colsWidth );
    XLSX.utils.book_append_sheet( workBook, workSheet, sheetName);
    XLSX.writeFile( workBook, path.resolve(fileName)) 
    Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: 'El reporte se descargó exitosamente.',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
    });
};

export const getKardexManagementByVerticalUtils = async (date, caraterLimit) => {
    const admin = idmRootAdminProd.includes(getdecrypt('rolUser'))
    for(let j = 0; j < verticals.length; j++){
        const inputManagement = (admin) ? verticals[j].label : getdecrypt('vertical').replace(/"/g, '')
        const _date = date ? moment(date).format("Y-MM-DD") : undefined;
        const processNew = `${_date}/` + (`${inputManagement}`);
        const response = await getKardexByManagementDate(processNew).catch(err => {
            console.log('Error', err)
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) }
        });
        if (response.status !== 200 && admin){
            verticals[j].subList=[]
            verticals[j].noSelected=true
            continue
        } 
        let reduxMaper = mapSelectedListToReducer (response.data.Items, "management", caraterLimit);
        if (!admin) return reduxMaper;
        verticals[j].subList=reduxMaper
        verticals[j].noSelected=true
    }
    return verticals
}


export const getManagementByVerticalUtils = async (caraterLimit, requireSublist = true) => {
    const admin = idmRootAdminProd.includes(getdecrypt('rolUser'))
    const vertical = admin && sessionStorage.getItem('staffVertical') ? sessionStorage.getItem('staffVertical') : getdecrypt('vertical').replace(/"/g, '');
    for(let j = 0; j < verticals.length; j++){
        const response = await getMngmntsAndProcessList (admin && requireSublist? verticals[j].label :vertical).catch(err => {
            console.log('Error', err)
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });
        if (response.status !== 200 && admin){
            verticals[j].subList=[]
            verticals[j].noSelected=true
            continue
        } 
        let reduxMaper = mapSelectedListToReducer (response.data.Items, "management", caraterLimit);
        if (!admin || !requireSublist) return reduxMaper;
        verticals[j].subList=reduxMaper
        verticals[j].noSelected=true
    }
    return verticals
}

export const getInformationDocumentStaff = async ( rolUser ) => {
    await getCtrlBrdInfoByUser(getdecrypt('userLogin'))
    .catch( error =>{ console.log(error)})
    .then( response => {
        const dataStaffByUser = response.data.Items[0]
        const adminFlag = dataStaffByUser?.isAdministrative
        const staffVertical = dataStaffByUser?.vertical ?  dataStaffByUser?.vertical : '';
        const admin = idmRootAdminProd.includes(rolUser)
        sessionStorage.setItem('isAdministrative', adminFlag ? adminFlag : false)
        if(enableRol.includes(rolUser) ) setSessionStorageEncrypted('vertical',staffVertical)
        if(admin && staffVertical) setSessionStorageEncrypted('vertical', staffVertical.replace(/"/g, ''))
    })
}