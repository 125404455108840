import { combineReducers } from 'redux';
import alertaReducer from './alertaReducer';
import fileReducer from './fileReducer';
import casesReducer from './casesReducer';
import analystReducer from './analystReducer';
import prioritizationReducer from './prioritizationReducer';
import selectReducer from './selectReducer';
import libPrioritizationReducer from './libPrioritizationReducer';
import dynamicTableReducer from './dynamicTableReducer';
import dynamicModalReducer from './dynamicModalReducer';
import simplySelectReducer from './simplySelectReducer';
import reportDisbursementReducer from './reportDisbursementReducer';
import productivityByProductReducer from './productivityByProductReducer';
import backLinkReducer from './backLinkReducer';
import fngReducer from './fngReducer';
import productivityControlBoardReducer from './productivityControlBoardReducer';
import leaderProductivityCtrlBrdReducer from './leaderProductivityCtrlBrdReducer';
import filesDNOReducer from './filesDNOReducer';
import bdbDocumentsReducer from './bdbDocumentsReducer';
import kardexReducer from './kardexReducer';
import trainDocsOCRReducer from './trainDocsOCRReducer';
import uploadLargeFilesReducer from './uploadLargeFilesReducer';
import OCRAnalyzeDocumentsReducer from './OCRAnalyzeDocumentsReducer';

export default combineReducers({
    alerta: alertaReducer,
    file: fileReducer,
    cases: casesReducer, 
    analyst: analystReducer,
    prioritization: prioritizationReducer,
    prioritizationLib: libPrioritizationReducer,
    select: selectReducer,
    simplySelect: simplySelectReducer,
    dynamicTable: dynamicTableReducer,
    dynamicModal: dynamicModalReducer,
    productivityProduct: productivityByProductReducer,
    reportDisbursement: reportDisbursementReducer,
    backLink: backLinkReducer,
    fng: fngReducer,
    prodContBoard : productivityControlBoardReducer,
    leaderProdBoard: leaderProductivityCtrlBrdReducer,
    plant: filesDNOReducer,
    documentsReducer: bdbDocumentsReducer,
    kardex: kardexReducer,
    trainDocsOCR: trainDocsOCRReducer,
    uploadLargeFiles: uploadLargeFilesReducer,
    OCRAnalyzeDocuments: OCRAnalyzeDocumentsReducer
});
