import { 
    GET_ITEMS_COMPONENT_START,
    GET_ITEMS_COMPONENT_SUCCESS,
    GET_ITEMS_COMPONENT_FAIL,
    GET_ITEMS_COMPONENT_REDIRECT,
    UPDATE_PRIORITY_START,
    UPDATE_PRIORITY_SUCCESS,
    UPDATE_PRIORITY_FAIL
} from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    error: false,
    loading: false,
    data: null,
    alert: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_ITEMS_COMPONENT_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_ITEMS_COMPONENT_SUCCESS:
            return {
                ...state,
                data: action.payload.Item,
                loading: false,
                error: false
            }
        case GET_ITEMS_COMPONENT_FAIL:
            return {
                ...state,
                loading: false,
                error: false
            } 
        case GET_ITEMS_COMPONENT_REDIRECT:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PRIORITY_START:
            return {
                ...state,
                loading: action.payload,
                error: false
            }
        case UPDATE_PRIORITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                alert: action.payload
        }
        case UPDATE_PRIORITY_FAIL:
            return {
                ...state,
                loading: action.payload,
                error: false
            }
        default:
            return state;
    }
}