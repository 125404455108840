import Swal from 'sweetalert2';
import moment from "moment";
import { SET_MANAGEMENT_LIST_MANAGER, ENABLE_MANAGE_AREA, SET_AREA_LIST, REPLOBLATE_TIMEINIT, UPDATE_ACTIVE_EDIT, ACTIVE_EVENT_TEMPORAL, UPDATE_ACTIVE_EVENT, SET_PROCESS_LIST, CHANGE_LOADING_VISIBILITY, RESET_ACTIVE_MESSAGE, UPDATE_DAILY_MNGMNT_COUNTERS, UPDATE_DAILY_CARDS, EDIT_CARDS, UPDATE_REPOBLATE, CHANGE_AREA, RESET_ACTIVE_EVENT_NO_STORED } from "../bdb-types";
import uuid from "uuid";
import { formattedNames, mapSelectedListToReducer, secondsToHour, addMinutes, b64_to_utf8_Util, decryptUsingAES256utils, getManagementByVerticalUtils } from "../bdb-helpers/utils";
import { b64_to_utf8, getdecrypt } from "../bdb-services/GeneralService/globalService";
import { getDlyMngmntInfo, getCtrlBrdInfoByCc, getMngmntsAndProcessList, deleteCtrlBrdById, putCtrlBrd, postCtrlBrd } from '../bdb-services/GeneralService/controlboardService';
import { ABSENCE, idmRootAdminProd } from '../../constants';



export const updateActiveEvent = (event) => ({
    type: UPDATE_ACTIVE_EVENT,
    payload: event
})
export const selectEditCards = (event) => ({
    type: EDIT_CARDS,
    payload: event
})
const activeEventTemporal = (event) => ({
    type: ACTIVE_EVENT_TEMPORAL,
    payload: event
})
export const updateLoading = (state) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
})
export const resetActveEvent = () => ({
    type: RESET_ACTIVE_MESSAGE
})
export const resetActveEventNoStored = () => ({
    type: RESET_ACTIVE_EVENT_NO_STORED
})


export const updateCounters = (counts) => ({
    type: UPDATE_DAILY_MNGMNT_COUNTERS,
    payload: counts
})

export const setManagementManager = (event) => ({
    type: SET_MANAGEMENT_LIST_MANAGER,
    payload:event

})
export const updateCards = (cards) => ({
    type: UPDATE_DAILY_CARDS,
    payload: cards
})
export const enableManageArea = (event) => ({
    type: ENABLE_MANAGE_AREA,
    payload: event
})
export const updateRepoblate = (changePoblate) => ({
    type: UPDATE_REPOBLATE,
    payload: changePoblate
})
export const reploblateTimeInit = (timeInit) => ({
    type: REPLOBLATE_TIMEINIT,
    payload: timeInit
})
export const updateActiveEdit = (event) => ({
    type: UPDATE_ACTIVE_EDIT,
    payload: event
})

const setAreaList = (option) => ({
    type: SET_AREA_LIST,
    payload: option
})
const setProcessList = (option) => ({
    type: SET_PROCESS_LIST,
    payload: option
})

export const saveChangeArea = (event) =>({
    type: CHANGE_AREA,
    payload: event
})

export const getManagementsAndProcesses = (management, areaData, isAdministrative) => {
    return async (dispatch,getState) => {
        dispatch(updateLoading(true));
        const admin = idmRootAdminProd.includes(getdecrypt('rolUser'))
        const verticalData = admin && sessionStorage.getItem('staffVertical') ? sessionStorage.getItem('staffVertical') : getdecrypt('vertical').replace(/"/g, '');
        const vertical = encodeURIComponent(verticalData)
        const inputManagement = encodeURIComponent(management ? management : sessionStorage.getItem("currentManagement"));
        const processNew = areaData ? `${inputManagement}/${encodeURIComponent(areaData)}` : `${inputManagement}`;
        const isAdminFlag = isAdministrative ? isAdministrative.toString() : 'false'
        const response = await getMngmntsAndProcessList(`${vertical}/${processNew}/${isAdminFlag}`).catch(err => {
            console.log('Error', err)
            dispatch(setAreaList([]));
            dispatch(setProcessList([]));
            dispatch(updateLoading(false));
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });
        if (response.status !== 200) return;
        if (areaData === undefined) {
            let dataMapped = mapSelectedListToReducer(response.data.Items, "area", 26);
            dispatch(setAreaList(dataMapped));
        } else {
            const dataProcess = mapSelectedListToReducer(response.data.Items, "process", 26)
            dispatch(setProcessList(dataProcess));
        }
        dispatch(updateLoading(false));

    }
}

export function postInsertProductivity(dataInsert, method, modalEnable=true) {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true));
        const dateNow = moment();
        const user = getdecrypt('userLogin');
        const dataStaff = await getBoardControl();
        const NotFound = (dataStaff.status === 404) ? true : false;
        const icon = NotFound ? 'info' : 'error';
        const iconColor = NotFound ? '##002c76' : '#FF5733 ';
        const titlehtml = NotFound ? null : '<h3>Lo sentimos</h3>';
        let activeModal = { icon: 'success', tittle: '¡Listo!', text: 'El registro se realizó con éxito' }
        let response
        let partitionCart = !!getState().prodContBoard.activeEvent.partitions ? getState().prodContBoard.activeEvent.partitions : false;
        if (dataStaff.status) {
            dispatch(updateLoading(false));
            activeModal = {
                icon: icon,
                iconColor: iconColor,
                tittle: titlehtml,
                html: dataStaff.status === 404 ? `Para continuar es necesario que<br>verifiques con tu líder que tu usuario se<br>encuentre relacionado en el archivo de <br>planta de la herramienta.` : `El registro no se ha podido realizar, <br>intenta de nuevo`
            }
        }
        let validation = getState().prodContBoard.prodCards.filter(card => {
            const cardBeginDate = card.processType === 'new' ? card.beginDate.slice(11, card.beginDate.length) : card.beginDate;
            const cardEndDate = card.processType === 'new' ? card.endDate.slice(11, card.endDate.length) : card.endDate;
            if (card.typeTime || cardBeginDate > dataInsert.timeEnd || cardEndDate < dataInsert.timeInit || (getState().prodContBoard.restocking && card.enabled) || card.conflict) {
                return false
            }
            return true
        })
        
        let exception= false;
        for(let val in validation){
            if(Boolean(dataInsert.process.option.isMasive)){
                if(validation[val].processType=== 'No Productivo'){
                    exception=true;
                }else{
                    exception=false;
                    break;
                }
            } else if (dataInsert.area.option.id==='Ausentismos'){
                if(Boolean(validation[val].isMasiveProccess)){
                    exception=true;
                }else{
                    exception=false;
                    break;
                }
            }
        }

        if (validation.length >= 1 && (!partitionCart) && !exception) {
            activeModal = activeModal = { icon: 'error', iconColor: '#FF5733 ', tittle: '<h3>Lo sentimos</h3>', html: `Ya registraste una actividad en la franja <br>horaria seleccionada.` }
        };

        if ((dataStaff.status || (validation.length >= 1 && (!partitionCart) && !exception)) && modalEnable) {
            dispatch(updateLoading(false));
            await Swal.fire({
                icon: activeModal.icon,
                iconColor: activeModal.iconColor,
                title: activeModal.tittle,
                html: activeModal.html,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            })
            return;
        } 

        const body =
        {
            "id": (method === 'put') ? dataInsert.id : uuid.v4(),
            "createAt": dateNow.format('YYYY-MM-DDTHH:mm:ss'),
            "date": dateNow.format('YYYY/MM/DD'),
            "beginDate": dataInsert.timeInit,
            "endDate": dataInsert.timeEnd,
            "management": dataInsert.management.option.id,
            "area": dataInsert.area.option.id,
            "new": dataInsert.process.option.id,
            "ammount": Number(dataInsert.amount),
            "comments": dataInsert.observations,
            "rrhhManagement": dataStaff['management'],
            "user": user,
            "documentNumber": process.env.REACT_APP_ENABLE_CHARACTER_NUMBER ? dataStaff["documentNumber"] : dataStaff["documentNumber"].replace(/\D/g, ''),
            "fullName": dataStaff['fullName'],
            "frequency": dataInsert.process.option.frequency,
            "daylyGoal": !!dataInsert.process.option.daylyGoal ? dataInsert.process.option.daylyGoal : '0',
            "assignedPeople": Number(dataInsert.process.option.assignedPeople) === NaN || (!Number(dataInsert.process.option.assignedPeople)) ? 1 : Number(dataInsert.process.option.assignedPeople),
            "hasAmount": dataInsert.process.option.hasAmount,
            "isAutomatic": false,
            "isAdministrative": sessionStorage.getItem('isAdministrative') === 'true' ? 1 : 0,
            "isStrategic":  Boolean(dataInsert.process.option.isStrategic) ? 1 : 0,
            "partitions": !!dataInsert.partitions ? dataInsert.partitions : null,
            "caseSmax": dataInsert.caseSmax ? dataInsert.caseSmax : null,
            "vertical":getdecrypt('vertical').replace(/"/g, '')
        }
        if(Boolean(dataInsert.process.option.isMasive)){
            body["isMasiveProccess"]=1; 
            body["approvedRegisters"]= dataInsert.approveds === "" ? '0' : dataInsert.approveds;
            body["returnedRegisters"]= dataInsert.rejecteds === "" ? '0' :dataInsert.rejecteds;
            body["othersCount"]= dataInsert.processAmount === "" ? '0' : dataInsert.processAmount;
            body["aprovedCount"]= dataInsert.processAmount === "" ? '0' : dataInsert.processAmount;
            body["returnedCount"]= "0";
        }else{
            body["isMasiveProccess"]=0;
            body["aprovedCount"]= dataInsert.approveds === "" ? '0' : dataInsert.approveds; 
            body["returnedCount"]= dataInsert.rejecteds === "" ? '0' : dataInsert.rejecteds; 
            body["othersCount"]= dataInsert.total === "" ? '0' : dataInsert.total;
            body["approvedRegisters"]="";
            body["returnedRegisters"]="";
        }
        if (method === 'put') {
            dispatch(updateLoading(true));
            response = await putCtrlBrd(body)
                .then(e => {
                    dispatch(getDailyMngmnt());
                    dispatch(updateRepoblate(false));
                    dispatch(updateLoading(false));

                    return e
                })
                .catch(err => {
                    console.log('Error', err)
                    activeModal = { icon: 'error', tittle: '¡Lo sentimos!', text: 'El registro no se ha podido actualizar, <br>intenta de nuevo' }
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
                });
        } else if (method === 'post') {
            dispatch(updateLoading(true));
            response = await postCtrlBrd(body)
                .then(e => {
                    dispatch(getDailyMngmnt());
                    dispatch(updateRepoblate(false));
                    dispatch(updateLoading(false));
                    dispatch(saveChangeArea(undefined));
                    return e
                })
                .catch(err => {
                    console.log('Error', err)
                    activeModal = activeModal = { icon: 'error', tittle: '¡Lo sentimos!', text: 'El registro no se ha podido realizar, <br>intenta de nuevo' }
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
                });
        }
        dispatch( resetActveEvent() );
        if (response.status === 200 || response.status === 201) { sessionManagement(dataInsert) };
        await Swal.fire({
            icon: activeModal.icon,
            title: activeModal.tittle,
            html: activeModal.text,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#002c76',
        })
    }
}

export function validationConflic(dataInsert) {
    return async (dispatch, getState) => {
        const dataStaff = await getBoardControl();
        let activeModal = { icon: 'success', tittle: '¡Listo!', text: 'El registro se realizó con éxito' }
        let partitionCart = !!getState().prodContBoard.activeEvent.partitions ? getState().prodContBoard.activeEvent.partitions : false;
        const NotFound = (dataStaff.status === 404) ? true : false;
        const icon = NotFound ? 'info' : 'error';
        const iconColor = NotFound ? '##002c76' : '#FF5733 ';
        const titlehtml = NotFound ? null : '<h3>Lo sentimos</h3>';
        if (dataStaff.status) {
            activeModal = {
                icon: icon,
                iconColor: iconColor,
                tittle: titlehtml,
                html: dataStaff.status === 404 ? `Para continuar es necesario que<br>verifiques con tu líder que tu usuario se<br>encuentre relacionado en el archivo de <br>planta de la herramienta.` : `El registro no se ha podido realizar, <br>intenta de nuevo`
            }
        }


        let validation = getState().prodContBoard.prodCards.filter(card => {
            const cardBeginDate = card.processType === 'new' ? card.beginDate.slice(11, card.beginDate.length) : card.beginDate;
            const cardEndDate = card.processType === 'new' ? card.endDate.slice(11, card.endDate.length) : card.endDate;
            if (card.typeTime || cardBeginDate > dataInsert.timeEnd || cardEndDate < dataInsert.timeInit || (getState().prodContBoard.restocking && card.enabled) || card.conflict) {
                return false
            }
            return true
        })
        
        let exception= false;
        for(let val in validation){
            if(Boolean(dataInsert.process.option.isMasive)){
                if(validation[val].processType=== 'No Productivo'){
                    exception=true;
                }else{
                    exception=false;
                    break;
                }
            } else if (dataInsert.area.option.id==='Ausentismos'){
                if(Boolean(validation[val].isMasiveProccess)){
                    exception=true;
                }else{
                    exception=false;
                    break;
                }
            }
        }

        if (validation.length >= 1 && (!partitionCart) && !exception) {
            activeModal = activeModal = { icon: 'error', iconColor: '#FF5733 ', tittle: '<h3>Lo sentimos</h3>', html: `Ya registraste una actividad en la franja <br>horaria seleccionada.` }
        };

        if (dataStaff.status || (validation.length >= 1 && (!partitionCart) && !exception)) {
            dispatch(updateLoading(false));
            await Swal.fire({
                icon: activeModal.icon,
                iconColor: activeModal.iconColor,
                title: activeModal.tittle,
                html: activeModal.html,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            })
            return false;
        } else{
            dispatch(updateLoading(false));
            return true;
        }

    }
}


export const getBoardControl = async () => {
    const cc = b64_to_utf8(sessionStorage.getItem('documentNumberAdviser'));
    let dataReques = {
        'number': cc ? cc : undefined,
        'vertical': getdecrypt('vertical') ? getdecrypt('vertical').replace(/"/g,'') : null
    }
    try {
        const response = await getCtrlBrdInfoByCc(dataReques).catch(err => {
            console.log('Error', err)
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });
        if (response.status !== 200) return { status: response.status };
        const responseDataStaff = response.data.Items[0];
        return responseDataStaff;
    } catch (error) {
        console.log(error)
    }

}

export function getDailyMngmnt() {

    return async (dispatch) => {
        dispatch(updateLoading(true))
        const userLogin = getdecrypt('userLogin')
        const result = await getDlyMngmntInfo(userLogin)
            .catch(err => {
                dispatch(updateCards([]))
                dispatch(updateLoading(false))
                dispatch(updateCounters())
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        if (result.status !== 200) return;
        const prodHours = secondsToHour(result.data.prodHoursFriendly + result.data.chageTaskTotal);
        const nonProdHours = secondsToHour(result.data.nonProdHoursFriendly);
        const totalHours = secondsToHour(result.data.hoursSeg + result.data.chageTaskTotal);
        let conflictsflag = false;
        let temporalCard = [];
        let approveds = 0;
        let rejecteds = 0;
        let processAmount=0;
        result.data.productivity.forEach(item => {
            if (item.conflict) conflictsflag = true;
            if (!item.typeTime) {
                temporalCard.push({ ...item, enabled: true })
            } else {
                temporalCard.push(item)
            }
            if (!!item.aprovedCount || !!item.returnedCount) {
                rejecteds += item.returnedCount;
                if(Boolean(item.isMasiveProccess)){
                    processAmount += item.aprovedCount;
                }else{
                    approveds += item.aprovedCount;
                }
            }
        })
        let counter = {
            times: [
                { name: 'Productivas', counter: (prodHours), color: 'black' },
                { name: 'Ausentismos', counter: nonProdHours, color: 'black' },
                { name: 'Total', counter: totalHours, color: (result.data.hoursSeg + result.data.chageTaskTotal) >= 34200 ? 'black' : 'orange' }
            ],
            cases: [
                { name: 'Aprobados', counter: approveds },
                { name: 'Devueltos', counter: rejecteds },
                { name: 'Total casos', counter: (approveds + rejecteds + processAmount) },
                { name: 'Procesos', counter: processAmount },
            ]
        };
        let dataTimeStam = temporalCard.length > 0 ? addMinutes(temporalCard[0].endDate) : '';
        dispatch(updateCounters(counter));
        dispatch(updateCards(temporalCard));
        dispatch(reploblateTimeInit(dataTimeStam));
        dispatch(updateLoading(false));
        if (conflictsflag) {
            dispatch(enableManageArea(true));
            Swal.fire({
                icon: 'warning',
                title: '¡Tienes actividades en conflicto!',
                html: `Se registraron actividades en la misma franja<br>horaria. Por favor verifica y ajústalas.`,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76'
            });
        } else {
            dispatch(enableManageArea(false));
        }
    }
}

export function deleteProductivityCard(data, deleteCard) {

    return async (dispatch, getState) => {
        dispatch(updateLoading(true));
        let activeEditTem = getState().prodContBoard.activeEvent;
        if (!!data && !!data.partitions && (data.partitions.length > 0) && !deleteCard) {
            await deleteCtrlBrdById(data)
                .then(response => {
                    if (response.status = 200 && (!!data && !!data.partitions && activeEditTem.partitions.length > 0)) {
                        dispatch(postInsertProductivity({ ...activeEditTem }, 'put'))
                    }
                })
                .catch(err => {
                    console.log('Error', err)
                    dispatch(updateLoading(false));
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };

                });
        } else if (!!data || !!deleteCard) {
            let modalAlert = await Swal.fire({
                icon: 'info',
                html: '¿Estás seguro de <br> eliminar este registro?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                confirmButtonColor: '#002c76',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
            })
            if (modalAlert.isConfirmed) {
                dispatch(updateLoading(true));
                await deleteCtrlBrdById(data).catch(err => {
                    console.log('Error', err)
                    return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
                })
            }
            dispatch(getDailyMngmnt())
        }

        dispatch(updateLoading(false));
    }
}



export function initCardSumze() {
    return async (dispatch) => {
        dispatch(updateLoading(true))
        await dispatch(getDailyMngmnt())
        dispatch(updateLoading(false))
    }
}

export function reploblate(dataInsert) {
    return async (dispatch, getState) => {
        dispatch(updateRepoblate(true))
        const updata_1 = dataInsert.management;
        const updata_2 = dataInsert.area;
        const updata_3 = dataInsert.new;
        let repoblationEvent = {}
            repoblationEvent = {
            id: dataInsert.id,
            management: { id: "management", option: { id: updata_1, label: formattedNames(updata_1, 26) } },
            area: { id: "area", option: { id: updata_2, label: formattedNames(updata_2, 26) } },
            process: { id: "process", option: { id: updata_3, label: formattedNames(updata_3, 26), hasAmount: Boolean(dataInsert.ammount > 0), daylyGoal: dataInsert.daylyGoal, isMasive:dataInsert.isMasiveProccess, isAdministrative: Boolean(dataInsert.isAdministrative), isStrategic: Boolean(dataInsert.isStrategic) } },
            timeInit: dataInsert.beginDate,
            timeEnd: dataInsert.endDate,
            approveds: Boolean(dataInsert.isMasiveProccess) ?`${dataInsert.approvedRegisters}`:(dataInsert.aprovedCount ? `${dataInsert.aprovedCount}`:''),
            rejecteds: Boolean(dataInsert.isMasiveProccess) ?`${dataInsert.returnedRegisters}`:(dataInsert.returnedCount ? `${dataInsert.returnedCount}`:''),
            total: dataInsert.othersCount? `${dataInsert.othersCount}`: '',
            observations: dataInsert.comments,
            partitions: !!dataInsert.partitions ? dataInsert.partitions : null,
            amount: dataInsert.ammount > 0 ? `${dataInsert.ammount}` : '',
            processType: dataInsert.processType,
            caseSmax: dataInsert.caseSmax ? dataInsert.caseSmax : null,
            processAmount: Boolean(dataInsert.isMasiveProccess) ? `${dataInsert.aprovedCount}` : '',
            }

        dispatch(updateActiveEvent(repoblationEvent));
        dispatch(activeEventTemporal(repoblationEvent));

    }
}

export function isSameData(dataInsert, newDataInsert ,conparefield, affectations) {
    let flag = true
    conparefield.forEach((field) => {
        const comp1 = field === "area" || field === "management" || field === "process" ? (dataInsert[field].option ? dataInsert[field].option.id : '') : dataInsert[field];
        const comp2 = field === "area" || field === "management" || field === "process" ? (newDataInsert[field].option ? newDataInsert[field].option.id : '') : newDataInsert[field];
        if (comp1 !== comp2 || !flag) {
            flag = false
            return;
        }
        else if (!ABSENCE.includes(comp1) && field === "area") {
            affectations.forEach((item) => {
                if (newDataInsert[item] !== dataInsert[item]) {
                    flag = false;
                    return;
                }
            })
        }
    })
    return flag
}


export function sessionManagement() {
    return async (dispatch, getState) => {
        let temporalActiveEvent = getState().prodContBoard.activeEvent;
        sessionStorage.setItem('currentManagement', JSON.stringify(temporalActiveEvent.management.option))
        sessionStorage.setItem('currentArea', JSON.stringify(temporalActiveEvent.area.option))
        sessionStorage.setItem('currentProcess', JSON.stringify(temporalActiveEvent.process.option))
    }
}

export function identificationCard(inputData) {
    return async (dispatch) => {
        for (let i = 0; i < inputData.length; i++) {
            if (inputData[i].enabled) {
                const currentCard = inputData[i]
                dispatch(selectEditCards(currentCard))
            }
        }
    }
}

export function getManagementByVerticalMANAGER(requireSublist) {
    return async (dispatch) =>{
        const dataMapped = await  getManagementByVerticalUtils (27,requireSublist)
        dispatch(setManagementManager(dataMapped))
    }
}






