import axios from 'axios';
import * as CryptoJS from "crypto-js";
import { JSEncrypt } from 'jsencrypt';
import { v4 as uuidv4 } from "uuid";
import { osName, osVersion, browserVersion, deviceType, browserName } from 'react-device-detect';

axios.interceptors.request.use(
    config => {
        //interceptor order disbursement
        if ( sessionStorage.getItem("APP") === "0" || !sessionStorage.getItem("APP") ) return config;
        const documentNumberAdviserDecrypt = decodeURIComponent(escape(window.atob( sessionStorage.getItem('documentNumberAdviser') )));
        const keyToken = sessionStorage.getItem('keyToken');
        const token = sessionStorage.getItem('token');
        const payloadCrypt = {
            'accessToken' : decryptUsingAES256(token, documentNumberAdviserDecrypt, (decodeURIComponent(escape(window.atob( process.env.REACT_APP_AES_KEY ))))),
            'userKey' : decryptUsingAES256(keyToken, documentNumberAdviserDecrypt, (decodeURIComponent(escape(window.atob( process.env.REACT_APP_AES_KEY ))))),
            'customerId' : getCustomer()
        }
        if (!config.url.includes(process.env.REACT_APP_SELF_ASSIGN_ENDPOINT)) {
            const authorization = encryptUsingRSA(JSON.stringify(payloadCrypt), process.env.REACT_APP_PUBLIC_SHA_KEY);
            const header = JSON.stringify(getHeader());
            config.headers.tokenheader = authorization + '#separator#' + header;
        } else {
            config.headers.Authorization = encryptUsingRSA(JSON.stringify(payloadCrypt), process.env.REACT_APP_PUBLIC_SHA_KEY);
        }
        return config;
    },
    error => {
        Promise.reject(error)
        sessionStorage.clear();
});
function getCustomer() {
    const custIdentType = sessionStorage.getItem('typeCustomer');
    const custIdentNum = sessionStorage.getItem('numberCustomer');
    return custIdentType + custIdentNum;
}
function getHeader() {
    const dateNow = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP"
    }).format(Date.now());
    let jsonHeader = {};
    const documentNumberAdviserDecrypt = decodeURIComponent(escape(window.atob( sessionStorage.getItem('documentNumberAdviser') )));
    jsonHeader['messageId'] = uuidv4();
    jsonHeader['eventDate'] = dateNow;
    jsonHeader['deviceId'] = `${osName} ${osVersion} ${deviceType} ${browserName} ${browserVersion}`;
    jsonHeader['channel'] = 'office';
    jsonHeader['productId'] = 'digital-self-assign';
    jsonHeader['sessionId'] = getSessionId();
    jsonHeader['publicIp'] = decryptUsingAES256(sessionStorage.getItem('ip'), documentNumberAdviserDecrypt, (decodeURIComponent(escape(window.atob( process.env.REACT_APP_AES_KEY )))));
    jsonHeader['office'] = decryptUsingAES256(sessionStorage.getItem('officeCode'), documentNumberAdviserDecrypt, (decodeURIComponent(escape(window.atob( process.env.REACT_APP_AES_KEY )))));
    return jsonHeader;
}

function getSessionId(){
    let sessionid = sessionStorage.getItem('SessionID') ? sessionStorage.getItem('SessionID') : null;
    if ((sessionid === null)) {
        sessionid = uuidv4();
        sessionStorage.setItem('SessionID', sessionid);
    }
    return sessionid;
}

function encryptUsingRSA(message, publicKey) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publicKey);
    return jsEncrypt.encrypt(message);
}

function decryptUsingAES256(value, iv, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _iv = CryptoJS.enc.Hex.parse(iv);
    return CryptoJS.AES.decrypt(
        value, _key, {
            keySize: 128,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
}

