import { UPDATE_FILE, UPDATE_FILE_ERROR, UPDATE_FILE_SUCCESS } from '../bdb-types';

// Cada reducer tiene su state
const initialState = {
    error: null,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case UPDATE_FILE:
            return {
                ...state,
                loading: action.payload
            }
        case UPDATE_FILE_ERROR:
            return {
                ...state,
                loading: action.payload,
            }
        case UPDATE_FILE_SUCCESS:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state;
    }
}