import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { forwardRef } from 'react';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import { getDocumentsDisbursement } from '../../bdb-services/GeneralService/analystDisbursementService';
import { useDispatch } from 'react-redux';
import { setFinishLoading, setStartloading } from '../../bdb-actions/bdbDocumentsActions';
import Swal from 'sweetalert2';
const Table = ({ data }) => {
const dispatch = useDispatch();
const cols = [
  {
    title: 'Fecha radicación', field:'createAt',  cellStyle: { width: '15%', padding: '2vh 1vw' }, defaultSort: 'desc', render: rowData =>
      <div>
        {formatDate(rowData.dateAssignment)}
        <br />
        {tConvert(rowData.hourAssignment)}
      </div>
  },
  { title: 'Nº de identificación', field: 'document', cellStyle: { width: '15%', padding: '2vh 1vw' }, render: rowData => <div>{`${rowData.typeDocument} ${rowData.document.replace(/.(?=(?:.{3})+$)/g, '$&.')}`}</div> },
  { title: 'Producto', field: 'product', cellStyle: { width: '10%', padding: '2vh 1vw' } },
  { title: 'Número de control', field: 'numberControl', cellStyle: { with: '12%', padding: '2vh 1vw' } },
  { title: 'Proyecto crediticio', field: 'creditProject', cellStyle: { width: '14%', padding: '2vh 1vw' } },
  {
    title: 'Estado', cellStyle: { width: '12%', padding: '2vh 1vw' }, render: rowData =>
      <div>
        {
          (rowData.reqStatus === 'Devuelto') ?
            <>
              <div className='disbursement-returned' onClick={() => showModal(rowData)}>
                <div className='disbursement-returned-icon'></div>
                <span>{rowData.reqStatus}</span>
              </div>
            </>
            :
            <>
              <span className={'disbursement-status-indicator ' + getClassStatus(rowData.reqStatus)}></span> <span>{rowData.reqStatus}</span>
            </>
        }
      </div>
  },
  {
    title: 'Documentos', cellStyle: { width: '20%', padding: '2vh 2vw' }, render: rowData =>
      <div onClick={() => { redirect(rowData) }} className="row cursor-pointer" ><div className="_order-sends-documents-preview"></div><div className="label-preview">Ver</div></div>
  }

];
const showModal = (data)=>{
  Swal.fire({
    title: 'Causal de devolución',
    iconHtml: "<div class='icon-warning'></div>",
    html:`<div id="swal-content-reason" class="custom-swal-content"></div>`,
    customClass:{
      popup:"swal-wide",
      title:"custom-swal-header",
      icon:"icon-warning no-border",
      closeButton:"custom-swal-close-btn"
    },
    showCancelButton: false,
    showConfirmButton: false,
    showCloseButton: true,
    didRender(){
      document.getElementById("swal-content-reason").innerText = data.reasonReturn??''
    }
  })

}
const  formatDate = (_date)=> {
  const date = new Date(_date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
  const str = date.toLocaleString('es-ES', options);
  return str
}
const tConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM';
    time[0] = +time[0] % 12 || 12;
    
  }
  return time.join('');
}

const getClassStatus = (status) => {
  if (status === 'En trámite') {
    return 'status-open'
  }
  else if (status === 'Aprobado') {
    return 'status-approved'
  }
  else {
    return 'status-send'
  }
}
const redirect = async (data) => {
  const enpointSelf = process.env.REACT_APP_SELFASSIGNMENT;
  dispatch( setStartloading());
  const customerInfo = {
    "custidentnum": data.document,
    "custidenttype": data.typeCustomer,
    "creditProject": data.creditProject,
    "controlNumber": data.numberControl,
    "userRole": getdecrypt('rolUser')
  }
  sessionStorage.setItem('typeCustomer', data.typeCustomer);
  sessionStorage.setItem('numberCustomer', data.document);

  await getDocumentsDisbursement(customerInfo).catch(
    err => {
      console.log('error', err);
      return { status: 500 }
    }
  )
    .then(
      data => {
       dispatch( setFinishLoading());
        if (data.status === 200) {
          const documentDisbursement = data.data;
          sessionStorage.setItem('documentDisbursement', JSON.stringify(documentDisbursement));
          window.open(`${enpointSelf}documentsDC`);

        }
      }
    )
}
const CustomSearch = forwardRef((pros, ref) => (
  <div className='_table_search-icon'>
  </div>
));

const tableIcons = {
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <CustomSearch {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}
  const defaultMaterialTheme = createTheme();
  return (
    <div className='_table_container-table' style={{ maxWidth: '100%' }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          icons={tableIcons}
          columns={cols}
          data={data}

          localization={{
            toolbar: {
              searchPlaceholder: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: "No se encontraron resultados"
            }
          }}
          style={{
            backgroundColor: '#f6f8fa',
            borderRadius: '0px',
            boxShadow: 'none',
            border: '0px',

          }}
          options={{
            headerStyle: {
              backgroundColor: '#f6f8fa',
              color: '#4a4a4a',
              fontWeight: 600,
              fontSize: '11px',
              padding: '2vh 0vw 2vh 1vw'
            },
            paging: false,
            rowStyle: {
              color: '#4a4a4a',
              fontSize: '11px',
              backgroundColor: '#fff'
            },
            search: true,
            sorting: true,
            showTitle: false,
            draggable: false,
            searchFieldStyle: {
              width: '384px',
              height: '35px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(236, 236, 236, 0.5)',
              border: 'solid 1px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#fff',
              marginLeft: '10px'
            },
            minBodyHeight: '50vh',
            maxBodyHeight: '60vh'

          }}
        />

      </ThemeProvider>
    </div>
  )
}

export default Table