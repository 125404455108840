export const VoBoCentralizedVectorStructure = {
	title: 'Convenio',
	subtitle: 'VoBo centralizado',
	infoLabel: [],
	iconInfo: false,
	infoLabel: null,
    buttonOk: true,
    buttonCancel: true,
	buttonOkText: null,
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: null,
	structure: {
		row1: 
		[
			{ 	
				id: 'code',
                name: 'code',
				type: 'input',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Escribe el código',
				value: null
			}
		],
		row2: [
			{ 	
				id: 'name',
                name: 'name',
				type: 'input',
				styleClass: 'input-large-dynamic-modal',
                span: 'Nombre convenio',
                placeholder: 'Escribe el nombre',
				value: null
			},
			
		]
	},
	arrowStyle: {},
	smallModal: false
}

export const VoBoPaymentVectorStructure = {
	title: 'Convenio',
	subtitle: 'VoBo pagaduría',
	infoLabel: [],
	iconInfo: false,
	infoLabel: null,
    buttonOk: true,
    buttonCancel: true,
	buttonOkText: null,
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: null,
	structure: {
		row1: 
		[
			{ 	
				id: 'name',
                name: 'name',
				type: 'select',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Escribe el código',
				autocomplete: true,
				value: null
			}
		],
		row2: [
			{ 	
				id: 'firstUser',
                name: 'firstUser',
				type: 'input',
				styleClass: 'input-small-dynamic-modal',
                span: 'Gestor asignado 1',
                placeholder: 'Usuario de red',
				alert: null,
				title_alert_active: null,
				title_alert: null,
				value: null
			},
			{ 	
				id: 'secondUser',
                name: 'secondUser',
				type: 'input',
				styleClass: 'input-small-dynamic-modal',
                span: 'Gestor asignado 2',
                placeholder: 'Usuario de red',
				alert: null,
				title_alert_active: null,
				title_alert: null,
				value: null
			},
			{ 	
				id: 'thirdUser',
                name: 'thirdUser',
				type: 'input',
				styleClass: 'input-small-dynamic-modal',
                span: 'Gestor asignado 3',
                placeholder: 'Usuario de red',
				alert: null,
				title_alert_active: null,
				title_alert: null,
				value: null
			},
			{ 	
				id: 'fourthdUser',
                name: 'fourthdUser',
				type: 'input',
				styleClass: 'input-small-dynamic-modal',
                span: 'Gestor asignado 4',
                placeholder: 'Usuario de red',
				alert: null,
				title_alert_active: null,
				title_alert: null,
				value: null
			}
		]
	},
	arrowStyle: {bottom: '22px'},
	smallModal: false
}

export const pausedModalStructure = {
	title: null,
	subtitle: null,
	infoLabel: ['¿Estás seguro de poner en', 'pausa el caso?'],
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Pausar',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	structure: {
		row1: 
		[
			{ 	
				id: 'pausedReason',
                name: 'pausedReason',
				type: 'select',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Selecciona la causal',
				autocomplete: false,
				options: [
					{
						code: "0",
						id: "Errores en flujos Mantiz",
						label: "Errores en flujos Mantiz"
					},
					{
						code: "1",
						id: "Recursos FNG agotados",
						label: "Recursos FNG agotados"
					},
					{
						code: "2",
						id: "Desembolso por PPE",
						label: "Desembolso por PPE"
					},
					{
						code: "3",
						id: "Requiere autorización / VoBo",
						label: "Requiere autorización / VoBo"
					},
				]
			}
		]
	},
	arrowStyle: {left: '0% !important', bottom: '21px'},
	smallModal: true
}
export const pausedModalWithoutOptionsStructure = {
	title: null,
	subtitle: null,
	infoLabel: ['¿Estás seguro de poner en', 'pausa el caso?'],
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Pausar',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	smallModal: true
}
export const pausedOrderDisbursementModalStructure = {
	title: null,
	subtitle: null,
	infoLabel: ['¿Estás seguro de poner en', 'pausa el caso?'],
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Pausar',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	buttonsClass:'button-dynamic-modal-large',
	structure: {
		row1: 
		[
			{ 	
				id: 'pausedReason',
                name: 'pausedReason',
				type: 'select',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Selecciona la causal',
				autocomplete: false,
				options: [
					{
						code: "0",
						id: "Inconsistencia en documentos",
						label: "Inconsistencia en documentos"
					},
					{
						code: "1",
						id: "Bloqueo en aplicativos",
						label: "Bloqueo en aplicativos"
					}
				]
			}
		]
	},
	arrowStyle: {left: '0% !important', bottom: '21px'},
	smallModal: true
}
export const rejectOrderDisbursementModalStructure = {
	title: null,
	infoLabel: [''],
	subtitle: null,
    dialogClassName: 'swal-wide',
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Devolver',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	reverseOrder: true,
	structure: {
		row1: 
		[
			{ 	
				id: 'rejectReason',
                name: 'rejectReason',
				type: 'textarea',
				styleClass: 'form-control',
                placeholder: 'Escribe la causal de devolución (máximo 2000 caracteres).',
				value: null
			}
		]
	},
	buttonsClass:'button-dynamic-modal-large reverse-order-buttons',
	smallModal: true
}

export const abortModalStructure = {
	title: null,
	subtitle: null,
	infoLabel: ['¿Estás seguro de rechazar', 'el caso?'],
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Rechazar',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	structure: {
		row1: 
		[
			{ 	
				id: 'abortReason',
                name: 'abortReason',
				type: 'select',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Selecciona la causal',
				autocomplete: false,
				options: [
					{
						code: "0",
						id: "Sust. de pasivos premium / plus",
						label: "Sust. de pasivos premium / plus"
					}
				]
			}
		]
	},
	arrowStyle: {left: '0% !important', bottom: '21px'},
	smallModal: true
}

export const pausedPreaprovedModalStructure = {
	title: null,
	subtitle: null,
	infoLabel: ['¿Estás seguro de poner en', 'pausa el caso?'],
	iconInfo: true,
    buttonOk: true,
	buttonCancel: true,
	buttonOkText: 'Pausar',
    buttonCancelText: null,
	buttonOkColor: null,
	buttonCancelColor: '#c61f01',
	structure: {
		row1: 
		[
			{ 	
				id: 'pausedReason',
                name: 'pausedReason',
				type: 'select',
				styleClass: 'input-large-dynamic-modal',
                span: 'Código convenio',
                placeholder: 'Selecciona la causal',
				autocomplete: false,
				options: [
					{
						code: "0",
						id: "Devuelto al comercial",
						label: "Devuelto al comercial"
					},
					{
						code: "1",
						id: "Error en flujo Mantiz",
						label: "Error en flujo Mantiz"
					}
				]
			}
		]
	},
	arrowStyle: {left: '0% !important', bottom: '21px'},
	smallModal: true
}
