import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import { getPriorityHousing, updatePriorityHousing } from '../../bdb-actions/priotitizationActions';
import './PrioritizationHousing.css';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import Swal from 'sweetalert2';
import { processesByPriorities } from '../../../constants';

const PrioritizationSettingsHousing = () => {

  const data = useSelector(state => state.prioritization.data);
  const loading = useSelector(state => state.prioritization.loading);
  const error = useSelector(state => state.prioritization.error);
  const alert = useSelector(state => state.prioritization.alert);
  const [enableRestore, setEnableRestore] = useState(false);
  const [labelProcess, setLabelProcess] = useState(null);
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState(null);
  const [showOptions0, setShowOptions0] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);
  const [priorities, setPriorities] = useState(
    [
      {id: 'date', value: 'Fecha'},
      {id: 'amount', value: 'Monto'},
      {id: 'moduleOption', value: 'Canal'},
      {id: 'currentProcess', value: 'Proceso'}
    ]
  );

  useEffect(() => {
    const getItems = () => dispatch(getPriorityHousing());
    getItems();
  }, []);

  const [processOption, setProcessOption] =
  useState( () => { 
    const keys = Object.keys(processesByPriorities['housing']);
    let optionsList = [];
    keys.forEach((key, index) => {
      optionsList.push({id: key, label: processesByPriorities['housing'][key], optionSelected: 'date'});
    });
    return optionsList;
  });
  
  useEffect(() => {
    if(data !== null) {
      let optionsList = [];
      const housing = processesByPriorities['housing'];
      Object.keys(housing).forEach((key, index) => {
        var configInit = Object.keys(data[key]).filter( function(value) { 
            return data[key][value] 
        });
        optionsList.push({id: key, label: housing[key], optionSelected: configInit.shift()});
      });
      setProcessOption(optionsList);
    }
  }, [data]);

  const enableRestoreStyle = () => {
    if(data === null){
      return 'display-none';
    }
    const keys = Object.keys(processesByPriorities['housing']);
    let validation = [];
    keys.forEach((key) => {
      validation.push(data[key]['date'])
    });

    return validation.includes(false) ? 'button-restore label-restore' : 'display-none' ;
  }
  
  const oneButton = () => {
    return enableRestore ? 'flex-around' : 'flex-center';
  }

  const buttonApplyStyle = () => {
    return labelProcess === null ? 'button-apply-disable text-button' : 'button-apply-enable text-button';
  }

  const modalApply = () => {
    if (labelProcess === null){
      return;
    }
    Swal.fire({
      icon: 'info',
      style: 'modal-text-button modal-subtitle-info',
      title: `<p class="titel-modal">¿Estás seguro de cambiar\n la priorización?</p>`,
      html:
        'Los cambios se aplicarán para los <br> próximos cargues',
      showCancelButton: true,
      confirmButtonText: 'Aplicar',
      confirmButtonColor: '#002c76',
      cancelButtonColor: '#c61f01',
      cancelButtonText: 'Cancelar',
    }).then( result => {
      if(result.isConfirmed) {
        dispatch(updatePriorityHousing(data));
      }
    });
  }

  const showModalRestore = () => {
    Swal.fire({
      icon: 'info',
      style: 'modal-text-button modal-subtitle-info',
      title: `<p class="titel-modal">¿Estás seguro de reiniciar\n la configuración?</p>`,
      text:
        'Todos los procesos se priorizarán por fecha',
      showCancelButton: true,
      confirmButtonText: 'Reiniciar',
      confirmButtonColor: '#002c76',
      cancelButtonColor: '#c61f01',
      cancelButtonText: 'Cancelar',
    }).then( result => {
      if(result.isConfirmed) {
        restorePriorityHousing();
        dispatch(updatePriorityHousing(data));
      }
    });
  }

  const restorePriorityHousing = () => {
    const keys = Object.keys(processesByPriorities['housing']);
    keys.forEach((key, index) => {
      selectOptions('date', index);
      setShowOptions0(false);
      setShowOptions1(false);
      setShowOptions2(false);
    });

    setLabelProcess(null);
  }

  const redirectToHome = () => {
    Swal.fire({
      icon: 'info',
      title: '<div>¡Lo sentimos!</div>',
      html:
        'Presentamos inconvenientes técnicos,<br> intenta de nuevo',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002c76'
    });
  }

  const getSelectList = index => {
    return processOption[index]['id'] === 'housingAnalysisProperty' ?
      priorities.filter(property => property.id !== 'moduleOption') : priorities.filter(property => property.id !== 'currentProcess')
  }

  function setOptionToShow (index) {
    switch (index) {
      case 0: 
       setShowOptions0(!showOptions0);
       break;
      case 1: 
        setShowOptions1(!showOptions1);
        break;
      case 2: 
        setShowOptions2(!showOptions2);
        break;
      default: break;
    }
  }

  function getOptionToShow (index) {
    switch (index) {
      case 0: return showOptions0;
      case 1: return showOptions1;
      case 2: return showOptions2;
      default: break;
    }
  }

  function getOptionStyle(option, index) {
    const classCss = processOption[index]['optionSelected'] === option ? 'option-style option-select-color' : 'option-style';
    return classCss;
  }

  function selectOptions(option, index) {
    setOptionToShow(index);
    let processOptionTemp = processOption;
    processOptionTemp[index]['optionSelected'] = option;
    changePriority(processOptionTemp[index].id, option);
    setProcessOption(processOptionTemp);
    setLabelProcess(processOptionTemp);
  }

  function changePriority(process, type) {
    var keysData = Object.keys(data[process]);
    keysData.forEach(key => {
      data[process][key] = key === type;
    });
  }


  function getNameSelect(id) {
    return priorities.find(priority => priority.id === id)['value'];
  }

    return ( 
      <Fragment>
            <LeaderOptions />
            { loading ? <Loading /> : null}
            { error ? redirectToHome() : null}
            { alert ? <p className="alert alert-success text-center mt-4 alert-color">La priorización se configuró con éxito</p> : null }
            <div className="flex-center">
              <div className="tittle">Configuración de la demanda</div>
            </div>
            <div className="flex-center">
              <div className="div-subtittle">
                <div className="subtittle">Selecciona la(s) categoría(s) en el orden que deseas priorizar los próximos cargues.</div>
              </div>
            </div>
            <div className="padding-27">
              <div className={oneButton()}>
                <div>
                  <div onClick={() => {modalApply()}} className={buttonApplyStyle()} >Aplicar cambios</div>
                </div>
                <div>
                  {<div onClick={() => {showModalRestore()}} className={enableRestoreStyle()}>Reiniciar config.</div>}
                </div>
              </div>
            </div>
          <div className="flex-between">
            {
              processOption.map((option, index) => (
                <div className="width-box">
                <div className="priority-box priority-label enable-box enable-label">{option['label']}
                </div>
                <div className="select-container">
                  <div className="position-absolute">
                    <div className="d-flex">
                        <input className="select-style-priority text-select-priority" 
                        value={getNameSelect(option['optionSelected'])}></input> 
                    </div>
                    <div className="simple-div">
                        <spam className="icon-arrow-housing" 
                        onClick={() => {setOptionToShow(index)}}></spam>
                    </div>
                      { 
                      getOptionToShow(index) ?
                        <div className="options-housing">
                          {getSelectList(index).map(item => (
                            <div className={getOptionStyle(item['id'], index)}
                            onClick={() => {selectOptions(item['id'], index)}}>
                            <span className="span-option-style">{item['value']}</span>
                          </div>
                          ))
                          }
                        </div> : null
                      }
                      </div>
                    </div>
              </div>
              ))
            }
          </div>
      </Fragment>
     );
}
 
export default PrioritizationSettingsHousing;