
import { SET_DUPLICATE_STAFF ,RESET_PLANT_FILE, NUMBER_RECORDS,CHANGE_LOADING_VISIBILITY, SET_STATUS_STAFF, SET_LOADING_STATUS, UPDATE_FORM_USER_STAFF, RESET_FORM_USER_STAFF, UPDATE_SEARCH_VALUE, SET_ERROR_SEARCH, POPULATE_USERS_STAFF, SET_VERTS_AND_MANGMTS, SET_AREAS_STAFF_LIST, SET_PROCESSES_STAFF_LIST } from "../bdb-types";

const initialState = {
    hideUploadedFile: false,
    loading:false,
    numberRecords:"",
    statusStaff: [],
    duplicatesStaff:[],
    loadingStatus: false,
    userInfo: {
        nameUser: "",
        documentUser: "",
        positionCode: "",
        management: "",
        area: "",
        process: ""

    },
    searchValue: "",
    error: false,
    usersStaff: [],
    verticalsAndManagements: [],
    areas: [],
    processes: []
};

export default function(state = initialState, action) {
    switch ( action.type ) {
        case RESET_PLANT_FILE:
            return {
                ...state,
                hideUploadedFile: action.payload,
                statusStaff: []
            }
        case NUMBER_RECORDS:
            return {
                ...state,
                numberRecords: action.payload
            }
        case CHANGE_LOADING_VISIBILITY:
            return {
                ...state,
                loading: action.payload
            }
        case SET_STATUS_STAFF:
            return {
                ...state,
                statusStaff: [ ...action.payload ]
            }
        case SET_DUPLICATE_STAFF:
            return {
                ...state,
                duplicatesStaff: [...action.payload]
            }
        case SET_LOADING_STATUS:
            return {
                ...state,
                loadingStatus: action.payload
            }
        case UPDATE_FORM_USER_STAFF:
            return {
                ...state,
                userInfo: { ...state.userInfo, ...action.payload }   
            }
        case RESET_FORM_USER_STAFF:
            return {
                ...state,
                userInfo: { ...initialState.userInfo }
            }
        case UPDATE_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload
            }
        case POPULATE_USERS_STAFF:
            return {
                ...state,
                usersStaff: [...action.payload]
            }
        case SET_ERROR_SEARCH:
            return {
                ...state,
                error: action.payload
            }
        case SET_VERTS_AND_MANGMTS:
            return {
                ...state,
                verticalsAndManagements: [...action.payload]
            }
        case SET_AREAS_STAFF_LIST:
            return {
                ...state,
                areas: [...action.payload]
            }
        case SET_PROCESSES_STAFF_LIST:
            return {
                ...state,
                processes: [...action.payload]
            }
        default:
            return {
                ...state
            }

    }
}