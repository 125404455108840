import React, { useState, Fragment, useEffect } from 'react';
import SimpleSelectComponent from '../../../bdb-components/SimpleSelectComponent/SimpleSelectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getBodyCasesOpenDocuments, getDocumentDisbursement, getSelectDocumentDisbursement, viewLoginClose, updateLoadingAnalist, getBase64ByDocHandle } from '../../../bdb-actions/analystActions';
import Preview from "../../../bdb-components/Preview/Preview";
import Loading from '../../../bdb-components/LoadingComponent/Loading';
import { excelDocumentsOrderDisbursment, idmAnalystProdRol } from '../../../../constants';
import { downloadExcel } from '../../../bdb-helpers/utils';
import { getdecrypt } from '../../../bdb-services/GeneralService/globalService';
import './DocumentsDC.scss';
const DocumentsDCView = () => {
    const isComercial = (!!sessionStorage.getItem('cnUserRol') || (getdecrypt('rolUser')=== idmAnalystProdRol[1]) )
    const isDisbursementLeader = (getdecrypt('rolUser') === 'LideresDesembolsos')
    const dispatch = useDispatch();
    let enablemanagement = false;
    const optionsSelecteds = useSelector(state => state.simplySelect.multipleOption);
    const openCasesDocumen = useSelector(state => state.analyst.openCasesDocumen);
    const loading = useSelector(state => state.analyst.loading);
    const management = useSelector(state => state.analyst.management);
    const previewedDocument = useSelector(state => state.analyst.previewedDocument);
    const downloadDocumentDisburse = useSelector(state => state.analyst.downloadDocumentDisburse);
    const selectStyle = {
        height: '40px',
        fontSize: '16px',
        zIndex: '5',
        padding: '9px 20px 9px 16px',
        color: '#4a4a4a',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHheight: 'normal',
        letterSpacing: 'normal',
        borderRadius: '4px',
        border: 'solid 1px #d6d6d6',
        backgroundColor: '#ffffff',
        width: '100%'
    };

    const styleList = {
        maxHeight: '167px',
        zIndex: '30',
        padding: '4px 6px 4px 5px',
        position: 'absolute',
        fontSize: '16px !important',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.5',
        letterSpacing: 'normal',
        color: '#4a4a4a'
    };

    const styleArrow = {
        zIndex: '15', 'bottom': '0px',
        'margin': '-5% 0% 0% 0%'
    };
    const [savedDocs, setSavedDocs] = useState([])
    const [viewDoc, setViewDoc] = useState([])

    const downloadEventDocument = (index) => {
        const selectDownloadDocument = openCasesDocumen.filter(data => data.id.toUpperCase() === excelDocumentsOrderDisbursment[index].toUpperCase());
        const hasDocHandle = !!selectDownloadDocument[0].docHandle
        if (hasDocHandle){
            return dispatch(getBase64ByDocHandle(selectDownloadDocument[0].docHandle, true))
        }
        dispatch(getDocumentDisbursement(selectDownloadDocument[0]));

    };
    useEffect(() => {
        if (!!downloadDocumentDisburse) {
            downloadExcel(downloadDocumentDisburse);
        }
    }, [downloadDocumentDisburse]);

    const getDocument = () => {
    };
    useEffect(() => {
        let dataDisbursement = [];
        const dataStorage = JSON.parse(sessionStorage.getItem('documentDisbursement'));
        const hasDochandle = !!dataStorage[0].documentHandle;
        if (hasDochandle){
            dataDisbursement = dataStorage.map((data) => {
                return { id: `${data.typeNameLabel}`, label: `${data.typeNameLabel}`, cloudPath: data.documentHandle, docHandle: data.documentHandle };
            });
            dataDisbursement.forEach(element => {
                dispatch(getBase64ByDocHandle(element.docHandle));
            });

        } else {
            dataDisbursement = dataStorage.map((data) => {
                return { id: `${data.typeName}`, label: `${data.typeName}`, cloudPath: data.cloudPath, body: data.body };
            });
            dataDisbursement.forEach(element => {
                let dataDownload =[{id:'management', option:element}]
                dispatch(getSelectDocumentDisbursement(dataDownload));
            });
        }
        dispatch(getBodyCasesOpenDocuments(dataDisbursement))
        dispatch(viewLoginClose(true));
    }, []);

    useEffect(() => {
        if (savedDocs[0] === undefined){
            setSavedDocs([previewedDocument])
        }else{
            savedDocs.push(previewedDocument)
        }

        if (viewDoc.length === 0 && optionsSelecteds.length > 0 && optionsSelecteds[0].option) {
            setViewDoc([previewedDocument])
            dispatch(updateLoadingAnalist(false))
        }
    }, [previewedDocument]);

    useEffect(() => {
        if (optionsSelecteds.length > 0 && optionsSelecteds[0].option) {
            let prev = savedDocs.filter(element => element.fileCloud === optionsSelecteds[0].option.cloudPath)
            setViewDoc(prev)
            if( prev.length === 0 ) dispatch (updateLoadingAnalist(true))    
        }
    }, [optionsSelecteds]);

    useEffect(() => {
        if (!!management.id) { getDocument(management.id) };
    }, [management]);

    const filterExcel = (lisDocument) => {
        return lisDocument.filter(data => !excelDocumentsOrderDisbursment.includes(data.id));
    };

    const closeWindo = () => {
        dispatch(viewLoginClose(false));
        window.close()
    }


    return (
        <Fragment>
            {loading ? <Loading></Loading> : null}
            <div className='container-documents-box'>

                <div className='container-download-document-sm my-3'>
                        <div className='container-download-document' onClick={() => downloadEventDocument(1)} >
                            <div className={'download-document-icon download-document-btn'} ></div>
                            <span className='documents-header-col'>Orden desembolso</span>
                        </div>
                        {
                            (isComercial || isDisbursementLeader)?
                            <>
                            <div class="line-separator-document-disbursement"></div>
                            <div className='container-download-document' onClick={() => downloadEventDocument(0)} >
                                <div className={'download-document-icon download-document-btn'} ></div>
                                <span className='documents-header-col'>Archivo Plano</span>
                            </div>
                            </>:null
                        }
                </div>
                <div className='container-elements-search'>
                    <div className='input-documents'>
                        <span className='documents-header-col'>Selecciona el documento que deseas visualizar</span>
                        <SimpleSelectComponent
                            id={'management'}
                            small={false}
                            width={'592px'}
                            absolute={true}
                            options={filterExcel(openCasesDocumen)}
                            initSelect={null}
                            autocomplete={true}
                            placeholder={'Selecciona un documento'}
                            disabled={enablemanagement}
                            arrow={styleArrow}
                            styleSelect={selectStyle}
                            selected={management.option}
                            listStyle={styleList}
                        />
                    </div>
                    <div className='container-download-documents'>
                        <div className='container-download-document' onClick={() => downloadEventDocument(1)} >
                            <div className={'download-document-icon download-document-btn'} ></div>
                            <span className='documents-header-col'>Orden desembolso</span>
                        </div>
                        {
                            (isComercial || isDisbursementLeader) ?
                            (
                            <>
                            <div class="line-separator-document-disbursement"></div>
                            <div className='container-download-document' onClick={() => downloadEventDocument(0)} >
                                <div className={'download-document-icon download-document-btn'} ></div>
                                <span className='documents-header-col'>Archivo Plano</span>
                            </div>
                            </>
                            ):null
                        }
                    </div>

                    <div className="ml-auto" >
                        <div className={'close-document-disbursemen '} onClick={() => closeWindo()}></div>
                    </div>
                </div>

                {!!viewDoc[0] && viewDoc[0].src ?
                    <Preview
                        documentInfo={viewDoc[0]}
                    /> : null
                }

            </div>
        </Fragment>
    );
}
export default DocumentsDCView;