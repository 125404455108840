import React, {Component, useEffect, useState} from 'react';
import * as CryptoJS from "crypto-js";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import { changeLoadingVisibility, reserveGuarantee, resetQueryGuarantee } from '../../../bdb-actions/fngActions';
import Loading from '../../../bdb-components/LoadingComponent/Loading';
import { formatDate } from '../../../bdb-helpers/dates/datesHelper';

import './FngQuery.css'

export const FngQuery = ( { guarantee } ) => {

    const history = useHistory();
    const loading = useSelector(state => state.fng.loading);
    const dataReserveGuarantee = useSelector( state => state.fng.reserveInfo );
    const [ error, setError ] = useState( undefined );
    const dispatch = useDispatch();
    const { clientInfo, guaranteeInfo, guaranteeExtraInfo } = guarantee;
    const { garaCodigo, periodosDeprlr } = guaranteeInfo;
    const { nombre, btiidCodigo, clieCodigo } = clientInfo;
    const { fechaCorteFiscal } = guaranteeExtraInfo;
    const [ period, setPeriod ] = useState( periodosDeprlr );
    const [ unitTerm, setUnitTerm ] = useState( undefined );
    const [ term, setTerm ] = useState( '' );
    const [ taxCourtDate, setTaxCourtDate ] = useState( undefined );
    const [ taxCourtDateError, setTaxCourtDateError ] = useState( undefined );
    const [ disableButton, setDisableButton ] = useState( true );
    const [ prevnum, setPrevnum ] = useState( '' );
    const [commissionCollectionPeriod,setCommissionCollectionPeriod] = useState('');
    const [dayORmonth, setDayORmonth] = useState('');

    useEffect( () => {
        if ( !dataReserveGuarantee ) {
            return;
        }
        if ( dataReserveGuarantee.error ) {
            setError( dataReserveGuarantee.errorDescription );
        } else {
            setError( undefined );
            history.push( '/reserve-guarantee/reserve' );
        }
    }, [ dataReserveGuarantee ] );
    useEffect(() =>{
        let termInt = parseInt(term)
        if (commissionCollectionPeriod === '61' && dayORmonth === 'Meses' && termInt <=12 ){
            setError('Para plazo de obligación de 12 meses o menos, seleccionar en periodo de comisión anual anticipada')
            setDisableButton(true);
        }else{
            setError(null)
        }
        if(dayORmonth === 'Días' && !!termInt && !taxCourtDateError ){
            setDisableButton(false);
        }
    },[term,dayORmonth, commissionCollectionPeriod]);

    const onSubmitHandle = ( e ) => {
        e.preventDefault();
        dispatch( changeLoadingVisibility( false ) );
        const payload = {
            'guaranteeId' : garaCodigo,
            'user' : decryptUsingAES256( sessionStorage.getItem( 'userLogin' ), b64_to_utf8( sessionStorage.getItem( 'documentNumberAdviser' ) ), b64_to_utf8( process.env.REACT_APP_AES_KEY ) ),
            'period': period,
            'unitTerm': unitTerm,
            'term': term,
            'taxCourtDate' : fechaCorteFiscal ? fechaCorteFiscal : taxCourtDate
        };
        dispatch( reserveGuarantee( payload ) );
        setDisableButton(true);
    };

    const handleOnChangePeriod = ( { target } ) => {
        const value = target.value;
        setCommissionCollectionPeriod(value)
        if ( value) {
            setPeriod( value );
        }
        setDisableButton( !( !(isNaN(value))  && !!unitTerm && !!term && ( !!taxCourtDate || !!fechaCorteFiscal ) ) );
        };

    function getRequestedProducts() {
    return [
      { name: "Días", value: "Días" }, { name: "Meses", value: "Meses" }];
    }

    const handleOnChangeTerm = ( { target } ) => {
        const value = target.value;
        var allow=false;
        const regex = /^[0-9]*$/;
            if  ( regex.test(value)) {
                setTerm(value);
                allow=true;
                setPrevnum(value);
            }
            else {
                if(regex.test(prevnum)){
                    allow=true;
                }
                else{
                    allow=false;
                }
                setDisableButton( !( allow && !!value && !(isNaN(period)) && !!unitTerm && ( !!taxCourtDate || !!fechaCorteFiscal ) ) );
            };       
        setDisableButton( !( allow && !!value && !(isNaN(period)) && !!unitTerm && ( !!taxCourtDate || !!fechaCorteFiscal ) ) );
    };

    const handleOnChangeUnitTerm = ( { target } ) => {
        const value = target.value;
        setDayORmonth(value)
        if ( value) {
            setUnitTerm( value );
        }
        setDisableButton( !( !(isNaN(period))&& !error && !!value && !!term && ( !!taxCourtDate || !!fechaCorteFiscal ) ) );
    };

    const handleOnChangeTaxCourtDate = ( { target } ) => {
        const value = target.value;
        if ( value && value.length === 10 ) {
            const splitA = value.split( "/" );
            const day = splitA[ 0 ];
            const month = splitA[ 1 ];
            const year = splitA[ 2 ];
            if ( !day || day.length !== 2 || !month || month.length !== 2 || !year || year.length !== 4 ) {
                setTaxCourtDateError( 'Formato inválido' );
            } else {
                setTaxCourtDateError( undefined );
            }
            const numericDay = day ? parseInt( day, 10 ) : undefined;
            const numericMonth = month ? parseInt( month, 10 ): undefined;
            const numericYear = year ? parseInt( year, 10 ) : undefined;
            if (
                !!numericDay && !!numericMonth && !!numericYear &&
                !isNaN( numericDay ) && !isNaN( numericMonth ) && !isNaN( numericYear ) &&
                addZero2Number( numericDay, 2 ).length === 2 &&
                addZero2Number( numericMonth, 2 ).length === 2 && ( numericYear + '' ).length === 4 &&
                day === addZero2Number( numericDay, 2 ) && month === addZero2Number( numericMonth, 2 )
            ) {
                const date = new Date( numericYear, numericMonth - 1, numericDay );
                setTaxCourtDate( formatDate( date, 'yyyymmdd' ) );
                setDisableButton( !( !!unitTerm && !!term && !error ) );
            } else {
                setTaxCourtDateError( 'Formato inválido' );
                setDisableButton( true );
            }
        } else {
            setDisableButton( true );
            setTaxCourtDateError( 'Formato inválido' );
        }

    };

    function addZero2Number( number, length ) {
        return ( number + '' ).length === length ? ( number + '' ) : '0' + number;
    }

    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    function decryptUsingAES256(value, iv, key) {
        const _key = CryptoJS.enc.Utf8.parse(key);
        const _iv = CryptoJS.enc.Hex.parse(iv);
        return CryptoJS.AES.decrypt(
            value, _key, {
                keySize: 128,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
    }

    return (
    <>
        { loading ? <Loading message={'Consultando reserva'}/> :
        <form className='query-main-container' onSubmit={ onSubmitHandle }>
            <div className='query-form-container'>
                <p className='query-form-title'>Solicitar reserva</p>
                {
                    !!error ? (
                        <div className='query-fng-error'>
                            { error }
                        </div> ) : ''
                }
                <div className='query-inputs-container'>
                    <div className='query-form-group'>
                        <div className='query-form-input-title-container'>
                            <p className='query-form-input-title'>Nombre del cliente</p>
                        </div>
                        <input
                            className='query-form-input'
                            id='queryCustomerName'
                            type='text'
                            defaultValue={ nombre }
                            disabled
                        />
                    </div>
                    <div className='query-form-group'>
                        <div className='query-form-input-title-container'>
                            <p className='query-form-input-title'>Documento del cliente</p>
                        </div>
                        <input
                            className='query-form-input'
                            id='queryCustomerNum'
                            type='text'
                            defaultValue={ btiidCodigo + '. ' + clieCodigo }
                            disabled
                        />
                    </div>
                    <div className='query-form-group'>
                        <div className='query-form-input-title-container'>
                            <p className='query-form-input-title'>Número de garantía</p>
                        </div>
                        <input
                            className='query-form-input'
                            id='queryGuaranteeNumber'
                            type='text'
                            defaultValue={ garaCodigo }
                            disabled
                        />
                    </div>
                    <div className='query-form-group'>
                        <div className='query-form-input-title-container'>
                            <p className='query-form-input-title'>Fecha corte fiscal</p>
                        </div>
                        <input
                            className={ ( fechaCorteFiscal ? 'query-form-input' : 'query-form-input-active' ) + ( taxCourtDateError ? ' query-form-input-error' : '' ) }
                            id='queryTaxCourtDate'
                            type='text'
                            value={ fechaCorteFiscal && formatDate( new Date( fechaCorteFiscal ), 'dd/mm/yyyy' ) }
                            disabled = { !!fechaCorteFiscal }
                            placeholder='DD/MM/AAAA'
                            onChange={ handleOnChangeTaxCourtDate }
                        />
                    </div>
                    {
                        !periodosDeprlr || ( periodosDeprlr && ![ 60, 61 ].includes( periodosDeprlr ) ) ?
                            <div className='query-form-group'>
                                <div className='query-form-input-title-container'>
                                    <p className='query-form-input-title'>Período cobro de comisión</p>
                                </div>
                                <select
                                    className='query-form-input-select-active'
                                    id='queryPeriod'
                                    onChange={ handleOnChangePeriod }
                                >
                                    <option>Seleccionar</option>
                                    <option value={ 60 }>Anual Anticipado</option>
                                    <option value={ 61 }>Unica Anticipada</option>
                                </select>
                            </div> :
                            undefined
                    }
                    <div className='query-form-group'>
                        <div className='query-form-input-title-container'>
                            <p className='query-form-input-title'>Plazo de la obligación</p>
                        </div>                       
                        <input
                            className='query-form-num'
                            id='query-digit'
                            type='text'
                            inputMode='numeric'
                            placeholder='#'
                            minLength={1}
                            maxLength={3}
                            onChange={ handleOnChangeTerm }
                            value={term}/>
                            <div className='buttonGroup'>
                                <ButtonGroup className="w" size= "sm" toggle>
                                    {getRequestedProducts().map((row, idx) => (
                                    <ToggleButton className="toggle" key={idx} id={`radio-${idx}`} type="radio"
                                        variant="outline-success" name="radio" checked={ unitTerm == row.value}
                                        value={row.value} onChange={handleOnChangeUnitTerm}>
                                            {row.name}
                                    </ToggleButton>
                                    ))}
                                </ButtonGroup>      
                            </div>                                             
                    </div>
                </div>
                <div className='query-button-container'>
                    <button
                        type='submit'
                        id='queryRequestReserve'
                        className={ disableButton ? 'query-request-reserve-disabled' : 'query-request-reserve' }
                        disabled={ disableButton }>
                        Solicitar reserva
                    </button>
                </div>
            </div>
        </form>}
    </>
    )

}
