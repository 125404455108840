import {
    REMOVE_KEY_PAIR,
    SET_KEY_PAIR,
    UPDATE_FILE,
    SET_GEOMETRIES,
    SET_PAGE_NUMBER,
    SET_MAX_PAGES,
    SET_IS_DRAWING,
    SET_CURRENT_KEY_PAIR,
    SET_ONBASE_TYPE,
    CHANGE_LOADING_VISIBILITY

} from '../bdb-types/index';
import { trainDocsOCRKeys } from '../../constants';
import Swal from 'sweetalert2';
import { postTrainOCRDocs } from '../bdb-services/GeneralService/globalService';

export const updateLoading = (state) => ({
    type: CHANGE_LOADING_VISIBILITY,
    payload: state
})

const removeKeyPair = keyPairPosition => ({
    type: REMOVE_KEY_PAIR,
    payload: keyPairPosition
});

const uptdFile = _file => ({
    type: UPDATE_FILE,
    payload: _file
});

const uptdKeyPair = _keyPair => ({
    type: SET_KEY_PAIR,
    payload: _keyPair
});

const uptdGeometries = _geometries => ({
    type: SET_GEOMETRIES,
    payload: _geometries
});

const uptdPageNumber = _pgNumber => ({
    type: SET_PAGE_NUMBER,
    payload: _pgNumber
});

const uptdMaxPages = _maxPgs => ({
    type: SET_MAX_PAGES,
    payload: _maxPgs
});

const uptdIsDrawing = _isDrawing => ({
    type: SET_IS_DRAWING,
    payload: _isDrawing
});

const uptdCurrentKeyPair = _currKeyPair => ({
    type: SET_CURRENT_KEY_PAIR,
    payload: _currKeyPair
});

const uptdOnBaseType = _onBaseType => ({
    type: SET_ONBASE_TYPE,
    payload: _onBaseType
});

export function setRemoveKeyPair(keyPairPosition) {
    return async (dispatch) => {
        dispatch(removeKeyPair(keyPairPosition))
    }
}

export function removeFile() {
    return async (dispatch) => {
        return dispatch(uptdFile(null))
    }
}

export function uploadFile(_file) {
    return async (dispatch, getState) => {
        dispatch(uptdFile(_file))
        dispatch(updateLoading(true));
        let response
        const { fileNameKey } = getState().trainDocsOCR;
        const b64Stream = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (event) => {
                reject(event.target.error);
            };

            reader.readAsDataURL(_file);
        });
        const body = {
            "stream": b64Stream,
            "fileName": _file.name,
            fileNameKey
        }
        response = await postTrainOCRDocs(body, 'NEW').then((res) => {
            return { ...res, status: 200 }
        })
        .catch(err => {
            console.log('Error', err)
            return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
        });
        dispatch(updateLoading(false));
        if (response.status !== 200) {
            await Swal.fire({
                icon: 'error',
                title: '<div>¡Lo sentimos!</div>',
                html: 'Este Archivo no se pudo cargar',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            }).then(result => {
                dispatch(uptdFile(null))
            });
        }
    }
}

export function setKeyPairs(_keyPair) {
    return async (dispatch) => {
        dispatch(uptdKeyPair(_keyPair))
    }
}

export function setGeometries(_geometries) {
    return async (dispatch) => {
        dispatch(uptdGeometries(_geometries))
    }
}

export function setPageNumber(_pgNumber) {
    return async (dispatch) => {
        dispatch(uptdPageNumber(_pgNumber))
    }
}

export function setmaxPages(_maxPgs) {
    return async (dispatch) => {
        dispatch(uptdMaxPages(_maxPgs))
    }
}

export function setIsDrawing(_isDrawinng) {
    return async (dispatch) => {
        dispatch(uptdIsDrawing(_isDrawinng))
    }
}

export function setCurrentKeyPair(_currKeyPair) {
    return async (dispatch) => {
        dispatch(uptdCurrentKeyPair(_currKeyPair))
    }
}

export function setonBaseType(_onBaseType) {
    return async (dispatch) => {
        dispatch(uptdOnBaseType(_onBaseType))
    }
}

const mapOCRKeys = (list) => {
    let trainDocsOCRKeysTmp = {}
    let elemAux
    for (const element of Object.keys(list)) {
        elemAux = { ...list[element] }
        if (!trainDocsOCRKeysTmp[elemAux['category']]) trainDocsOCRKeysTmp[elemAux['category']] = {};
        trainDocsOCRKeysTmp[elemAux['category']][element] = elemAux['label'];
    }
    return trainDocsOCRKeysTmp
}

export function handleNewKey(keyPr) {
    return async (dispatch, getState) => {
        const { keyPairs, pageNumber } = getState().trainDocsOCR;
        const { value: _key } = await Swal.fire({
            title: 'Comentanos la etiqueta que encontraste:',
            input: 'select',
            inputOptions: mapOCRKeys(trainDocsOCRKeys),
            inputPlaceholder: 'Escoge una etiqueta',
            showCancelButton: true,
            confirmButtonColor: '#002c76',
            confirmButtonColor: '#002c76',
            confirmButtonText: 'Agregar',
            cancelButtonText: 'Cancelar'
        })

        if (_key) {
            keyPr.key = _key
            keyPr.value = "gen_val"
            keyPr.label = trainDocsOCRKeys[_key].label
            keyPr.selected = false
            let tmpKeyPairs = [...keyPairs];
            tmpKeyPairs[pageNumber - 1] = [...keyPairs[pageNumber - 1], keyPr]
            dispatch(setKeyPairs([...tmpKeyPairs]));
            dispatch(setCurrentKeyPair(null));
        }
        else {
            dispatch(setCurrentKeyPair(null));
        }
    }
}

export function handleSendJSON() {
    return async (dispatch, getState) => {
        const { keyPairs, file, onBaseType, geometries, leftMargin, topMargin, fileNameKey} = getState().trainDocsOCR;
        let keyPairsCounter = keyPairs.reduce((accumulator, keyPr) => accumulator + keyPr.length, 0);
        // if (!onBaseType || keyPairsCounter === 0) {
        //     let missing = !onBaseType ? 'la caja documental' : 'ninguna etiqueta';
        //     await Swal.fire({
        //         icon: 'error',
        //         title: '<div>¡Lo sentimos!</div>',
        //         html: `No puedes cargar el documento sin indicar ${missing}`,
        //         confirmButtonText: 'Aceptar',
        //         confirmButtonColor: '#002c76',
        //     })
        //     return;
        // }
        let response;
        const JSONAux = {
            name: file.name,
            onBaseType,
            numPages: keyPairs.length,
            pages: keyPairs.map((_page, nPage) => {
                return {
                    geometry: geometries[nPage],
                    keyPairs: _page.map((rw) => {
                        return getRow(rw, { left: leftMargin, top: topMargin })
                    })
                }
            })
        }
        const jsonB64 = Buffer.from(JSON.stringify(JSONAux)).toString('base64');
        const body = {
            "stream": jsonB64,
            "fileName": file.name,
            onBaseType,
            fileNameKey
        }
        response = await postTrainOCRDocs(body, 'JSON').then((res) => {
            return { ...res, status: 200 }
        })
            .catch(err => {
                console.log('Error', err)
                return { ...err.response, status: (err.response && err.response.status ? err.response.status : 500) };
            });
        dispatch(updateLoading(false));
        if (response.status !== 200) {
            await Swal.fire({
                icon: 'error',
                title: '<div>¡Lo sentimos!</div>',
                html: 'Este Archivo no se pudo cargar',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#002c76',
            })
            return;
        }
        dispatch(uptdFile(null))
    }
};

const getRow = (keyPr, _offSet) => {
    const _r = {
        key: keyPr.key,
        value: keyPr.value,
        vertices: [
            { x: keyPr.x - _offSet.left, y: keyPr.y - _offSet.top },
            { x: keyPr.x + keyPr.width - _offSet.left, y: keyPr.y - _offSet.top },
            { x: keyPr.x + keyPr.width - _offSet.left, y: keyPr.y + keyPr.height - _offSet.top },
            { x: keyPr.x - _offSet.left, y: keyPr.y + keyPr.height - _offSet.top }
        ]
    }
    return _r
}