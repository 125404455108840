import React, { useState, Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import './reportCasesGpo.scss';
import PausedGPO from '../../bdb-components/TableBodyComponent/PausedGPO';
import {getCasesPaused} from '../../bdb-actions/analystActions';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink';
import {openCasesGpo} from '../../bdb-actions/analystActions';
import {saveCasePausedGpo} from '../../bdb-actions/reportDisbursementActions';
import { useHistory } from "react-router-dom";

const Analyst = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeView = useSelector( state => state.analyst.changeView );
  const loading = useSelector( state => state.analyst.loading );
  const wait = useSelector( state => state.cases.loading);
  const casesPause = useSelector ( state => state.analyst.getCaseGpo );
  const initialPausedCauses = useSelector ( state => state.analyst.initialPaused );
  const saveCasePaused = useSelector(state => state.reportDisbursement.saveCasePaused);
  const [process, setProcess] = useState(false);
  const [filter, setFilterPausedCases] = useState( "" );

useEffect(()=>{
  if(saveCasePaused === 'Pausado'){
    setProcess(true);
    setTimeout(() => {
      setProcess(false)
      dispatch(saveCasePausedGpo(false));
    }, 4000);
  }
},[saveCasePaused])

  

  useEffect(() => {
    setFilterPausedCases( [] );
  }, []);

  useEffect(() => {
      dispatch(getCasesPaused())
}, [])

  const newCaseGpo =() =>{
    dispatch(saveCasePausedGpo(false));
    dispatch(openCasesGpo(false))  
    history.push('/report-cases/disbursement');
  }

  const applyFilter = ( value ) => {
    setFilterPausedCases( value );
  }

  return (
    <Fragment>
        <BackLink url={'/menu-disbursement'}/>
            
      <div className="container">
        <div hidden={!changeView}>
          { loading || wait ? <Loading /> : null } 
            <div className='report-cases-gpo-box'>
              <h2 className="text-center management-title">Reportar Casos</h2>
              <div>
                  { process ? <div className='succses-case'>El caso se pausó con éxito</div> :null }
              </div>
              <div className='riport-case-gpo' onClick={ newCaseGpo }> Reportar Caso </div>
            </div>
          {
            initialPausedCauses.length === 0 && casesPause.length === 0 ? null :
              <div className="search-case-div">
                <input
                    className="search-case-input"
                    placeholder="Buscar caso por Nº caso"
                    value={filter}
                    onChange={ e => applyFilter( e.target.value ) }
                />
              </div>
          }
          {
            (casesPause.length === 0 ? null :
              <div className="div-table" hidden={!changeView}>
                  <table className="table table-striped">
                  <thead className="bg-primaty table-head-gray head-tbl">
                  <tr>
                    {
                      global.isGroupDisbursement() ? <th scope="col">Nº Caso</th> : null
                    }
                    {
                      !global.isGroupDisbursement() ? <th scope="col">Documento</th>: null
                    }
                    {
                      global.isGroupDisbursement() ? <th scope="col">Producto</th> : null
                    }
                    {
                      global.isGroupDisbursement() ? <th scope="col">Proceso</th> : null
                    }
                    {
                      !global.isGroupDisbursement() ?<th scope="col">Nº Caso</th> : null
                    }
                    <th scope="col">Fecha recibido</th>
                    <th scope="col">Hora recibido</th>
                    <th scope="col">Estado</th>
                    {
                      !global.isGroupDisbursement() ? <th scope="col">Proceso</th> : null
                    }
                    {
                      global.isLibrances() ? <th scope="col">Convenio</th> : null
                    }
                    <th scope="col"></th>
                  </tr> 
                  </thead>
                  { casesPause.length === 0 ? null : (
                      casesPause.filter((to_filter)=>to_filter.requestNumber.includes(filter)).map(row => (
                          <tbody key={row.requestNumber}>
                          <PausedGPO key={row.requestNumber} row={row} />
                          </tbody>
                      ))

                  )}
                </table>
              </div>
            )
          }
        </div>     
      </div>
    </Fragment>
   );
}

export default Analyst;