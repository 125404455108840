exports.formatDate = ( date, format ) => {
    if ( !date ) {
        return;
    }
    const year = date.getFullYear();
    let month = `${ date.getMonth() + 1 }`;
    month = month.length === 1 ? `0${ month }` : month;
    let day = `${ date.getDate() }`;
    day = day.length === 1 ? `0${ day }` : day;
    switch ( format ) {
        case 'dd/mm/yyyy':
            return `${ day }/${ month }/${ year }`;
        case 'yyyymmdd':
            return `${ year }${ month }${ day }`;
        default:
            return undefined;
    }

};