import React, { useState, Fragment, useEffect } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import './Analyst.css';
import Swal from 'sweetalert2';
import Paused from '../../bdb-components/TableBodyComponent/Paused';
import {
  getCasePending, putApproved, putReject, putPause, getCaseOpen, loadCasesInOpen, loadCasesInPause,
  putPaused, putAbort, filterPausedCases, resetPausedCases, updateLoadingAnalist, changeRequestedProducts
} from '../../bdb-actions/analystActions';
import {
  managements, processesByManagements, PausedReasonVector, AbortReasonVector,
  disbursementPreaproved, RejectReasonVector
} from '../../../constants';
import SelectList from '../../bdb-components/SelectListComponent/SelectList';
import { pausedModalStructure, abortModalStructure, pausedPreaprovedModalStructure, pausedOrderDisbursementModalStructure, pausedModalWithoutOptionsStructure, rejectOrderDisbursementModalStructure } from '../../../structures';
import { startDynamicModal, finalizeDynamicModal } from '../../bdb-actions/dynamicModalActions';
import DynamicModalComponent from '../../bdb-components/DynamicModalComponent/DynamicModalComponent';
import { setOptionSelectedSimplyComponent } from '../../bdb-actions/simplySelectActions';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink';
import { getSecondaryKeysConfig, getdecrypt, b64_to_utf8 } from '../../bdb-services/GeneralService/globalService';
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';
import { getDocumentsDisbursement, getDocumentsByRequest } from '../../bdb-services/GeneralService/analystDisbursementService';
import { flujoRedescuentoName } from '../../../constants';
const enpointSelf = process.env.REACT_APP_SELFASSIGNMENT;
const managementType = sessionStorage.getItem("managementType")
const isDisbursement  = !!managementType && managementType == 'disbursement'
const Analyst = () => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.dynamicModal.show);
  const vector = useSelector(state => state.dynamicModal.vector);
  let especifict = useSelector(state => state.analyst.especifict);
  const changeView = useSelector(state => state.analyst.changeView);
  const loading = useSelector(state => state.analyst.loading);
  const notFound = useSelector(state => state.analyst.notFound);
  const wait = useSelector(state => state.cases.loading);
  const casesOpen = useSelector(state => state.analyst.open);
  let casesPause = useSelector(state => state.analyst.inPause);
  const option = useSelector(state => state.select.option);
  const [management, setManagement] = useState(b64_to_utf8(sessionStorage.getItem('management')));
  const [process, setProcess] = useState(null);
  const [modals, setModals] = useState({ paused: false, abort: false });
  const [radioValue, setRadioValue] = useState();
  const [filter, setFilterPausedCases] = useState('');

  useEffect(() => {
    if (!filter || filter === '') {
      dispatch(resetPausedCases());
      return;
    }
    dispatch(filterPausedCases(filter));
  }, [filter]);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    if (!!vector) {
      setModals({ paused: false, abort: true })
      dispatch(finalizeDynamicModal());
      if (modals['reject']) {
        dispatch(putReject({...especifict,reasonReturn:vector['rejectReason']})); }
      if (modals['paused']) { 
        dispatch(putPaused(especifict, vector['pausedReason'])); }
      if (modals['abort']) { dispatch(putAbort(especifict, vector['abortReason'])); }
    }
  }, [vector]);

  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setFilterPausedCases('');
    const loadCasesPause = () => dispatch(loadCasesInPause());
    const loadCasesOpen = () => dispatch(loadCasesInOpen());
    loadCasesPause();
    loadCasesOpen();
  }, []);

  useEffect(() => {
    getSecondaryKeysConfig();
  }, [])



  const handleManageCase = () => {
    const getCase = () => {
      if (!!option) {
          setProcess(option.label);
          casesOpen.length === 0 ? dispatch(getCasePending(management, option.id, option.indexName)) :
          dispatch(getCaseOpen(casesOpen[0]));
          dispatch(setOptionSelectedSimplyComponent(null))
      }
    }
    getCase();
  }

  const putCaseApproved = () => {
    Swal.fire({
      icon: 'info',
      title: `<p class="titel-modal">¿Estás seguro de aprobar \nel caso ${especifict.requestNumber}?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Aprobar',
      confirmButtonColor: '#002c76',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        setFilterPausedCases('');
        dispatch(putApproved(especifict));
      }
    });
  }
  const setRequestedProducts = (value)=>{
    dispatch(changeRequestedProducts(especifict, value))
  }
  const putCaseReject = () => {
    if ( especifict.process === "Orden de Desembolsos") {
      setModals({ reject: true, abort: false });
      dispatch(startDynamicModal(true));

    } else {
      Swal.fire({
        icon: 'info',
        title: `<p class="titel-modal">¿Estás seguro de devolver \nel caso ${especifict.requestNumber}?</p>`,
        showCancelButton: true,
        confirmButtonText: 'Devolver',
        confirmButtonColor: '#002c76',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          setFilterPausedCases('');
          dispatch(putReject(especifict));
        }
      });
  }
}

  const putCaseAbort = reason => {
    Swal.fire({
      icon: 'info',
      title: `<p class="titel-modal">¿Estás seguro de rechazar \nel caso ${especifict.requestNumber}?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Rechazar',
      confirmButtonColor: '#002c76',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        setFilterPausedCases('');
        dispatch(putPause());
        dispatch(putAbort(especifict, reason));
        setRequestedProducts(1)
      }
    });
  }

  const putCasePaused = reason => {
    Swal.fire({
      icon: 'info',
      title: `<p class="titel-modal">¿Estás seguro de pausar \nel caso ${especifict.requestNumber}?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Pausar',
      confirmButtonColor: '#002c76',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        setFilterPausedCases('');
        dispatch(putPause());
        dispatch(putPaused(especifict, reason));
      }
    });
  }

  function managementName() {
    return managements.find(row => row.id === management).label;
  }


  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  function getOptionsManagement() {
    const keys = Object.keys(processesByManagements[management]);
    let optionsList = [];
    keys.forEach((key, index) => {
      optionsList[index] = { id: key, label: processesByManagements[management][key]['value'], initPriorization: processesByManagements[management][key]['initPriorization'] }
    });
    return optionsList;
  }

  function getOptionsRadio() {
    return [{ name: "Si", value: '1' }, { name: "No", value: '0' }];
  }

  function getRequestedProducts() {
    return [
      { name: "1", value: 1 }, { name: "2", value: 2 }, { name: "3", value: 3 }];
  }

  function resultIsLibrance(value) {
    setRadioValue(value)
    especifict.libranza = value;
  }

  const structureToAdd = () => {
    if (modals['reject']) {
      let structure = rejectOrderDisbursementModalStructure;
      structure['infoLabel'][0] = `¿Estás seguro de devolver el caso ${especifict.requestNumber}?`
	    structure['input'] = 'textarea'

      return structure;
    }
    if (modals['paused']) {
      let structure = especifict.process === disbursementPreaproved 
        ? pausedPreaprovedModalStructure : especifict.process == "Orden de Desembolsos" 
        ? pausedOrderDisbursementModalStructure : pausedModalStructure;
      structure['infoLabel'][1] = `pausa el caso ${especifict.requestNumber}?`
      return structure;
    }
    if (modals['abort']) {
      let structure = abortModalStructure;
      structure['infoLabel'][1] = `el caso ${especifict.requestNumber}?`
      return structure;
    }
    return null;
  }

  const vectorToAdd = () => {
    if (modals['reject']) { return RejectReasonVector; }
    if (modals['paused']) { return PausedReasonVector; }
    if (modals['abort']) { return AbortReasonVector; }
    return null;
  }

  const showModalPaused = processToPaused => {
    if ( especifict.process === "Orden de Desembolsos" || processToPaused === disbursementPreaproved) {
      setModals({ paused: true, abort: false });
      dispatch(startDynamicModal(true));
    } else {
      putCasePaused(null);
    }
  }

  const showModalAbort = () => {
    if (management === 'massiveBanking') {
      setModals({ paused: false, abort: true });
      dispatch(startDynamicModal(true));
    } else {
      putCaseAbort(null);
    }
  }

  const redirect = async () => {

    if(especifict.typeCustomer === undefined){
      switch(especifict.typeDocument){
        case 'CC':
          especifict.typeCustomer = 'C';
          break;
        case 'CE':
          especifict.typeCustomer = 'E';
          break;
        case 'TI':
          especifict.typeCustomer = 'T';
          break;
        case 'NJ':
        case 'NI':
        case 'NE':
          especifict.typeCustomer = 'N';
          break;
        case 'PA':
          especifict.typeCustomer = 'P';
          break;
        case 'RC':
          especifict.typeCustomer = 'R';
          break;
        default:
          console.log("not found");

      }
    }
    dispatch(updateLoadingAnalist(true));
    const customerInfo = {
      "custidentnum": especifict.document,
      "custidenttype": especifict.typeCustomer,
      "creditProject": especifict.creditProject,
      "controlNumber": especifict.numberControl,
      "custdoctype": especifict.typeDocument,
      "userRole": getdecrypt('rolUser')
    }
    sessionStorage.setItem('typeCustomer', especifict.typeCustomer);
    sessionStorage.setItem('typeDocument', especifict.typeDocument);
    sessionStorage.setItem('numberCustomer', especifict.document);
    customerInfo.custdoctype = especifict.flow === flujoRedescuentoName ? customerInfo.custidenttype.replace(/\./g, '') : customerInfo.custdoctype;
    if(especifict.flow !== flujoRedescuentoName){
      await getDocumentsDisbursement(customerInfo).catch(
        err => {
          console.log('error', err);
          return { status: 500 }
        }
      )
        .then(
          data => {
            dispatch(updateLoadingAnalist(false));
            if (data.status == 200) {
              const documentDisbursement = data.data;
              sessionStorage.setItem('documentDisbursement', JSON.stringify(documentDisbursement));
              window.open(`${enpointSelf}documentsDC`);
  
            }
          }
        )
        return
    }
    await getDocumentsByRequest(customerInfo).catch(
      err => {
        console.log('error', err);
        return { status: 500 }
      }
    )
    .then(
      data => {
        dispatch(updateLoadingAnalist(false));
        if (data.status == 200) {
          const documentDisbursement = data.data;
          sessionStorage.setItem('documentDisbursement', JSON.stringify(documentDisbursement));
          window.open(`${enpointSelf}documentsDC`);

        }
      }
    )
  }



  const applyFilter = (value) => {
    setFilterPausedCases(value);
  }
  function getTitle() {
    return especifict.process === processesByManagements.reviewDoc.disbursementOrder?.value ? `N° Control ${especifict.numberControl}` : `Caso ${especifict.requestNumber}`;
  };

  function getSubTittle() {
    const formatProduct = global.translateCopies(especifict.product);
    if ( especifict.process === "Orden de Desembolsos" ) return `${formatProduct} - ${especifict.process}`;
    if ( especifict.process === "Desembolso Comercial" ) return `${especifict.line} - ${especifict.currentProcess} - ${formatProduct}`;
    if ( especifict.process === "Vivienda - Desembolso") return `${especifict.currentProcess}`;
    if ( global.isGroupDisbursement() ) return `${especifict.line } - ${especifict.currentProcess} - ${formatProduct}`;
    return `${especifict.process}`;
  }

  return (
    <Fragment>
      {["groupDisbursement", "reviewDoc"].includes( management ) ? <BackLink url='/menu-disbursement' /> : <DailyManagement />}
      <div className="container">
        <div hidden={!changeView}>
          {loading || wait ? <Loading /> : null}
          {showModal ?
            <DynamicModalComponent
              modalStructure={structureToAdd()}
              vectorModal={vectorToAdd()}
              selecBotton={"disbOrd"}
              />
            : null
          }
          <div>
            <h2 className="text-center management-title">{managementName()}</h2>
            {notFound ? <div className="alert-rectangle alert-text">No hay casos disponibles para este proceso</div> : null}
            <div className="d-flex justify-content-center align-items-center">
              <SelectList
                options={getOptionsManagement()}
                enableSubmenu={management === 'librances'}
                handleButton={handleManageCase} />
            </div>
          </div>
          {
           (casesPause.length === 0 && !filter) ? null :
              <div className="search-case-div">
                <input
                  className="search-case-input"
                  placeholder="Buscar (Nº caso/control o documento)"
                  value={filter}
                  onChange={e => applyFilter(e.target.value)}
                />
              </div>
          }
          {
            ((casesPause.length === 0 && !filter) ? null :
              <div className="div-table" hidden={!changeView}>
                <table className="table table-striped">
                  <thead className="bg-primaty table-head-gray head-tbl">
                    <tr>
                      <th scope="col">Nº Caso/Control</th>
                      <th scope="col">Documento</th>
                      <th scope="col">Proceso</th>
                      <th scope="col">Producto</th>
                      <th scope="col">Fecha recibido</th>
                      <th scope="col">Hora recibido</th>
                      <th scope="col">Estado</th>
                      {
                        global.isLibrances() ? <th scope="col">Convenio</th> : null
                      }
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {casesPause.length === 0 ? 
                  <tbody>
                    <tr>
                      <td  className="td-component unset-align" colSpan={8}>
                        <center>
                        No se encontraron registros.
                        </center>
                      </td>  
                    </tr>
                  </tbody>
                : (
                    casesPause.map(row => (
                      <tbody key={row.requestNumber}>
                        <Paused key={row.requestNumber} row={row} actives={casesOpen} isDisbursement={isDisbursement} />
                      </tbody>
                    ))

                  )}
                </table>
              </div>
            )
          }
        </div>
        {loading || wait ? null :
          <div hidden={changeView} className="row justify-content-center" style={{ "marginTop": "-3%" }}>
            <div className="col-md-8">
              <h2 className="text-center management-title-case">{managementName()}</h2>
              <div className="card">
                <div hidden={especifict !== {} ? false : true} className="card-body">
                  <div className="title-case">{getTitle()}</div>
                  {
                    global.isLibrances() ?
                      <div className="title-process">{especifict.process} - conv. {especifict.libranzaCode}</div> :
                      <div className="title-process">{ getSubTittle() }</div> 
                  }
                  {
                    especifict.process === processesByManagements.reviewDoc.disbursementOrder.value ?
                      <>
                        <div className='d-flex align-items-center button-preview'>
                          <div className='preview'></div>
                          <div className='label-preview' onClick={redirect}>Documentos</div>
                        </div>
                        <div>
                          <div className='d-flex justify-content-between'>
                            <div className='form-order-row'>
                              <label className='label-modal'>Documento cliente</label>
                              <div className='form-control form-control-disabled'>{`${especifict.typeDocument} ${especifict.document}`}</div>
                            </div>
                            <div className='form-order-row'>
                              <label className='label-modal'>Proyecto crediticio</label>
                              <div className='form-control form-control-disabled'>{especifict.creditProject}</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <div className='form-order-row'>
                              <label className='label-modal'>Fecha recibido</label>
                              <div className='form-control form-control-disabled'>{especifict.dateAssignment}</div>
                            </div>
                            <div className='form-order-row'>
                              <label className='label-modal'>Hora recibido</label>
                              <div className='form-control form-control-disabled'>{especifict.hourAssignment}</div>
                            </div>
                          </div>
                        </div>
                      </>
                      : <>
                        <div className="row r-gap-20">
                          <div className="col-md-6">
                            <label className="label-modal">Documento cliente</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" placeholder="99999" value={especifict.document ? especifict.document : ""} />
                          </div>
                          <div className="col-md-6">
                            <label className="label-modal">Fecha recibido</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" placeholder="2020/01/06" value={especifict.dateAssignment ? especifict.dateAssignment : ""} />
                          </div>

                          <div className="col-md-6">
                            <label className="label-modal">Hora recibido</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" placeholder="08:00:00 AM" value={especifict.hourAssignment ? especifict.hourAssignment : ""} />
                          </div>
                          <div className='col-md-6'>
                            <label className='label-modal'># productos solicitados</label>
                            <ButtonGroup className="w-100" size='sm' toggle>
                              {getRequestedProducts().map((row, idx) => (
                                <ToggleButton key={idx} id={`radio-${idx}`} type="radio"
                                  variant="outline-success" name="radio" checked={ especifict.requestedProducts == row.value}
                                  value={row.value} onChange={(e) => { setRequestedProducts(e.currentTarget.value) }}>
                                  {row.name}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                          </div>
                        </div>

                      </>
                  }

                  <div className="row-footer">
                    <div className="div-footer my-3">
                      <button className="btn btn-primary d-block w-50 color-blue button-footer" onClick={() => { showModalPaused(especifict.process) }}>Pausar</button>
                      <button disabled={ especifict.libranza !== radioValue} className="btn btn-primary d-block w-50 button-footer" data-cy ='putCaseApproved' onClick={putCaseApproved}>Aprobar</button>
                      <button disabled={ especifict.libranza !== radioValue} className="btn btn-primary d-block w-50 button-footer" data-cy ='putCaseReject' onClick={putCaseReject}>Devolver</button>
                      {management === 'massiveBanking-' ?
                        <button className="btn btn-primary d-block w-50 color-blue button-footer" onClick={() => { showModalAbort() }}>Rechazar</button>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </Fragment>
  );
}

export default Analyst;
