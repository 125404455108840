import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import Modal from "react-bootstrap/Modal";
import Dragger from '../../bdb-components/DraggerComponent/Dragger';
import { getItemsComponent, updatePriorityComponent } from '../../bdb-actions/priotitizationActions';
import './Prioritization.css';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import Swal from 'sweetalert2';

  const options = [
    { id: 0, label: 'Producto', value: 'product' },
    { id: 1, label: 'Flujo', value: 'flow'},
    { id: 2, label: 'Priorización', value: 'priorization' }
  ]
  const tittleOptions = 'Arrastra y ordena';

const PrioritizationSettings = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const data = useSelector(state => state.prioritization.data);
    const loading = useSelector(state => state.prioritization.loading);
    const alert = useSelector(state => state.prioritization.alert);
    const error = useSelector(state => state.prioritization.error);

    const [dataProducts, setDataProducts] = useState('');
    const [dataFlow, setDataFlow] = useState('');
    const [dataPriority, setDataPriority] = useState('');

    const [enableRestore, setEnableRestore] = useState(false);

    const [labelPriority1, setlabelPriority1] = useState(null);
    const [labelPriority2, setlabelPriority2] = useState(null);
    const [labelPriority3, setlabelPriority3] = useState(null);

    const [valuePriority1, setValuePriority1] = useState(null);
    const [valuePriority2, setValuePriority2] = useState(null);
    const [valuePriority3, setValuePriority3] = useState(null);

    const [showOptions1, setShowOptions1] = useState(false); 
    const [showOptions2, setShowOptions2] = useState(false); 
    const [showOptions3, setShowOptions3] = useState(false);

    const [showModalRestore, setShowModalRestore] = useState(false);

    const updatePriority = (order, product, priority, flow) => dispatch(updatePriorityComponent(order, product, priority, flow))
    
    useEffect(() => {
      const getItems = () => dispatch(getItemsComponent());
      getItems();
    }, []);

    useEffect(() => {
      if(data !== null) {
        setEnableRestore(compareComponets());
        if (compareComponets()) {
          const priorities = data.orderConfig.split('#')
            .filter(element => element !== 'dateAssignment' && element !== 'hourAssignment');

          setValuePriority1(!!priorities[0] && priorities[0] !== null ? options.find(option => option.value === priorities[0]).value : null);
          setValuePriority2(!!priorities[1] && priorities[1] !== null ? options.find(option => option.value === priorities[1]).value : null);
          setValuePriority3(!!priorities[2] && priorities[2] !== null ? options.find(option => option.value === priorities[2]).value : null);

          setlabelPriority1(!!priorities[0] && priorities[0] !== null ? options.find(option => option.value === priorities[0]).label : null);
          setlabelPriority2(!!priorities[1] && priorities[1] !== null ? options.find(option => option.value === priorities[1]).label : null);
          setlabelPriority3(!!priorities[2] && priorities[2] !== null ? options.find(option => option.value === priorities[2]).label : null);
        } 
        setDataProducts([{ title: 'products', items: data.componentConfig.product}]);
        setDataFlow([{ title: 'flow', items: data.componentConfig.flow}]);
        setDataPriority([{ title: 'priorization', items: data.componentConfig.priorization}]);
      }
    }, [data]);

    useEffect(() => {
      if (localStorage.getItem('products')) {
        setDataProducts(JSON.parse(localStorage.getItem('products')))
      } else {
        setDataProducts([{ 
              title: 'products',
              items: data !== null ? data.componentConfig.product : []
            }])
      }
    }, [setDataProducts]);

    useEffect(() => {
      if (localStorage.getItem('flow')) {
        setDataFlow(JSON.parse(localStorage.getItem('flow')))
      } else {
        setDataFlow([{
          title: 'flow',
          items: data !== null ? data.componentConfig.flow : []
        }])
      }
    }, [setDataFlow]);

    useEffect(() => {
      if (localStorage.getItem('priorization')) {
        setDataPriority(JSON.parse(localStorage.getItem('priorization')))
      } else {
        setDataPriority([{
          title: 'priorization',
          items: data !== null ? data.componentConfig.priorization : []
        }])
      }
    }, [setDataPriority]);

    const enableRestoreStyle = () => {
      return enableRestore ? 'button-restore label-restore' : 'display-none';
    }

    const oneButton = () => {
      return enableRestore ? 'flex-around' : 'flex-center';
    }

    const getPlaceholder = priority => {
      const label = 'Selecciona la categoría';
      if (priority === 'priority1') { return labelPriority1 !== null ? labelPriority1 : label; }
      if (priority === 'priority2') { return labelPriority2 !== null ? labelPriority2 : label; }
      if (priority === 'priority3') { return labelPriority3 !== null ? labelPriority3 : label; }
    }

    const selectOption = (index, priority) => {
      if (priority === 'priority1' && options[index].value !== valuePriority2 
        && options[index].value !== valuePriority2) {

        if (labelPriority2 !== null) { setlabelPriority2(null); }
        if (labelPriority3 !== null) { setlabelPriority3(null); }
        setlabelPriority1(options[index].label);
        setValuePriority1(options[index].value);
        setShowOptions1(false);
      }
      if (priority === 'priority2' && options[index].value !== valuePriority1
      && options[index].value !== valuePriority3) {

        if (labelPriority3 !== null) { setlabelPriority3(null); }
        setlabelPriority2(options[index].label);
        setValuePriority2(options[index].value);
        setShowOptions2(false);
      }
      if (priority === 'priority3' && options[index].value !== valuePriority1 
      && options[index].value !== valuePriority2) {
        setlabelPriority3(options[index].label);
        setValuePriority3(options[index].value);
        setShowOptions3(false);
      }
    }

    const priorityOptionsValidation = priority => {
      if (priority === 'priority1') {
        return showOptions1;
      }
      if (priority === 'priority2') {
        return showOptions2 && labelPriority1 !== null;
      }
      if (priority === 'priority3') {
        return showOptions3 && labelPriority1 !== null && labelPriority2 !== null;
      }
    }

    const initOptions = priority => {
      if (priority === 'priority1') {
        return labelPriority1 === null ? null : selectOptionForLabel(valuePriority1, !showOptions1);
      }
      if (priority === 'priority2') {
        return labelPriority2 === null ? null : selectOptionForLabel(valuePriority2, !showOptions2);
      }
      if (priority === 'priority3') {
        return labelPriority3 === null ? null : selectOptionForLabel(valuePriority3, !showOptions3);
      }
      
    }

    const selectOptionForLabel = (label, showOptions) => {
      if (label === 'product') {
        return <Dragger data={dataProducts} label={tittleOptions} showIcon={showOptions}/>
      }
      if (label === 'flow') {
        return <Dragger data={dataFlow} label={tittleOptions} showIcon={showOptions}/>
      }
      if (label === 'priorization') {
        return <Dragger data={dataPriority} label={tittleOptions} showIcon={showOptions}/>
      }
    }

    const disableBoxPriority = priority => {
      const style = 'priority-box priority-label';
      const enable = 'enable-box enable-label';
      const disable = 'disable-box disable-label';
      if (priority === 'priority1') {
        return `${style} ${enable}`;
      }
      if (priority === 'priority2') {
        return labelPriority1 !== null ? `${style} ${enable}` : `${style} ${disable}`;
      }
      if (priority === 'priority3') {
        return labelPriority1 !== null && labelPriority2 !== null ? `${style} ${enable}` : `${style} ${disable}`;
      }
    }

    const disableSelectPriority = priority => {
      const style = 'dropbtn';
      const enable = 'droplabel-enable';
      const disable = 'droplabel-disable';

      if (priority === 'priority1') {
        return labelPriority1 !== null ? `${style} ${enable}` : `${style} ${disable}`;
      }
      if (priority === 'priority2') {
        return labelPriority2 !== null ? `${style} ${enable}` : `${style} ${disable}`;
      }
      if (priority === 'priority3') {
        return labelPriority3 !== null ? `${style} ${enable}` : `${style} ${disable}`;
      }
    }

    const disableIconSelect = priority => {
      if (priority === 'priority1') {
        return 'icon-drop';
      }
      if (priority === 'priority2') {
        return labelPriority1 === null ? 'icon-drop-disable' : 'icon-drop';
      }
      if (priority === 'priority3') {
        return labelPriority2 === null ? 'icon-drop-disable' : 'icon-drop';
      } 
    }

    const restorePriorityStyle = priority => {
      if (priority === 'priority1') {
        return 'display-none';
      }
      if (priority === 'priority2') {
        return labelPriority2 !== null && labelPriority3 === null ? 'icon-x-blue cursor-pointier' : 'display-none';
      }
      if (priority === 'priority3') {
        return labelPriority3 !== null ? 'icon-x-blue cursor-pointier' : 'display-none';
      } 
    }

    const restorePriority = priority => {
      if (priority === 'priority2') { 
        setlabelPriority2(null);
        setValuePriority2(null);
      }
      if (priority === 'priority3') {
        setlabelPriority3(null);
        setValuePriority3(null);
       } 
       saveLocalConfiguration();
    }

    const saveLocalConfiguration = () => {
      setDataFlow(localStorage.getItem('flow') ? JSON.parse(localStorage.getItem('flow')) : 
        [{title: 'flow', items: data !== null ? data.componentConfig.flow : []}]);
      setDataPriority(localStorage.getItem('priorization') ? JSON.parse(localStorage.getItem('priorization')) :
        [{title: 'priorization', items: data !== null ? data.componentConfig.priorization : []}]);
      setDataProducts(localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) :
        [{title: 'products', items: data !== null ? data.componentConfig.product : []}]);
    }

    const optionSelected = (label) => {
      return labelPriority1 === label || labelPriority2 === label || labelPriority3 === label 
        ? 'dropdown-selected' : null;
    }

    const buttonApplyStyle = () => {
      return labelPriority1 === null ? 'button-apply-disable text-button' : 'button-apply-enable text-button';
    }

    const applyChanges = () => {
      
      const priority1 = valuePriority1 !== null ? valuePriority1 : '';
      const priority2 = valuePriority2 !== null ? `#${valuePriority2}` : '';
      const priority3 = valuePriority3 !== null ? `#${valuePriority3}` : '';    

      const newOrder = `${priority1}${priority2}${priority3}#dateAssignment#hourAssignment`;

      const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : null;
      const priorities = localStorage.getItem('priorization') ? JSON.parse(localStorage.getItem('priorization')) : null;
      const flows = localStorage.getItem('flow') ? JSON.parse(localStorage.getItem('flow')) : null;

      const newProduct = products !== null ? products[0].items : data ? data.componentConfig.product : [];
      const newPriority = priorities !== null ? priorities[0].items : data ? data.componentConfig.priorization : [];
      const newFlow = flows !== null ? flows[0].items : data ? data.componentConfig.flow : [];

      updatePriority(newOrder, newProduct, newPriority, newFlow);
      removeListLocalStorage();
      setEnableRestore(true);

    }

    const restorePriorityDefault = () => {

      setShowModalRestore(false);

      const products = [{ title: 'products', items: data !== null ? data.componentDefault.product : [] }];
      const flow = [{ title: 'flow', items: data !== null ? data.componentDefault.flow : [] }];
      const priority = [{ title: 'priorization', items: data !== null ? data.componentDefault.priorization : [] }];

      restoreLocalStorage(products);
      restoreLocalStorage(flow);
      restoreLocalStorage(priority);
    
      const priorities = data.orderDefault.split('#')
            .filter(element => element !== 'dateAssignment' && element !== 'hourAssignment');

      setValuePriority1(!!priorities[0] && priorities[0] !== null ? options.find(option => option.value === priorities[0]).value : null);
      setValuePriority2(!!priorities[1] && priorities[1] !== null ? options.find(option => option.value === priorities[1]).value : null);
      setValuePriority3(!!priorities[2] && priorities[2] !== null ? options.find(option => option.value === priorities[2]).value : null);

      setlabelPriority1(!!priorities[0] && priorities[0] !== null ? options.find(option => option.value === priorities[0]).label : null);
      setlabelPriority2(!!priorities[1] && priorities[1] !== null ? options.find(option => option.value === priorities[1]).label : null);
      setlabelPriority3(!!priorities[2] && priorities[2] !== null ? options.find(option => option.value === priorities[2]).label : null);
      
      updatePriority(data.orderDefault, data.componentDefault.product, data.componentDefault.priorization, data.componentDefault.flow);
      removeListLocalStorage();
      setEnableRestore(false);
    }

    const restoreLocalStorage = element => {
      localStorage.removeItem(element[0].title);
      localStorage.setItem(element[0].title, JSON.stringify(element));
    }

    const removeListLocalStorage = () => {
      localStorage.removeItem('products');
      localStorage.removeItem('flow');
      localStorage.removeItem('priorization');
    }

    const compareComponets = () => {
      return data.componentConfig !== data.componentDefault && data.orderConfig !== data.orderDefault;
    }

    const modalApply = () => {
      Swal.fire({
        icon: 'info',
        title: `<p class="titel-modal">¿Estás seguro de cambiar\n la priorización?</p>`,
        html:
          'Los cambios se aplicarán para los <br> próximos cargues',
        showCancelButton: true,
        confirmButtonText: 'Aplicar',
        confirmButtonColor: '#002c76',
        cancelButtonColor: '#c61f01',
        cancelButtonText: 'Cancelar',
      }).then( result => {
        if(result.isConfirmed){
          applyChanges();
        }
      });
    }

    const modalRestore = () => {
      Swal.fire({
        icon: 'info',
        title: `<p class="titel-modal">¿Estás seguro de reiniciar la configuración?</p>`,
        text: `La priorización volverá a la predeterminada`,
        showCancelButton: true,
        confirmButtonText: 'Aplicar',
        confirmButtonColor: '#002c76',
        cancelButtonColor: '#c61f01',
        cancelButtonText: 'Cancelar',
      }).then( result => {
        if(result.isConfirmed){
          restorePriorityDefault();
        }
      });
    }

    const redirectToHome = () => {
      Swal.fire({
        icon: 'info',
        title: '<div>¡Lo sentimos!</div>',
        html:
          'Presentamos inconvenientes técnicos,<br> intenta de nuevo',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#002c76'
    }).then( result => {
        if(result.isConfirmed){
          history.push('/finantial-leader');
        }
      });
    }

    const cancel = () => { 
      setShowModalRestore(false);
    }

    return ( 
      <Fragment>
            <LeaderOptions />
            { loading ? <Loading /> : null}
            { error ? redirectToHome() : null}
            { alert ? <p className="alert alert-success text-center mt-4 alert-color">La priorización se configuró con éxito</p> : null }
            <div className="flex-center">
              <div className="tittle">Configuración de la demanda</div>
            </div>
            <div className="flex-center">
              <div className="div-subtittle">
                <div className="subtittle">Selecciona la(s) categoría(s) en el orden que deseas priorizar los próximos cargues.</div>
              </div>
            </div>
            <div className="padding-27">
              <div className={oneButton()}>
                <div>
                  <div onClick={() => {modalApply()}} className={buttonApplyStyle()}>Aplicar cambios</div>
                </div>
                <div>
                  <div onClick={() => {setShowModalRestore(true)}} className={enableRestoreStyle()}>Reiniciar config.</div>
                </div>
              </div>
            </div>
            <div className="flex-between">
              <div className="width-box">
                <div className={disableBoxPriority('priority1')}>Prioridad 1 
                  <div onClick={() => {restorePriority('priority1')}} className={restorePriorityStyle('priority1')}></div>
                </div>
                <div className="dropdown">
                  <div className={disableSelectPriority('priority1')} onClick={() => {setShowOptions1(!showOptions1)}}>
                    {getPlaceholder('priority1')}<div className={disableIconSelect('priority1')}></div>
                  </div>
                    { priorityOptionsValidation('priority1') ? 
                      <div className="dropdown-content">
                          {options.map((option, index) => (
                              <a className={optionSelected(option.label)} 
                              onClick={() => {selectOption(index, 'priority1')}}>{option.label}</a>
                          ))}
                      </div>
                  : null} 
                </div>
                { initOptions('priority1') }
              </div>

              <div className="width-box">
                <div className={disableBoxPriority('priority2')}>Prioridad 2 
                  <div onClick={() => {restorePriority('priority2')}} className={restorePriorityStyle('priority2')}></div>
                </div>
                <div className="dropdown">
                  <div className={disableSelectPriority('priority2')} onClick={() => {setShowOptions2(!showOptions2)}}>
                    {getPlaceholder('priority2')}<div className={disableIconSelect('priority2')}></div>
                  </div>
                  { priorityOptionsValidation('priority2') ? 
                      <div className="dropdown-content">
                          {options.map((option, index) => (
                              <a className={optionSelected(option.label)}
                              onClick={() => {selectOption(index, 'priority2')}}>{option.label}</a>
                          ))}
                      </div>
                  : null} 
                </div>
                <div></div>
                { initOptions('priority2') }
              </div>

              <div className="width-box">
                <div className={disableBoxPriority('priority3')}>Prioridad 3 
                  <div onClick={() => {restorePriority('priority3')}} className={restorePriorityStyle('priority3')}></div>
                </div>
                <div className="dropdown">
                  <div className={disableSelectPriority('priority3')} onClick={() => {setShowOptions3(!showOptions3)}}>
                    {getPlaceholder('priority3')}<div className={disableIconSelect('priority3')}></div>
                  </div>
                  { priorityOptionsValidation('priority3')  ? 
                      <div className="dropdown-content">
                          {options.map((option, index) => (
                              <a className={optionSelected(option.label)}
                              onClick={() => {selectOption(index, 'priority3')}}>{option.label}</a>
                          ))}
                      </div>
                  : null} 
                </div>
                <div></div>
                { initOptions('priority3') }
              </div>
            </div>   
            {
              <Modal show={showModalRestore} centered onHide={!showModalRestore}>
                <Modal.Body>
                    <div className="paddin-modal">
                        <div className="modal-center">
                            <div className="modal-icon-info"></div>
                        </div>
                        <div className="modal-title-info modal-margin-title">¿Estás seguro de reiniciar la configuración?</div>
                        <div className="modal-subtitle-info modal-margin-subtitle">La priorización volverá a la predeterminada</div>
                        <div>
                            <div className="prioritizeImage"></div>
                        </div>
                        <div className="flex-content">
                            <div onClick={() => {restorePriorityDefault()}} className="modal-button-blue modal-text-button cursor-pointer">Reiniciar</div>
                            <div onClick={() => {setShowModalRestore(false)}} className="modal-button-red modal-text-button cursor-pointer">Cancelar</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
          }  
      </Fragment>
     );
}
 
export default PrioritizationSettings;