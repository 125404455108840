import axios from 'axios';

export const requestFactory = ( path, method, headers, params, payload ) => {
    const url = process.env.REACT_APP_SELF_ASSIGN_ENDPOINT + path;
    const options = {
        url: url,
        method,
        headers,
        params,
        data : payload
    };
    return axios.get( url, {params} );
}