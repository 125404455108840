import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/icons';
import LeaderOptions from '../../bdb-components/LeaderOptionsComponent/LeaderOptions';
import { loadCasesAction } from '../../bdb-actions/casesActions';
import Loading from '../../bdb-components/LoadingComponent/Loading';
import '../../../assets/styles/leaderStyle.css';
import { useHistory } from "react-router-dom";
import Table from '../../bdb-components/TableComponent/Table';
import {caseStatuses, managements, tittleDisbursementManagement} from '../../../constants';
import DailyManagement from '../../bdb-components/DailyManagementComponent/DailyManagement';
import BackLink from '../../bdb-components/BackLinkComponent/BackLink';
const Leader = () => {

    const dispatch = useDispatch();

    const [management, setManagement] = 
        useState(b64_to_utf8(sessionStorage.getItem('management')));

    useEffect(() => {
        const loadCases = () => dispatch(loadCasesAction(management));
        loadCases();
    }, []);

    const result = useSelector(state => state.cases.result);
    const loading = useSelector( state => state.cases.loading );
    
    function managementName() {
        if ( global.isGroupDisbursement() )  return tittleDisbursementManagement;
        return managements.find(row => row.id === management).label;
    }

    function b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    const getTittleComplement = () => {
        const generic = 'casos';
        return global.isGroupDisbursement() ? `${generic} desembolso`: generic;
    }

    return (
       <Fragment>
            <DailyManagement/>
            <LeaderOptions />
            <h2 className="text-center my-5">Indicadores {managementName()}</h2>
            <div className="d-flex justify-content-center align-items-center">
            { loading ? <Loading /> : null}
            <div>
                {!!result ? 
                    <Table 
                        key={ management }
                        result={ result }
                        headers={ caseStatuses }
                        group={ management }
                        tittleComplement= { getTittleComplement() }
                        container={ {width: '670px'} }
                    />
                : null}   
            </div>
            </div>
            <div className="text-center button-marging">
                <a 
                    href={`${process.env.REACT_APP_GRAFANA_ENDPOINT}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="btn btn-primary button-style text-light" >
                        <div className="button-text-style">
                            <div >Ver detalle</div>
                            <div className="arrow-icon"></div>
                        </div> 
                    </button>
                </a>
            </div>
       </Fragment>
       
     );  
}

export default Leader;