import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getdecrypt } from '../../bdb-services/GeneralService/globalService';
import { idmAnalystProdRol } from '../../../constants'
import './buttonReportOrder.scss';

const ButtonReportOrder = ({ relative=true}) => {
     const [rol, setRol] = useState(null)
     const history = useHistory();

    const controlNews = () =>{
        history.push("/orderDisbursement");
    }

    useEffect(() =>{
        if(rol === null) setRol(getdecrypt('rolUser'));
    },[])

    return ( 
        <Fragment>
            <div className='container-bt-OD'>
            { 
                rol === idmAnalystProdRol[1] ?
                <div className={' dly-relative-OD dly-novedades-OD'} data-cy="btn-OD" onClick={ controlNews }>
                    <div className='button-news-OD'></div>
                    <span className='dly-text-OD'>
                      Orden de desembolso
                    </span>
                </div>:
                null
            }
            </div>
        </Fragment>
    );
}

export default ButtonReportOrder;